export const MainImageTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
        >
            <path
                d="M22.0539 24.5H23.2197C24.3403 24.5 24.9006 24.5 25.2095 24.2638C25.4785 24.058 25.645 23.7434 25.6651 23.4029C25.6882 23.012 25.3774 22.5406 24.7558 21.5979L21.2822 16.3297C20.7686 15.5508 20.5118 15.1613 20.1881 15.0256C19.9051 14.9069 19.5871 14.9069 19.3041 15.0256C18.9804 15.1613 18.7236 15.5508 18.21 16.3297L17.3513 17.6321M22.0539 24.5L13.1869 11.5502C12.677 10.8055 12.422 10.4331 12.1035 10.3022C11.8249 10.1877 11.5131 10.1877 11.2344 10.3022C10.916 10.4331 10.661 10.8055 10.151 11.5502L3.28976 21.5708C2.64009 22.5196 2.31526 22.994 2.33376 23.3886C2.34986 23.7322 2.51512 24.0511 2.78536 24.26C3.09568 24.5 3.66635 24.5 4.80769 24.5H22.0539ZM24.3616 7C24.3616 8.933 22.8118 10.5 20.9 10.5C18.9882 10.5 17.4384 8.933 17.4384 7C17.4384 5.067 18.9882 3.5 20.9 3.5C22.8118 3.5 24.3616 5.067 24.3616 7Z"
                stroke={props.stroke}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
