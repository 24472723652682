export const LogDescriptionIcon = (props: IIconTooltipProps) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.1816 13.3737H8.49073M2.33398 13.6668L5.58783 12.4154C5.79595 12.3353 5.90001 12.2953 5.99737 12.243C6.08384 12.1966 6.16628 12.143 6.24382 12.0829C6.33112 12.0151 6.40996 11.9363 6.56763 11.7786L13.1816 5.16467C13.8292 4.517 13.8292 3.46692 13.1816 2.81925C12.5339 2.17158 11.4838 2.17158 10.8361 2.81925L4.22221 9.43318C4.06454 9.59085 3.9857 9.66969 3.91796 9.75699C3.85779 9.83453 3.80421 9.91697 3.75779 10.0034C3.70553 10.1008 3.66551 10.2049 3.58546 10.413L2.33398 13.6668ZM2.33398 13.6668L3.54077 10.5292C3.62713 10.3047 3.67031 10.1924 3.74437 10.141C3.80909 10.0961 3.88918 10.0791 3.96657 10.0938C4.05513 10.1108 4.14018 10.1958 4.31028 10.3659L5.63491 11.6905C5.80501 11.8606 5.89006 11.9457 5.90698 12.0343C5.92176 12.1117 5.90476 12.1917 5.85982 12.2565C5.8084 12.3305 5.69614 12.3737 5.47161 12.4601L2.33398 13.6668Z"
                    stroke={props.color}
                    strokeWidth="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}
