import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { Link, useNavigate } from "react-router"

import { useDispatch } from "react-redux"
import { EyeOffNewIcon } from "../../assets/svgs/eyeOffNew.svg"
import { ReactComponent as PasswordIcon } from "../../assets/svgs/password-icon.svg"
import { useInputValidations } from "../../hooks/inputValidations.hook"
import { FrontBubbleIcon } from "../common/frontBubble.component"
import { AuthInputElement } from "../elements/authInput.element copy"
import { ButtonElement } from "../elements/button.element"
import { Container } from "../elements/container.element"
import { PrimaryText } from "../elements/primaryText.element"

import { AuthActionCreator } from "../../actions/auth.action"
import { ROUTES_CONFIG } from "../../config/routes.config"

export const AuthSignup = () => {
    const { control, handleSubmit, watch, clearErrors } = useForm<ISignupInputProps>()
    // const { loading } = useSelector(RunTimeStateSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const validationConfig = useInputValidations()
    const newPassword = watch("newPassword")

    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showConfirmPassword, setConfirmPassword] = useState<boolean>(false)

    // const isLoading: boolean = useMemo(() => RuntimeHelper.isObtainToken(), [loading])
    const notifyError = (text: string) => toast.error(text)
    // let location = useLocation()
    // let params = new URLSearchParams(location.search)
    // let from = params.get("from") || "/"

    const onSubmit: SubmitHandler<ISignupInputProps> = (data, event?: React.BaseSyntheticEvent) => {
        event?.preventDefault()
        localStorage.setItem("userEmail", data.email as string)
        const payload: Partial<ISignupInputProps> = {
            email: data.email as string,
            password: data.newPassword as string,
            first_name: data.first_name as string,
            last_name: data.last_name as string,
        }
        dispatch(
            AuthActionCreator.userSignUp(
                payload,
                () => {
                    navigate(ROUTES_CONFIG.checkEmail)
                },
                (res: { message: string }) => {
                    notifyError(res.message)
                }
            )
        )
    }

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword)
    }
    const toggleConfirmPasswordVisibility = () => {
        setConfirmPassword((prevConfirmPassword) => !prevConfirmPassword)
    }

    return (
        <>
            <div className="w-[400px] bg-[#ECFDFF] p-[40px] pb-[32px] m-auto rounded-[16px] mt-[96px] custom-shadow">
                <div className="flex justify-center items-center">
                    <FrontBubbleIcon size="large" />
                </div>
                <div className="mt-[24px] flex justify-center mb-[8px]">
                    <PrimaryText typography="3xl" className="leading-[38px] text-gray-900">
                        Start your free trial
                    </PrimaryText>
                </div>
                <div className="flex justify-center items-center">
                    <PrimaryText size="md" className="text-gray-600">
                        Sign up in less than 2 minutes
                    </PrimaryText>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-[24px]">
                    <Container>
                        <AuthInputElement
                            name="first_name"
                            type="text"
                            reactHookControl={control}
                            reactHookValidations={{
                                required: validationConfig.required,
                                pattern: validationConfig.name,
                            }}
                            placeholder="First name"
                            className={"mb-[20px]"}
                        />
                        <AuthInputElement
                            name="last_name"
                            type="text"
                            reactHookControl={control}
                            reactHookValidations={{
                                required: validationConfig.required,
                                pattern: validationConfig.last_name,
                            }}
                            placeholder="Last name"
                            className={"mb-[20px]"}
                        />
                        <AuthInputElement
                            name="email"
                            type="email"
                            reactHookControl={control}
                            reactHookValidations={{
                                required: validationConfig.email.message,
                                pattern: validationConfig.email,
                            }}
                            placeholder="Email"
                            className={"mb-[20px]"}
                        />

                        <AuthInputElement
                            name="newPassword"
                            type={showPassword ? "text" : "password"}
                            defaultValue=""
                            reactHookControl={control}
                            reactHookValidations={{
                                required: validationConfig.newPassword.message,
                                pattern: validationConfig.newPassword,
                            }}
                            placeholder="Create a password"
                            postfix={
                                <div onClick={togglePasswordVisibility}>
                                    {showPassword ? <PasswordIcon /> : <EyeOffNewIcon />}
                                </div>
                            }
                            className={"mb-[20px]"}
                        />

                        <AuthInputElement
                            name="confirmPassword"
                            type={showConfirmPassword ? "text" : "password"}
                            defaultValue=""
                            reactHookControl={control}
                            reactHookValidations={{
                                validate: (value) => {
                                    if (value === newPassword) {
                                        clearErrors("confirmPassword")
                                    } else {
                                        return value !== newPassword && "Password does not match. Please try again."
                                    }
                                },
                                required: validationConfig.confirmPassword.message,
                            }}
                            placeholder="Confirm password"
                            postfix={
                                <div onClick={toggleConfirmPasswordVisibility}>
                                    {showConfirmPassword ? <PasswordIcon /> : <EyeOffNewIcon />}
                                </div>
                            }
                            className={"mb-[24px]"}
                        />
                        <div
                        // to={"/auth/check-email"}
                        >
                            <ButtonElement
                                textClass="text-[16px]"
                                type="submit"
                                // loading={isLoading}
                                size="medium"
                                className={
                                    "w-full mb-[24px] bg-gradient-to-br from-[#22CCEE] to-[#06AED4] rounded-[8px] h-[44px] text-white text-[16px]"
                                }
                            >
                                Get started
                            </ButtonElement>
                        </div>
                    </Container>
                    <div className="flex justify-center items-center gap-[4px] h-[20px]">
                        <PrimaryText size="small" weight="light">
                            Already have an account?
                        </PrimaryText>
                        <Link to={ROUTES_CONFIG.welcome} viewTransition>
                            <ButtonElement
                                viewType="only-text"
                                size="medium"
                                textClass="chrome:mb-[4px] safari:mb-[4px]"
                            >
                                Log in
                            </ButtonElement>
                        </Link>
                    </div>
                </form>
            </div>
        </>
    )
}
