import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
import SelectInputElement from "../../../elements/selectInput.component"
import { AMAZON_DOMAINS } from "../../../marketing/domains.config"

const AddTrackpackStepInput: React.FC<AddTrackPackProps> = ({
    asinHeaderText,
    asinHeaderDescription,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    handlePrev,
}) => {
    const { addAsinToTrackpack } = useSelector(ProductStateSelector) as {
        addAsinToTrackpack: { id: string; region: string }
    }

    const { control, handleSubmit, setValue, getValues } = useForm()
    const dispatch = useDispatch()
    const data = useSelector(ProductStateSelector)
    const [defaultOption, setDefaultOption] = useState(
        AMAZON_DOMAINS.find((i) => i.value === addAsinToTrackpack?.region) || null
    )

    const selected_country = AMAZON_DOMAINS?.find((val) => {
        return val?.value === data?.selectedTrackpackPayload?.amazon_tld
    })

    const onSubmit = () => {
        const values = getValues()
        const value = values?.amazon_tId?.value || values?.amazon_tId
        dispatch(ProductsActionCreator.selectedTrackpackPayload({ amazon_tld: value }))
        handleNext && handleNext()
    }

    useEffect(() => {
        if (selected_country) {
            setValue("amazon_tId", selected_country.value)
        }
    }, [selected_country, setValue])

    useEffect(() => {
        if (addAsinToTrackpack?.region && !data.selectedTrackpackPayload?.amazon_tld) {
            dispatch(ProductsActionCreator.selectedTrackpackPayload({ amazon_tld: addAsinToTrackpack.region }))
            setDefaultOption(AMAZON_DOMAINS.find((i) => i.value === addAsinToTrackpack?.region) || null)
        }
    }, [addAsinToTrackpack?.region, data.selectedTrackpackPayload?.amazon_tld, dispatch])

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText weight="medium" size="lg" className="text-gray-700 text-[24px] font-medium mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>

            <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[8px] ml-[6px]">
                {asinHeaderDescription}
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} className=" mt-[36px]">
                <>
                    {" "}
                    <SelectInputElement
                        name="amazon_tId"
                        reactHookControl={control}
                        reactHookValidations={{ required: true }}
                        defaultValue={defaultOption}
                        countries={AMAZON_DOMAINS}
                        placeholder="Choose market..."
                        isOptionImage={true}
                        isInputImage={true}
                        errorMessage="Please choose your market to continue "
                    />
                    <div className="flex justify-between items-center mt-[24px]">
                        <div onClick={handlePrev} className="flex items-center">
                            <BackChevronIcon />
                            <PrimaryText className="text-[18px]  cursor-pointer text-[#667085]">Back</PrimaryText>
                        </div>
                        <ButtonElement
                            type="submit"
                            size="large"
                            className={"w-[99px] bg-[#A5F0FC] rounded-[12px] h-[44px] text-[#0E7090] text-[14px]"}
                        >
                            {buttonText}
                        </ButtonElement>
                    </div>
                </>
            </form>
        </>
    )
}

export default AddTrackpackStepInput
