export const FlipArrowIcon = (props: IArrowProps) => {
    return (
        <>
            <svg
                onClick={props.onClick}
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.5 4.5H8.25C9.49264 4.5 10.5 5.50736 10.5 6.75C10.5 7.99264 9.49264 9 8.25 9H6M1.5 4.5L3.5 2.5M1.5 4.5L3.5 6.5"
                    stroke={props.color ?? "#667085"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
