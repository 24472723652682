import { useSelector } from "react-redux"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { AuthStateSelector } from "../../../../selectors/authState.selector"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
const AdditionalTrackpackStepFiveView: React.FC<AddTrackPackProps> = ({
    asinHeaderText,
    icon,
    handleCloseDialog,
    handlePrev,
    buttonText,
    asinsCount,
}) => {
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const { selectedDropdownList, selectedTrackpackData } = useSelector(ProductStateSelector)

    const selectedTrackpackID = localStorage.getItem("selectedTrackpackID")
    const selectedObject =
        selectedDropdownList?.trackpacks?.find(
            (item: { metadata: { id: number | string } }) => item.metadata.id === selectedTrackpackID
        ) || selectedTrackpackData

    const ownerName =
        getAccessLevel?.first_name && getAccessLevel?.last_name
            ? getAccessLevel?.first_name
            : getAccessLevel && getAccessLevel?.username
    return (
        <>
            <div className="flex items-center justify-between">
                {icon}
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-[#344054] text-[24px] font-medium mt-[24px] ml-[6px]">
                {asinHeaderText}{" "}
                <PrimaryText className="text-[#344054] text-[24px] font-medium inline capitalize">
                    {ownerName}
                </PrimaryText>
                !
            </PrimaryText>
            <PrimaryText className="text-[16px] font-[300] mt-[16px] text-[#667085] mx-[6px] ">
                I’ve added {asinsCount} {asinsCount! > 1 ? "ASINs" : "ASIN"} to your Trackpack called&nbsp;
                <PrimaryText className="text-[16px] font-[500] inline  text-[#667085] ">
                    {selectedObject?.metadata?.name}.
                </PrimaryText>
                &nbsp;I’ll alert you as soon as {asinsCount! > 1 ? "they’re" : "it’s"} ready to view.
            </PrimaryText>

            <div className="flex justify-between  items-center mt-[48px]">
                <div className="flex items-center">
                    {/* <BackChevronIcon />
                    <PrimaryText onClick={handlePrev} className="text-[18px]  cursor-pointer text-[#667085]">
                        Back
                    </PrimaryText> */}
                </div>

                <ButtonElement
                    type="submit"
                    size="large"
                    onClick={handleCloseDialog}
                    textClass="text-[18px] leading-[28px]"
                    className={"w-[99px] bg-[#A5F0FC] rounded-[12px] px-[30px] py-[8px] text-[#0E7090] "}
                >
                    {buttonText}
                </ButtonElement>
            </div>
        </>
    )
}
export default AdditionalTrackpackStepFiveView
