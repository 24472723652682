import { useLocation, useNavigate } from "react-router"

import { EmailVerify } from "../../assets/svgs/emailVerifiyIcon.svg"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { ButtonElement } from "../elements/button.element"
import { PrimaryText } from "../elements/primaryText.element"

export const VerifiedEmail = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const isRecoverPassword = location.state?.isRecoverPassword

    const handleLoginClick = () => {
        navigate(ROUTES_CONFIG.welcome)
    }

    return (
        <>
            <div className="w-[400px] bg-[#ECFDFF] p-[40px] pb-[32px] m-auto rounded-[16px] mt-[96px] custom-shadow">
                <div className="flex justify-center items-center">
                    <EmailVerify />
                </div>
                <div className="mt-[24px] flex justify-center  mb-[8px]">
                    <PrimaryText typography={"3xl"} className="leading-[38px] text-gray-900">
                        {isRecoverPassword ? "Success!" : "Email verified"}
                    </PrimaryText>
                </div>

                <PrimaryText size={"md"} weight={"light"} className="text-gray-600 text-center">
                    {isRecoverPassword ? (
                        <>
                            <PrimaryText size={"md"} weight={"light"} className="text-gray-600 text-center">
                                Your password has been reset.
                            </PrimaryText>
                            <PrimaryText size={"md"} weight={"light"} className="text-gray-600 text-center">
                                Click below to log in.
                            </PrimaryText>
                        </>
                    ) : (
                        "You’re all set. Click below to log in."
                    )}
                </PrimaryText>

                <ButtonElement
                    size="medium"
                    className={
                        "w-full mt-[40px] bg-gradient-to-br from-[#22CCEE] to-[#06AED4] rounded-[8px] h-[44px] text-white text-[16px]"
                    }
                    onClick={handleLoginClick}
                >
                    Log in
                </ButtonElement>
            </div>
        </>
    )
}
