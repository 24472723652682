export const PaginationForwardArrow = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.16663 10.0003H15.8333M15.8333 10.0003L9.99996 4.16699M15.8333 10.0003L9.99996 15.8337"
                stroke="#344054"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
