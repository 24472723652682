import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const SearchpackHubCentralSkeletonLoader = ({
    length,
    isAsinNumber,
    trackingAsin,
    isTrackpack,
    ownProduct,
    sortedTrackingsStatuses,
    isStatic,
}: CardSkeletonLoaderProps) => {
    const skeletonBaseColor = "#EAECF0"
    const skeletonHighlightColor = "#F0F0F0"

    return (
        <table className="w-full bg-white rounded-[10px]">
            <thead className="bg-gray-200 border-b border-gray-200 w-full">
                <tr className="">
                    <th className="pl-[20px] py-[12px] text-left span-child">
                        <div className="">
                            <Skeleton baseColor="#D0D5DD" count={1} width={152} height={12} className="rounded-[4px]" />
                        </div>
                    </th>
                    <th className="text-right">
                        <Skeleton baseColor="#D0D5DD" count={1} width={84} height={12} className="rounded-[4px]" />
                    </th>
                    <th className="w-[66px]">
                        <td className="w-[66px]"></td>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr className="border-b last:border-b-0  border-gray-200  ">
                    <td className="pl-[20px] py-[12px] text-left span-child">
                        <Skeleton baseColor="#EAECF0" count={1} width={152} height={12} className="rounded-[4px]" />
                    </td>
                    <td className="pl-[20px] py-[12px] text-right">
                        <Skeleton baseColor="#EAECF0" count={1} width={84} height={12} className="rounded-[4px]" />
                    </td>
                </tr>
                <tr className="border-b last:border-b-0  border-gray-200  ">
                    <td className="pl-[20px] py-[12px] text-left span-child">
                        <Skeleton baseColor="#EAECF0" count={1} width={152} height={12} className="rounded-[4px]" />
                    </td>
                    <td className="pl-[20px] py-[12px] text-right">
                        <Skeleton baseColor="#EAECF0" count={1} width={84} height={12} className="rounded-[4px]" />
                    </td>
                </tr>
                <tr className="border-b last:border-b-0  border-gray-200  ">
                    <td className="pl-[20px] py-[12px] text-left span-child">
                        <Skeleton baseColor="#EAECF0" count={1} width={152} height={12} className="rounded-[4px]" />
                    </td>
                    <td className="pl-[20px] py-[12px] text-right">
                        <Skeleton baseColor="#EAECF0" count={1} width={84} height={12} className="rounded-[4px]" />
                    </td>
                </tr>
                <tr className="border-b last:border-b-0  border-gray-200  ">
                    <td className="pl-[20px] py-[12px] text-left span-child">
                        <Skeleton baseColor="#EAECF0" count={1} width={152} height={12} className="rounded-[4px]" />
                    </td>
                    <td className="pl-[20px] py-[12px] text-right">
                        <Skeleton baseColor="#EAECF0" count={1} width={84} height={12} className="rounded-[4px]" />
                    </td>
                </tr>
                <tr className="border-b last:border-b-0  border-gray-200  ">
                    <td className="pl-[20px] py-[12px] text-left span-child">
                        <Skeleton baseColor="#EAECF0" count={1} width={152} height={12} className="rounded-[4px]" />
                    </td>
                    <td className="pl-[20px] py-[12px] text-right">
                        <Skeleton baseColor="#EAECF0" count={1} width={84} height={12} className="rounded-[4px]" />
                    </td>
                </tr>
                <tr className="border-b last:border-b-0  border-gray-200  ">
                    <td className="pl-[20px] py-[12px] text-left span-child">
                        <Skeleton baseColor="#EAECF0" count={1} width={152} height={12} className="rounded-[4px]" />
                    </td>
                    <td className="pl-[20px] py-[12px] text-right">
                        <Skeleton baseColor="#EAECF0" count={1} width={84} height={12} className="rounded-[4px]" />
                    </td>
                </tr>
                <tr className="border-b last:border-b-0  border-gray-200  ">
                    <td className="pl-[20px] py-[12px] text-left span-child">
                        <Skeleton baseColor="#EAECF0" count={1} width={152} height={12} className="rounded-[4px]" />
                    </td>
                    <td className="pl-[20px] py-[12px] text-right">
                        <Skeleton baseColor="#EAECF0" count={1} width={84} height={12} className="rounded-[4px]" />
                    </td>
                </tr>
                <tr className="border-b last:border-b-0  border-gray-200  h-[44px] ">
                    <td className="pl-[20px] py-[12px] text-left span-child">
                        <Skeleton baseColor="#EAECF0" count={1} width={152} height={12} className="rounded-[4px]" />
                    </td>
                    <td className="pl-[20px] py-[12px] text-right">
                        <Skeleton baseColor="#EAECF0" count={1} width={84} height={12} className="rounded-[4px]" />
                    </td>
                </tr>
                <tr className="border-b last:border-b-0  border-gray-200  ">
                    <td className="pl-[20px] py-[12px] text-left span-child">
                        <Skeleton baseColor="#EAECF0" count={1} width={152} height={12} className="rounded-[4px]" />
                    </td>
                    <td className="pl-[20px] py-[12px] text-right">
                        <Skeleton baseColor="#EAECF0" count={1} width={84} height={12} className="rounded-[4px]" />
                    </td>
                </tr>
                <tr className="border-b last:border-b-0  border-gray-200  ">
                    <td className="pl-[20px] py-[12px] text-left span-child">
                        <Skeleton baseColor="#EAECF0" count={1} width={152} height={12} className="rounded-[4px]" />
                    </td>
                    <td className="pl-[20px] py-[12px] text-right">
                        <Skeleton baseColor="#EAECF0" count={1} width={84} height={12} className="rounded-[4px]" />
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default SearchpackHubCentralSkeletonLoader
