import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { SearchPackIcon } from "../../../assets/svgs/searchpack.svg"

const SearchpackHubCardSkeletonLoader = ({
    length,
    isAsinNumber,
    trackingAsin,
    isTrackpack,
    ownProduct,
    sortedTrackingsStatuses,
    isStatic,
}: CardSkeletonLoaderProps) => {
    const skeletonBaseColor = "#EAECF0"
    const skeletonHighlightColor = "#F0F0F0"

    return (
        <>
            <></>
            {isTrackpack &&
                length &&
                Array.from({ length })?.map((_, index) => (
                    <div
                        key={index}
                        className="flex gap-2 p-[10px] pr-[8px] border border-gray-200 bg-gray-50 rounded-[10px] w-[278px] mb-[8px] custom-span"
                    >
                        <div className="flex items-start gap-x-[8px]" id="searchpack-hub-skelton">
                            <div className="bg-[#EAECF0] w-[40px] h-[40px] flex items-center justify-center rounded-[6px]">
                                <SearchPackIcon color={"#fff"} width={28} height={28} />
                            </div>
                            <div className=" flex flex-col mt-[6px] gap-[6px] justify-start min-w-[178px]">
                                <Skeleton
                                    highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                    baseColor={skeletonBaseColor}
                                    count={1}
                                    className="mb-[6px] "
                                    width={128}
                                    height={12}
                                    borderRadius={6}
                                />
                                <Skeleton
                                    highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                    baseColor={skeletonBaseColor}
                                    count={1}
                                    width={160}
                                    className="mb-[6px]"
                                    height={12}
                                    borderRadius={6}
                                />
                            </div>
                            <div className="trash-icon">
                                <Skeleton
                                    highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                    baseColor={skeletonBaseColor}
                                    count={1}
                                    width={24}
                                    className="rounded-full !h-[24px]"
                                    height={24}
                                />
                            </div>
                        </div>
                    </div>
                ))}
        </>
    )
}

export default SearchpackHubCardSkeletonLoader
