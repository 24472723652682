export const NewAddAsinsIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.3333 12.2502V7.9335C23.3333 5.97331 23.3333 4.99322 22.9518 4.24453C22.6163 3.58596 22.0808 3.05053 21.4223 2.71497C20.6736 2.3335 19.6935 2.3335 17.7333 2.3335H10.2666C8.30644 2.3335 7.32635 2.3335 6.57766 2.71497C5.91909 3.05053 5.38366 3.58596 5.0481 4.24453C4.66663 4.99322 4.66663 5.97331 4.66663 7.9335V20.0668C4.66663 22.027 4.66663 23.0071 5.0481 23.7558C5.38366 24.4144 5.91909 24.9498 6.57766 25.2854C7.32635 25.6668 8.30644 25.6668 10.2666 25.6668H14M21 24.5002V17.5002M17.5 21.0002H24.5"
                stroke="#0E7090"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
