export const AccountUserIcon = (props: IArrowProps) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 6.66699L12.5 6.66699M12.5 6.66699C12.5 8.0477 13.6193 9.16699 15 9.16699C16.3807 9.16699 17.5 8.0477 17.5 6.66699C17.5 5.28628 16.3807 4.16699 15 4.16699C13.6193 4.16699 12.5 5.28628 12.5 6.66699ZM7.5 13.3337L17.5 13.3337M7.5 13.3337C7.5 14.7144 6.38071 15.8337 5 15.8337C3.61929 15.8337 2.5 14.7144 2.5 13.3337C2.5 11.9529 3.61929 10.8337 5 10.8337C6.38071 10.8337 7.5 11.9529 7.5 13.3337Z"
                stroke={props.color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
