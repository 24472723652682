export const ResetPasswordIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="56" height="56" rx="12" fill="url(#paint0_linear_2558_39952)" />
            <path
                d="M33.8332 25.6667V23.3333C33.8332 20.1117 31.2215 17.5 27.9998 17.5C24.7782 17.5 22.1665 20.1117 22.1665 23.3333V25.6667M27.9998 30.9167V33.25M24.2665 38.5H31.7332C33.6934 38.5 34.6734 38.5 35.4221 38.1185C36.0807 37.783 36.6161 37.2475 36.9517 36.589C37.3332 35.8403 37.3332 34.8602 37.3332 32.9V31.2667C37.3332 29.3065 37.3332 28.3264 36.9517 27.5777C36.6161 26.9191 36.0807 26.3837 35.4221 26.0481C34.6734 25.6667 33.6934 25.6667 31.7332 25.6667H24.2665C22.3063 25.6667 21.3262 25.6667 20.5775 26.0481C19.919 26.3837 19.3835 26.9191 19.048 27.5777C18.6665 28.3264 18.6665 29.3065 18.6665 31.2667V32.9C18.6665 34.8602 18.6665 35.8403 19.048 36.589C19.3835 37.2475 19.919 37.783 20.5775 38.1185C21.3262 38.5 22.3063 38.5 24.2665 38.5Z"
                stroke="white"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2558_39952"
                    x1="0"
                    y1="0"
                    x2="67.1038"
                    y2="34.2366"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#22CCEE" />
                    <stop offset="1" stop-color="#06AED4" />
                </linearGradient>
            </defs>
        </svg>
    )
}
