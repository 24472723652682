export const FilledStar = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.76917 1.05498C4.85457 0.849659 5.14543 0.849659 5.23083 1.05498L6.26388 3.53872C6.29988 3.62528 6.38128 3.68442 6.47473 3.69191L9.15613 3.90688C9.3778 3.92465 9.46768 4.20128 9.29879 4.34595L7.25585 6.09595C7.18465 6.15694 7.15356 6.25263 7.17531 6.34382L7.79946 8.96042C7.85106 9.17672 7.61575 9.34769 7.42597 9.23177L5.13031 7.8296C5.05031 7.78073 4.94969 7.78073 4.86969 7.8296L2.57403 9.23177C2.38425 9.34769 2.14894 9.17672 2.20054 8.96042L2.82469 6.34382C2.84644 6.25263 2.81535 6.15694 2.74415 6.09595L0.701207 4.34595C0.532322 4.20128 0.622204 3.92465 0.843867 3.90688L3.52527 3.69191C3.61872 3.68442 3.70012 3.62528 3.73612 3.53872L4.76917 1.05498Z"
            fill="#FDB022"
            stroke="#DC6803"
            strokeWidth="0.6"
        />
    </svg>
)

export const EmptyStar = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.76917 1.05498C4.85457 0.849659 5.14543 0.849659 5.23083 1.05498L6.26388 3.53872C6.29988 3.62528 6.38128 3.68442 6.47473 3.69191L9.15613 3.90688C9.3778 3.92465 9.46768 4.20128 9.29879 4.34595L7.25585 6.09595C7.18465 6.15694 7.15356 6.25263 7.17531 6.34382L7.79946 8.96042C7.85106 9.17672 7.61575 9.34769 7.42597 9.23177L5.13031 7.8296C5.05031 7.78073 4.94969 7.78073 4.86969 7.8296L2.57403 9.23177C2.38425 9.34769 2.14894 9.17672 2.20054 8.96042L2.82469 6.34382C2.84644 6.25263 2.81535 6.15694 2.74415 6.09595L0.701207 4.34595C0.532322 4.20128 0.622204 3.92465 0.843867 3.90688L3.52527 3.69191C3.61872 3.68442 3.70012 3.62528 3.73612 3.53872L4.76917 1.05498Z"
            fill="none"
            stroke="#DC6803"
            strokeWidth="0.6"
        />
    </svg>
)

export const PartialStar = ({ fillPercentage }: any) => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath id="starClip">
                <path d="M4.76917 1.05498C4.85457 0.849659 5.14543 0.849659 5.23083 1.05498L6.26388 3.53872C6.29988 3.62528 6.38128 3.68442 6.47473 3.69191L9.15613 3.90688C9.3778 3.92465 9.46768 4.20128 9.29879 4.34595L7.25585 6.09595C7.18465 6.15694 7.15356 6.25263 7.17531 6.34382L7.79946 8.96042C7.85106 9.17672 7.61575 9.34769 7.42597 9.23177L5.13031 7.8296C5.05031 7.78073 4.94969 7.78073 4.86969 7.8296L2.57403 9.23177C2.38425 9.34769 2.14894 9.17672 2.20054 8.96042L2.82469 6.34382C2.84644 6.25263 2.81535 6.15694 2.74415 6.09595L0.701207 4.34595C0.532322 4.20128 0.622204 3.92465 0.843867 3.90688L3.52527 3.69191C3.61872 3.68442 3.70012 3.62528 3.73612 3.53872L4.76917 1.05498Z" />
            </clipPath>
        </defs>
        <rect width={`${fillPercentage}%`} height="10" fill="#FDB022" clipPath="url(#starClip)" />
        <path
            d="M4.76917 1.05498C4.85457 0.849659 5.14543 0.849659 5.23083 1.05498L6.26388 3.53872C6.29988 3.62528 6.38128 3.68442 6.47473 3.69191L9.15613 3.90688C9.3778 3.92465 9.46768 4.20128 9.29879 4.34595L7.25585 6.09595C7.18465 6.15694 7.15356 6.25263 7.17531 6.34382L7.79946 8.96042C7.85106 9.17672 7.61575 9.34769 7.42597 9.23177L5.13031 7.8296C5.05031 7.78073 4.94969 7.78073 4.86969 7.8296L2.57403 9.23177C2.38425 9.34769 2.14894 9.17672 2.20054 8.96042L2.82469 6.34382C2.84644 6.25263 2.81535 6.15694 2.74415 6.09595L0.701207 4.34595C0.532322 4.20128 0.622204 3.92465 0.843867 3.90688L3.52527 3.69191C3.61872 3.68442 3.70012 3.62528 3.73612 3.53872L4.76917 1.05498Z"
            fill="none"
            stroke="#DC6803"
            strokeWidth="0.6"
        />
    </svg>
)
