import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
import SemiAutomatedInputElement from "../../../elements/semiAutomatedInputElement"

const SemiAutomatedStepOneView: React.FC<AddTrackPackProps> = ({
    asinHeaderText,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    isPrevButton,
    handlePrev,
}) => {
    const { control, handleSubmit, watch, setValue } = useForm()
    const dispatch = useDispatch()
    const total_asins = watch("total_asins")
    const { addAsinToTrackpack } = useSelector(ProductStateSelector)

    const extractAmazonTLD = (url: string) => {
        const tldRegex = /^(?:https?:\/\/)?(?:www\.)?amazon\.([a-z.]{2,})(?:\/.*)?$/
        const match = url.match(tldRegex)
        return match ? match[1] : undefined
    }

    const onSubmit = () => {
        if (total_asins) {
            const hasURL = typeof total_asins === "object" && total_asins.url !== undefined
            const asinValue = typeof total_asins === "string" ? total_asins : total_asins.asin

            const url = typeof total_asins === "object" ? total_asins.url : undefined
            const amazontld = url && extractAmazonTLD(url)

            if (!asinValue) {
                console.error("ASIN value is missing.")
                return
            }

            if (hasURL) {
                dispatch(
                    ProductsActionCreator.selectedTrackpackPayload({ total_asins: [asinValue], amazon_tld: amazontld })
                )
                handleNext && handleNext("three")
            } else {
                dispatch(ProductsActionCreator.selectedTrackpackPayload({ total_asins: [asinValue] }))
                handleNext && handleNext("two")
            }
        }
    }

    useEffect(() => {
        if (addAsinToTrackpack?.asin) {
            setValue("total_asins", { asin: addAsinToTrackpack.asin })
        }
    }, [addAsinToTrackpack?.asin, setValue])


    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-gray-700 text-[24px] font-medium mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>

            <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[4px] ml-[6px]">
                Let’s start building your new Trackpack together.
                <PrimaryText size="sm-medium" weight="light" className=" text-gray-700">
                    Simply give me an ASIN or URL and hit Tab to enter.
                </PrimaryText>
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} className=" mt-[36px]">
                <>
                    <SemiAutomatedInputElement
                        name="total_asins"
                        className=" border-[#D0D5DD] rounded-[12px] placeholder:italic tag-input"
                        placeholder="E.g. B08S6XWCN5 or www.amazon.com/DP/B08S6XWCN5"
                        reactHookControl={control}
                    />

                    <div className="flex justify-between  items-center mt-[48px]">
                        <div className="flex items-center">
                            <BackChevronIcon />
                            <PrimaryText onClick={handlePrev} className="text-[18px]  cursor-pointer text-[#667085]">
                                Back
                            </PrimaryText>
                        </div>

                        <ButtonElement
                            size="large"
                            textClass="text-[18px] leading-[28px]"
                            className={"w-[99px] bg-[#A5F0FC] rounded-[12px] px-[30px] py-[8px] text-[#0E7090] "}
                        >
                            {buttonText}
                        </ButtonElement>
                    </div>
                </>
            </form>
        </>
    )
}

export default SemiAutomatedStepOneView
