import { useVirtualizer } from "@tanstack/react-virtual"
import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useDispatch, useSelector } from "react-redux"
import { Tooltip } from "react-tooltip"
import type { Product } from "../../../@types/app/data"
import { SearchpackActionCreator } from "../../../actions/searchpack.action"
import { FilterIcon } from "../../../assets/svgs/filterIcon.svg"
import { ReactComponent as RatingIcon } from "../../../assets/svgs/rating-icon.svg"
import { img_url } from "../../../config/dashboard.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useLoadingStatus } from "../../../hooks/useLoadingStatus.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../selectors/searchpack.selector"
import FilterDropdown from "../../common/filterDrowpdown.component"
import { ProductCard } from "../../common/productCard.component"
import CardSkeletonLoader from "../../common/skeletonLoader/cardSkeleton.component"
import { PrimaryText } from "../../elements/primaryText.element"
import CardCustomTooltipContent from "../tooltip-alerts/cardTooltipAlerts.component"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

export const SearchpackAsinCard = (props: IAsinContrastCard) => {
    const sidebarRef = useRef<HTMLDivElement>(null)
    const [showBorder, setShowBorder] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedDropdownFilter, setSelectedDropdownFilter] = useState<string>("All")
    const { ranksnapshotData, selectedSearchpackLastDataTime, selectedSearchpackValues } =
        useSelector(SearchpackStateSelector)

    const { loading } = useSelector(RunTimeStateSelector)
    const isGetRankSnapshotDataLoading: boolean = useLoadingStatus(loading, RuntimeHelper.getRanksnapshotDataLoading())
    const isGetRankTrackerDataLoading: boolean = useLoadingStatus(loading, RuntimeHelper.getRanktrackerDataLoading())

    const dispatch = useDispatch()
    const [selectedProduct, setSelectedProduct] = useState<any[]>([])

    useEffect(() => {
        if (isGetRankSnapshotDataLoading) {
            dispatch(
                SearchpackActionCreator.selectedSearchpackProduct({
                    selectedProducts: [],
                })
            )
        }

        if (
            selectedSearchpackLastDataTime?.status === "ACTIVE" &&
            ranksnapshotData &&
            ranksnapshotData.search_card_data &&
            ranksnapshotData.search_card_data.length > 0
        ) {
            const productsToSelect = ranksnapshotData?.search_card_data?.slice(0, 5)
            setSelectedProduct(productsToSelect)
            dispatch(
                SearchpackActionCreator.selectedSearchpackProduct({
                    selectedProducts: productsToSelect,
                })
            )
        } else {
            dispatch(
                SearchpackActionCreator.selectedSearchpackProduct({
                    selectedProducts: [],
                })
            )
        }
    }, [ranksnapshotData, isGetRankSnapshotDataLoading, dispatch, selectedSearchpackLastDataTime?.status])

    // Function to get the original index of an item in ranksnapshotData
    const getOriginalIndex = (asin: string): number => {
        return ranksnapshotData?.search_card_data?.findIndex((item) => item.asin === asin) ?? -1
    }

    // Maintain the original order when selecting/deselecting products

    const SelectedSearchpackProductFunc = (item: Product, index: number | null | undefined): void => {
        const isSelectedIndex: number = selectedProduct.findIndex((e: Product) => e?.asin === item?.asin)
        let updatedProducts: Product[]
        let selectedItemIndex: number | null = null

        if (isSelectedIndex !== -1) {
            // Remove item if already selected
            updatedProducts = selectedProduct.filter((e: Product) => e?.asin !== item?.asin)
        } else {
            // Add item at its original position
            const originalIndex: number = getOriginalIndex(item.asin)
            const insertIndex: number = selectedProduct.findIndex(
                (p: Product) => p?.asin && getOriginalIndex(p.asin) > originalIndex
            )

            updatedProducts = [...selectedProduct]
            if (insertIndex === -1) {
                updatedProducts.push(item)
                selectedItemIndex = updatedProducts.length - 1
            } else {
                updatedProducts.splice(insertIndex, 0, item)
                selectedItemIndex = insertIndex
            }
        }

        setSelectedProduct(updatedProducts)
        dispatch(
            SearchpackActionCreator.selectedSearchpackProduct({
                selectedProducts: updatedProducts,
                selectedIndex: selectedItemIndex, // Include the index of added item
            })
        )
    }

    useEffect(() => {
        const handleScroll = () => {
            if (sidebarRef.current) {
                setShowBorder(sidebarRef.current.scrollTop > 0)
            }
        }

        const currentSidebarRef = sidebarRef.current
        if (currentSidebarRef) {
            currentSidebarRef.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (currentSidebarRef) {
                currentSidebarRef.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    const filteredData = useMemo(() => {
        if (selectedSearchpackLastDataTime?.status === "ARCHIVED") {
            return []
        }

        let data: IRankSnapshotProduct[] = []
        if (selectedDropdownFilter === "All") {
            data = ranksnapshotData?.search_card_data || []
        } else if (selectedDropdownFilter === "Tracked") {
            data = ranksnapshotData?.search_card_data?.filter((card: any) => card.is_tracked) || []
        } else if (selectedDropdownFilter === "Untracked") {
            data = ranksnapshotData?.search_card_data?.filter((card: any) => !card.is_tracked) || []
        }

        setSelectedProduct(data?.slice(0, 5))
        dispatch(SearchpackActionCreator.selectedSearchpackProduct({ selectedProducts: data?.slice(0, 5) }))
        return data
    }, [selectedSearchpackLastDataTime?.status, selectedDropdownFilter, dispatch, ranksnapshotData?.search_card_data])

    useEffect(() => {
        setSelectedDropdownFilter("All")
    }, [selectedSearchpackValues?.selectedSearchpackID])

    const virtualizer = useVirtualizer({
        count: filteredData.length,
        getScrollElement: () => sidebarRef.current,
        estimateSize: () => 120,
        overscan: 5,
    })

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleItemClick = (item: string) => {
        setSelectedDropdownFilter(item)
        setIsOpen(false)
    }

    const dropdownOptions = [
        { label: "All", value: "All" },
        { label: "Tracked", value: "Tracked" },
        { label: "Untracked", value: "Untracked" },
    ]

    return (
        <div className="w-full">
            <div
                style={{
                    height: "calc(100vh - 298px)",
                }}
                className="relative bg-gray-50 w-full py-[12px] px-[12px] rounded-2xl outline outline-1 outline-gray-50 justify-start items-start inline-flex flex-col pb-0"
            >
                <div
                    className={`w-full flex justify-between gap-[4px] items-center  py-[6.5px] pl-[8px] mr-[2px]   ${
                        showBorder ? "border-b border-gray-200 " : ""
                    }`}
                >
                    <PrimaryText size="md" weight={"medium"} className={"text-[#088AB2]"}>
                        ASINs
                    </PrimaryText>
                    <div className=" relative" onClick={toggleDropdown}>
                        <div
                            className="cursor-pointer outline-none"
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-offset={-3}
                            data-tooltip-class-name={"!ml-[28px]"}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className="" label="Sort ASINs" />
                            )}
                        >
                            <FilterIcon />
                        </div>
                        {isOpen && (
                            <FilterDropdown
                                options={dropdownOptions}
                                selectedItem={selectedDropdownFilter}
                                handleItemClick={handleItemClick}
                                className="!w-[120px]"
                            />
                        )}
                    </div>
                </div>
                <div
                    ref={sidebarRef}
                    className={`w-[305px] ${
                        isGetRankSnapshotDataLoading ? "overflow-y-hidden" : "overflow-y-scroll"
                    } max-h-full productCardScroll`}
                >
                    <div className="flex flex-col gap-[8px] max-h-full" style={{ height: "calc(100vh - 346px)" }}>
                        {isGetRankSnapshotDataLoading || isGetRankTrackerDataLoading ? (
                            <CardSkeletonLoader length={12} isTrackpack={false} />
                        ) : ranksnapshotData?.search_card_data?.length === 0 ||
                          ranksnapshotData?.search_card_data === undefined ? (
                            <CardSkeletonLoader length={12} isStatic={true} isTrackpack={false} />
                        ) : filteredData.length > 0 ? (
                            <div
                                style={{
                                    height: virtualizer.getTotalSize(),
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {virtualizer.getVirtualItems().map((virtualItem, index) => {
                                    const item = filteredData[virtualItem.index]
                                    const amazonTld = selectedSearchpackLastDataTime?.amazon_tld ?? "com"

                                    return (
                                        <div
                                            key={virtualItem.key}
                                            data-index={virtualItem.index}
                                            ref={virtualizer.measureElement}
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                transform: `translateY(${virtualItem.start}px)`,
                                            }}
                                        >
                                            <div
                                                onClick={() => SelectedSearchpackProductFunc(item, index)}
                                                className="last:pb-[8px]"
                                            >
                                                <ProductCard
                                                    amazon_tld={amazonTld}
                                                    id={item.asin}
                                                    src={item.image_filename ? `${img_url}${item.image_filename}` : ""}
                                                    altText="Asin dress"
                                                    title={item.title ?? ""}
                                                    price={item.price.value ?? 0}
                                                    rating={item.rating ?? 0}
                                                    ratingIcon={<RatingIcon />}
                                                    ratingsCount={item.ratings_total ?? 0}
                                                    color={item.color}
                                                    currency={item.price.currency ?? "USD"}
                                                    selectedProduct={selectedProduct}
                                                    ownProduct={item.is_own}
                                                    trackedProduct={item.is_tracked}
                                                    isSearchrankCard={true}
                                                    searchpackData={selectedSearchpackLastDataTime}
                                                    searchpackTab={"rank-snapshot"}
                                                    selectedDropdownFilter={selectedDropdownFilter}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <PrimaryText
                                size="xs"
                                weight={"light"}
                                className={"text-gray-600 italic mb-[6px] ml-[8px] mt-[12px]"}
                            >
                                No ASINs added to existing Searchpack
                            </PrimaryText>
                        )}
                    </div>

                    <Tooltip
                        id="tracking-off-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-off-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-error-card-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEE4E2",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow red"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-error-card-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-success-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#DCFAE6",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow green"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content)
                            return <CardCustomTooltipContent id="tracking-success-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="status-warning-tooltip"
                        style={{
                            padding: "8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            display: "inline-block",
                            zIndex: 10000,
                            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                        }}
                        clickable
                        place="top"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            return <CardCustomTooltipContent id="status-warning-tooltip" dataWarnings={content} />
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
