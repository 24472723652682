export const StockTooltipIcon = (props: IIconTooltipProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
        >
            <path
                d="M25.0181 8.40991L13.9996 13.9996M13.9996 13.9996L2.98111 8.40991M13.9996 13.9996L13.9997 25.2449M8.81449 5.24967L19.1849 11.083M25.6663 18.8038V9.19553C25.6663 8.78995 25.6663 8.58715 25.6009 8.40628C25.543 8.24627 25.4484 8.0994 25.3233 7.97547C25.1819 7.8354 24.9878 7.73691 24.5995 7.53994L15.0069 2.67359C14.6393 2.48709 14.4555 2.39383 14.2608 2.35728C14.0885 2.32492 13.9108 2.32492 13.7385 2.35728C13.5439 2.39383 13.3601 2.48709 12.9924 2.67359L3.39982 7.53994C3.01155 7.73691 2.81741 7.8354 2.67605 7.97548C2.55099 8.0994 2.45635 8.24628 2.39845 8.40629C2.33301 8.58715 2.33301 8.78995 2.33301 9.19554V18.8038C2.33301 19.2094 2.33301 19.4122 2.39845 19.5931C2.45635 19.7531 2.55099 19.9 2.67605 20.0239C2.81741 20.164 3.01155 20.2624 3.39982 20.4594L12.9924 25.3258C13.3601 25.5123 13.5439 25.6055 13.7385 25.6421C13.9108 25.6744 14.0885 25.6744 14.2608 25.6421C14.4555 25.6055 14.6393 25.5123 15.0069 25.3258L24.5995 20.4594C24.9878 20.2624 25.1819 20.164 25.3233 20.0239C25.4484 19.9 25.543 19.7531 25.6009 19.5931C25.6663 19.4122 25.6663 19.2094 25.6663 18.8038Z"
                stroke={props.stroke}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
