export const WarningIcon = (props: IPropsIcon) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.942113 13.3834L7.35314 2.33639C7.53525 2.02259 7.65486 1.81749 7.75588 1.67521C7.84281 1.55276 7.88314 1.52614 7.88725 1.5236C7.95908 1.49213 8.04092 1.49213 8.11276 1.5236C8.11686 1.52614 8.15718 1.55276 8.24412 1.67521C8.34514 1.81749 8.46475 2.02259 8.64686 2.33639L15.0579 13.3834C15.2406 13.6984 15.3598 13.9048 15.4335 14.0636C15.4963 14.1991 15.4998 14.2475 15.5 14.2526C15.4915 14.3294 15.4511 14.3996 15.3881 14.4458C15.3839 14.448 15.3404 14.4697 15.1895 14.4835C15.0147 14.4995 14.7757 14.5 14.411 14.5H1.58898C1.22428 14.5 0.985337 14.4995 0.810487 14.4835C0.6596 14.4697 0.616074 14.448 0.611902 14.4458C0.548868 14.3996 0.508464 14.3294 0.5 14.2526C0.500219 14.2476 0.503635 14.1992 0.566499 14.0636C0.640154 13.9048 0.759358 13.6984 0.942113 13.3834L0.509661 13.1325L0.942113 13.3834Z"
                fill="#FEF0C7"
                stroke="#F79009"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M8 6V10.2" stroke="#F79009" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="8.00039" cy="12.1" r="0.6" fill="#F79009" />
        </svg>
    )
}
