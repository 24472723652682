export const AsinLinkExternalSvg = (props: ILinkExternalSvgProps) => {
    return (
        <>
            <svg
                className={props.className}
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.5 4.5L10.5 1.5M10.5 1.5H7.5M10.5 1.5L6.5 5.5M5 2.5H3.9C3.05992 2.5 2.63988 2.5 2.31901 2.66349C2.03677 2.8073 1.8073 3.03677 1.66349 3.31901C1.5 3.63988 1.5 4.05992 1.5 4.9V8.1C1.5 8.94008 1.5 9.36012 1.66349 9.68099C1.8073 9.96323 2.03677 10.1927 2.31901 10.3365C2.63988 10.5 3.05992 10.5 3.9 10.5H7.1C7.94008 10.5 8.36012 10.5 8.68099 10.3365C8.96323 10.1927 9.1927 9.96323 9.33651 9.68099C9.5 9.36012 9.5 8.94008 9.5 8.1V7"
                    stroke={props.color}
                    strokeWidth="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>

            {/* <svg
                className={props.className}
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.75 4.25L8.75 1.75M8.75 1.75H6.25M8.75 1.75L5.41667 5.08333M4.16667 2.58333H3.25C2.54993 2.58333 2.1999 2.58333 1.93251 2.71958C1.69731 2.83942 1.50608 3.03064 1.38624 3.26585C1.25 3.53323 1.25 3.88327 1.25 4.58333V7.25C1.25 7.95007 1.25 8.3001 1.38624 8.56749C1.50608 8.80269 1.69731 8.99392 1.93251 9.11376C2.1999 9.25 2.54993 9.25 3.25 9.25H5.91667C6.61673 9.25 6.96677 9.25 7.23415 9.11376C7.46936 8.99392 7.66058 8.80269 7.78042 8.56749C7.91667 8.3001 7.91667 7.95007 7.91667 7.25V6.33333"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg> */}
        </>
    )
}
