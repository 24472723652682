import { EXTENDED_TRACKPACK_LIST } from "../@store"
import { IExtendedTrackpack, ITrackpackState } from "../@types/app/trackpack"

export class TrackpackStateClass implements ITrackpackState {
    [EXTENDED_TRACKPACK_LIST]: { trackpacks: IExtendedTrackpack[] } | undefined

    constructor() {
        this[EXTENDED_TRACKPACK_LIST] = undefined
    }
}
