import { useVirtualizer } from "@tanstack/react-virtual"
import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useDispatch, useSelector } from "react-redux"
import { Tooltip } from "react-tooltip"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { SearchpackActionCreator } from "../../../../actions/searchpack.action"
import { AddIcon } from "../../../../assets/svgs/addIcon.svg"
import { RightChevronIcon } from "../../../../assets/svgs/RightChevronIcon.svg"
import { ASIN_PRODUCTS_COLOR } from "../../../../config/colors.config"
import { img_url } from "../../../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../../../config/routes.config"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { mergeColorsWithKeywordData } from "../../../../helpers/util.helper"
import { useAppRouteReady } from "../../../../hooks/app/searchpack/navigation"
import { useAppSearchParams } from "../../../../hooks/app/searchpack/useAppSearchParams"
import { useLoadingStatus } from "../../../../hooks/useLoadingStatus.hook"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../../selectors/searchpack.selector"
import { KeywordPerformanceSubCards } from "../../../common/keywordPerformanceSubcards.component"
import { ProductCard } from "../../../common/productCard.component"
import CardSkeletonLoader from "../../../common/skeletonLoader/cardSkeleton.component"
import KeywordsLoadingCard from "../../../common/skeletonLoader/keywordsLoadingCard"
import KeywordsSkeletonSubCard from "../../../common/skeletonLoader/keywordsSkeletonSubCard"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
import CardCustomTooltipContent from "../../tooltip-alerts/cardTooltipAlerts.component"
import CommonTooltip from "../../tooltipItems/commonTooltip.component"

const KeywordPerformnceAsinCard = () => {
    const routeReady = useAppRouteReady(`${ROUTES_CONFIG.searchpackTracker}/keyword-performance`)
    const { updateSearchParams, getParamValueAsSet } = useAppSearchParams(["sp_id"])
    const keyword = getParamValueAsSet("keyword")
    const asin = getParamValueAsSet("asin")

    const sidebarRef = useRef<HTMLDivElement>(null)
    const panelRef = useRef<HTMLDivElement>(null)
    const [, setShowBorder] = useState(false)
    const [isPanelOpen, setPanelOpen] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState<any[]>([])
    const [selectedTerms, setSelectedTerms] = useState<any[]>([])
    const [displayedProduct, setDisplayedProduct] = useState<any>(null)
    const [isAtTop, setIsAtTop] = useState(true)
    const [init, setInit] = useState(false)

    const { userActionData } = useSelector(AccountStateSelector)

    const { loading } = useSelector(RunTimeStateSelector)
    const { searchpackKeywordPerformance } = useSelector(SearchpackStateSelector)

    const isKeywordPerformanceLoading = useLoadingStatus(
        loading,
        RuntimeHelper.getSearchpackKeywordPerformanceHandler()
    )
    const dispatch = useDispatch()

    const { selectedSearchpackProductsRank, selectedSearchpackLastDataTime, selectedSearchpackWithTerm } =
        useSelector(SearchpackStateSelector)

    useEffect(() => {
        dispatch && dispatch(SearchpackActionCreator.setSelectedSearchPackTerms(selectedTerms))
    }, [dispatch, selectedTerms])

    useEffect(() => {
        if (selectedSearchpackLastDataTime) {
            const options: { as_user?: string } = {}

            if (userActionData) {
                options.as_user = userActionData.id + ""
            }

            // Reset all filters and selections
            setSelectedProduct([])
            setDisplayedProduct(null)
            setSelectedTerms([])
            setPanelOpen(false)

            // Fetch new data
            dispatch(SearchpackActionCreator.getSearchPackTermData({}, options))
        }
    }, [selectedSearchpackLastDataTime, dispatch])

    const SelectedProductFunc = (item: any) => {
        if (displayedProduct?.asin === item?.asin && selectedProduct.length) {
            return
        } else {
            setDisplayedProduct(item)
            setSelectedProduct([item])

            dispatch(
                SearchpackActionCreator.selectedSearchpackKeywordPerformance({
                    selectedProducts: item,
                })
            )

            // Force a re-calculation of rank data for selected terms with new ASIN
            const updatedRankData = getRankForSelectedTerms()
            dispatch(
                SearchpackActionCreator.selectedSearchpackKeywordPerformanceRank({
                    selectedProducts: updatedRankData.map((data) => ({
                        term: { ...data.term, color: data.term.color },
                        rankData: data.rankData,
                    })),
                })
            )
        }
        setPanelOpen(false)
    }

    useEffect(() => {
        if (!selectedTerms || !routeReady || !init || !updateSearchParams) return

        updateSearchParams({
            keyword:
                selectedTerms
                    ?.map((i) => i?.term_id || 0)
                    ?.filter((i) => !!i)
                    ?.join(",") || "",
        })
    }, [init, routeReady, selectedTerms, updateSearchParams])

    const handleSelectedTerm = (item: any) => {
        setSelectedTerms((prev) => {
            const contains = prev.some((term: any) => term.id === item.id)
            if (contains) {
                return prev.filter((term: any) => term.id !== item.id)
            }
            return [...prev, item]
        })
    }

    const filteredData = useMemo(() => {
        if (selectedSearchpackWithTerm) {
            setSelectedTerms([])
            const filteredSelectedSearchpackWithTerm = selectedSearchpackWithTerm?.searchpacks?.filter(
                (term: TrackpackDropdown) => term.id === selectedSearchpackLastDataTime?.id
            )
            return filteredSelectedSearchpackWithTerm
        }
        return []
    }, [selectedSearchpackProductsRank, selectedSearchpackWithTerm])

    const getRankForSelectedTerms = () => {
        if (!searchpackKeywordPerformance?.search_rank_data_by_asin || selectedTerms.length === 0) return []

        return selectedTerms.map((term) => {
            // Update to use displayedProduct?.asin instead of a fixed ASIN
            const asinData = searchpackKeywordPerformance.search_rank_data_by_asin
                .filter((item) => item.asin === displayedProduct?.asin)
                .find((asinData: any) =>
                    asinData.date_term_rank_data.some((rankData: any) => rankData.term_id === term.term_id)
                )

            if (asinData) {
                const rankData = asinData.date_term_rank_data.filter(
                    (rankData: any) => rankData.term_id === term.term_id
                )
                return { term, rankData }
            }

            return { term, rankData: [] }
        })
    }

    const rankDataForSelectedTerms = useMemo(() => {
        const rankData = getRankForSelectedTerms()
        return rankData.map((data) => {
            const enrichedData = mergeColorsWithKeywordData(filteredData[0]?.search_terms, ASIN_PRODUCTS_COLOR)
            const enrichedItem = enrichedData.find((item: any) => item.term === data.term.term)
            const itemColor = enrichedItem?.color
            return { term: { ...data.term, color: itemColor }, rankData: data.rankData }
        })
    }, [selectedTerms, searchpackKeywordPerformance])

    useEffect(() => {
        dispatch(
            SearchpackActionCreator.selectedSearchpackKeywordPerformanceRank({
                selectedProducts: rankDataForSelectedTerms,
            })
        )
    }, [rankDataForSelectedTerms, dispatch])

    useEffect(() => {
        if (
            selectedSearchpackWithTerm?.searchpacks &&
            selectedSearchpackWithTerm?.searchpacks?.length > 0 &&
            routeReady
        ) {
            const selectedSearchpack =
                selectedSearchpackWithTerm.searchpacks.find((pack) => pack.id === selectedSearchpackLastDataTime?.id) ||
                selectedSearchpackWithTerm.searchpacks[0]

            // Only set initial terms if no terms are currently selected
            if (!init) {
                const enrichedData = mergeColorsWithKeywordData(
                    filteredData?.[0]?.search_terms || [],
                    ASIN_PRODUCTS_COLOR
                )

                const selectedTerms =
                    selectedSearchpack.search_terms?.length > 0
                        ? keyword?.length
                            ? selectedSearchpack.search_terms.filter((i) => keyword.includes(`${i.term_id}`))
                            : selectedSearchpack.search_terms.slice(0, 5).map((item) => {
                                  const enrichedItem = enrichedData.find((data: any) => data.term === item.term)
                                  return { ...item, color: enrichedItem?.color }
                              })
                        : []
                // Find the matching enriched item to get its color
                setSelectedTerms(selectedTerms)
                setInit(true)
            }
        }
        // do not include `keyword`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedSearchpackWithTerm,
        selectedSearchpackLastDataTime,
        displayedProduct,
        selectedTerms?.length,
        filteredData,
        selectedTerms,
        init,
    ])

    useEffect(() => {
        init && !!selectedSearchpackLastDataTime?.id && setInit(false)
        // do not include init
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSearchpackWithTerm])

    // Modify useEffect to prevent resetting selection on data changes
    useEffect(() => {
        if (!searchpackKeywordPerformance) return

        const initialProduct = searchpackKeywordPerformance.search_card_data_with_latest_rank?.find(
            (i) => i.asin === asin[0]
        )

        const initialProducts = initialProduct
            ? [initialProduct]
            : searchpackKeywordPerformance.search_card_data_with_latest_rank?.slice(0, 5) || []

        const displayedProduct = initialProducts[0]
        displayedProduct && setDisplayedProduct(displayedProduct)
        setSelectedProduct(initialProducts)

        dispatch(
            SearchpackActionCreator.selectedSearchpackKeywordPerformance({
                selectedProducts: displayedProduct,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchpackKeywordPerformance])

    useEffect(() => {
        displayedProduct &&
            displayedProduct?.asin !== asin[0] &&
            updateSearchParams({
                asin: displayedProduct.asin,
            })
        // do not include `asin`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedProduct, updateSearchParams])

    // Update useEffect to trigger rank data refresh when ASIN changes
    useEffect(() => {
        if (displayedProduct && selectedTerms.length > 0) {
            const rankData = getRankForSelectedTerms()
            dispatch(
                SearchpackActionCreator.selectedSearchpackKeywordPerformanceRank({
                    selectedProducts: rankData.map((data) => ({
                        term: { ...data.term, color: data.term.color },
                        rankData: data.rankData,
                    })),
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedProduct, selectedTerms, searchpackKeywordPerformance])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
                setPanelOpen(false)
            }
        }

        if (isPanelOpen) {
            document.addEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isPanelOpen])

    useEffect(() => {
        const handleScroll = () => {
            if (sidebarRef.current) {
                setShowBorder(sidebarRef.current.scrollTop > 0)
            }
        }

        const currentSidebarRef = sidebarRef.current
        if (currentSidebarRef) {
            currentSidebarRef.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (currentSidebarRef) {
                currentSidebarRef.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    // Handle scroll for the virtualized list
    const handlePanelScroll = () => {
        if (panelRef.current) {
            const { scrollTop } = panelRef.current
            setIsAtTop(scrollTop <= 1) // Using a small threshold to detect top position
        }
    }

    // Attach the scroll event listener
    useEffect(() => {
        const currentPanelRef = panelRef.current
        if (currentPanelRef) {
            currentPanelRef.addEventListener("scroll", handlePanelScroll)
        }

        return () => {
            if (currentPanelRef) {
                currentPanelRef.removeEventListener("scroll", handlePanelScroll)
            }
        }
    }, [])

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAddKeywordsModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const handleSidePanelOpen = () => {
        setPanelOpen((curr) => !curr)
    }

    const activeAsinClickHandler = () => {
        SelectedProductFunc(displayedProduct)
        handleSidePanelOpen()
    }

    const rowVirtualizer = useVirtualizer({
        count:
            ((searchpackKeywordPerformance?.search_card_data_with_latest_rank &&
                searchpackKeywordPerformance.search_card_data_with_latest_rank.length) ||
                0) - (displayedProduct ? 1 : 0),
        getScrollElement: () => panelRef.current,
        estimateSize: () => 93,
        overscan: 5,
    })

    const virtualItems = rowVirtualizer.getVirtualItems()

    return (
        <div className="w-full">
            <div className="relative bg-gray-50 w-full py-[12px] px-[12px] rounded-2xl outline outline-1 outline-gray-50 justify-start items-start inline-flex flex-col">
                <div className={`w-full flex justify-between gap-[4px] items-center mb-[8px] mt-[4px]`}>
                    <div className=" flex gap-[6px] items-center">
                        <PrimaryText size="md" weight={"medium"} className={"text-[#088AB2] pl-[8px]"}>
                            Active ASIN
                        </PrimaryText>
                    </div>
                    <div
                        className="cursor-pointer outline-none"
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-20}
                        data-tooltip-class-name={"!ml-[72px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Select ASIN" />
                        )}
                    >
                        <div className="flex items-center" onClick={handleSidePanelOpen}>
                            <PrimaryText weight="medium" size="xs" className="text-gray-400 my-[4px] leading-[16px]">
                                Select ASIN
                            </PrimaryText>
                            <RightChevronIcon className="cursor-pointer" color={"#98A2B3"} width={"21"} height={"21"} />
                        </div>
                    </div>
                    {isPanelOpen && (
                        <div
                            className="absolute top-[36px] left-[312px] transition-all w-[316px] mr-[20px] bg-gray-50 border border-gray-200 z-[50] rounded-[16px] px-[12px]"
                            style={{
                                boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
                                height: "calc(100vh - 390px)",
                            }}
                        >
                            <div
                                ref={panelRef}
                                className="mr-[-1px] h-full overflow-y-auto custom-scroll"
                                style={{
                                    paddingTop: isAtTop ? "12px" : "0",
                                    transition: "padding-top 0.2s ease-out",
                                }}
                            >
                                <div
                                    className="flex flex-col gap-y-[8px] max-h-full"
                                    style={{
                                        height: `${rowVirtualizer.getTotalSize()}px`,
                                        position: "relative",
                                    }}
                                >
                                    {isKeywordPerformanceLoading ? (
                                        <CardSkeletonLoader length={10} isTrackpack={false} />
                                    ) : searchpackKeywordPerformance?.search_card_data_with_latest_rank?.length === 0 ||
                                      searchpackKeywordPerformance?.search_card_data_with_latest_rank === undefined ? (
                                        <CardSkeletonLoader length={10} isStatic={true} isTrackpack={false} />
                                    ) : (
                                        virtualItems.map((virtualItem) => {
                                            const dataIndex =
                                                displayedProduct && searchpackKeywordPerformance
                                                    ? virtualItem.index >=
                                                      searchpackKeywordPerformance.search_card_data_with_latest_rank?.indexOf(
                                                          displayedProduct
                                                      )
                                                        ? virtualItem.index + 1
                                                        : virtualItem.index
                                                    : virtualItem.index

                                            const searchCardData =
                                                searchpackKeywordPerformance?.search_card_data_with_latest_rank?.[
                                                    dataIndex
                                                ]
                                            return (
                                                <div
                                                    key={virtualItem.key}
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        height: `${virtualItem.size}px`,
                                                        transform: `translateY(${virtualItem.start}px)`,
                                                    }}
                                                    onClick={() => SelectedProductFunc(searchCardData)}
                                                >
                                                    <ProductCard
                                                        id={searchCardData?.asin ?? ""}
                                                        src={`${img_url}${searchCardData?.image_filename ?? ""}`}
                                                        altText={searchCardData?.title ?? ""}
                                                        title={searchCardData?.title ?? ""}
                                                        price={searchCardData?.price?.value ?? 0}
                                                        currency={searchCardData?.price?.currency ?? "USD"}
                                                        rating={searchCardData?.rating ?? 0}
                                                        ratingsCount={searchCardData?.ratings_total ?? 0}
                                                        amazon_tld="com"
                                                        color={searchCardData?.color}
                                                        activeAsinColor={"#98A2B3"}
                                                        ownProduct={searchCardData?.is_own ?? false}
                                                        isSearchrankCard={true}
                                                        searchpackData={selectedSearchpackLastDataTime}
                                                        searchpackTab={"keyword-performance"}
                                                        selectedProduct={selectedProduct}
                                                        virtualItemIndex={virtualItem.index}
                                                        trackedProduct={!!searchCardData?.is_tracked}
                                                    />
                                                </div>
                                            )
                                        })
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    ref={sidebarRef}
                    className={`w-[305px] ${
                        isKeywordPerformanceLoading ? "overflow-y-hidden" : "overflow-y-scroll"
                    } max-h-full productCardScroll`}
                >
                    <div className="flex flex-col gap-[8px] max-h-full">
                        {isKeywordPerformanceLoading ? (
                            <CardSkeletonLoader length={1} isTrackpack={false} className="!mb-0 last:!mb-0" />
                        ) : searchpackKeywordPerformance?.search_card_data_with_latest_rank?.length === 0 ||
                          searchpackKeywordPerformance?.search_card_data_with_latest_rank === undefined ? (
                            <CardSkeletonLoader
                                length={1}
                                isStatic={true}
                                isTrackpack={false}
                                className="!mb-0 last:!mb-0"
                            />
                        ) : (
                            displayedProduct && (
                                <div onClick={activeAsinClickHandler}>
                                    <ProductCard
                                        id={displayedProduct?.asin}
                                        src={`${img_url}${displayedProduct?.image_filename}`}
                                        altText={displayedProduct?.title}
                                        title={displayedProduct?.title}
                                        price={displayedProduct?.price?.value ?? 0}
                                        currency={displayedProduct?.price?.currency ?? "USD"}
                                        rating={displayedProduct?.rating}
                                        ratingsCount={displayedProduct?.ratings_total}
                                        amazon_tld="com"
                                        color={displayedProduct?.activeAsinColor}
                                        activeAsinColor={"#98A2B3"}
                                        ownProduct={displayedProduct?.is_own}
                                        isSearchrankCard={true}
                                        selectedProduct={selectedProduct}
                                        hideSelectedProductIcon={true}
                                        trackedProduct={displayedProduct?.is_tracked}
                                    />
                                </div>
                            )
                        )}
                    </div>
                    <Tooltip
                        id="tracking-off-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-off-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-error-card-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEE4E2",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow red"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-error-card-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-success-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#DCFAE6",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow green"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content)
                            return <CardCustomTooltipContent id="tracking-success-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="status-warning-tooltip"
                        style={{
                            padding: "8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            display: "inline-block",
                            zIndex: 10000,
                            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                        }}
                        clickable
                        place="top"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            return <CardCustomTooltipContent id="status-warning-tooltip" dataWarnings={content} />
                        }}
                    />
                </div>
            </div>

            <div className="relative mt-[22px] bg-gray-50 w-full py-[12px] px-[12px] rounded-2xl outline outline-1 outline-gray-50 justify-start items-start inline-flex flex-col pb-0">
                <div className={`w-full flex justify-between gap-[4px] items-center mb-[12px] `}>
                    <div className=" flex gap-[6px] items-center">
                        <PrimaryText
                            size="md"
                            weight={"medium"}
                            className={"text-[#088AB2] pl-[8px] pt-[7px] pb-[3px]"}
                        >
                            Keywords
                        </PrimaryText>
                    </div>
                    <div
                        className="cursor-pointer outline-none"
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[38px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Add Searchpack" />
                        )}
                    >
                        <ButtonElement
                            onClick={() => !isKeywordPerformanceLoading && displayedProduct && handleOpen}
                            viewType="only-icon"
                            className={`
                                 ${
                                     !!isKeywordPerformanceLoading || !displayedProduct
                                         ? "cursor-not-allowed pointer-events-none bg-gray-200"
                                         : "bg-gradient-to-br from-cyan-100 to-cyan-200"
                                 }
                                p-[10px] rounded-[10px] disabled:bg-gray-100
                                hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                                active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400
                            `}
                        >
                            <AddIcon
                                strokeColor={!!isKeywordPerformanceLoading || !displayedProduct ? "#D0D5DD" : "#0E7090"}
                            />
                        </ButtonElement>
                    </div>
                </div>
                <div
                    ref={sidebarRef}
                    className={`w-[305px] ${
                        isKeywordPerformanceLoading ? "overflow-y-hidden" : "overflow-y-scroll"
                    } max-h-full productCardScroll`}
                >
                    <div className="flex flex-col gap-[8px] max-h-full" style={{ height: "calc(100vh - 524px)" }}>
                        {isKeywordPerformanceLoading ? (
                            <KeywordsLoadingCard />
                        ) : searchpackKeywordPerformance?.search_card_data_with_latest_rank?.length === 0 ||
                          searchpackKeywordPerformance?.search_card_data_with_latest_rank === undefined ? (
                            <KeywordsSkeletonSubCard length={6} isStatic={true} className="!mb-0 last:!mb-0" />
                        ) : (
                            !isKeywordPerformanceLoading &&
                            displayedProduct &&
                            filteredData[0]?.search_terms.map((item: any, index: number) => {
                                const status = item.status
                                if (status !== "ACTIVE") return null

                                const amazon_tld = item?.metadata?.amazon_tld ?? ""
                                const mainImageUrl = item?.latest_data?.main_image_filename
                                    ? `${img_url}${item.latest_data.main_image_filename}`
                                    : ""

                                // Merging the color data with search terms
                                const enrichedData = mergeColorsWithKeywordData(
                                    filteredData[0]?.search_terms || [],
                                    ASIN_PRODUCTS_COLOR
                                )

                                // Find the matching enriched item to get its color
                                const enrichedItem = enrichedData.find((data: any) => data.term === item.term)

                                // Use enrichedItem.color if it exists, otherwise fallback to item.color
                                const itemColor = enrichedItem?.color
                                return (
                                    <div
                                        key={index}
                                        onClick={() => handleSelectedTerm({ ...item, color: enrichedItem?.color })}
                                        className="last:pb-[12px]"
                                    >
                                        <KeywordPerformanceSubCards
                                            amazon_tld={amazon_tld}
                                            id={item?.id}
                                            src={mainImageUrl}
                                            altText="Asin dress"
                                            title={item?.term ?? ""}
                                            price={item?.price ?? 0}
                                            rating={item?.rating ?? 0}
                                            ratingsCount={item?.ratings_total ?? 0}
                                            color={itemColor}
                                            currency={item?.currency ?? "USD"}
                                            selectedProduct={selectedTerms}
                                            ownProduct={item?.own_product}
                                            metadata={item?.metadata}
                                            currentIssue={item?.currentIssue}
                                        />
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KeywordPerformnceAsinCard
