import React, { useEffect, useState } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

export type SearchPhraseTagInputProps = {
  id?: string;
  name: string;
  className?: string;
  placeholder?: string;
  keywords?: string[];
  reactHookControl: Control<FieldValues>;
};

const SearchPhraseTagInput = (props: SearchPhraseTagInputProps) => {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState<string[]>([]);

  const addTags = (newTags: string[], cb?: (value: any) => void) => {
    let updatedTags = [...tags];

    for (const tag of newTags) {
      const safeTag = tag?.trim?.() || "";
      if (!safeTag || updatedTags.includes(safeTag)) return;
      updatedTags = [...updatedTags, safeTag];
    }

    setTags(updatedTags);
    setInputValue("");
    cb && cb(updatedTags);
  };

  const addTag = (onChange: (value: any) => void) =>
    addTags([inputValue], onChange);

  const removeTag = (tagToRemove: string, onChange: (value: any) => void) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
    onChange(updatedTags);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    onChange: (value: any) => void,
  ) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault();
      addTag(onChange);
    }
  };

  useEffect(() => {
    if (props.keywords && props.keywords.length) addTags(props.keywords);
  }, [props.keywords]);

  return (
    <Controller
      control={props.reactHookControl}
      name={props.name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <div>
            <div
              style={{
                display: "flex",
                gap: "8px",
                flexWrap: "wrap",
                border: "1px solid #D0D5DD",
                padding: "10px 14px",
                borderRadius: "12px",
              }}
            >
              {tags.map((tag, index) => (
                <div
                  key={index}
                  className="flex items-center py-[2px] pl-[6px] pr-[4px] last:mr-0 bg-[#F2F4F7] rounded-[6px]"
                >
                  <span className="text-[14px] font-[400]">{tag}</span>
                  <button
                    onClick={() => removeTag(tag, onChange)}
                    type="button"
                    style={{
                      margin: "2px 0 2px 6px",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 4L4 12M4 4L12 12"
                        stroke="#344054"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              ))}

              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, onChange)}
                placeholder={
                  tags.length === 0 && !inputValue
                    ? props.placeholder || ""
                    : ""
                }
                className="placeholder:text-gray-300 placeholder:italic placeholder:pl-[4px]"
                style={{
                  border: "none",
                  outline: "none",
                  flexGrow: 1,
                  minWidth: "150px",
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default SearchPhraseTagInput;
