import dayjs from "dayjs"
import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CartesianGrid, DotProps, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts"
import { RuntimeActionCreators } from "../../../../../actions/runTime.action"
import { ASIN_PRODUCTS_COLOR } from "../../../../../config/colors.config"
import { convertToProductObject } from "../../../../../helpers/util.helper"
import { useRankTrackerGraphData } from "../../../../../hooks/app/searchpack/rank-tracker/useRankTrackerGraphData"
import { useDismissPopups } from "../../../../../hooks/app/searchpack/useDismissPopups"
import { SearchpackStateSelector } from "../../../../../selectors/searchpack.selector"
import { IRankTrackerGraphDotOnClick, RankTrackerGraphDot } from "./rank-tracker-graph-dot.component"
import { RankTrackerGraphTooltip } from "./rank-tracker-graph-tooltip.component"

export interface ITankTrackerGraphProps {
    selectedRange: number
    selectedSearchpackKeywordsData: ISearchpackKeywordData[]
}

export const RankTrackerGraph = ({ selectedSearchpackKeywordsData, selectedRange }: ITankTrackerGraphProps) => {
    const tooltip = useRef<HTMLDivElement>(null)

    const dispatch = useDispatch()
    const { dismissPopups } = useDismissPopups()

    const { rankTrackerListingChange, selectedSearchpackProductsRank } = useSelector(SearchpackStateSelector)

    const [value, setValue] = useState<{
        color: TAsinProductColor
        product: IProductData
        asin: string
    }>()
    const [tooltipVisible, setTooltipVisible] = useState(false)

    const over = useCallback(() => {
        setTooltipVisible(false)
    }, [])

    const { asinData, changeData, dateRange } = useRankTrackerGraphData(
        selectedSearchpackKeywordsData,
        selectedSearchpackProductsRank,
        rankTrackerListingChange,
        selectedRange,
        over
    )
    useEffect(() => {
        over?.()
    }, [selectedSearchpackProductsRank?.selectedProducts, selectedRange, over])

    const customMouseClick = useCallback(
        (e: IRankTrackerGraphDotOnClick) => {
            setTooltipVisible(false)
            dispatch(RuntimeActionCreators.closeAllPopup())
            setTimeout(() => {
                if (tooltip.current && rankTrackerListingChange?.objects) {
                    setTooltipVisible(true)
                    tooltip.current.style.top = `${e.cy! - 12}px`
                    tooltip.current.style.left = `${e.cx! - 0.85}px`

                    const productObject = convertToProductObject(e.changes, rankTrackerListingChange?.objects)

                    setValue({
                        color: e.color,
                        product: productObject,
                        asin: e.asin,
                    })
                }
            }, 300)
        },

        [dispatch, rankTrackerListingChange?.objects]
    )

    const onGraphClick = () => {
        dismissPopups()
        over()
    }

    return (
        <div className={"relative pb-[30px] graph"} style={{ height: "calc(100% - 26px)" }} onClick={onGraphClick}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />

                    <XAxis
                        dataKey="timestamp"
                        domain={["dataMin", "dataMax"]}
                        type="number"
                        ticks={dateRange}
                        allowDuplicatedCategory={false}
                        tickLine={{ display: "none" }}
                        tick={{ fill: "#98A2B3" }}
                        axisLine={{ stroke: "#EAECF0" }}
                        className={"text-[6px] mt-[2px] tracking-[1px] text-[#98A2B3] font-normal uppercase"}
                        dy={12}
                        angle={-45}
                        tickFormatter={(tickValue) => dayjs.unix(tickValue).format("MMM-DD")}
                    />

                    <YAxis
                        tickCount={10}
                        tickLine={{ display: "none" }}
                        className={"text-[10px] tracking-[1px] font-normal"}
                        tick={{ fill: "#98A2B3" }}
                        axisLine={{ stroke: "#EAECF0" }}
                        interval={0}
                        domain={["dataMin", "dataMax"]}
                        allowDecimals={false}
                        tickFormatter={(tickValue) => {
                            return tickValue
                        }}
                        reversed={true}
                    />

                    {asinData?.map((asinItem) => {
                        return (
                            <>
                                {asinItem.data
                                    .filter((i) => i.typeHint === "organic")
                                    .map((line) => (
                                        <Line
                                            data={line.data}
                                            key={`line-organic-${asinItem.asin}-${line.start}-${line.end}`}
                                            type="monotone"
                                            dataKey="value"
                                            stroke={asinItem.color.default}
                                            strokeWidth={1.6}
                                            connectNulls
                                            isAnimationActive={true}
                                            dot={<></>}
                                            activeDot={<></>}
                                        />
                                    ))}

                                {asinItem.data
                                    .filter((i) => i.typeHint === "paid")
                                    .map((line) => (
                                        <Line
                                            data={line.data}
                                            key={`line-organic-${asinItem.asin}-${line.start}-${line.end}`}
                                            type="monotone"
                                            dataKey="value"
                                            stroke={asinItem.color.default}
                                            strokeWidth={1.6}
                                            strokeDasharray="5 5"
                                            isAnimationActive={true}
                                            dot={<></>}
                                            activeDot={<></>}
                                        />
                                    ))}
                            </>
                        )
                    })}

                    {changeData.map((change) => {
                        return (
                            <Line
                                data={change.data}
                                key={`line-changes-${change.asin}`}
                                type="monotone"
                                isAnimationActive={false}
                                dataKey="value"
                                stroke={"transparent"}
                                strokeWidth={6}
                                strokeDasharray="0"
                                dot={(props: DotProps) => {
                                    const { payload } = props as DotProps & { payload: { label: string } }
                                    return (
                                        <RankTrackerGraphDot
                                            {...props}
                                            product={change.product}
                                            payload={{ label: payload.label }}
                                            customMoveClick={customMouseClick}
                                            dataKey={`value`}
                                        />
                                    )
                                }}
                                activeDot={(props: DotProps) => {
                                    const { payload } = props as DotProps & { payload: { label: string } }
                                    return (
                                        <RankTrackerGraphDot
                                            {...props}
                                            product={change.product}
                                            payload={{ label: payload.label }}
                                            customMoveClick={customMouseClick}
                                            dataKey={`value`}
                                        />
                                    )
                                }}
                            />
                        )
                    })}
                </LineChart>
            </ResponsiveContainer>

            <RankTrackerGraphTooltip
                id={`1`}
                over={over}
                product={value?.product}
                tooltipVisible={tooltipVisible}
                tooltip={tooltip}
                color={value?.color || ASIN_PRODUCTS_COLOR[0]}
            />
        </div>
    )
}
