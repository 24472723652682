const DescriptionIcon = ({ strokeColor }: { strokeColor?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
            <path
                d="M12.0004 11.9993H7.556M1.72266 12.2771L4.80558 11.0913C5.00277 11.0155 5.10137 10.9776 5.19361 10.9281C5.27555 10.8841 5.35366 10.8333 5.42712 10.7763C5.50983 10.7121 5.58453 10.6374 5.73392 10.488L12.0004 4.22152C12.6141 3.60787 12.6141 2.61295 12.0004 1.9993C11.3868 1.38565 10.3919 1.38565 9.77823 1.9993L3.5117 8.26581C3.36231 8.4152 3.28761 8.4899 3.22343 8.57261C3.16642 8.64608 3.11566 8.72419 3.07168 8.80612C3.02216 8.89837 2.98424 8.99696 2.9084 9.19415L1.72266 12.2771ZM1.72266 12.2771L2.86606 9.30428C2.94788 9.09155 2.98879 8.98518 3.05896 8.93646C3.12028 8.89388 3.19616 8.87778 3.26949 8.89178C3.3534 8.9078 3.43398 8.98839 3.59515 9.14955L4.8502 10.4046C5.01136 10.5658 5.09195 10.6464 5.10797 10.7303C5.12197 10.8036 5.10587 10.8795 5.06329 10.9408C5.01457 11.011 4.9082 11.0519 4.69547 11.1337L1.72266 12.2771Z"
                stroke={strokeColor || "#667085"}
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default DescriptionIcon
