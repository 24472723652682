export const NewTrashIcon = () => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1.25" width="14" height="3.5" rx="1.4" fill="#FEE4E2" stroke="#F04438" />
                <path
                    d="M2 4.75H8H14V11.0577C14 12.3501 14 12.9963 13.7548 13.49C13.5391 13.9242 13.1948 14.2772 12.7715 14.4985C12.2902 14.75 11.6601 14.75 10.4 14.75H5.6C4.33988 14.75 3.70982 14.75 3.22852 14.4985C2.80516 14.2772 2.46095 13.9242 2.24524 13.49C2 12.9963 2 12.3501 2 11.0577V4.75Z"
                    fill="#FEE4E2"
                />
                <path
                    d="M6.5 8.59615H9.5M2 4.75V11.0577C2 12.3501 2 12.9963 2.24524 13.49C2.46095 13.9242 2.80516 14.2772 3.22852 14.4985C3.70982 14.75 4.33988 14.75 5.6 14.75H10.4C11.6601 14.75 12.2902 14.75 12.7715 14.4985C13.1948 14.2772 13.5391 13.9242 13.7548 13.49C14 12.9963 14 12.3501 14 11.0577V4.75H8H2Z"
                    stroke="#F04438"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}
