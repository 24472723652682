import { useCallback, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useContainsChanges } from "../../hooks/containsChanges.hook"
import { NotificationStateSelector } from "../../selectors/notificationStateSelector"

let timeout: any = null

export const CustomDotSvg = (props: ICustomSvgDot) => {
    const { cx, cy, color, customMoveClick, dataKey, id, initialTimestamp, payload: propsPayload } = props

    const { notifiSelection } = useSelector(NotificationStateSelector)

    const payload = useMemo<ISelectedProduct>(
        () => ({
            timestamp: propsPayload!.timestamp,
            color: color,
            product: propsPayload![id!],
            id: id!,
        }),
        [propsPayload, color, id]
    )

    const handleMouseOver = useCallback(() => {
        if (payload) {
            customMoveClick?.({
                cx,
                cy,
                payload,
            })
        }
    }, [customMoveClick, cx, cy, payload])

    useEffect(() => {
        const storedData = sessionStorage.getItem("timeStamp")
        if (
            storedData &&
            notifiSelection &&
            payload.id === notifiSelection?.notifiAsin?.[0] &&
            payload?.timestamp.split("T")[0] === storedData?.split("T")[0]
        ) {
            timeout && clearInterval(timeout)
            timeout = setTimeout(() => {
                handleMouseOver()
                sessionStorage.removeItem("timeStamp")
            }, 1000)
        }
    }, [notifiSelection, handleMouseOver, payload])

    const isFirst = initialTimestamp && initialTimestamp === propsPayload!.timestamp
    const { hasActualAsin, hasChanges } = useContainsChanges(payload.product)

    if (hasChanges || isFirst) {
        return (
            <svg
                id={dataKey!.split("[")[0]}
                x={isFirst ? cx! - 6.5 : cx! - 8}
                y={isFirst ? cy! - 7 : cy! - 7.5}
                width={hasActualAsin && !isFirst ? 11 : 33}
                height={hasActualAsin && !isFirst ? 11 : 33}
                viewBox={hasActualAsin && !isFirst ? "0 0 12 10" : "0 0 200 200"}
                onClick={handleMouseOver}
                className={`cursor-pointer hover:${color?.default}`}
            >
                {hasActualAsin && !isFirst ? (
                    <path
                        d="M5.576 9.265C5.66737 9.41119 5.8276 9.5 6 9.5C6.17239 9.5 6.33263 9.41119 6.424 9.265L11.424 1.265C11.5203 1.11087 11.5254 0.916593 11.4373 0.757616C11.3492 0.598639 11.1818 0.5 11 0.5L0.999999 0.500001C0.818237 0.500001 0.65079 0.598639 0.562678 0.757617C0.474565 0.916594 0.479666 1.11087 0.576 1.265L5.576 9.265Z"
                        fill="white"
                        stroke={color?.default}
                        stroke-linejoin={10}
                        strokeWidth={1.5}
                    />
                ) : (
                    <circle
                        cx={isFirst ? "40" : "46"}
                        cy={isFirst ? "46" : "46"}
                        r={isFirst ? "25" : "25"}
                        strokeWidth={isFirst ? "9" : "8"}
                        fill={isFirst ? "white" : color?.default}
                        stroke={isFirst ? color?.default : "white"}
                    />
                )}
            </svg>
        )
    }
    return null
}
