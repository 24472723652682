export const ImageUnavailableThumbnail = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.8919 5L35 13.1081M35 5L26.8919 13.1081M20.4054 5.81081H12.7838C10.0592 5.81081 8.69691 5.81081 7.65626 6.34105C6.74088 6.80746 5.99665 7.55169 5.53024 8.46707C5 9.50773 5 10.87 5 13.5946V27.2162C5 29.9408 5 31.3031 5.53024 32.3437C5.99665 33.2591 6.74088 34.0033 7.65626 34.4698C8.69691 35 10.0592 35 12.7838 35H27.7027C29.2108 35 29.9648 35 30.5834 34.8342C32.2623 34.3844 33.5736 33.0731 34.0234 31.3943C34.1892 30.7756 34.1892 30.0216 34.1892 28.5135M17.1622 14.7297C17.1622 16.5209 15.7101 17.973 13.9189 17.973C12.1277 17.973 10.6757 16.5209 10.6757 14.7297C10.6757 12.9385 12.1277 11.4865 13.9189 11.4865C15.7101 11.4865 17.1622 12.9385 17.1622 14.7297ZM24.4433 20.2727L10.7262 32.7428C9.95464 33.4442 9.56887 33.7949 9.53475 34.0987C9.50517 34.362 9.60614 34.6232 9.80517 34.7982C10.0348 35 10.5561 35 11.5988 35H26.8205C29.1543 35 30.3212 35 31.2377 34.6079C32.3883 34.1157 33.3049 33.1991 33.7971 32.0485C34.1892 31.132 34.1892 29.9651 34.1892 27.6313C34.1892 26.8461 34.1892 26.4535 34.1033 26.0878C33.9955 25.6283 33.7886 25.1979 33.4972 24.8266C33.2653 24.5311 32.9587 24.2859 32.3455 23.7953L27.8094 20.1665C27.1958 19.6755 26.8889 19.4301 26.551 19.3434C26.2532 19.2671 25.9398 19.277 25.6474 19.3719C25.3156 19.4797 25.0248 19.744 24.4433 20.2727Z"
                stroke="#475467"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
