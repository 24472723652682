import dayjs from "dayjs"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import ReactPlayer from "react-player"
import { useSelector } from "react-redux"
import { ModalVideoUnavailable } from "../../../assets/svgs/modalVideoUnavailable.svg"
import { PopupPlayIcon } from "../../../assets/svgs/popupPlayIcon.svg"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import ArrowModalActions from "../../common/arrowModalActions.component"
import InnerModalHeader from "../../common/innerModalHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import { ChartsWrapper } from "./chartsWrapper.component"

export const MainVideoModal = (data: IDialogOptionsProps) => {
    const [, setPlayingIndex] = useState<number | null>(null)
    const { modal } = useSelector(RunTimeStateSelector)
    const { active, history, setActive, total } = useProductHistory(data.data as IProductData, "main_video")
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    const pastVideo = history?.past?.value
    const presentVideo = history?.present?.value

    const [isPlaying, setIsPlaying] = useState(false)

    const handlePlayClick = () => {
        setIsPlaying(true)
    }

    return (
        <>
            <ChartsWrapper
                className={`p-[48px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] m-auto 
                       ${
                           pastVideo && presentVideo ? "w-[1040px]" : "w-[768px]"
                       } shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
            >
                <InnerModalHeader color={modal?.color} type="main_video" />
                <PrimaryText className="text-[#344054] text-[24px] ml-[6px] font-[500] leading-[32px] mb-[4px]">
                    Main Video
                </PrimaryText>
                <div className={`ml-[8px] flex items-center gap-[10px]`}>
                    <ArrowModalActions total={total} active={active} setActive={setActive} />
                    <div className="flex items-center justify-between w-full">
                        <PrimaryText className="text-[12px] uppercase leading-[12px] text-[#667085] font-[400]">
                            {tCommon("timestamp", {
                                timestamp: dayjs(history?.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                            })}
                        </PrimaryText>
                    </div>
                </div>
                <div className="mt-[40px]">
                    <div className="flex gap-[12px]">
                        {pastVideo && (
                            <div className="bg-[#F2F4F7] rounded-[16px] p-4 pb-[18px] min-w-[260px] relative mr-[12px]">
                                <PrimaryText className="w-[32px] py-[5.5px] px-[6px] bg-[#F04438] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                    Was
                                </PrimaryText>
                                <div className="w-[228px] h-[128px] overflow-hidden border border-gray-200 rounded-[8px]">
                                    {pastVideo.thumbnail_filename === "" ? (
                                        <div className="object-contain chrome:w-full safari:w-full h-full rounded-[4px] flex items-center justify-center border border-gray-200 select-none cursor-pointer bg-gray-900">
                                            <ModalVideoUnavailable />
                                        </div>
                                    ) : (
                                        <img
                                            className="w-full h-full object-cover bg-gray-900"
                                            src={pastVideo.thumbnail_filename}
                                            alt="Past Video Thumbnail"
                                        />
                                    )}
                                </div>
                                <div className="flex flex-col">
                                    <div>
                                        {presentVideo?.title ? (
                                            <PrimaryText
                                                size="xs"
                                                weight="medium"
                                                className="text-gray-700 text-[18px] leading-[22px] ml-[4px] mt-[10px]"
                                            >
                                                {presentVideo.title}
                                                {/* Men's Casual Blazer Lightweight Suit Jackets Regular Fit Cotton Blend
                                                Fit Cotton Blend */}
                                            </PrimaryText>
                                        ) : (
                                            <PrimaryText
                                                size="xs"
                                                weight="medium"
                                                className="text-gray-300 text-[18px] leading-[22px] italic  ml-[4px] mt-[10px]"
                                            >
                                                Video title unavailable
                                            </PrimaryText>
                                        )}
                                    </div>
                                    {/* <div>
                                        {presentVideo?.publisher ? (
                                            <PrimaryText
                                                // size="xs-extra-small"
                                                weight="book"
                                                className="text-gray-500 max-w-[154px] text-[10px] text-ellipsis overflow-hidden line-clamp-1 uppercase tracking-[0.4px] mt-[4px] leading-[10px] ml-[5px]"
                                            >
                                                {presentVideo.publisher}
                                            </PrimaryText>
                                        ) : (
                                            <PrimaryText
                                                size="xs"
                                                weight="book"
                                                className="text-gray-300 text-[10px] uppercase max-w-[192px] italic text-ellipsis overflow-hidden mt-[4px] line-clamp-2 ml-[5px]"
                                            >
                                                Publisher name unavailable
                                            </PrimaryText>
                                        )}
                                    </div> */}
                                </div>
                                <div className="absolute left-[16px] bottom-[18px]">
                                    <PrimaryText
                                        weight="book"
                                        size="xs"
                                        className="text-gray-500 leading-[10px] ml-[4px] italic"
                                    >
                                        Video unavailable
                                    </PrimaryText>
                                </div>
                            </div>
                        )}

                        {presentVideo && (
                            <div className="bg-gray-100 p-4 rounded-[16px] min-w-[672px] relative">
                                <PrimaryText className="w-[32px] py-[5.5px] px-[6px] text-white bg-[#47CD89] rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                    Now
                                </PrimaryText>
                                <div className="relative w-full h-[360px] overflow-hidden border border-gray-200 rounded-[8px]">
                                    {isPlaying ? (
                                        <ReactPlayer
                                            url={presentVideo.url}
                                            controls={true}
                                            onEnded={() => setPlayingIndex(null)}
                                            width="100%"
                                            height="100%"
                                            playing={true}
                                            style={{ backgroundColor: "#101828" }}
                                        />
                                    ) : (
                                        <>
                                            {presentVideo.thumbnail_filename === "" ? (
                                                <div className="object-cover chrome:w-full safari:w-full h-full rounded-[4px] flex items-center justify-center border border-gray-200 select-none cursor-pointer bg-gray-900">
                                                    <ModalVideoUnavailable />
                                                </div>
                                            ) : (
                                                <>
                                                    <img
                                                        className="w-full h-full object-cover bg-gray-900"
                                                        src={presentVideo.thumbnail_filename}
                                                        alt="Present Video Thumbnail"
                                                    />
                                                    <div
                                                        onClick={handlePlayClick}
                                                        className="absolute bottom-[14px] left-[14px] cursor-pointer"
                                                    >
                                                        <PopupPlayIcon />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="flex flex-col">
                                    <div>
                                        {presentVideo?.title ? (
                                            <PrimaryText
                                                size="lg"
                                                weight="medium"
                                                className="text-gray-700 leading-[22px] ml-[4px] mt-[16px]"
                                            >
                                                {presentVideo.title}
                                                {/* Men's Casual Blazer Lightweight Suit Jackets Regular Fit Cotton Blend
                                                Fit Cotton Blend */}
                                            </PrimaryText>
                                        ) : (
                                            <PrimaryText
                                                size="xs"
                                                weight="medium"
                                                className="text-gray-300 text-[18px] leading-[22px] max-w-[192px] italic text-ellipsis overflow-hidden line-clamp-2 mt-[16px] ml-[4px]"
                                            >
                                                Video title unavailable
                                            </PrimaryText>
                                        )}
                                    </div>
                                    {/* <div>
                                        {presentVideo?.publisher ? (
                                            <PrimaryText
                                                size="xs"
                                                weight="book"
                                                className="text-gray-500 text-[10px] max-w-[192px] text-ellipsis capitalize overflow-hidden line-clamp-2 mt-[8px] ml-[5px]"
                                            >
                                                {presentVideo.publisher}
                                            </PrimaryText>
                                        ) : (
                                            <PrimaryText
                                                size="xs"
                                                weight="book"
                                                className="text-gray-300 text-[10px] max-w-[192px] italic text-ellipsis capitalize overflow-hidden line-clamp-2 mt-[8px] ml-[5px]"
                                            >
                                                PUBLISHER NAME UNAVAILABLE
                                            </PrimaryText>
                                        )}
                                    </div> */}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ChartsWrapper>
        </>
    )
}
