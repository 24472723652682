import dayjs from "dayjs"
import { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { Header } from "./header.component"
import { ProductListItem } from "./productListItem.component"

export const LogView = ({ infoVisible, selectedRange }: ILogViewProps) => {
    const parentRef = useRef<HTMLDivElement>(null)
    // const [showBorder, setShowBorder] = useState(false)
    // const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
    const { popups } = useSelector(RunTimeStateSelector)
    const dispatch = useDispatch()
    const { selectedLogProduct, selectedProductRange } = useSelector(ProductStateSelector)

    const range = useMemo(() => {
        return selectedProductRange?.selectedRange || 0
    }, [selectedProductRange?.selectedRange])

    const selectedASINProduct: IGroupProductData | undefined = useMemo(() => {
        return selectedLogProduct
    }, [selectedLogProduct])

    const compiledProductData = useMemo(() => {
        let startDate: dayjs.Dayjs
        const endDate: dayjs.Dayjs = dayjs().startOf("day")
        startDate = endDate.subtract(range, "day").startOf("day")

        const data =
            range === 0
                ? selectedASINProduct?.product_data ?? []
                : selectedASINProduct?.product_data?.filter((record) => {
                      const timestamp: dayjs.Dayjs = dayjs(record.timestamp)
                      return timestamp.isAfter(startDate) && timestamp.isBefore(endDate.add(1, "day"))
                  }) ?? []
        return [...data].reverse()
    }, [range, selectedASINProduct])

    useEffect(() => {
        const handleScroll = () => {
            // for close tooltip
            if (popups) {
                dispatch(RuntimeActionCreators.closeAllPopup())
            }

            // if (parentRef.current) {
            //     const { scrollTop, scrollHeight, clientHeight } = parentRef.current
            //     const isBottom = scrollHeight - scrollTop === clientHeight
            //     setIsScrolledToBottom(isBottom)
            //     setShowBorder(parentRef.current.scrollTop > 0)
            // }
        }

        const parentElement = parentRef.current
        if (parentElement) {
            parentElement.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (parentElement) {
                parentElement.removeEventListener("scroll", handleScroll)
            }
        }
    }, [dispatch, popups])

    return (
        <>
            <div
                ref={parentRef}
                className={`
                    ${
                        infoVisible
                            ? "h-calc-359 overflow-auto scroll-hidden -mr-[30px] "
                            : "h-calc-227 overflow-auto scroll-hidden -mr-[30px]"
                    }`}
            >
                <Header />
                <div className={"overflow-auto h-full"}>
                    <div
                        className={"log-w-calc-10 h-full"}
                        style={{
                            width:
                                selectedRange === 7 || selectedRange === 14 ? "calc(100% - 30px)" : "calc(100% - 25px)",
                        }}
                    >
                        <div className="h-full">
                            {selectedASINProduct && (
                                <div className={"h-full"}>
                                    {(() => {
                                        return compiledProductData?.map((product, index) => {
                                            const isLastItem = compiledProductData?.length - 1 === index
                                            return (
                                                <ProductListItem
                                                    key={index}
                                                    product={product}
                                                    color={selectedASINProduct?.color}
                                                    isFirst={index === 0}
                                                    isLast={isLastItem}
                                                    selectedRange={selectedProductRange?.selectedRange}
                                                />
                                            )
                                        })
                                    })()}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ height: "16px" }}></div>
            </div>
        </>
    )
}
