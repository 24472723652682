import { GraphSkeleton } from "../../../../../assets/svgs/graph-skeleton.svg"
import { OrganicRankingIcon } from "../../../../../assets/svgs/organicRanking.svg"
import { PaidRankingIcon } from "../../../../../assets/svgs/paidRankingIcon.svg"
import { RuntimeHelper } from "../../../../../helpers/runtime.helper"
import { useLoadingState } from "../../../../../hooks/useLoadingStatus.hook"
import { PrimaryText } from "../../../../elements/primaryText.element"
import { RankTrackerGraph } from "./rank-tracker-graph.component"

export const RankTrackerGraphView = ({
    selectedSearchpackKeywordsData,
    infoVisible,
    selectedRange,
    graphFilters = [],
    loading,
}: IGraphViewProps & { graphFilters?: ("Paid" | "Organic")[]; loading?: boolean }) => {
    const isLoading: boolean = useLoadingState(RuntimeHelper.getRanktrackerDataLoading)

    const getFilteredData = () => {
        if (!selectedSearchpackKeywordsData) return []

        return selectedSearchpackKeywordsData.map((item) => ({
            ...item,
            asinRanks: item.asinRanks.map((rank) => ({
                ...rank,
                paid: graphFilters?.includes("Paid") ? rank.paid : undefined,
                organic: graphFilters?.includes("Organic") ? rank.organic : undefined,
            })),
        }))
    }

    const getView = () => {
        if (isLoading || loading) return <GraphSkeleton />

        return (
            <div
                className={`rounded-[10px] border border-gray-200 bg-white`}
                style={{ height: infoVisible ? "calc(100vh - 289px)" : "calc(100vh - 243px)" }}
            >
                <div className={"py-[40px] px-[24px] h-full"}>
                    <div className={"relative h-full"}>
                        <div
                            className={
                                "h-[full] absolute top-1/2 left-0/4 -translate-x-1/2 -translate-y-1/2 -rotate-90 pt-[22px]"
                            }
                        >
                            <PrimaryText
                                size={"xs-medium"}
                                weight={"book"}
                                className={" flex tracking-[3px] h-full text-[#344054]"}
                                uppercase
                            >
                                SEARCH RANKING
                            </PrimaryText>
                        </div>
                        {selectedSearchpackKeywordsData && (
                            <RankTrackerGraph
                                selectedRange={selectedRange || 0}
                                selectedSearchpackKeywordsData={getFilteredData()}
                            />
                        )}

                        {/* Only show legend if at least one filter is selected */}
                        {graphFilters?.length > 0 && (
                            <div className="flex justify-center">
                                <div className="flex py-[4px] px-[12px] gap-[24px] bg-[#ECFDFF] rounded-[6px]">
                                    {
                                        <PrimaryText
                                            size={"xs-medium"}
                                            weight={"book"}
                                            uppercase
                                            className={
                                                "flex items-center justify-center gap-[8px] text-[#155B75] tracking-[5px] leading-[10px]"
                                            }
                                        >
                                            PAID <PaidRankingIcon className="my-[3px]" />
                                        </PrimaryText>
                                    }

                                    {
                                        <PrimaryText
                                            size={"xs-medium"}
                                            weight={"book"}
                                            uppercase
                                            className={
                                                "flex items-center gap-[8px] justify-center text-[#155B75] tracking-[5px] leading-[10px]"
                                            }
                                        >
                                            ORGANIC <OrganicRankingIcon className="my-[3px]" />
                                        </PrimaryText>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return <>{getView()}</>
}
