import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { MarketIcon } from "../../../../assets/svgs/marketIcon.svg"
import { NewAddAsinsIcon } from "../../../../assets/svgs/newAddAsins.svg"
import { ThumbIcon } from "../../../../assets/svgs/thumbIcon.svg"
import { TrackPackError } from "../../../../assets/svgs/trackPackError.svg"
import { TrackPackNameIcon } from "../../../../assets/svgs/trackPackName.svg"
import { useRefetchSPAfterModal } from "../../../../hooks/app/searchpack/useRefetchSPAfterModal"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import AddTrackpackStepFiveView from "./addTrackpackStepFiveView.component"
import AddTrackPackStepFourView from "./addTrackPackStepFour.component"
import AddTrackpackStepInput from "./addTrackpackStepInput.component"
import AddTrackPackStepOneView from "./addTrackPackStepOne.component"
import AddTrackPackStepThreeView from "./addTrackPackStepThree.component"

const AddTrackPackOutlet = () => {
    const { newAsinTrackPack } = useSelector(RunTimeStateSelector)
    const dispatch = useDispatch()
    const [hasError, setHasError] = useState(false)
    const [isTrackpackSuccess, setIsTrackpackSuccess] = useState(false)
    const [trackpackName, setTrackpackName] = useState("")
    const { refetchSP } = useRefetchSPAfterModal()

    const handleCloseDialog = (success = false) => {
        success && refetchSP()

        dispatch(RuntimeActionCreators.closeNewTrackPackAsinModal())
        dispatch(
            ProductsActionCreator.selectedTrackpackPayload({
                name: "",
                amazon_tld: "",
                competitor_asins: [],
                own_asins: [],
                total_asins: [],
                selected_Asins: {},
            })
        )
        setHasError(false)
        setIsTrackpackSuccess(false)
    }

    const handleErrorModal = () => {
        setHasError(true)
        dispatch(
            RuntimeActionCreators.openNewTrackPackAsinModal({
                open: true,
                stepNumber: "five",
            })
        )
    }

    const handleSuccessModal = (name: string) => {
        setIsTrackpackSuccess(true)
        setTrackpackName(name)
        dispatch(
            RuntimeActionCreators.openNewTrackPackAsinModal({
                open: true,
                stepNumber: "six",
            })
        )
    }

    const getModalBody = () => {
        if (isTrackpackSuccess && newAsinTrackPack?.stepNumber === "six") {
            return (
                <AddTrackpackStepFiveView
                    icon={<ThumbIcon />}
                    handleCloseDialog={() => handleCloseDialog(true)}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openNewTrackPackAsinModal({
                                open: true,
                                stepNumber: "four",
                            })
                        )
                    }
                    asinHeaderText="Bravo"
                    buttonText="Done"
                    trackpackName={trackpackName}
                />
            )
        }
        if (hasError && newAsinTrackPack?.stepNumber === "five") {
            return (
                <AddTrackPackStepFourView
                    icon={<TrackPackError />}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openNewTrackPackAsinModal({
                                open: true,
                                stepNumber: "four",
                            })
                        )
                    }
                    asinHeaderText="Error adding new Trackpack"
                    asinHeaderDescription="I couldn’t complete the setup of your new Trackpack at this time due to a server communication issue. Please try again later."
                    buttonText="Done"
                />
            )
        }
        switch (newAsinTrackPack?.stepNumber) {
            case "one":
                return (
                    <AddTrackpackStepInput
                        icon={<MarketIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Identify Market"
                        asinHeaderDescription="Please tell me which Amazon market this Trackpack is for."
                        buttonText="Next"
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openNewTrackPackAsinModal({
                                    open: true,
                                    stepNumber: "two",
                                })
                            )
                        }
                        handlePrev={() => {
                            dispatch(
                                RuntimeActionCreators.openTrackPackFlowModal({
                                    open: true,
                                })
                            )
                            handleCloseDialog()
                        }}
                    />
                )
            case "two":
                return (
                    <AddTrackPackStepOneView
                        icon={<NewAddAsinsIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Add ASINs"
                        asinHeaderDescription="Let’s start building your new Trackpack. Simply give me one ASIN or URL at a time and hit Tab to enter."
                        buttonText="Next"
                        isPrevButton={true}
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openNewTrackPackAsinModal({
                                    open: true,
                                    stepNumber: "four",
                                })
                            )
                        }
                        handlePrev={() =>
                            dispatch(
                                RuntimeActionCreators.openNewTrackPackAsinModal({
                                    open: true,
                                    stepNumber: "one",
                                })
                            )
                        }
                    />
                )
            case "four":
                return (
                    <AddTrackPackStepThreeView
                        icon={<TrackPackNameIcon />}
                        handleCloseDialog={handleCloseDialog}
                        handlePrev={() =>
                            dispatch(
                                RuntimeActionCreators.openNewTrackPackAsinModal({
                                    open: true,
                                    stepNumber: "two",
                                })
                            )
                        }
                        asinHeaderText="Trackpack Name"
                        asinHeaderDescription="Let’s give your new Trackpack a name."
                        buttonText="Next"
                        isError={handleErrorModal}
                        isAddtionalSteps={false}
                        isSuccess={(name) => handleSuccessModal(name)}
                    />
                )

            default:
                return <></>
        }
    }
    return <>{newAsinTrackPack?.open ? <>{getModalBody()}</> : null}</>
}

export default AddTrackPackOutlet
