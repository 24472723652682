export const TrackPackChatIcon = (props: IArrowProps) => {
    return (
        <svg width="50" height="45" viewBox="0 0 50 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3941_26818)">
        <g clipPath="url(#clip1_3941_26818)">
        <path d="M18.7114 45C18.0285 45 17.3256 44.4587 17.3256 43.5495V37.8575C17.3256 36.5085 16.2813 35.9263 15 35.9263H11.5859C5.33597 35.9263 0 31.6071 0 25.027L0.000917895 11.9379C0.000917895 5.35791 5.08999 0 11.3399 0H38.6651C44.9149 0 50 5.35368 50 11.9337C50 11.9337 49.9991 18.447 49.9991 25.027C49.9991 31.6071 44.914 35.9263 38.6641 35.9263H30.5685C28.6204 35.9263 26.7567 36.2844 25.4513 37.811L19.7195 44.5179C19.4544 44.8266 19.097 45 18.7114 45Z" fill="url(#paint0_linear_3941_26818)"/>
        <path d="M38 21C36.3448 21 35 19.6671 35 18.0267V11.9733C35 10.3329 36.3448 9 38 9C39.6552 9 41 10.3329 41 11.9733V18.0267C41 19.6671 39.6552 21 38 21Z" fill="#344054"/>
        <path d="M40 12.5012C40 12.7788 39.7736 13 39.5 13C39.2264 13 39 12.7741 39 12.5012V11.4988C39 11.2212 39.2264 11 39.5 11C39.7736 11 40 11.2259 40 11.4988V12.5012Z" fill="white"/>
        <path d="M12 21C10.3448 21 9 19.6671 9 18.0267V11.9733C9 10.3329 10.3448 9 12 9C13.6552 9 15 10.3329 15 11.9733V18.0267C15 19.6671 13.6552 21 12 21Z" fill="#344054"/>
        <path d="M14 12.5012C14 12.7788 13.7736 13 13.5 13C13.2264 13 13 12.7741 13 12.5012V11.4988C13 11.2212 13.2264 11 13.5 11C13.7736 11 14 11.2259 14 11.4988V12.5012Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0049 25.5781C20.9626 25.2726 21.1979 25 21.5084 25H28.4916C28.8021 25 29.0374 25.2773 28.9951 25.5781C28.7127 27.5147 27.0328 29 25 29C22.9672 29 21.2873 27.5147 21.0049 25.5781ZM22.8828 27.9989C23.504 28.4266 24.2427 28.6616 25.005 28.6616C25.7674 28.6616 26.5061 28.4313 27.1273 27.9989C26.5061 27.5664 25.7674 27.3314 25.005 27.3314C24.2427 27.3314 23.504 27.5664 22.8828 27.9989Z" fill="#088AB2"/>
        </g>
        </g>
        <defs>
        <linearGradient id="paint0_linear_3941_26818" x1="0" y1="0" x2="44.7514" y2="49.7238" gradientUnits="userSpaceOnUse">
        <stop stop-color="#67E3F9"/>
        <stop offset="1" stop-color="#A5F0FC"/>
        </linearGradient>
        <clipPath id="clip0_3941_26818">
        <path d="M0 0H50V45H0V0Z" fill="white"/>
        </clipPath>
        <clipPath id="clip1_3941_26818">
        <rect width="50" height="45" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        
    )
}




