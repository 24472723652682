import dayjs from "dayjs"

export const memoizedRetentionTime = (() => {
    const cache = new Map<string, number>()

    return (last_updated_at: string, retentionDays: number, currentTime: string) => {
        const dateKey = `${dayjs(last_updated_at).format("YYYY-MM-DD")}-${retentionDays}-${dayjs(currentTime).format(
            "YYYY-MM-DD"
        )}`

        if (cache.has(dateKey)) {
            return cache.get(dateKey) || 0
        }

        const lastUpdatedAt = dayjs(last_updated_at).startOf("day")
        const current = dayjs(currentTime).startOf("day")

        const elapsedDays = current.diff(lastUpdatedAt, "day")
        const remainingDays = Math.max(retentionDays - elapsedDays, 0)

        cache.set(dateKey, remainingDays)
        return remainingDays
    }
})()