import { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"

import { OnboardingUserActionCreators } from "../../actions/onBoardingUser.action"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { useInputValidations } from "../../hooks/inputValidations.hook"
import { OnboardingUserStateSelector } from "../../selectors/onBoardingState.selector"
import { BubbleListToTop, BubblesList } from "../elements/bubblesList.element"
import { ButtonElement } from "../elements/button.element"
import { Container } from "../elements/container.element"
import { InputElement } from "../elements/input.element"
import { RadioGroupElement } from "../elements/radioGroup.element"
import { MarketingFooter } from "./marketingFooter.component"

import { Transition } from "@headlessui/react"
import { MarketingHeader } from "./marketingHeader.component"

export const ListingManageForm = () => {
    const validationConfig = useInputValidations()

    const { t } = useTranslation("marketing", {
        keyPrefix: "listingManageChat.conversation",
    })

    const userOnBoard = useSelector(OnboardingUserStateSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [completed, setCompleted] = useState<boolean>(false)

    const { control, handleSubmit, setValue, watch } = useForm<IListingManageFormInputs>({
        defaultValues: {
            listingsManage: userOnBoard?.listingsManage ?? "",
            istrackCompetitor: userOnBoard?.istrackCompetitor ?? "No",
            listingsTrack: userOnBoard?.listingsTrack ?? "",
        },
    })
    const istrackCompetitor = watch("istrackCompetitor")

    const onSubmit: SubmitHandler<IListingManageFormInputs> = (data) => {
        dispatch(OnboardingUserActionCreators.createOnboardingUser(data))
        navigate(ROUTES_CONFIG.trackingChatForm)
    }

    useEffect(() => {
        if (istrackCompetitor === "No") {
            setValue("listingsTrack", "")
        }
    }, [setValue, istrackCompetitor])

    const radioOptions = [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
    ]

    return (
        <>
            <div className="xl:pt-[28px] lg:pt-[28px] md:pt-[20px] sm:pt-[20px] xs:pt-[20px]">
                <div className="flex justify-center 2xl:gap-[16px] xl:gap-[16px] lg:gap-[16px] md:gap-[16px] sm:gap-[0px] xs:gap-[0px]">
                    <MarketingHeader />

                    <div
                        className="flex flex-col justify-start items-start xs:overflow-x-hidden sm:overflow-y-auto md:pr-[20px] lg:pr-[20px] hidden-scrollbar w-[504px] md:h-md-calc-onboarding lg:h-lg-calc-onboarding xl:h-xl-calc-onboarding"
                        // style={{ height: "calc(100vh - 250px)" }}
                    >
                        <div className={""}>
                            <BubbleListToTop>
                                <BubblesList
                                    interval={500}
                                    maxSleep={500}
                                    onFinish={() => {
                                        setCompleted(true)
                                    }}
                                    conversationItems={[
                                        {
                                            text: t("1"),
                                        },
                                        {
                                            text: t("2"),
                                        },
                                        {
                                            text: t("3"),
                                        },
                                    ]}
                                />
                            </BubbleListToTop>
                        </div>

                        <Transition
                            show={completed}
                            enter="transition ease-out duration-300 transform"
                            enterFrom="translate-y-full opacity-0"
                            enterTo="translate-y-0 opacity-100"
                            leave="transition ease-in duration-200 transform"
                            leaveFrom="translate-y-0 opacity-100"
                            leaveTo="translate-y-full opacity-0"
                        >
                            <div className="flex-1 xl:min-w-[375px] lg:min-w-[375px] md:min-w-[375px] sm:min-w-[240px] xs:min-w-[240px]">
                                <form onSubmit={handleSubmit(onSubmit)} className={"w-full"}>
                                    <Container>
                                        <InputElement
                                            name="listingsManage"
                                            type="text"
                                            defaultValue=""
                                            reactHookControl={control}
                                            reactHookValidations={{
                                                pattern: validationConfig.numbersOnly,
                                                required: validationConfig.required,
                                            }}
                                            placeholder="Number of listings here..."
                                            className="mb-[12px]"
                                        />
                                        <p className="comeInAnimation fadeInBottom w-fit px-[12px] py-[8px] mb-[15px] rounded-xl xs:bg-gray-200 md:bg-gray-100 text-slate-700 xs:text-[0.875rem] sm:text-[0.875rem] md:text-[1rem] lg:text-[1rem] font-light leading-7">
                                            Do you also track competitor listings?
                                        </p>

                                        <div>
                                            <RadioGroupElement
                                                name="istrackCompetitor"
                                                reactHookControl={control}
                                                options={radioOptions}
                                                defaultValue={istrackCompetitor}
                                                reactHookValidations={{
                                                    required: validationConfig.required,
                                                }}
                                                className="xl:mb-[28px] lg:mb-[12px] md:mb-[12px] sm:mb-[12px] xs:mb-[12px]"
                                            />
                                        </div>
                                        {/* {istrackCompetitor === "Yes" && ( */}
                                        <Transition
                                            show={istrackCompetitor === "Yes"}
                                            enter="transition ease-out duration-400 transform"
                                            enterFrom="translate-y-full opacity-0"
                                            enterTo="translate-y-0 opacity-100"
                                            leave="transition ease-in duration-400 transform"
                                            leaveFrom="translate-y-0 opacity-100"
                                            leaveTo="translate-y-full opacity-0"
                                        >
                                            <p className=" w-fit px-[12px] py-[8px] mb-[15px] rounded-xl xs:bg-gray-200 md:bg-gray-100 text-slate-700 xs:text-[0.875rem] sm:text-[0.875rem] md:text-[1rem] lg:text-[1rem] font-light leading-7">
                                                How many competitor listings do you track?
                                            </p>

                                            <InputElement
                                                name="listingsTrack"
                                                type="text"
                                                defaultValue=""
                                                reactHookControl={control}
                                                reactHookValidations={{
                                                    pattern: validationConfig.numbersOnly,
                                                    required:
                                                        istrackCompetitor === "Yes"
                                                            ? validationConfig.required
                                                            : undefined,
                                                }}
                                                placeholder="Number of listings here..."
                                                className=""
                                            />
                                        </Transition>
                                        {/* )} */}

                                        {completed && (
                                            <ButtonElement
                                                type="submit"
                                                size="large"
                                                className={
                                                    "xs:mt-[36px] sm:mt-[36px] md:mt-[24px] lg:mt-[24px]  xl:mt-[24px] text-[#0E7090]"
                                                }
                                            >
                                                {t("button")}
                                            </ButtonElement>
                                        )}
                                    </Container>
                                </form>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>

            <MarketingFooter currentStep={4} />
        </>
    )
}
