export enum SearchpackAction {
    CREATE_SEARCHPACK = "CREATE_SEARCHPACK",
    ACTIVE_TAB = "ACTIVE_TAB",
    CREATE_SEARCHPACK_ACCOUNT = "CREATE_SEARCHPACK_ACCOUNT",
    ARCHIVE_SEARCHPACK = "ARCHIVE_SEARCHPACK",
    ARCHIVE_SEARCHPACK_SEARCH_TERM = "ARCHIVE_SEARCHPACK_SEARCH_TERM",
    GET_SEARCHPACKS_LIST = "GET_SEARCHPACKS_LIST",
    SET_SEARCHPACKS_LIST = "SET_SEARCHPACKS_LIST",
    SET_SELECTED_SEARCHPACK = "SET_SELECTED_SEARCHPACK",
    ADD_KEYWORDS_TO_SEARCHPACK = "ADD_KEYWORDS_TO_SEARCHPACK",
    GET_SEARCHPACK_WITH_LAST_DATA_TIME = "GET_SEARCHPACK_WITH_LAST_DATA_TIME",
    SET_SELECTED_SEARCHPACK_VALUES = "SET_SELECTED_SEARCHPACK_VALUES",
    SET_SELECTED_SEARCHPACK_NAME = "SET_SELECTED_SEARCHPACK_NAME",
    SET_SELECTED_SEARCHPACK_TERMS = "SET_SELECTED_SEARCHPACK_TERMS",
    SET_SEARCHPACKS_LAST_DATA_TIME = "SET_SEARCHPACKS_LAST_DATA_TIME",
    GET_RANKSNAPSHOT_DATA = "GET_RANKSNAPSHOT_DATA",
    SET_RANKSNAPSHOT_DATA = "SET_RANKSNAPSHOT_DATA",
    SELECT_SEARCHPACK_PRODUCT = "SELECT_SEARCHPACK_PRODUCT",
    SELECT_SEARCHPACK_PRODUCT_RANK = "SELECT_SEARCHPACK_PRODUCT_RANK",
    STORE_SEARCHPACK_PRODUCTS = "STORE_SEARCHPACK_PRODUCTS",
    SET_SEARCHPACK_WITHSEARCHTERM = "SET_SEARCHPACK_WITHTERM",
    GET_SEARCHPACK_WITHSEARCHTERM = "GET_SEARCHPACK_WITHTERM",
    GET_SEARCHPACK_SEARCHTERM_WITH_ID = "GET_SEARCHPACK_SEARCHTERM_WITH_ID",
    SET_KEYWORD_PAYLOAD = "SET_KEYWORD_PAYLOAD",
    GET_SEARCHPACK_RANK_TRACKER = "GET_SEARCHPACK_RANK_TRACKER",
    SET_SEARCHPACK_RANK_TRACKER = "SET_SEARCHPACK_RANK_TRACKER",
    GET_RANK_TRACKER_LISTING_CHANGE = "GET_RANK_TRACKER_LISTING_CHANGE",
    SET_RANK_TRACKER_LISTING_CHANGE = "SET_RANK_TRACKER_LISTING_CHANGE",
    GET_SEARCHPACK_KEYWORD_PERFORMANCE = "GET_SEARCHPACK_KEYWORD_PERFORMANCE",
    SET_SEARCHPACK_KEYWORD_PERFORMANCE = "SET_SEARCHPACK_KEYWORD_PERFORMANCE",
    SELECT_SEARCHPACK_PERFORMANCE_KEYWORD = "SELECT_SEARCHPACK_PERFORMANCE_KEYWORD",
    GET_SEARCHPACK_KEYWORD_PERFORMANCE_BSR = "GET_SEARCHPACK_KEYWORD_PERFORMANCE_BSR",
    SET_SEARCHPACK_KEYWORD_PERFORMANCE_BSR = "SET_SEARCHPACK_KEYWORD_PERFORMANCE_BSR",
    SELECT_SEARCHPACK_PERFORMANCE_KEYWORD_RANK = "SELECT_SEARCHPACK_PERFORMANCE_KEYWORD_RANK",
    GET_KEYWORD_PERFORMANCE_LISTING_CHANGE = "GET_KEYWORD_PERFORMANCE_LISTING_CHANGE",
    SET_KEYWORD_PERFORMANCE_LISTING_CHANGE = "SET_KEYWORD_PERFORMANCE_LISTING_CHANGE",
}

export const SearchpackActionCreator = {
    selectedSearchpackPayload: (payload: Partial<ISelectedSearchpackPayload>): ISagaAction => {
        return {
            type: SearchpackAction.CREATE_SEARCHPACK,
            payload,
        }
    },

    setActiveTab: (payload: string): ISagaAction => {
        return {
            type: SearchpackAction.ACTIVE_TAB,
            payload,
        }
    },

    createSearchpack: (
        payload: Partial<ISelectedSearchpackPayload>,
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: SearchpackAction.CREATE_SEARCHPACK_ACCOUNT,
            payload,
            onSuccess,
            onError,
        }
    },

    archive: (
        payload: Partial<IArchiveSearchPackAPIPayload>,
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: SearchpackAction.ARCHIVE_SEARCHPACK,
            payload,
            onSuccess,
            onError,
        }
    },

    archiveKeyword: (
        payload: IArchiveSearchPackSearchTermAPIPayload,
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: SearchpackAction.ARCHIVE_SEARCHPACK_SEARCH_TERM,
            payload,
            onSuccess,
            onError,
        }
    },

    getSearchpackList: (
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void,
        select?: number
    ) => {
        return {
            type: SearchpackAction.GET_SEARCHPACKS_LIST,
            payload: { pathParams, queryParams, select },
            onSuccess,
            onError,
        }
    },
    setSearchpacksList: (data: IProductTrackpackList) => ({
        type: SearchpackAction.SET_SEARCHPACKS_LIST,
        payload: data,
    }),

    resetSearchpacksList: () => ({
        type: SearchpackAction.SET_SEARCHPACKS_LIST,
        payload: undefined,
    }),

    setSelectedSearchpack: (data: number | null) => ({
        type: SearchpackAction.SET_SELECTED_SEARCHPACK,
        payload: data,
    }),
    addKeywordsToSearchpacks: (
        payload: Partial<ISelectedSearchpackPayload>,
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: SearchpackAction.ADD_KEYWORDS_TO_SEARCHPACK,
            payload,
            onSuccess,
            onError,
        }
    },
    getSearchpackWithLastDataTime: (
        searchpackId?: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_SEARCHPACK_WITH_LAST_DATA_TIME,
            payload: { searchpackId, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setSelectedSearchPackValues: (payload: any) => ({
        type: SearchpackAction.SET_SELECTED_SEARCHPACK_VALUES,
        payload,
    }),
    setSelectedSearchPackName: (payload: any) => ({
        type: SearchpackAction.SET_SELECTED_SEARCHPACK_NAME,
        payload,
    }),
    setSelectedSearchPackTerms: (payload: TSearchpackKeyword[]) => ({
        type: SearchpackAction.SET_SELECTED_SEARCHPACK_TERMS,
        payload,
    }),
    setSearchpackLastDataTime: (data: ISearchpackLastDataTime) => ({
        type: SearchpackAction.SET_SEARCHPACKS_LAST_DATA_TIME,
        payload: data,
    }),

    resetSearchpackLastDataTime: () => ({
        type: SearchpackAction.SET_SEARCHPACKS_LAST_DATA_TIME,
        payload: undefined,
    }),

    getRanksnapshotData: (
        searchpackId?: number,
        date?: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: any },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_RANKSNAPSHOT_DATA,
            payload: { searchpackId, date, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },

    setRanksnapshotData: (data: any) => ({
        type: SearchpackAction.SET_RANKSNAPSHOT_DATA,
        payload: data,
    }),

    resetRanksnapshotData: () => ({
        type: SearchpackAction.SET_RANKSNAPSHOT_DATA,
        payload: undefined,
    }),

    selectedSearchpackProduct: (payload: any): ISagaAction => {
        return {
            type: SearchpackAction.SELECT_SEARCHPACK_PRODUCT,
            payload,
        }
    },
    selectedSearchpackProductRank: (payload: any): ISagaAction => {
        return {
            type: SearchpackAction.SELECT_SEARCHPACK_PRODUCT_RANK,
            payload,
        }
    },
    selectedSearchpackKeywordPerformance: (payload: any): ISagaAction => {
        return {
            type: SearchpackAction.SELECT_SEARCHPACK_PERFORMANCE_KEYWORD,
            payload,
        }
    },

    selectedSearchpackKeywordPerformanceRank: (payload: any): ISagaAction => {
        return {
            type: SearchpackAction.SELECT_SEARCHPACK_PERFORMANCE_KEYWORD_RANK,
            payload,
        }
    },

    getSearchPackTermData: (
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_SEARCHPACK_WITHSEARCHTERM,
            payload: { pathParams, queryParams },
            onSuccess,
            onError,
        }
    },

    getSearchPackTermWithId: (packId?: number, onSuccess?: (data?: any) => void, onError?: () => void) => {
        return {
            type: SearchpackAction.GET_SEARCHPACK_SEARCHTERM_WITH_ID,
            payload: { packId },
            onSuccess,
            onError,
        }
    },

    setSelectedSearchPackTermData: (data: IProductSearchpackWithTerm) => ({
        type: SearchpackAction.SET_SEARCHPACK_WITHSEARCHTERM,
        payload: data,
    }),
    setSelectedKeywordPayload: (data: ISearchpackKeywordPayload) => ({
        type: SearchpackAction.SET_KEYWORD_PAYLOAD,
        payload: data,
    }),

    getSearchpackRankTracker: (
        packTermId?: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_SEARCHPACK_RANK_TRACKER,
            payload: { packTermId, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },

    setSearchpackRankTracker: (data: any) => ({
        type: SearchpackAction.SET_SEARCHPACK_RANK_TRACKER,
        payload: data,
    }),

    resetSearchpackRankTracker: () => ({
        type: SearchpackAction.SET_SEARCHPACK_RANK_TRACKER,
        payload: undefined,
    }),

    getSearchpackKeywordPerformance: (
        packId?: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_SEARCHPACK_KEYWORD_PERFORMANCE,
            payload: { packId, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setSearchpackKeywordPerformance: (data: any) => ({
        type: SearchpackAction.SET_SEARCHPACK_KEYWORD_PERFORMANCE,
        payload: data,
    }),

    getSearchpackKeywordPerformanceBsr: (
        packId?: number,
        asin?: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_SEARCHPACK_KEYWORD_PERFORMANCE_BSR,
            payload: { packId, asin, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setSearchpackKeywordPerformanceBsr: (data: any) => ({
        type: SearchpackAction.SET_SEARCHPACK_KEYWORD_PERFORMANCE_BSR,
        payload: data,
    }),

    getkRankTrackerListingChange: (
        packTermId?: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: IListingChangesResponse) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_RANK_TRACKER_LISTING_CHANGE,
            payload: { packTermId, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setRankTrackerListingChange: (data: IGroupedListingChanges) => ({
        type: SearchpackAction.SET_RANK_TRACKER_LISTING_CHANGE,
        payload: data,
    }),
    getSearchpackAsinKeywords: (
        packTermId?: number,
        asin?: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: IListingChangesResponse) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_RANK_TRACKER_LISTING_CHANGE,
            payload: { packTermId, asin, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },

    getKeywordPerformanceListingChange: (
        packTermId: number,
        asin: string,
        since?: string,
        onSuccess?: (data?: IListingChangesResponse) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_KEYWORD_PERFORMANCE_LISTING_CHANGE,
            payload: { packTermId, asin, since },
            onSuccess,
            onError,
        }
    },
    setKeywordPerformanceListingChange: (data: IGroupedListingChanges) => ({
        type: SearchpackAction.SET_KEYWORD_PERFORMANCE_LISTING_CHANGE,
        payload: data,
    }),
}
