import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { UtilHelper } from "../helpers/util.helper"

export class SearchpackService {
    static async createSearchpack(payload: Partial<any>): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.CREATE_SEARCPACK,

            { ...payload },

            "POST"
        )

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }
        // @ts-ignore
        return undefined
    }

    static async archiveSearchpack({ id, status }: IArchiveSearchPackAPIPayload): Promise<Response> {
        const url = HttpHelper.getUrl(API_ROUTES.ARCHIVE_SEARCPACK, {
            pack_id: id,
        })

        const result = await HttpHelper.sendRequest(url, { status: status }, "PATCH")

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }

        // @ts-ignore
        return undefined
    }

    static async archiveSearchpackSearchTerm({
        packId: pack_id,
        searchTerm,
        status,
    }: IArchiveSearchPackSearchTermAPIPayload): Promise<Response> {
        const url = HttpHelper.getUrl(API_ROUTES.ARCHIVE_SEARCPACK_SEARCH_TERM, {
            pack_id,
            status,
        })

        const result = await HttpHelper.sendRequest(url, { search_terms: [searchTerm] }, "POST")

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }

        // @ts-ignore
        return undefined
    }

    ///////////////////////// get Searchpack List //////////////////////////////

    static async getSearchpacksList(pathParams?: { [key: string]: string }, queryParams?: { [key: string]: string }) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACKS_LIST}${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<IProductTrackpackList>(result)
    }

    static async addKeywordToSearchpack(id: string, payload: any): Promise<Response> {
        const url = `${API_ROUTES.ADD_KEYWORDS_TO_SEARCHPACK}/${id}/searchterms/active`

        const result = await HttpHelper.sendRequest(
            url,

            { ...payload },

            "POST"
        )

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }
        // @ts-ignore
        return undefined
    }

    static async getSearchpackWithLastDataTime(
        searchpackId: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ): Promise<any> {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACKS_LIST}/${searchpackId}/with_last_timestamp${pathString}${queryString}`

        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<any>(result)
    }

    static async getRanksnapshotData(
        searchpackId: number,
        date?: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ): Promise<any> {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACKS_LIST}/${searchpackId}/rank_snapshot/date/${date}${pathString}${queryString}`

        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<any>(result)
    }

    static async getSearchpacksWithTerm(
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACK_WITHSEARCHTERM}${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<IProductSearchpackWithTerm>(result)
    }

    static async getSearchpackTermWithId(packId: number) {
        const url = `${API_ROUTES.GET_SEARCHPACKS_LIST}/${packId}/with_searchterms`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<IProductSearchpackWithTerm>(result)
    }

    static async getSearchpackTermTracker(
        packTermId: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACK_RANK_TRACKER}/${packTermId}/rank_tracker/data${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<any>(result)
    }

    static async getSearchpackKeywordPerformance(
        packId: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACK_KEYWORD_PERFORMANCE}/${packId}/keyword_performance/data${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")
        return HttpHelper.validateResponse<any>(result)
    }

    static async getTermTrackerListingChange(
        packTermId: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_RANK_TRACKER_LISTING_CHANGE}/${packTermId}/rank_tracker/listing_changes${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        const response = (await HttpHelper.validateResponse<IListingChangesResponse>(result)) as IListingChangesResponse

        // Group listing changes by day and then by ASIN
        const groupedChanges = response.listing_changes.reduce(
            (acc: { [key: string]: { [asin: string]: IListingChanges[] } }, change) => {
                // Get YYYY-MM-DD format from timestamp
                const day = new Date(change.timestamp).toISOString().split("T")[0]
                const asin = change.asin

                if (!acc[asin]) {
                    acc[asin] = {}
                }
                if (!acc[asin][day]) {
                    acc[asin][day] = []
                }
                acc[asin][day].push(change)
                return acc
            },
            {}
        )

        return {
            ...response,
            groupedListingChanges: groupedChanges,
        } as IGroupedListingChanges
    }

    static async getSearchpackKeywordPerformanceBsr(
        packId: number,
        asin: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACK_KEYWORD_PERFORMANCE_BSR}/${packId}/keyword_performance/asin/${asin}/bsr${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")
        return HttpHelper.validateResponse<any>(result)
    }

    static async getKeywordPerformanceListingChange(packId: number, asin: string, since?: string) {
        const result = await HttpHelper.sendRequest(
            HttpHelper.getUrl(API_ROUTES.GET_SEARCHPACK_KEYWORD_PERFORMANCE_LISTING_CHANGE, {
                pack_id: packId,
                asin: asin,
            }),
            { since },
            "GET"
        )

        type IListingChangesResponseWithAsin = IListingChangesResponse & {
            asin: string
        }

        const response = (await HttpHelper.validateResponse<IListingChangesResponseWithAsin>(
            result
        )) as IListingChangesResponseWithAsin

        // Group listing changes by day and then by ASIN
        const groupedChanges = response.listing_changes.reduce(
            (acc: { [key: string]: { [asin: string]: IListingChanges[] } }, change) => {
                // Get YYYY-MM-DD format from timestamp
                const day = new Date(change.timestamp).toISOString().split("T")[0]
                const asin = response.asin

                if (!acc[asin]) {
                    acc[asin] = {}
                }
                if (!acc[asin][day]) {
                    acc[asin][day] = []
                }
                acc[asin][day].push({
                    ...change,
                    asin: asin,
                })
                return acc
            },
            {}
        )

        return {
            ...response,
            groupedListingChanges: groupedChanges,
        } as IGroupedListingChanges
    }
}
