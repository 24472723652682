import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { extractVideoId } from "../helpers/util.helper"
import { RunTimeStateSelector } from "../selectors/RunTimeStateSelector"

export const useVideoCategories = (
    history: {
        present: IProductHistoryBase<IProductDataVideo[]>
        past: IProductHistoryBase<IProductDataVideo[]>
    },
    data: IProductData
) => {
    const { modal } = useSelector(RunTimeStateSelector)
    const scrollableDivRef = useRef<HTMLDivElement | null>(null)

    const [hasScroll, setHasScroll] = useState(false)
    const [showTopBorder, setShowTopBorder] = useState(false)
    const [showBottomBorder, setShowBottomBorder] = useState(false)
    const [currentVideo, setCurrentVideo] = useState<IProductDataVideo | null>(null)
    const [playing, setPlaying] = useState<boolean>(false)

    const videoIndex = modal?.index

    const [view, setView] = useState<"added" | "removed">("added")

    const { reorderedPastVideos, reorderedPresentVideos } = useMemo(() => {
        const pastVideos = history?.past?.value || []
        const presentVideos = history?.present?.value || []

        const reorderVideos = (videos: IProductDataVideo[], index: number | undefined): IProductDataVideo[] => {
            if (!videos || videos.length === 0 || index === undefined || index < 0 || index >= videos.length) {
                return videos
            }
            const reordered = [...videos]
            const [firstVideo] = reordered.splice(index, 1)
            reordered.unshift(firstVideo)
            return reordered
        }

        const getUniqueValues = (array1: IProductDataVideo[], array2: IProductDataVideo[]): IProductDataVideo[] => {
            return array1.filter(
                (item1) =>
                    !array2.some((item2) => extractVideoId(item2?.video_url) === extractVideoId(item1?.video_url))
            )
        }

        const reorderAndFilterUnique = (
            pastVideos: IProductDataVideo[],
            presentVideos: IProductDataVideo[],
            index: number | undefined
        ): { reorderedPastVideos: IProductDataVideo[]; reorderedPresentVideos: IProductDataVideo[] } => {
            const reorderedPastVideos = reorderVideos(pastVideos, index)
            const reorderedPresentVideos = reorderVideos(presentVideos, index)

            const uniqueReorderedPastVideos = getUniqueValues(reorderedPastVideos, reorderedPresentVideos)
            const uniqueReorderedPresentVideos = getUniqueValues(reorderedPresentVideos, reorderedPastVideos)

            return {
                reorderedPastVideos: uniqueReorderedPastVideos,
                reorderedPresentVideos: uniqueReorderedPresentVideos,
            }
        }

        const showUnavailablePast = data.videos?.length && !history.past
        const pastVideosPlaceholder = showUnavailablePast
            ? Array(presentVideos.length).fill({ video_url: null, thumbnail_url: null })
            : pastVideos

        return reorderAndFilterUnique(pastVideosPlaceholder, presentVideos, videoIndex)
    }, [data, history.past, history?.present?.value, videoIndex])

    const categorizeVideos = (videos: IProductDataVideo[]) => ({
        videosForProduct: videos.filter((video) => video.type === "videos_for_this_product" || video.type === ""),
        relatedVideos: videos.filter((video) => video.type === "related_videos"),
    })

    const videoCategories = useMemo(() => {
        const pastCategories = categorizeVideos(reorderedPastVideos)
        const presentCategories = categorizeVideos(reorderedPresentVideos)
        const categories = view === "added" ? presentCategories : pastCategories

        if (categories.videosForProduct.length) {
            setCurrentVideo(categories.videosForProduct[0])
        } else if (categories.relatedVideos.length) {
            setCurrentVideo(categories.relatedVideos[0])
        }

        return categories
    }, [reorderedPastVideos, reorderedPresentVideos, view])

    useEffect(() => {
        if (!currentVideo) {
            if (videoCategories.videosForProduct.length) {
                setCurrentVideo(videoCategories.videosForProduct[0])
            } else if (videoCategories.relatedVideos.length) {
                setCurrentVideo(videoCategories.relatedVideos[0])
            }
        }
    }, [currentVideo, videoCategories, view])

    useEffect(() => {
        if (scrollableDivRef.current) {
            setHasScroll(scrollableDivRef.current.scrollHeight > scrollableDivRef.current.clientHeight)
        }
    }, [videoCategories])

    useEffect(() => {
        const scrollableDiv = scrollableDivRef.current // Capture the current ref value

        const handleScroll = () => {
            if (scrollableDiv) {
                const scrollTop = scrollableDiv.scrollTop
                const clientHeight = scrollableDiv.clientHeight
                const scrollHeight = scrollableDiv.scrollHeight

                const isAtTop = scrollTop === 0
                const isAtBottom = scrollTop + clientHeight >= scrollHeight

                // Only bottom border at the top
                if (isAtTop) {
                    setShowTopBorder(false)
                    setShowBottomBorder(true)
                }
                // Only top border at the bottom
                else if (isAtBottom) {
                    setShowTopBorder(true)
                    setShowBottomBorder(false)
                }
                // Both borders in the middle
                else {
                    setShowTopBorder(true)
                    setShowBottomBorder(true)
                }
            }
        }

        // Initial check and adding event listeners
        handleScroll()
        window.addEventListener("resize", handleScroll)
        scrollableDiv?.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("resize", handleScroll)
            scrollableDiv?.removeEventListener("scroll", handleScroll)
        }
    }, [])

    // useEffect(() => {
    //     if (!currentVideo) {
    //         if (videoCategories.videosForProduct.length) {
    //             setCurrentVideo(videoCategories.videosForProduct[0])
    //         } else if (videoCategories.relatedVideos.length) {
    //             setCurrentVideo(videoCategories.relatedVideos[0])
    //         }
    //     }
    // }, [currentVideo, videoCategories])

    const handleVideoSelect = useCallback(
        (index: number, isForProduct: boolean) => {
            const selectedVideo = isForProduct
                ? videoCategories.videosForProduct[index]
                : videoCategories.relatedVideos[index]
            setCurrentVideo(selectedVideo)
            // setPlayingIndex(index)
            setPlaying(false)
        },
        [videoCategories]
    )

    const currentDisplayedVideo = playing ? currentVideo : null

    return {
        currentVideo,
        playing,
        videoCategories,
        currentDisplayedVideo,
        view,
        setView,
        hasScroll,
        showTopBorder,
        showBottomBorder,
        scrollableDivRef,
        handleVideoSelect,
        setCurrentVideo,
        setPlaying,
    }
}
