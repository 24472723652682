import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { PrimaryText } from "../../../elements/primaryText.element"

import dayjs from "dayjs"
import { ReactComponent as LeftScrollArrow } from "../../../../assets/svgs/leftScrollArrow.svg"
import { ReactComponent as RightScrollArrow } from "../../../../assets/svgs/rightScrollArrow.svg"
import { img_url } from "../../../../config/dashboard.config"
import { ASIN_AMAZON_LINK_BASE } from "../../../../config/routes.config"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { SEATTLE_TIMEZONE } from "../../../../helpers/util.helper"
import { useDateRange } from "../../../../hooks/app/searchpack/useRange"
import { useLoadingStatus } from "../../../../hooks/useLoadingStatus.hook"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../../selectors/searchpack.selector"
import { dateUtils } from "../../../../utils/date"
import { extendedDayjs } from "../../../../utils/dayjs"
import RankSnapshotSkeletonComponent from "../../../common/skeletonLoader/rankSnapShotSkeleton.component"

const RankTrackerTableView = ({
    selectedSearchpackKeywordsData,
    selectedRange,
    tableFilters,
}: {
    selectedSearchpackKeywordsData: ISearchpackKeywordData[]
    selectedRange: number
    tableFilters: Array<"Paid" | "Organic">
}) => {
    const { loading } = useSelector(RunTimeStateSelector)
    const isGetRankTrackerDataLoading: boolean = useLoadingStatus(loading, RuntimeHelper.getRanktrackerDataLoading())

    const parentRef = useRef<HTMLDivElement>(null)
    const trackRef = useRef<HTMLDivElement>(null)
    const itemRefs = useRef<(HTMLDivElement | null)[]>([])
    const isDragging = useRef(false)
    const startDragX = useRef(0)
    const [canScrollLeft, setCanScrollLeft] = useState(false)
    const [canScrollRight, setCanScrollRight] = useState(false)
    const [thumbPosition, setThumbPosition] = useState(0)
    const [thumbWidth, setThumbWidth] = useState(0)

    const dispatch = useDispatch()

    const { selectedSearchpackLastDataTime, selectedSearchpackProductsRank, searchpackRankTracker } =
        useSelector(SearchpackStateSelector)

    const amazonTld = selectedSearchpackLastDataTime?.amazon_tld ?? "com"
    const url = ASIN_AMAZON_LINK_BASE.replace("com", amazonTld)

    useEffect(() => {
        const updateScrollState = () => {
            const parent = parentRef.current
            if (parent) {
                const { scrollWidth, clientWidth, scrollLeft } = parent
                const track = trackRef.current

                if (track) {
                    const thumbWidthRatio = clientWidth / scrollWidth
                    setThumbWidth(track.clientWidth * thumbWidthRatio)
                    setThumbPosition((scrollLeft / scrollWidth) * track.clientWidth)
                }

                setCanScrollLeft(scrollLeft > 0)
                setCanScrollRight(scrollLeft + clientWidth < scrollWidth)
            }
        }

        const parent = parentRef.current
        if (parent) {
            updateScrollState()
            parent.addEventListener("scroll", updateScrollState)
        }

        window.addEventListener("resize", updateScrollState)

        return () => {
            if (parent) parent.removeEventListener("scroll", updateScrollState)
            window.removeEventListener("resize", updateScrollState)
        }
    }, [selectedSearchpackProductsRank?.selectedProducts])

    useEffect(() => {
        const updateScrollState = () => {
            const parent = parentRef.current
            if (parent) {
                const { scrollWidth, clientWidth } = parent
                const track = trackRef.current

                if (track) {
                    const thumbWidthRatio = clientWidth / scrollWidth
                    setThumbWidth(track.clientWidth * thumbWidthRatio)
                    setThumbPosition((parent.scrollLeft / scrollWidth) * track.clientWidth)
                }

                setCanScrollLeft(parent.scrollLeft > 0)
                setCanScrollRight(parent.scrollLeft + clientWidth < scrollWidth)
            }
        }

        const observer = new MutationObserver(updateScrollState)
        if (parentRef.current) {
            observer.observe(parentRef.current, { childList: true, subtree: true })
        }

        updateScrollState() // Initial calculation

        return () => observer.disconnect()
    }, [selectedSearchpackProductsRank?.selectedProducts])

    useEffect(() => {
        if (selectedSearchpackProductsRank?.selectedIndex !== undefined) {
            const index = selectedSearchpackProductsRank.selectedIndex
            const targetItem = itemRefs.current[index]

            if (targetItem && parentRef.current) {
                const parent = parentRef.current
                const itemOffsetLeft = targetItem.offsetLeft
                const itemWidth = targetItem.clientWidth
                const parentWidth = parent.clientWidth
                const newScrollLeft = itemOffsetLeft - (parentWidth - itemWidth) / 2

                parent.scrollTo({
                    left: newScrollLeft,
                    behavior: "smooth",
                })
            }
        }
    }, [selectedSearchpackProductsRank?.selectedIndex, selectedSearchpackProductsRank?.selectedProducts])

    const handleScroll = useCallback((direction: "left" | "right") => {
        const parent = parentRef.current
        if (parent) {
            const { clientWidth } = parent
            const scrollAmount = clientWidth / 2
            const newScrollLeft =
                direction === "left"
                    ? Math.max(parent.scrollLeft - scrollAmount, 0)
                    : Math.min(parent.scrollLeft + scrollAmount, parent.scrollWidth - clientWidth)

            parent.scrollTo({ left: newScrollLeft, behavior: "smooth" })
        }
    }, [])

    const handleThumbDragStart = (e: React.MouseEvent | React.TouchEvent) => {
        isDragging.current = true
        startDragX.current = "touches" in e ? e.touches[0].clientX : e.clientX
    }

    const handleThumbDragMove = useCallback(
        (e: MouseEvent | TouchEvent) => {
            if (!isDragging.current) return

            const parent = parentRef.current
            const track = trackRef.current

            if (parent && track) {
                const currentX = "touches" in e ? e.touches[0].clientX : e.clientX
                const deltaX = currentX - startDragX.current
                startDragX.current = currentX

                const trackWidth = track.clientWidth
                const { scrollWidth } = parent

                const newThumbPosition = Math.min(Math.max(thumbPosition + deltaX, 0), trackWidth - thumbWidth)

                setThumbPosition(newThumbPosition)

                const newScrollLeft = (newThumbPosition / trackWidth) * scrollWidth
                parent.scrollTo({ left: newScrollLeft })
            }
        },
        [thumbPosition, thumbWidth]
    )

    const handleThumbDragEnd = () => {
        isDragging.current = false
    }

    useEffect(() => {
        if (isDragging.current) {
            document.addEventListener("mousemove", handleThumbDragMove)
            document.addEventListener("touchmove", handleThumbDragMove)
            document.addEventListener("mouseup", handleThumbDragEnd)
            document.addEventListener("touchend", handleThumbDragEnd)
        }

        return () => {
            document.removeEventListener("mousemove", handleThumbDragMove)
            document.removeEventListener("touchmove", handleThumbDragMove)
            document.removeEventListener("mouseup", handleThumbDragEnd)
            document.removeEventListener("touchend", handleThumbDragEnd)
        }
    }, [thumbPosition, selectedSearchpackProductsRank?.selectedProducts, handleThumbDragMove])

    const hasToday = useMemo(() => {
        const today = extendedDayjs().tz(SEATTLE_TIMEZONE).format("YYYY-MM-DD")
        return !!selectedSearchpackKeywordsData.find((data) => data.label === today)
    }, [selectedSearchpackKeywordsData])

    const dateRange = useDateRange(selectedRange, "string", hasToday, false, SEATTLE_TIMEZONE)

    const computedDateRange = useMemo(() => {
        if (selectedRange) return dateRange
        if (!selectedSearchpackKeywordsData.length) return []
        const first = selectedSearchpackKeywordsData[0]
        let oldest = extendedDayjs(first.label).startOf("day")
        let newest = oldest.clone()

        selectedSearchpackKeywordsData.forEach(({ label }) => {
            const dateObj = extendedDayjs(label)
            if (dateObj.isBefore(oldest)) oldest = dateObj
            if (dateObj.isAfter(newest)) newest = dateObj
        })

        return dateUtils.calendar.formRange(oldest.format("YYYY-MM-DD"), newest.format("YYYY-MM-DD"))
    }, [dateRange, selectedRange, selectedSearchpackKeywordsData])

    const data = useMemo(() => {
        let preparedData: typeof selectedSearchpackKeywordsData = []

        computedDateRange.forEach((date) => {
            const existing = selectedSearchpackKeywordsData.find((i) => i.label === date)
            if (existing) return preparedData.push(existing)

            return preparedData.push({
                label: `${date}`,
                asinRanks:
                    selectedSearchpackProductsRank?.selectedProducts?.map(({ asin, color }) => ({
                        asin,
                        color,
                    })) || [],
            })
        })

        return preparedData
    }, [computedDateRange, selectedSearchpackKeywordsData, selectedSearchpackProductsRank?.selectedProducts])

    const formattedSelectedSearchpackKeywordsData = useMemo(() => {
        return dateUtils.sortObjectsByDate("label", data, "YYYY-MM-DD", "desc")
    }, [data])

    return (
        <div className=" rounded-lg">
            <div
                className={"overflow-auto top-scroll -mr-[28px]"}
                onScroll={() => {
                    dispatch(RuntimeActionCreators.closeAllPopup())
                }}
            >
                <div
                    className=""
                    style={{
                        maxHeight: "calc(100vh - 277px)",
                        width: "calc(100% - 28px)",
                    }}
                >
                    {isGetRankTrackerDataLoading ? (
                        <RankSnapshotSkeletonComponent />
                    ) : (searchpackRankTracker && searchpackRankTracker?.search_rank_data_by_date?.length === 0) ||
                      (selectedSearchpackProductsRank?.selectedProducts &&
                          selectedSearchpackProductsRank?.selectedProducts?.length === 0) ? (
                        <RankSnapshotSkeletonComponent isStatic={true} />
                    ) : (
                        selectedSearchpackProductsRank?.selectedProducts &&
                        selectedSearchpackProductsRank?.selectedProducts?.length > 0 && (
                            <>
                                <div className="rounded-[10px] h-full  border border-gray-200 bg-gray-200 rounded-bl-[10px] rounded-br-[10px] overflow-hidden ">
                                    <div className="flex items-center pt-[12px] gap-x-[32px] pr-[12px] pl-[34px]  ">
                                        <div className="min-w-[114px]"></div>

                                        <div className="text-black flex items-center flex-1 gap-[10px]">
                                            {/* Left Arrow */}
                                            <button
                                                onClick={() => handleScroll("left")}
                                                disabled={!canScrollLeft}
                                                className={` ${
                                                    !canScrollLeft ? " cursor-not-allowed" : "cursor-pointer"
                                                }`}
                                            >
                                                <LeftScrollArrow />
                                            </button>

                                            <div
                                                ref={trackRef}
                                                className="relative w-full bg-gray-200 h-[4px] my-[3px]"
                                            >
                                                <div
                                                    className="bg-[#D0D5DD] h-[4px] absolute rounded-[8px] cursor-pointer"
                                                    style={{ width: `${thumbWidth}px`, left: `${thumbPosition}px` }}
                                                    onMouseDown={handleThumbDragStart}
                                                    onTouchStart={handleThumbDragStart}
                                                ></div>
                                            </div>

                                            {/* Right Arrow */}
                                            <button
                                                onClick={() => handleScroll("right")}
                                                disabled={!canScrollRight}
                                                className={` ${
                                                    !canScrollRight ? " cursor-not-allowed" : "cursor-pointer"
                                                }`}
                                            >
                                                <RightScrollArrow />
                                            </button>
                                        </div>
                                    </div>
                                    <div ref={parentRef} className={` scroll-hidden overflow-x-auto `}>
                                        <div className="bg-gray-200">
                                            <div
                                                className={
                                                    "flex py-[16px] min-w-fit  rounded-tr-[8px] rounded-tl-[8px] border-b border-[#EEE] z-20 relative"
                                                }
                                            >
                                                <div
                                                    className={
                                                        "py-[8px] pl-[24px] flex items-end sticky left-0 bg-gray-200 justify-start min-w-[156px] "
                                                    }
                                                >
                                                    <PrimaryText
                                                        size={"small"}
                                                        weight={"medium"}
                                                        className={"w-[156px] text-gray-700 leading-[20px]"}
                                                    >
                                                        ASINs
                                                    </PrimaryText>
                                                </div>
                                                {selectedSearchpackProductsRank?.selectedProducts.map(
                                                    (data: ISearchcardDataWithLatestRank, index: number) => {
                                                        return (
                                                            <Link to={url + data.asin} target="_blank" key={data.asin}>
                                                                <div
                                                                    className="min-w-[108px] flex flex-col items-center text-center"
                                                                    ref={(el) => (itemRefs.current[index] = el)}
                                                                >
                                                                    <img
                                                                        className="w-[68px] h-[68px] rounded-[6px] object-contain bg-white"
                                                                        style={{
                                                                            border: `1px solid ${data.color.default}`,
                                                                        }}
                                                                        src={
                                                                            data.image_filename
                                                                                ? `${img_url}${data.image_filename}`
                                                                                : ""
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </Link>
                                                        )
                                                    }
                                                )}
                                            </div>

                                            {/* Sub-header for Keywords and PAID | ORG. */}
                                            <div className="flex border-b border-[#EEE] min-w-fit bg-gray-100 z-10 relative">
                                                <div
                                                    className={
                                                        "py-[16px] bg-gray-100 pl-[24px] flex items-center sticky left-0 min-w-[156px] justify-start"
                                                    }
                                                >
                                                    <PrimaryText
                                                        size={"xs"}
                                                        weight={"medium"}
                                                        className={"text-gray-700 w-[156px]"}
                                                    >
                                                        Keywords
                                                    </PrimaryText>
                                                </div>
                                                {selectedSearchpackProductsRank?.selectedProducts.map(
                                                    (_: ISearchcardDataWithLatestRank, index: number) => (
                                                        <div
                                                            key={`paid-org-header-${_.asin}`}
                                                            className="min-w-[108px] text-center py-[16px] flex items-center justify-center"
                                                        >
                                                            {tableFilters.includes("Paid") &&
                                                            tableFilters.includes("Organic") ? (
                                                                <div className=" inline-flex gap-[8px]">
                                                                    <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                        PAID
                                                                    </span>
                                                                    <span className="text-[8px] leading-[10px] font-400 text-gray-300">
                                                                        |
                                                                    </span>
                                                                    <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                        ORG.
                                                                    </span>
                                                                </div>
                                                            ) : tableFilters.includes("Paid") ? (
                                                                <div className=" inline-flex gap-[8px]">
                                                                    <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                        PAID
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                    ORG.
                                                                </span>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>

                                            {formattedSelectedSearchpackKeywordsData.map(
                                                (item: ISearchpackKeywordData, rowIndex: number) => {
                                                    const { label, asinRanks } = item
                                                    return (
                                                        <div
                                                            key={`keyword-row-${item.label}`}
                                                            className="flex border-b border-[#EEE] min-w-fit bg-white z-10 relative last:border-b-0 last:rounded-br-[8px] last:rounded-bl-[8px]"
                                                        >
                                                            <div
                                                                className={
                                                                    "py-[16px] pl-[24px] flex items-center bg-white sticky left-0 min-w-[156px] justify-start"
                                                                }
                                                            >
                                                                <PrimaryText
                                                                    size={"xs"}
                                                                    weight={"light"}
                                                                    className={"text-gray-700 w-[156px] uppercase"}
                                                                >
                                                                    {dayjs(label).format("MMM-DD-YYYY")}
                                                                </PrimaryText>
                                                            </div>

                                                            {/* Render columns dynamically */}
                                                            {asinRanks.map((rank: IAsinRank, index: number) => (
                                                                <div
                                                                    key={`paid-org-${rowIndex}-${rank.asin}`}
                                                                    className="min-w-[108px] flex flex-col items-center text-center py-[16px]"
                                                                >
                                                                    <div className="flex items-center gap-[8px]">
                                                                        {tableFilters.includes("Organic") &&
                                                                        tableFilters.includes("Paid") ? (
                                                                            <>
                                                                                <span className="text-xs leading-[16px] font-[300] text-gray-700 min-w-[32px]">
                                                                                    {rank.paid || "-"}
                                                                                </span>
                                                                                <span className="text-gray-200 text-xs font-300 leading-[16px]">
                                                                                    |
                                                                                </span>
                                                                                <span className="text-xs font-[300] text-gray-700 leading-[16px] min-w-[32px]">
                                                                                    {rank.organic || "-"}
                                                                                </span>
                                                                            </>
                                                                        ) : tableFilters.includes("Paid") ? (
                                                                            <span className="text-xs leading-[16px] font-[300] text-gray-700 min-w-[32px]">
                                                                                {rank.paid || "-"}
                                                                            </span>
                                                                        ) : (
                                                                            <span className="text-xs leading-[16px] font-[300] text-gray-700 min-w-[32px]">
                                                                                {rank.organic || "-"}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    )}
                    <div style={{ height: "12px" }}></div>
                </div>
            </div>
        </div>
    )
}

export default RankTrackerTableView
