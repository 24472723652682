import dayjs from "dayjs"
import { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router"

import { useVirtualizer } from "@tanstack/react-virtual"
import { ASIN_AMAZON_LINK_BASE } from "../../../../config/routes.config"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { PrimaryText } from "../../../elements/primaryText.element"

import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { TableProductDataItem } from "./tableData.component"
import { TableTooltip } from "./tableTooltip.component"

export const TableView = (props: ITableData) => {
    const { selectedProducts, selectedProductRange, selectedDropdownList } = useSelector(ProductStateSelector)
    const parentRef = useRef<HTMLDivElement>(null)
    const [showBorder, setShowBorder] = useState(false)
    const { popups } = useSelector(RunTimeStateSelector)

    const [, setIsScrolledToBottom] = useState(false)

    const dispatch = useDispatch()
    const selectedTrackpackID = localStorage.getItem("selectedTrackpackID")

    const range = useMemo(() => {
        return selectedProductRange?.selectedRange || 0
    }, [selectedProductRange?.selectedRange])

    const compiledProductData = useMemo(() => {
        const data: ICompiledProductData = {}

        selectedProducts?.selectedProducts?.forEach((product) => {
            let startDate: dayjs.Dayjs
            const endDate: dayjs.Dayjs = dayjs().startOf("day")
            startDate = endDate.subtract(range, "day").startOf("day")
            const filteredProductData =
                range === 0
                    ? product?.product_data
                    : product?.product_data?.filter((record) => {
                          const timestamp: dayjs.Dayjs = dayjs(record.timestamp)
                          return timestamp.isAfter(startDate) && timestamp.isBefore(endDate.add(1, "day"))
                      })
            filteredProductData?.forEach((pData: IProductData) => {
                const mappedProducts = data[pData.timestamp]

                let tempProducts: {
                    color: TAsinProductColor
                    data?: IProductData & { currency?: any }
                    id: string
                }[] = []

                if (mappedProducts) {
                    const tempP = mappedProducts?.products?.find((p) => p.id === product?.actual_asin)

                    if (tempP) {
                        tempP.data = pData
                    }
                    tempProducts = [...mappedProducts.products]
                } else {
                    selectedProducts?.selectedProducts?.forEach((allProduct) => {
                        const newData =
                            product?.actual_asin === allProduct?.actual_asin
                                ? { ...pData, currency: product?.main_product_data?.currency }
                                : undefined
                        tempProducts.push({
                            color: allProduct?.color,
                            // @ts-ignore
                            data: newData,
                            id: allProduct?.actual_asin,
                        })
                    })

                    tempProducts = [...tempProducts]
                }
                data[pData.timestamp] = {
                    products: tempProducts,
                }
            })
        })
        // Transform the object into an array of key-value pairs
        const dataArray = Object.entries(data)

        // Sort the array based on the timestamp in descending order
        dataArray.sort((a, b) => {
            return dayjs(a[0]).unix() - dayjs(b[0]).unix()
        })

        // // Reconstruct the object from the sorted array
        // const sortedData: ICompiledProductData = {}
        // dataArray.forEach(([timestamp, products]) => {
        //     sortedData[timestamp] = products
        // })

        return dataArray.reverse()
    }, [range, selectedProducts?.selectedProducts])

    const rowVirtualizer = useVirtualizer({
        count: compiledProductData.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 185,
        // overscan: 5,
    })

    useEffect(() => {
        const handleScroll = () => {
            // for close tooltip
            if (popups) {
                dispatch(RuntimeActionCreators.closeAllPopup())
            }

            if (parentRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = parentRef.current
                const isBottom = scrollHeight - scrollTop === clientHeight
                setIsScrolledToBottom(isBottom)
                setShowBorder(parentRef.current.scrollTop > 0)
            }
        }

        const parentElement = parentRef.current
        if (parentElement) {
            parentElement.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (parentElement) {
                parentElement.removeEventListener("scroll", handleScroll)
            }
        }
    }, [dispatch, popups])

    const selectedObject = selectedDropdownList?.trackpacks?.find(
        (item: { metadata: { id: number | string } }) => item.metadata.id === selectedTrackpackID
    )

    const amazonTld = selectedObject?.metadata?.amazon_tld ?? "com"
    const url = ASIN_AMAZON_LINK_BASE.replace("com", amazonTld)
    return (
        <>
            <div
                className={"overflow-auto top-scroll -mr-[28px]"}
                onScroll={() => {
                    dispatch(RuntimeActionCreators.closeAllPopup())
                }}
            >
                <div
                    className={`${props.infoVisible ? "h-calc-359" : "h-calc-225"}`}
                    style={{
                        width: "calc(100% - 23px)",
                    }}
                >
                    {selectedProducts?.selectedProducts && selectedProducts?.selectedProducts.length > 0 && (
                        <div
                            ref={parentRef}
                            className={`rounded-[10px] border border-gray-200 h-[100px] scroll-hidden overflow-x-auto ${
                                showBorder ? "border-t border-gray-200 pb-[14px]" : ""
                            }`}
                            style={{
                                minHeight: `${props.infoVisible ? "calc(100vh - 373px)" : "calc(100vh - px)"}`,
                                height: `${rowVirtualizer.getTotalSize() + 98}px`,
                                width: "100%",
                                position: "relative",
                            }}
                        >
                            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                                const index = virtualRow.index
                                const item = compiledProductData[index]
                                if (!item) {
                                    return <></>
                                }

                                const timestamp = item[0]
                                const products = item[1].products

                                return (
                                    <>
                                        <div
                                            key={index + "table-data"}
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: `${virtualRow.index === 0 ? 305 : virtualRow.size}px`,
                                                transform: `translateY(${
                                                    virtualRow.index === 1
                                                        ? 281
                                                        : virtualRow.index === 0
                                                        ? 0
                                                        : virtualRow.start + (281 - 185)
                                                }px)`,
                                            }}
                                        >
                                            {index === 0 && (
                                                <>
                                                    <div
                                                        className={
                                                            "flex py-[8px] min-w-fit bg-[#EAECF0] rounded-tr-[8px] rounded-tl-[8px] border-b border-[#EEE] h-[96px] z-20 relative"
                                                        }
                                                        // style={{ transform: "rotateX(180deg)" }}
                                                    >
                                                        <div
                                                            className={
                                                                "py-[8px] pl-[24px] flex items-center sticky left-0 bg-[#EAECF0] justify-start"
                                                            }
                                                        >
                                                            <PrimaryText
                                                                size={"xs"}
                                                                weight={"medium"}
                                                                className={"w-[208px] text-gray-700"}
                                                            >
                                                                ASINs
                                                            </PrimaryText>
                                                        </div>

                                                        {selectedProducts?.selectedProducts.map((data, index) => {
                                                            return (
                                                                <Link to={url + data?.actual_asin} target="_blank">
                                                                    <div
                                                                        className="min-w-[172px] py-[8px] flex flex-col items-center text-center"
                                                                        key={index}
                                                                    >
                                                                        <img
                                                                            className="w-[68px] h-[68px] rounded-[6px] object-contain bg-gray-800"
                                                                            style={{
                                                                                border: `1px solid ${data?.color?.default}`,
                                                                            }}
                                                                            src={
                                                                                data?.main_product_data?.main_image
                                                                                    ? data?.main_product_data
                                                                                          ?.main_image
                                                                                    : ""
                                                                            }
                                                                            alt={""}
                                                                        />
                                                                    </div>
                                                                </Link>
                                                            )
                                                        })}
                                                    </div>
                                                </>
                                            )}
                                            <div
                                                className={
                                                    "flex  border-b border-[#EEE] min-w-fit bg-white z-10 relative"
                                                }
                                            >
                                                <div
                                                    className={
                                                        "flex items-center justify-start py-[27px] pl-[24px] text-start sticky left-0 bg-white z-10"
                                                    }
                                                >
                                                    <PrimaryText
                                                        size={"xs"}
                                                        weight={"medium"}
                                                        className={"text-gray-700 w-[208px]"}
                                                    >
                                                        {dayjs(timestamp)
                                                            .format("MMM-DD-YYYY")
                                                            ?.replace(/^\w+/, (month) => month.toUpperCase())}
                                                    </PrimaryText>
                                                </div>

                                                {products.map((tooltipProduct, iconIndex) => {
                                                    // const isSuccessWarning =
                                                    //     tooltipProduct?.data?.warnings?.includes("success")

                                                    // let isWarning = false
                                                    // if (!isSuccessWarning) {
                                                    //     isWarning =
                                                    //         !!tooltipProduct?.data?.warnings &&
                                                    //         tooltipProduct?.data?.warnings.length > 0
                                                    // }
                                                    return (
                                                        <div
                                                            key={iconIndex + "tooltip"}
                                                            className={
                                                                "min-w-[172px] flex flex-col gap-[6px] items-center justify-center py-[12px]"
                                                            }
                                                        >
                                                            <TableTooltip
                                                                color={tooltipProduct?.color}
                                                                product={tooltipProduct?.data}
                                                                iconNumber={iconIndex}
                                                                id={tooltipProduct.id}
                                                            />

                                                            {/* <div className="">
                                                                {isSuccessWarning ? (
                                                                    <div
                                                                        data-tooltip-id={"tracking-error-card-element"}
                                                                        data-tooltip-content={JSON.stringify({
                                                                            warnings: tooltipProduct?.data?.warnings,
                                                                        })}
                                                                    >
                                                                        <ErrorProduct />
                                                                    </div>
                                                                ) : (
                                                                    isWarning && (
                                                                        <div
                                                                            data-tooltip-id={"tracking-off-element"}
                                                                            data-tooltip-content={JSON.stringify({
                                                                                warnings:
                                                                                    tooltipProduct?.data?.warnings,
                                                                            })}
                                                                        >
                                                                            <WarningProductIcon />
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div> */}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <TableProductDataItem products={products} />
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    )}
                    <div style={{ height: "12px" }}></div>
                </div>
            </div>
        </>
    )
}
