export const NotificationIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.6654 18.6673V21.934C18.6654 23.2408 18.6654 23.8942 18.411 24.3933C18.1873 24.8323 17.8304 25.1893 17.3913 25.413C16.8922 25.6673 16.2388 25.6673 14.932 25.6673H6.06536C4.75858 25.6673 4.10518 25.6673 3.60605 25.413C3.16701 25.1893 2.81005 24.8323 2.58635 24.3933C2.33203 23.8942 2.33203 23.2408 2.33203 21.934V13.0673C2.33203 11.7605 2.33203 11.1071 2.58635 10.608C2.81005 10.169 3.16701 9.81201 3.60605 9.5883C4.10518 9.33398 4.75858 9.33398 6.06536 9.33398H9.33203M13.0654 18.6673H21.932C23.2388 18.6673 23.8922 18.6673 24.3913 18.413C24.8304 18.1893 25.1873 17.8323 25.411 17.3933C25.6654 16.8942 25.6654 16.2408 25.6654 14.934V6.06732C25.6654 4.76053 25.6654 4.10713 25.411 3.60801C25.1873 3.16896 24.8304 2.81201 24.3913 2.5883C23.8922 2.33398 23.2388 2.33398 21.932 2.33398H13.0654C11.7586 2.33398 11.1052 2.33398 10.6061 2.5883C10.167 2.81201 9.81005 3.16896 9.58635 3.60801C9.33203 4.10713 9.33203 4.76053 9.33203 6.06732V14.934C9.33203 16.2408 9.33203 16.8942 9.58635 17.3933C9.81005 17.8323 10.167 18.1893 10.6061 18.413C11.1052 18.6673 11.7586 18.6673 13.0654 18.6673Z"
                stroke="#0E7090"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
