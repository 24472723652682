import { Menu, Transition } from "@headlessui/react"
import { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from "react"
import { useController } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Container } from "./container.element"
import { PrimaryText } from "./primaryText.element"

import { ReactComponent as AlertIcon } from "../../assets/svgs/alert-icon.svg"
import { ReactComponent as CheckboxIcon } from "../../assets/svgs/check-box-icon.svg"
import { ReactComponent as CheckIcon } from "../../assets/svgs/check-icon.svg"
import { ChevronDownIcon } from "../../assets/svgs/chevrondownIcon.svg"
import { ReactComponent as RadioCheckboxIcon } from "../../assets/svgs/radio-check-icon.svg"
import { ReactComponent as RadioUnCheckboxIcon } from "../../assets/svgs/radio-uncheck-icon.svg"
import { ReactComponent as UnCheckboxIcon } from "../../assets/svgs/uncheck-box-icon.svg"
import CommonTooltip from "../dashboard/tooltipItems/commonTooltip.component"

export const SelectElement = (props: ISelectElementProps) => {
    const { t } = useTranslation("input")
    const ref = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
    const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null)
    const { onChange } = props

    const { field, fieldState } = useController({
        name: props.name,
        control: props.reactHookControl,
        rules: props.reactHookValidations,
        defaultValue: props.defaultValue || "",
    })

    let [selectedOption, setSelectedOption] = useState<{
        [key: string]: IOptionItem | undefined
    }>(() => {
        if (Array.isArray(props.defaultValue)) {
            let result: any = {}
            props.defaultValue.forEach((element) => {
                result[element] = props?.options?.find((o: any) => o.value === element)
            })
            return result
        }

        return {
            [field.value]: props?.options?.find((o: any) => o.value === field.value),
        }
    })

    useEffect(() => {
        if (Array.isArray(props.defaultValue)) {
            let result: any = {}
            props.defaultValue.forEach((element) => {
                result[element] = props?.options?.find((o: any) => o.value === element)
            })
            setSelectedOption(result)
        } else {
            const option = props?.options?.find((o: any) => o.value === props.defaultValue)
            option &&
                setSelectedOption({
                    [String(props.defaultValue)]: option,
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValue, props.options])

    const hasAllValues: boolean = useMemo(() => {
        const valuesA = props?.options?.map((item: any) => item.value)
        return valuesA?.every((value: any) => {
            return selectedOption[value]
        })
    }, [selectedOption, props?.options])

    const { checked, uncheck } = useMemo(() => {
        let stateIcons: {
            checked: ReactElement
            uncheck?: ReactElement
        } = {
            checked: <CheckIcon />,
        }
        switch (props?.type) {
            case "checkbox":
                stateIcons.checked = <CheckboxIcon />
                stateIcons.uncheck = <UnCheckboxIcon />
                break
            case "radio":
                stateIcons.checked = <RadioCheckboxIcon />
                stateIcons.uncheck = <RadioUnCheckboxIcon />
                break
        }
        return stateIcons
    }, [props.type])

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsDropdownOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    const selectAllOptions = () => {
        if (!hasAllValues) {
            props?.options?.forEach((item: any) => {
                selectedOption[item.value] = item
            })
            setSelectedOption({ ...selectedOption })
            field.onChange(selectedOption)
        } else {
            setSelectedOption({})
            field.onChange({})
        }
    }

    const handleMouseEnter = () => {
        const timeout = setTimeout(() => {
            setIsOpen(true)
        }, 300)
        setHoverTimeout(timeout)
    }

    const handleMouseLeave = () => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout)
        }
        setIsOpen(false)
    }

    const handleMenuState = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    return (
        <>
            <Menu
                ref={ref}
                as="div"
                className={`${props.displayIconInPlaceholder ? "relative" : ""} ${
                    props.parentClass
                } inline-block text-left w-full`}
            >
                <Menu.Button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleMenuState}
                    disabled={props.disabled}
                    className={`w-full flex
                            ${
                                props.displayIconInPlaceholder
                                    ? "px-[14px] py-[6px]  border-gray-300 "
                                    : "px-[14px] py-[8px]  border-gray-50"
                            }
                            rounded-[10px]
                            justify-between
                            items-center gap-x-1.5 ${props.disabled ? "!bg-white !text-gray-300" : "bg-white"}
                            !h-[34px]
                            ${props.className}
                            ${
                                props.displayIconInPlaceholder
                                    ? "px-[14px] py-[6px]  border-gray-300  focus:border-gray-600 focus:outline focus:outline-[2px] focus:outline-cyan-200"
                                    : "focus:border-gray-50 focus:outline  focus:outline-cyan-300/30 active:border-gray-50 active:outline  active:outline-cyan-300/30  focus-within:border-gray-50 focus-within:outline  focus-within:outline-cyan-300/30  focus-visible:border-gray-50 focus-visible:outline  focus-visible:outline-cyan-300/30"
                            }
                        
                            `}
                    style={{
                        boxShadow: props.customBoxShadow ?? "",
                        border: !props.displayIconInPlaceholder ? "" : "",
                    }}
                >
                    {props.displayIconInPlaceholder ? (
                        <PrimaryText
                            size="small"
                            weight="light"
                            color="text-gray-700"
                            className={`leading-5  ${
                                props.displayIconInPlaceholder && "flex  gap-[8px] items-center"
                            } ${props.buttonTextClass ?? ""}`}
                        >
                            {props.displayIconInPlaceholder &&
                                Object.entries(selectedOption)
                                    .filter((i) => i[1])
                                    .map((o, i) => <div key={i}>{o[1]?.icon}</div>)}

                            <PrimaryText
                                size="small"
                                weight="light"
                                color="text-gray-700"
                                className={`leading-5  ${
                                    props.displayIconInPlaceholder && "flex  gap-[8px] items-center"
                                } ${props.buttonTextClass ?? ""}`}
                            >
                                {(!props.staticPlaceholder &&
                                    Object.entries(selectedOption)
                                        .filter((i) => i[1])
                                        .map((o) => o[1]?.label)
                                        .join(",")) ||
                                    props.placeholder ||
                                    t("select.placeholder.default")}
                            </PrimaryText>
                        </PrimaryText>
                    ) : (
                        <PrimaryText
                            size="small"
                            weight="light"
                            color={
                                !Object.keys(selectedOption).some((key) => selectedOption[key])
                                    ? "text-gray-400 italic"
                                    : `${props.disabled ? "text-gray-200" : "text-gray-700"}`
                            }
                            className={`leading-5 h-full mt-[0px] ${
                                props.displayIconInPlaceholder && "flex  gap-[8px] items-center"
                            } ${props.buttonTextClass ?? ""}`}
                        >
                            {props.displayIconInPlaceholder &&
                                Object.entries(selectedOption)
                                    .filter((i) => i[1])
                                    .map((o) => o[1]?.icon)}

                            {(!props.staticPlaceholder &&
                                Object.entries(selectedOption)
                                    .filter((i) => i[1])
                                    .map((o) => o[1]?.label)
                                    .join(",")) ||
                                props.placeholder ||
                                t("select.placeholder.default")}
                        </PrimaryText>
                    )}

                    <ChevronDownIcon color={`${props.disabled ? "#D0D5DD" : "#667085"}`} />
                </Menu.Button>

                {fieldState.error && (
                    <div className="flex items-center mt-[12px]">
                        <div>
                            <AlertIcon />
                        </div>

                        <PrimaryText typography={"small-error"} className="ml-[3px]">
                            {fieldState.error.message}
                        </PrimaryText>
                    </div>
                )}

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        style={{
                            border: "1px solid #EAECF0",
                            boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
                        }}
                        className={`
                        ${props.optionsMenuClass ? props.optionsMenuClass : "w-full"}
                        ${
                            !props.displayIconInPlaceholder ? " absolute" : "md:w-[320px] xs:min-w-[270px] absolute"
                        }  z-[110] rounded-lg -left-[1px] mt-2  bg-white  focus:outline-none`}
                    >
                        <div
                            className={`${
                                props.paddingNone
                                    ? ""
                                    : "p-[6px] ] max-h-[295px] overflow-y-auto mr-[6px] my-[6px] py-0"
                            } ${props.displayIconInPlaceholder && "h-[116px]"}`}
                        >
                            {props.SelectAll && (
                                <Menu.Item key={999}>
                                    <div
                                        onClick={() => selectAllOptions()}
                                        className="py-[10px] pr-[10px] pl-[8px] mb-[2px] last:mb-0 flex gap-[8px] hover:cursor-pointer"
                                    >
                                        <Container className="w-[16px]">{hasAllValues ? checked : uncheck}</Container>

                                        <PrimaryText size="small" className="leading-5 text-gray-700 font-normal">
                                            All
                                        </PrimaryText>
                                    </div>
                                </Menu.Item>
                            )}
                            {props?.options?.map((item: any, key: any) => (
                                <Menu.Item key={key}>
                                    {({ active }) => (
                                        <div
                                            onClickCapture={handleMenuState}
                                            className={`mb-[2px] w-full last:mb-0 flex items-center ${
                                                props.rightCheckBox === false ? "gap-[8px]" : "justify-between "
                                            } hover:cursor-pointer py-[8px] pr-[8px] pl-[8px] rounded-[6px] hover:bg-gray-100 `}
                                            onClick={() => {
                                                if (props.type === "radio" || props.type === "check") {
                                                    selectedOption = {}
                                                }
                                                selectedOption[item.value] = selectedOption[item.value]
                                                    ? undefined
                                                    : item
                                                setSelectedOption({ ...selectedOption })
                                                field.onChange(selectedOption)
                                                if (onChange) {
                                                    onChange(item.value)
                                                }
                                            }}
                                        >
                                            <PrimaryText
                                                size="small"
                                                {...(props.type === "check" && selectedOption[item.value]
                                                    ? {
                                                          color: "text-[#344054]",
                                                          weight: "medium",
                                                      }
                                                    : {
                                                          color: "text-gray-700",
                                                          weight: "light",
                                                      })}
                                                className={`leading-5 line-clamp-1 ${
                                                    item.icon && "flex items-center gap-[8px]"
                                                }`}
                                            >
                                                {item.label}
                                                {item.icon && item.icon}
                                            </PrimaryText>

                                            {props.rightCheckBox === false && (
                                                <Container className="">
                                                    {selectedOption[item.value] ? checked : uncheck}
                                                </Container>
                                            )}
                                            {props.rightCheckBox && (
                                                <Container className="">
                                                    {selectedOption[item.value] ? checked : uncheck}
                                                </Container>
                                            )}
                                        </div>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>

                {props.isToolitpShow && !isDropdownOpen && isOpen && (
                    <CommonTooltip
                        className="w-auto h-3.5 flex-col justify-start items-start inline-flex absolute right-[-41%] top-[76%] z-10"
                        label={props.tooltipLabel}
                    />
                )}
            </Menu>
        </>
    )
}
