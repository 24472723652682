import { Outlet } from "react-router"

import { Header } from "../components/header.component"

export const GeneralContainer = () => {
    return (
        <div>
            <Header />
            <div className="content">
                <Outlet />
            </div>
        </div>
    )
}
