export const BackArrowIcon = (props: IIconTooltipProps) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6673 8.00016H3.33398M3.33398 8.00016L8.00065 12.6668M3.33398 8.00016L8.00065 3.3335"
                stroke={props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
