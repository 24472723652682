import { extendedDayjs } from "../utils/dayjs"
import { SEATTLE_TIMEZONE } from "./util.helper"

/**
 * Alter date range to include / exclude today from range based on `hasToday` flag.
 * It will shift range one day past if `hasToday` is false
 * @param sortedRange sorted date range in DESC order
 * @param hasToday flag to include today in range
 */
const alterRangeForToday = (sortedRange: string[], hasToday: boolean = true) => {
    if (hasToday) return sortedRange
    const newRange = sortedRange.slice(0, sortedRange.length - 1)
    newRange.unshift(extendedDayjs.utc(sortedRange.at(0), "YYYY-MM-DD").subtract(1, "day").format("YYYY-MM-DD"))
    return newRange
}

const getSinceDate = (range: number) => {
    if (!range) throw new Error("Unexpected parameter!")
    return extendedDayjs().tz(SEATTLE_TIMEZONE).add(-range, "day").startOf("day").utc().startOf("day").toISOString()
}

const dateHelper = {
    alterRangeForToday,
    getSinceDate,
}

export default dateHelper
