import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { useInputValidations } from "../../../../hooks/inputValidations.hook"
import { useLoadingStatus } from "../../../../hooks/useLoadingStatus.hook"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { ButtonElement } from "../../../elements/button.element"
import { InputElement } from "../../../elements/input.element"
import { PrimaryText } from "../../../elements/primaryText.element"
const AddTrackPackStepThreeView: React.FC<AddTrackPackProps> = ({
    totalSteps,
    asinHeaderText,
    asinHeaderDescription,
    icon,
    handleCloseDialog,
    handlePrev,
    buttonText,
    currentStep,
    isError,
    isSuccess,
}) => {
    const { control, handleSubmit, watch, setValue } = useForm<Partial<ISelectedTrackpackPayload>>()
    const validationConfig = useInputValidations()
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useLoadingStatus(loading, RuntimeHelper.createTrackpackLoading())

    const { userActionData } = useSelector(AccountStateSelector)

    const dispatch = useDispatch()
    const watchName = watch("name")
    const data = useSelector(ProductStateSelector)
    const name = typeof watchName === "string" ? watchName.trim() : ""
    const onSubmit = () => {
        let competitor: string[] = []
        competitor = data?.selectedTrackpackPayload?.total_asins || []
        const payload = {
            own_asins: [],
            name: name,
            amazon_tld: data?.selectedTrackpackPayload?.amazon_tld,
            competitor_asins: competitor?.map((val: string) => val?.toUpperCase()) ?? [],
        }

        dispatch(
            ProductsActionCreator.createTrackpackAccount(
                { ...payload },
                () => {
                    isSuccess && isSuccess(name)
                    if (userActionData !== undefined) {
                        dispatch(
                            ProductsActionCreator.getTrackpackList(
                                {},
                                { as_user: userActionData && userActionData?.id + "", status: "active" }
                            )
                        )
                    } else dispatch(ProductsActionCreator.getTrackpackList({}, { status: "active" }))
                },
                (res: { message: string }) => {
                    if (isError) {
                        isError()
                    }
                }
            )
        )
    }
    useEffect(() => {
        if (data?.selectedTrackpackPayload?.name) {
            setValue("name", data?.selectedTrackpackPayload?.name)
        }
    }, [data, setValue])

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-[#344054] text-[24px] font-medium mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>
            <PrimaryText className="text-[16px] font-[300] mt-[16px] text-gray-700 ml-[6px] ">
                {asinHeaderDescription}
            </PrimaryText>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-[36px]">
                <>
                    <InputElement
                        name="name"
                        type="text"
                        reactHookControl={control}
                        reactHookValidations={{
                            required: validationConfig.trackpackName.message,
                            pattern: validationConfig.trackpackName,
                        }}
                        placeholder="Start typing..."
                        // postfix={<IndicatorIcon />}
                        className="placeholder:itali placeholder:text-[#D0D5DD] !rounded-[12px]"
                        placeholderStyle={true}
                        shadowClass={true}
                    />
                    <div className="flex justify-between  items-center mt-[48px]">
                        <div className="flex items-center">
                            <BackChevronIcon />
                            <PrimaryText onClick={handlePrev} className="text-[18px]  cursor-pointer text-[#667085]">
                                Back
                            </PrimaryText>
                        </div>

                        <ButtonElement
                            type="submit"
                            size="large"
                            textClass="text-[18px] leading-[28px]"
                            className={"w-[99px] bg-[#A5F0FC] rounded-[12px] px-[30px] py-[8px] text-[#0E7090] "}
                            loading={isLoading}
                            disabled={isLoading}
                            viewType={isLoading ? "loading-button" : "primary"}
                        >
                            {buttonText}
                        </ButtonElement>
                    </div>
                </>
            </form>
        </>
    )
}
export default AddTrackPackStepThreeView
