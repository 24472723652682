import { handleAmazonProductRedirect, UtilHelper } from "../../helpers/util.helper"
import { PrimaryText } from "../elements/primaryText.element"

import { EyeSvg } from "../../assets/svgs/eye.svg"
import { EyeOffSvg } from "../../assets/svgs/eyeOff.svg"

import { useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import { ProductsActionCreator } from "../../actions/products.action"
import { RuntimeActionCreators } from "../../actions/runTime.action"
import { AddToTrackPackIcon } from "../../assets/svgs/addToTrackPackIcon.svg"
import { NewExternalLinkIcon } from "../../assets/svgs/newLinkIcon.svg"
import { PresentInTrackPackIcon } from "../../assets/svgs/presentInTrackPackIcon.svg"
import { EmptyStar, FilledStar, PartialStar } from "../../assets/svgs/rating-icon"
import { WarningIcon } from "../../assets/svgs/warningIcon.svg"
import { AuthStateSelector } from "../../selectors/authState.selector"
import CommonTooltip from "../dashboard/tooltipItems/commonTooltip.component"

export interface Root {
    warnings: string
}

export const ProductCard = (props: IProductCard) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const priceString = props.price!.toString()
    const [integerPart, decimalPart] = priceString.split(".")
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const isDashboardPath = location.pathname !== "/dashboard"

    const isOwner = props.ownProduct || props?.searchpackData?.owner_id === getAccessLevel?.id

    const ownerInitials =
        isOwner && props.isSearchrankCard
            ? getAccessLevel?.first_name?.charAt(0)?.toUpperCase?.() ||
              getAccessLevel?.last_name?.charAt(0)?.toLocaleUpperCase?.() ||
              getAccessLevel?.username?.charAt(0)?.toLocaleUpperCase?.() ||
              getAccessLevel?.email?.charAt(0)?.toLocaleUpperCase?.()
            : props?.metadata?.user_first_name
            ? props?.metadata?.user_first_name?.charAt(0)?.toUpperCase()
            : ""

    const getStars = () => {
        const stars = []
        const maxStars = 5
        const fullStars = Math.floor(props.rating!)
        const partialStarPercentage = Math.round((props.rating! - fullStars) * 100)

        for (let i = 0; i < fullStars; i++) {
            stars.push(<FilledStar key={i} />)
        }

        if (partialStarPercentage > 0 && stars.length < maxStars) {
            stars.push(<PartialStar key={fullStars} fillPercentage={partialStarPercentage} />)
        }

        while (stars.length < maxStars) {
            stars.push(<EmptyStar key={stars.length} />)
        }

        return stars
    }

    const checkProductSelected = () => {
        return (
            props.selectedProduct &&
            props.selectedProduct.some((e) => {
                if (props.isSearchrankCard) {
                    return e?.asin === props.id
                } else {
                    return e?.actual_asin === props.id
                }
            })
        )
    }

    const handleProductRedirect = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (checkProductSelected()) {
            handleAmazonProductRedirect(props.amazon_tld, props.id)
        }
    }

    const handleAddAsinToTrackpack = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()

        dispatch(
            ProductsActionCreator.setAddAsintoTrackpackData({
                asin: props.id,
                region: props.amazon_tld,
                sp_id: props.searchpackData?.id || 0,
                sp_tab: props.searchpackTab,
            })
        )

        dispatch(
            RuntimeActionCreators.openAddAsinToTrackpackFlowModal({
                open: true,
                sp_id: props.searchpackData?.id || 0,
                sp_tab: props.searchpackTab,
            })
        )
    }

    const ProductStatusIcon = (warnings: Root) => {
        return (
            <>
                {warnings.warnings !== null && (
                    <>
                        <div
                            data-tooltip-id={"status-warning-tooltip"}
                            data-tooltip-content={JSON.stringify({
                                trackPackId: props.metadata?.id,
                                warnings: warnings.warnings,
                            })}
                        >
                            <div className=" cursor-pointer">
                                <WarningIcon />
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    }

    const shouldShowOpacity = () => {
        if (props.virtualItemIndex !== undefined) return false
        return !checkProductSelected()
    }
    const [isLoading, setIsLoading] = useState(true)
    const [imageSrc, setImageSrc] = useState(props.src)
    const placeholder = "https://via.placeholder.com/68"
    return (
        <>
            <div
                data-tooltip-content="Hello world!"
                className={`relative w-[280px]  flex bg-white rounded-[10px] px-[8px] py-[8px] -outline-offset-1`}
                style={{
                    outline: `1px solid ${props.activeAsinColor || (props?.color && props?.color?.default)}`,
                }}
            >
                <div className={` w-full flex  sm:md:h-full cursor-pointer`}>
                    <div
                        className={`min-w-[68px] max-w-[68px] h-[68px] rounded mr-[10px] flex items-center justify-center ${
                            isLoading
                                ? `bg-placeholder-bg w-[68px] h-[68px] bg-no-repeat bg-contain !border-transparent  ${
                                      shouldShowOpacity() ? "opacity-20" : ""
                                  }`
                                : ""
                        }`}
                        style={{
                            border: `1px solid ${props.activeAsinColor || (props?.color && props.color.default)}`,
                        }}
                    >
                        <img
                            src={imageSrc}
                            alt={props.altText}
                            loading="lazy"
                            className={`w-full h-full object-contain rounded ${
                                shouldShowOpacity() ? "opacity-20" : ""
                            } ${isLoading ? "invisible" : "visible"}`}
                            onLoad={() => setIsLoading(false)}
                            onError={() => setImageSrc(placeholder)}
                        />
                    </div>
                    <div className="w-full flex flex-col justify-between h-[68px]">
                        <div className={`flex justify-between`}>
                            <div
                                className={` ${
                                    shouldShowOpacity() ? "opacity-20" : ""
                                } bg-transparent relative cursor-pointer border rounded-[3px] p-[4px] pr-[3px] flex gap-[3px] justify-center items-center h-[18px]`}
                                style={{
                                    borderColor: `${props.activeAsinColor || (props?.color && props.color.default)}`,
                                }}
                            >
                                <p
                                    onClick={handleProductRedirect}
                                    className={` text-[11px] tracking-[0.7px] font-[500] leading-[8px] uppercase`}
                                    style={{
                                        color: `${props.activeAsinColor || (props?.color && props.color.default)}`,
                                    }}
                                >
                                    {props.id}
                                </p>
                                <div onClick={handleProductRedirect} className="cursor-pointer">
                                    <NewExternalLinkIcon
                                        color={props.activeAsinColor || (props?.color && props.color.default)}
                                        className=""
                                    />
                                </div>
                            </div>

                            <div className={`flex ${props.currentIssue ? "gap-[4px]" : "gap-[4px]"}`}>
                                {props.ownProduct && ownerInitials !== "" && (
                                    <div className="flex justify-center items-center w-[16px] rounded-[4px] bg-modal-btn-gradient h-[16px]">
                                        <PrimaryText
                                            weight="book"
                                            size="xs-small"
                                            className=" text-[#FFF] leading-[15px] uppercase"
                                        >
                                            {ownerInitials}
                                        </PrimaryText>
                                    </div>
                                )}
                                {!props.isSearchrankCard && <ProductStatusIcon warnings={props.currentIssue} />}
                                {/* Only show AddToTrackPackIcon if NOT in virtual list */}
                                {isDashboardPath && props.virtualItemIndex === undefined && !props.trackedProduct && (
                                    <div
                                        data-tooltip-id="dashboard-tooltip"
                                        data-tooltip-place="bottom-end"
                                        data-tooltip-class-name={"!ml-[76px]"}
                                        data-tooltip-offset={-7}
                                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                            <CommonTooltip
                                                className=" w-auto  mt-[6px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                                label="Add to Trackpack"
                                            />
                                        )}
                                        onClick={handleAddAsinToTrackpack}
                                    >
                                        <AddToTrackPackIcon />
                                    </div>
                                )}
                                {props.virtualItemIndex !== undefined && !props.trackedProduct && (
                                    <div
                                        data-tooltip-id="dashboard-tooltip"
                                        data-tooltip-place="bottom-end"
                                        data-tooltip-class-name={"!ml-[76px]"}
                                        data-tooltip-offset={-7}
                                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                            <CommonTooltip
                                                className=" w-auto  mt-[6px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                                label="Add to Trackpack"
                                            />
                                        )}
                                        onClick={handleAddAsinToTrackpack}
                                    >
                                        <AddToTrackPackIcon />
                                    </div>
                                )}
                                {(props.selectedDropdownFilter === "Tracked" || props.trackedProduct) && (
                                    <div
                                        data-tooltip-id="dashboard-tooltip"
                                        data-tooltip-place="bottom-end"
                                        data-tooltip-class-name={"!ml-[90px]"}
                                        data-tooltip-offset={-7}
                                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                            <CommonTooltip
                                                className=" w-auto  mt-[6px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                                label="Present in Trackpack"
                                            />
                                        )}
                                        // className={`${shouldShowOpacity() ? "opacity-20" : ""}`}
                                        onClick={handleAddAsinToTrackpack}
                                    >
                                        <PresentInTrackPackIcon />
                                    </div>
                                )}
                                {!props.hideSelectedProductIcon ? (
                                    props.virtualItemIndex !== undefined || checkProductSelected() ? (
                                        <div className="w-[16px] h-[16px]">
                                            <EyeSvg />
                                        </div>
                                    ) : (
                                        <div className="w-[16px] h-[16px]">
                                            <EyeOffSvg />
                                        </div>
                                    )
                                ) : null}
                            </div>
                        </div>
                        {props?.title && (
                            <PrimaryText
                                weight={"medium"}
                                className={`text-[13px] line-1  mt-[18px] text-gray-700 leading-[13px] ${
                                    shouldShowOpacity() ? "opacity-20" : ""
                                } `}
                            >
                                {UtilHelper.truncateTitle(props?.title, 20)}
                            </PrimaryText>
                        )}

                        <div className={`mt-[6px] mb-[4px] h-[12px]  ${shouldShowOpacity() ? "opacity-20" : ""}`}>
                            <div className={"flex gap-[10px] items-center justify-between h-[12px]"}>
                                <PrimaryText className="flex items-start gap-[1px]">
                                    <sup className={"text-gray-700 text-[7px]  font-[400] leading-[13px] "}>
                                        {props.currency}
                                    </sup>
                                    <PrimaryText className={"text-gray-700 text-[13px] font-[500] leading-[13px]"}>
                                        {integerPart}
                                    </PrimaryText>
                                    {decimalPart && (
                                        <>
                                            <sup className={"text-gray-700 text-[7px] font-[400]  leading-[13px]"}>
                                                {decimalPart}
                                            </sup>
                                        </>
                                    )}
                                </PrimaryText>
                                <div className={"flex gap-[6px] items-start leading-normal"}>
                                    <PrimaryText
                                        size={"xs"}
                                        weight={"book"}
                                        className={"flex gap-[6px] items-start text-slate-700"}
                                    >
                                        <p className="text-[13px] leading-[13px]  font-normal tracking-[-0.26px] ">
                                            {props.rating}
                                        </p>
                                        <span className={"inline-flex items-center"}>
                                            {getStars().map((star, index) => (
                                                <span key={index}>{star}</span>
                                            ))}
                                        </span>
                                        <PrimaryText
                                            weight={"book"}
                                            className={"text-[13px] leading-[13px]  font-normal tracking-[-0.26px]"}
                                        >
                                            {props.ratingsCount?.toLocaleString()}
                                        </PrimaryText>
                                    </PrimaryText>
                                </div>
                                <div className={"flex items-center"}>
                                    <img src={props.reviewer} alt={""} className={"max-h-[16px] max-w-[16px]"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
