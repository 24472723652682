import { PrimaryText } from "../../elements/primaryText.element"
// import CommonTooltip from "../../tooltipItems/commonTooltip.component"

import Skeleton from "react-loading-skeleton"

const RankSnapshotSkeletonComponent = ({ isStatic }: CardSkeletonLoaderProps) => {
    const skeletonBaseColor = "#D0D5DD"
    const skeletonHighlightColor = "#F0F0F0"
    const lightSkeletonHighlightColor = "#EAECF0"
    return (
        <div>
            <div className=" rounded-lg">
                <div className={`rounded-[10px] h-full scroll-hidden overflow-x-auto `}>
                    <div className="border border-gray-200 rounded-bl-[10px] rounded-br-[10px] overflow-hidden">
                        <div
                            className={
                                "flex pb-[16px] pt-[38px] min-w-fit bg-gray-200 rounded-tr-[8px] rounded-tl-[8px] border-b border-[#EEE] z-20 relative"
                            }
                        >
                            <div
                                className={
                                    "py-[8px] pl-[24px] flex items-end sticky left-0 bg-gray-200 justify-start min-w-[256px] mr-[32px]"
                                }
                            >
                                <PrimaryText
                                    size={"small"}
                                    weight={"medium"}
                                    className={"w-[256px] text-gray-700 leading-[20px]"}
                                >
                                    <Skeleton
                                        highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                        baseColor={skeletonBaseColor}
                                        className="rounded-[6px]"
                                        count={1}
                                        width={152}
                                        height={12}
                                    />
                                </PrimaryText>
                            </div>
                            {Array.from({ length: 5 }).map((data, index) => (
                                <div
                                    key={`paid-org-header-${index}`}
                                    className="min-w-[108px] flex flex-col items-center text-center"
                                >
                                    <Skeleton
                                        highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                        baseColor={skeletonBaseColor}
                                        className="rounded-[6px]"
                                        count={1}
                                        width={68}
                                        height={68}
                                    />
                                </div>
                            ))}
                        </div>

                        {/* Sub-header for Keywords and PAID | ORG. */}
                        <div className="flex border-b border-[#EEE] min-w-fit bg-gray-100 z-10 relative">
                            <div
                                className={
                                    "py-[16px] pl-[24px] flex items-center sticky left-0 min-w-[254px] mr-[32px] justify-start"
                                }
                            >
                                <PrimaryText size={"xs"} weight={"medium"} className={"text-gray-700 w-[254px]"}>
                                    <Skeleton
                                        highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                        baseColor={skeletonBaseColor}
                                        className="rounded-[6px]"
                                        count={1}
                                        width={152}
                                        height={8}
                                    />
                                </PrimaryText>
                            </div>
                            {Array.from({ length: 5 }).map((_, index) => (
                                <div
                                    key={`paid-org-header-${index}`}
                                    className="min-w-[108px] text-center py-[16px] flex items-center justify-center"
                                >
                                    <div className=" inline-flex gap-[8px] items-center justify-center min-w-[68px] ml-[4px]">
                                        <span className="text-[8px] leading-[10px] font-400 text-gray-600">
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={20}
                                                height={8}
                                            />
                                        </span>
                                        <span className="text-[8px] leading-[10px] font-400 text-gray-300">|</span>
                                        <span className="text-[8px] leading-[10px] font-400 text-gray-600">
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={20}
                                                height={8}
                                            />{" "}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Data Rows */}
                        {Array.from({ length: 5 }).map((item, rowIndex) => {
                            return (
                                <div
                                    key={`keyword-row-${rowIndex}`}
                                    className="flex border-b border-[#EEE] min-w-fit bg-white z-10 relative last:border-b-0"
                                >
                                    {/* Keywords Column */}
                                    <div
                                        className={
                                            "py-[16px] pl-[24px] flex items-center sticky left-0 min-w-[254px] mr-[32px] justify-start"
                                        }
                                    >
                                        <PrimaryText size={"xs"} weight={"light"} className={"text-gray-700 w-[254px]"}>
                                            <Skeleton
                                                highlightColor={
                                                    isStatic ? lightSkeletonHighlightColor : skeletonHighlightColor
                                                }
                                                baseColor={lightSkeletonHighlightColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={152}
                                                height={8}
                                            />
                                        </PrimaryText>
                                    </div>

                                    {/* PAID | ORG. Columns */}
                                    {Array.from({ length: 5 }).map((_, index) => (
                                        <div
                                            key={`paid-org-${rowIndex}-${index}`}
                                            className="min-w-[108px] flex flex-col items-center text-center py-[16px]"
                                        >
                                            <div className="flex items-center gap-[8px] min-w-[68px] justify-center ml-[4px]">
                                                <span className="text-xs leadin-[16px] font-[300] text-gray-700">
                                                    <Skeleton
                                                        highlightColor={
                                                            isStatic
                                                                ? lightSkeletonHighlightColor
                                                                : skeletonHighlightColor
                                                        }
                                                        baseColor={lightSkeletonHighlightColor}
                                                        className="rounded-[6px]"
                                                        count={1}
                                                        width={20}
                                                        height={8}
                                                    />
                                                </span>{" "}
                                                <span className="text-gray-200 text-xs font-300 leadin-[16px]">|</span>
                                                <span className="text-xs font-[300] text-gray-700 leading-[16px]">
                                                    <Skeleton
                                                        highlightColor={
                                                            isStatic
                                                                ? lightSkeletonHighlightColor
                                                                : skeletonHighlightColor
                                                        }
                                                        baseColor={lightSkeletonHighlightColor}
                                                        className="rounded-[6px]"
                                                        count={1}
                                                        width={20}
                                                        height={8}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RankSnapshotSkeletonComponent
