export const BulletsIconTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width={props.width ?? "16"}
            height={props.height ?? "16"}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M2.66667 8.66634C3.03486 8.66634 3.33333 8.36786 3.33333 7.99967C3.33333 7.63148 3.03486 7.33301 2.66667 7.33301C2.29848 7.33301 2 7.63148 2 7.99967C2 8.36786 2.29848 8.66634 2.66667 8.66634Z"
                fill={props.stroke}
            />
            <path
                d="M2.66667 4.66634C3.03486 4.66634 3.33333 4.36786 3.33333 3.99967C3.33333 3.63148 3.03486 3.33301 2.66667 3.33301C2.29848 3.33301 2 3.63148 2 3.99967C2 4.36786 2.29848 4.66634 2.66667 4.66634Z"
                fill={props.stroke}
            />
            <path
                d="M2.66667 12.6663C3.03486 12.6663 3.33333 12.3679 3.33333 11.9997C3.33333 11.6315 3.03486 11.333 2.66667 11.333C2.29848 11.333 2 11.6315 2 11.9997C2 12.3679 2.29848 12.6663 2.66667 12.6663Z"
                fill={props.stroke}
            />
            <path
                d="M14 7.99968L6 7.99967M14 3.99968L6 3.99967M14 11.9997L6 11.9997M3.33333 7.99967C3.33333 8.36786 3.03486 8.66634 2.66667 8.66634C2.29848 8.66634 2 8.36786 2 7.99967C2 7.63148 2.29848 7.33301 2.66667 7.33301C3.03486 7.33301 3.33333 7.63148 3.33333 7.99967ZM3.33333 3.99967C3.33333 4.36786 3.03486 4.66634 2.66667 4.66634C2.29848 4.66634 2 4.36786 2 3.99967C2 3.63148 2.29848 3.33301 2.66667 3.33301C3.03486 3.33301 3.33333 3.63148 3.33333 3.99967ZM3.33333 11.9997C3.33333 12.3679 3.03486 12.6663 2.66667 12.6663C2.29848 12.6663 2 12.3679 2 11.9997C2 11.6315 2.29848 11.333 2.66667 11.333C3.03486 11.333 3.33333 11.6315 3.33333 11.9997Z"
                stroke={props.stroke}
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
