import { API_ROUTES, ROUTES_CONFIG } from "../config/routes.config"
import { unregisterServiceWorker } from "../hooks/notificationServiceWorker.hook"
import { getJwtToken } from "./util.helper"

export class HttpHelper {
    /**
     * Send request to server
     *
     * @param url
     * @param data
     * @param method
     * @param headers
     * @param appendApiUrl
     */
    static async sendRequest(
        url: string,
        data: any = {},
        method: "GET" | "POST" | "DELETE" | "PUT" | "PATCH" | "get" | "post" | "delete" | "put" = "GET",
        headers = {},
        appendApiUrl: boolean = false,
        handleUnauthorized = true,
        throwError = false
    ): Promise<any> {
        method = (method as any).toUpperCase()

        if (appendApiUrl && url[0] !== "/") {
            url += "/"
        }

        const params: any = {
            method,
            headers: Object.assign({}, headers),
        }

        const token = getJwtToken()

        if (typeof window !== "undefined" && token) {
            params.headers["Authorization"] = `Bearer ${token}`
        }

        if (method === "GET") {
            url = HttpHelper.appendQueryParams(url, data)
        } else {
            params.body = JSON.stringify(data)
            params.headers["Content-Type"] = "application/json"
        }

        let result

        try {
            result = await fetch((appendApiUrl ? process.env.REACT_APP_API_URL : "") + url, params)

            if (!result.ok) {
                if (result.status === 401 && handleUnauthorized) {
                    if (url !== API_ROUTES.GET_TOKEN) return HttpHelper.handleUnauthorized()
                    return result
                }

                throw new Error(`API request failed with status ${result.status}`)
            }

            return result
        } catch (error) {
            console.error("Failed to access API:", error)
            if (throwError) throw error
        }
    }

    /**
     * Handle 401 Unauthorized
     */
    private static handleUnauthorized() {
        if (typeof window !== "undefined" && window.location.pathname !== ROUTES_CONFIG.welcome) {
            localStorage.clear()
            unregisterServiceWorker()
            window.location.href = ROUTES_CONFIG.welcome
        }
    }

    /**
     * Append query param to url and get new url
     *
     * @param url
     * @param data
     */
    private static appendQueryParams(url: string, data: any = {}): string {
        const keys = Object.keys(data)

        if (keys.length) {
            url += "?"
        }

        const params = [] as string[]
        keys.forEach((key) => {
            if (typeof data[key] !== "undefined") {
                params.push(`${key}=${encodeURIComponent(data[key])}`)
            }
        })

        return url + params.join("&")
    }
    static async validateResponse<T>(result: {
        status: number
        statusText: string
        json: () => T | PromiseLike<T>
    }): Promise<T | { message: string }> {
        if (result.status === 404) {
            return { message: result.statusText }
        }

        const response: T = await result.json()
        return response
    }

    static getUrl(url: string, data: IIndexable = {}): string {
        const keys = Object.keys(data)
        keys.forEach((key) => {
            if (typeof data[key] !== "undefined") {
                url = url.replace(`{${key}}`, data[key])
            }
        })

        return url
    }

    static createQueryString(obj?: Record<string, any>) {
        if (!obj) return ""
        const params = new URLSearchParams()

        Object.entries(obj).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                if (Array.isArray(value)) {
                    value.forEach((val) => params.append(key, String(val)))
                } else {
                    params.append(key, String(value))
                }
            }
        })

        return params.toString()
    }
}
