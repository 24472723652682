import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ReactComponent as LeftScrollArrow } from "../../../assets/svgs/leftScrollArrow.svg"
import { ReactComponent as RightScrollArrow } from "../../../assets/svgs/rightScrollArrow.svg"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

import { ASIN_AMAZON_LINK_BASE } from "../../../config/routes.config"

import { Link } from "react-router"
import type { Product, RankData } from "../../../@types/app/data"
import { SearchpackActionCreator } from "../../../actions/searchpack.action"
import { NewPlusIcon } from "../../../assets/svgs/plusIcon.svg"
import { img_url } from "../../../config/dashboard.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { formatToDateString, getTodayDateString, SEATTLE_TIMEZONE } from "../../../helpers/util.helper"
import { useAppSearchParams } from "../../../hooks/app/searchpack/useAppSearchParams"
import { useLoadingStatus } from "../../../hooks/useLoadingStatus.hook"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../selectors/searchpack.selector"
import "../../../styles/datepicker.element.css"
import { dateUtils } from "../../../utils/date"
import { extendedDayjs } from "../../../utils/dayjs"
import RankSnapshotSkeletonComponent from "../../common/skeletonLoader/rankSnapShotSkeleton.component"
import SearchpackTrackerOnboarding from "../../common/skeletonLoader/searchpackTrackerOnboarding.component"
import DatePickerComponent from "../../elements/datePicker.element"

const RankSnapshot = (props: ITableData) => {
    const parentRef = useRef<HTMLDivElement>(null)
    const trackRef = useRef<HTMLDivElement>(null)
    const itemRefs = useRef<(HTMLDivElement | null)[]>([])
    const isDragging = useRef(false)
    const startDragX = useRef(0)
    const [canScrollLeft, setCanScrollLeft] = useState(false)
    const [canScrollRight, setCanScrollRight] = useState(false)
    const [thumbPosition, setThumbPosition] = useState(0)
    const [thumbWidth, setThumbWidth] = useState(0)
    const [disabledFilter, setDisabledFilter] = useState<"Paid" | "Organic" | null>(null)

    const dispatch = useDispatch()
    const { control } = useForm()

    const {
        selectedSearchpackLastDataTime,
        selectedSearchpackValues,
        ranksnapshotData,
        selectedSearchpackProducts,
        getSelectedSearchpackPayload,
    } = useSelector(SearchpackStateSelector)

    const { searchParams, updateSearchParams } = useAppSearchParams(["sp_id"])
    const [initialDate, setInitialDate] = useState<Date>()

    const { loading } = useSelector(RunTimeStateSelector)
    const { userActionData } = useSelector(AccountStateSelector)
    const isGetRankSnapshotDataLoading: boolean = useLoadingStatus(loading, RuntimeHelper.getRanksnapshotDataLoading())

    const amazonTld = selectedSearchpackLastDataTime?.amazon_tld ?? "com"
    const url = ASIN_AMAZON_LINK_BASE.replace("com", amazonTld)

    const isDisabled =
        (selectedSearchpackLastDataTime && selectedSearchpackLastDataTime?.search_data_last_available_at === null) ||
        selectedSearchpackProducts?.selectedProducts.length === 0

    useEffect(() => {
        const updateScrollState = () => {
            const parent = parentRef.current
            if (parent) {
                const { scrollWidth, clientWidth, scrollLeft } = parent
                const track = trackRef.current

                if (track) {
                    const thumbWidthRatio = clientWidth / scrollWidth
                    setThumbWidth(track.clientWidth * thumbWidthRatio)
                    setThumbPosition((scrollLeft / scrollWidth) * track.clientWidth)
                }

                setCanScrollLeft(scrollLeft > 0)
                setCanScrollRight(scrollLeft + clientWidth < scrollWidth)
            }
        }

        const parent = parentRef.current
        if (parent) {
            updateScrollState()
            parent.addEventListener("scroll", updateScrollState)
        }

        window.addEventListener("resize", updateScrollState)

        return () => {
            if (parent) parent.removeEventListener("scroll", updateScrollState)
            window.removeEventListener("resize", updateScrollState)
        }
    }, [selectedSearchpackProducts?.selectedProducts])

    useEffect(() => {
        const updateScrollState = () => {
            const parent = parentRef.current
            if (parent) {
                const { scrollWidth, clientWidth } = parent
                const track = trackRef.current

                if (track) {
                    const thumbWidthRatio = clientWidth / scrollWidth
                    setThumbWidth(track.clientWidth * thumbWidthRatio)
                    setThumbPosition((parent.scrollLeft / scrollWidth) * track.clientWidth)
                }

                setCanScrollLeft(parent.scrollLeft > 0)
                setCanScrollRight(parent.scrollLeft + clientWidth < scrollWidth)
            }
        }

        const observer = new MutationObserver(updateScrollState)
        if (parentRef.current) {
            observer.observe(parentRef.current, { childList: true, subtree: true })
        }

        updateScrollState() // Initial calculation

        return () => observer.disconnect()
    }, [selectedSearchpackProducts?.selectedProducts])

    const handleScroll = useCallback((direction: "left" | "right") => {
        const parent = parentRef.current
        if (parent) {
            const { clientWidth } = parent
            const scrollAmount = clientWidth / 2
            const newScrollLeft =
                direction === "left"
                    ? Math.max(parent.scrollLeft - scrollAmount, 0)
                    : Math.min(parent.scrollLeft + scrollAmount, parent.scrollWidth - clientWidth)

            parent.scrollTo({ left: newScrollLeft, behavior: "smooth" })
        }
    }, [])

    const handleThumbDragStart = (e: React.MouseEvent | React.TouchEvent) => {
        isDragging.current = true
        startDragX.current = "touches" in e ? e.touches[0].clientX : e.clientX
    }

    const handleThumbDragMove = useCallback(
        (e: MouseEvent | TouchEvent) => {
            if (!isDragging.current) return

            const parent = parentRef.current
            const track = trackRef.current

            if (parent && track) {
                const currentX = "touches" in e ? e.touches[0].clientX : e.clientX
                const deltaX = currentX - startDragX.current
                startDragX.current = currentX

                const trackWidth = track.clientWidth
                const { scrollWidth } = parent

                const newThumbPosition = Math.min(Math.max(thumbPosition + deltaX, 0), trackWidth - thumbWidth)

                setThumbPosition(newThumbPosition)

                const newScrollLeft = (newThumbPosition / trackWidth) * scrollWidth
                parent.scrollTo({ left: newScrollLeft })
            }
        },
        [thumbPosition, thumbWidth]
    )

    const handleThumbDragEnd = () => {
        isDragging.current = false
    }

    useEffect(() => {
        if (isDragging.current) {
            document.addEventListener("mousemove", handleThumbDragMove)
            document.addEventListener("touchmove", handleThumbDragMove)
            document.addEventListener("mouseup", handleThumbDragEnd)
            document.addEventListener("touchend", handleThumbDragEnd)
        }

        return () => {
            document.removeEventListener("mousemove", handleThumbDragMove)
            document.removeEventListener("touchmove", handleThumbDragMove)
            document.removeEventListener("mouseup", handleThumbDragEnd)
            document.removeEventListener("touchend", handleThumbDragEnd)
        }
    }, [thumbPosition, selectedSearchpackProducts?.selectedProducts, handleThumbDragMove])

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAddKeywordsModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    useEffect(() => {
        if (selectedSearchpackLastDataTime) {
            const lastAvailableDate = formatToDateString(
                selectedSearchpackLastDataTime.search_data_last_available_at,
                SEATTLE_TIMEZONE,
                false
            )

            const currentDate = getTodayDateString()
            updateSearchParams({ date: searchParams.date || lastAvailableDate || currentDate || "" })
        }
    }, [selectedSearchpackLastDataTime, searchParams.date, updateSearchParams, searchParams.sp_id])

    const handleDateChange = (date: Date | null) => {
        if (date && !isNaN(date.getTime())) {
            updateSearchParams({ date: formatToDateString(date) || "" })
        } else {
            updateSearchParams({ date: getTodayDateString() || "" })
        }
    }

    useEffect(() => {
        if (!selectedSearchpackLastDataTime) return // Wait for it

        if (selectedSearchpackValues && selectedSearchpackValues.selectedSearchpackID && searchParams.date) {
            const options: { as_user?: string } = {}

            if (userActionData) {
                options.as_user = userActionData.id + ""
            }

            const dateToUse = extendedDayjs(
                dateUtils.validDateOrUndefined(searchParams.date) || extendedDayjs().format("YYYY-MM-DD")
            ).format("YYYY-MM-DD")

            dispatch(
                SearchpackActionCreator.getRanksnapshotData(
                    selectedSearchpackValues.selectedSearchpackID,
                    dateToUse,
                    {},
                    options
                )
            )
        }
    }, [
        selectedSearchpackValues,
        searchParams.date,
        getSelectedSearchpackPayload,
        selectedSearchpackLastDataTime,
        userActionData,
        dispatch,
    ])

    const filteredRankData = useMemo(() => {
        if (ranksnapshotData) {
            const selectedAsins =
                selectedSearchpackProducts?.selectedProducts?.map((product: { asin: string }) => product.asin) || []

            return ranksnapshotData?.search_rank_data_by_term?.filter(
                (term: { rank_data: RankData[]; term: string; term_id: number; timestamp: string }) =>
                    term.rank_data.some((rank: RankData) => selectedAsins.includes(rank.asin))
            )
        }
        return []
    }, [selectedSearchpackProducts, ranksnapshotData])

    const selectedSearchpackKeywordsData = useMemo(() => {
        if (filteredRankData) {
            return filteredRankData.map(
                ({ term, rank_data }: { rank_data: RankData[]; term: string; term_id: number; timestamp: string }) => {
                    const selectedAsins =
                        selectedSearchpackProducts?.selectedProducts?.map(
                            (product: { asin: string }) => product.asin
                        ) || []

                    const asinRanks = selectedAsins.map((asin: string) => {
                        const paidRank =
                            disabledFilter !== "Paid"
                                ? rank_data.find((rank: RankData) => rank.asin === asin && rank.is_sponsored)?.rank ||
                                  "-"
                                : null

                        const organicRank =
                            disabledFilter !== "Organic"
                                ? rank_data.find((rank: RankData) => rank.asin === asin && !rank.is_sponsored)?.rank ||
                                  "-"
                                : null

                        return {
                            asin,
                            paid: paidRank,
                            organic: organicRank,
                        }
                    })

                    return {
                        label: term,
                        asinRanks,
                    }
                }
            )
        }
        return []
    }, [filteredRankData, disabledFilter, selectedSearchpackProducts])

    const handleFilterClick = (filter: "Paid" | "Organic") => {
        setDisabledFilter((currentFilter) => (currentFilter === filter ? null : filter))
    }

    useEffect(() => {
        // Check if selectedIndex is defined and within bounds
        if (selectedSearchpackProducts?.selectedIndex !== undefined) {
            const index = selectedSearchpackProducts.selectedIndex
            const targetItem = itemRefs.current[index]
            if (targetItem && parentRef.current) {
                const parent = parentRef.current
                const itemOffsetLeft = targetItem.offsetLeft
                const itemWidth = targetItem.clientWidth
                const parentWidth = parent.clientWidth
                const newScrollLeft = itemOffsetLeft - (parentWidth - itemWidth) / 2
                parent.scrollTo({
                    left: newScrollLeft,
                    behavior: "smooth", // Add smooth scrolling behavior
                })
            }
        }
    }, [selectedSearchpackProducts?.selectedIndex, selectedSearchpackProducts?.selectedProducts])

    useEffect(() => {
        if (searchParams?.date) {
            setInitialDate(dateUtils.validDateObjectOrUndefined(searchParams?.date))
        }
        // run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {selectedSearchpackLastDataTime &&
                selectedSearchpackLastDataTime?.search_data_last_available_at === null && (
                    <SearchpackTrackerOnboarding />
                )}
            <>
                <div className={"flex justify-between flex-wrap mb-[12px] w-full"}>
                    <div className={"flex gap-[12px] flex-col xl:flex-row lg:flex-row md:flex-col"}>
                        <div className="cursor-pointer outline-none">
                            <ButtonElement
                                viewType={
                                    isDisabled || isGetRankSnapshotDataLoading ? "is-loading-button" : "gradient-button"
                                }
                                onClick={handleOpen}
                                disabled={isDisabled || isGetRankSnapshotDataLoading}
                                size="medium"
                                className="px-[12px] py-[8px] rounded-[10px] inline-flex h-[36px] min-w-[110.5px] items-center"
                                parentClass="justify-start items-center gap-2 inline-flex  "
                                textClass={`  text-[${
                                    isDisabled || isGetRankSnapshotDataLoading ? "#D0D5DD" : "#088AB2"
                                }] flex item-center gap-[8px]`}
                            >
                                <NewPlusIcon
                                    className="my-[2px]"
                                    color={isDisabled || isGetRankSnapshotDataLoading ? "#D0D5DD" : "#088AB2"}
                                />
                                <PrimaryText className="mt-[2px] leading-[20px]" weight="medium" size="small">
                                    {" "}
                                    Keywords
                                </PrimaryText>
                            </ButtonElement>
                        </div>
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-offset={-11}
                            data-tooltip-class-name={"!ml-[75px]"}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className="" label="Filter Keywords" />
                            )}
                            className={`cursor-pointer outline-none px-[16px] py-[8px] h-[36px] inline-flex items-center gap-[12px] bg-white border border-gray-200 rounded-[10px] ${
                                isDisabled || isGetRankSnapshotDataLoading
                                    ? " cursor-not-allowed pointer-events-none"
                                    : ""
                            } `}
                        >
                            <PrimaryText
                                onClick={() => handleFilterClick("Paid")}
                                className={`text-gray-700 leading-[18px] cursor-pointer pt-[2px] ${
                                    disabledFilter === "Paid" ? "text-gray-200" : ""
                                }
                                ${
                                    isDisabled || isGetRankSnapshotDataLoading
                                        ? " cursor-not-allowed pointer-events-none text-gray-200"
                                        : ""
                                }    
                                `}
                                size="small"
                                weight="light"
                            >
                                Paid
                            </PrimaryText>
                            <PrimaryText
                                onClick={() => handleFilterClick("Organic")}
                                className={`text-gray-700 leading-[18px] cursor-pointer pt-[2px] ${
                                    disabledFilter === "Organic" ? "text-gray-200" : ""
                                }
                                ${
                                    isDisabled || isGetRankSnapshotDataLoading
                                        ? " cursor-not-allowed pointer-events-none text-gray-200"
                                        : ""
                                }    
                                `}
                                size="small"
                                weight="light"
                            >
                                Organic
                            </PrimaryText>
                        </div>
                        <div className="relative">
                            {selectedSearchpackLastDataTime && (
                                <DatePickerComponent
                                    className={` ${
                                        isDisabled || isGetRankSnapshotDataLoading
                                            ? "cursor-not-allowed pointer-events-none !text-gray-200"
                                            : "cursor-pointer"
                                    } `}
                                    selectedSearchpackLastDataTime={selectedSearchpackLastDataTime}
                                    onDateChange={handleDateChange}
                                    control={control}
                                    name="date"
                                    isDisabled={isDisabled || isGetRankSnapshotDataLoading}
                                    defaultDate={initialDate}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className=" rounded-lg">
                    <div
                        className={"overflow-auto top-scroll -mr-[28px]"}
                        onScroll={() => {
                            dispatch(RuntimeActionCreators.closeAllPopup())
                        }}
                    >
                        <div
                            className=""
                            style={{
                                maxHeight: "calc(100vh - 277px)",
                                width: "calc(100% - 28px)",
                            }}
                        >
                            {isGetRankSnapshotDataLoading ? (
                                <RankSnapshotSkeletonComponent />
                            ) : !ranksnapshotData ||
                              ranksnapshotData?.search_rank_data_by_term?.length === 0 ||
                              !selectedSearchpackProducts?.selectedProducts ||
                              selectedSearchpackProducts?.selectedProducts.length === 0 ? (
                                <RankSnapshotSkeletonComponent isStatic={true} />
                            ) : (
                                <div className="rounded-[10px] h-full  border border-gray-200 bg-gray-200 rounded-bl-[10px] rounded-br-[10px] overflow-hidden ">
                                    <div className="flex items-center pt-[12px] gap-x-[32px] pr-[12px] pl-[34px] ">
                                        <div className="min-w-[256px]"></div>

                                        <div className="text-black flex items-center flex-1 gap-[10px]">
                                            {/* Left Arrow */}
                                            <button
                                                onClick={() => handleScroll("left")}
                                                disabled={!canScrollLeft}
                                                className={` ${
                                                    !canScrollLeft ? "cursor-not-allowed" : "cursor-pointer"
                                                }`}
                                            >
                                                <LeftScrollArrow />
                                            </button>

                                            <div
                                                ref={trackRef}
                                                className="relative w-full bg-gray-200 h-[4px] my-[3px]"
                                            >
                                                <div
                                                    className="bg-[#D0D5DD] h-[4px] absolute rounded-[8px] cursor-pointer"
                                                    style={{
                                                        width: `${thumbWidth}px`,
                                                        left: `${thumbPosition}px`,
                                                    }}
                                                    onMouseDown={handleThumbDragStart}
                                                    onTouchStart={handleThumbDragStart}
                                                ></div>
                                            </div>

                                            {/* Right Arrow */}
                                            <button
                                                onClick={() => handleScroll("right")}
                                                disabled={!canScrollRight}
                                                className={` ${
                                                    !canScrollRight ? "cursor-not-allowed" : "cursor-pointer"
                                                }`}
                                            >
                                                <RightScrollArrow />
                                            </button>
                                        </div>
                                    </div>
                                    <div ref={parentRef} className={` scroll-hidden overflow-x-auto `}>
                                        <div className="bg-gray-200">
                                            <div
                                                className={
                                                    "flex py-[16px] min-w-fit  rounded-tr-[8px] rounded-tl-[8px] border-b border-[#EEE] z-20 relative"
                                                }
                                            >
                                                <div
                                                    className={
                                                        " pl-[24px] flex items-end sticky left-0 bg-gray-200 justify-start min-w-[300px]"
                                                    }
                                                >
                                                    <PrimaryText
                                                        size={"small"}
                                                        weight={"medium"}
                                                        className={"w-[300px] text-gray-700 leading-[20px]"}
                                                    >
                                                        ASINs
                                                    </PrimaryText>
                                                </div>
                                                {selectedSearchpackProducts?.selectedProducts.map(
                                                    (data: Omit<Product, "latest_rank">, index: number) => {
                                                        return (
                                                            <Link to={url + data?.asin} target="_blank" key={data.asin}>
                                                                <div
                                                                    ref={(el) => (itemRefs.current[index] = el)}
                                                                    className="min-w-[108px] py-[8px] flex flex-col items-center text-center"
                                                                >
                                                                    <img
                                                                        className="w-[68px] h-[68px] rounded-[6px] object-contain bg-white"
                                                                        style={{
                                                                            border: `1px solid ${data?.color?.default}`,
                                                                        }}
                                                                        src={
                                                                            data?.image_filename
                                                                                ? `${img_url}${data?.image_filename}`
                                                                                : ""
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </Link>
                                                        )
                                                    }
                                                )}
                                            </div>

                                            {/* Sub-header for Keywords and PAID | ORG. */}
                                            <div className="flex border-b border-[#EEE] min-w-fit bg-gray-100 z-10 relative">
                                                <div
                                                    className={
                                                        "py-[16px] bg-gray-100 pl-[24px] flex items-center sticky left-0 min-w-[302px] justify-start"
                                                    }
                                                >
                                                    <PrimaryText
                                                        size={"xs"}
                                                        weight={"medium"}
                                                        className={"text-gray-700 w-[302px]"}
                                                    >
                                                        Keywords
                                                    </PrimaryText>
                                                </div>
                                                {selectedSearchpackProducts?.selectedProducts.map(
                                                    (data: Omit<Product, "latest_rank">, index: number) => (
                                                        <div
                                                            ref={(el) => (itemRefs.current[index] = el)}
                                                            key={`paid-org-header-${data.asin}`}
                                                            className="min-w-[108px] text-center py-[16px] flex items-center justify-center"
                                                        >
                                                            {disabledFilter === "Paid" ? (
                                                                <div className=" inline-flex gap-[8px]">
                                                                    <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                        ORG.
                                                                    </span>
                                                                </div>
                                                            ) : disabledFilter === "Organic" ? (
                                                                <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                    PAID
                                                                </span>
                                                            ) : (
                                                                <div className=" inline-flex gap-[8px]">
                                                                    <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                        PAID
                                                                    </span>
                                                                    <span className="text-[8px] leading-[10px] font-400 text-gray-300">
                                                                        |
                                                                    </span>
                                                                    <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                        ORG.
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>

                                            {selectedSearchpackKeywordsData.map(
                                                (
                                                    item: {
                                                        label: string
                                                        asinRanks: {
                                                            asin: string
                                                            paid: string | number | null
                                                            organic: string | number | null
                                                        }[]
                                                    },
                                                    rowIndex: number
                                                ) => {
                                                    const { label, asinRanks } = item
                                                    return (
                                                        <div
                                                            key={`keyword-row-${rowIndex}`}
                                                            className="flex border-b border-[#EEE] min-w-fit bg-white z-10 relative last:border-b-0 last:rounded-br-[8px] last:rounded-bl-[8px]"
                                                        >
                                                            <div
                                                                className={
                                                                    "py-[16px] pl-[24px] flex items-center bg-white sticky left-0 min-w-[302px] justify-start"
                                                                }
                                                            >
                                                                <PrimaryText
                                                                    size={"xs"}
                                                                    weight={"light"}
                                                                    className={"text-gray-700 w-[302px]"}
                                                                >
                                                                    {label}
                                                                </PrimaryText>
                                                            </div>

                                                            {/* Render columns dynamically */}
                                                            {asinRanks.map((rank, index: number) => (
                                                                <div
                                                                    key={`paid-org-${rowIndex}-${index}`}
                                                                    className="min-w-[108px] flex flex-col items-center text-center py-[16px]"
                                                                >
                                                                    <div className="flex items-center gap-[8px]">
                                                                        {disabledFilter === "Paid" ? (
                                                                            <span className="text-xs leading-[16px] font-[300] text-gray-700 min-w-[32px]">
                                                                                {rank.organic}
                                                                            </span>
                                                                        ) : disabledFilter === "Organic" ? (
                                                                            <span className="text-xs leading-[16px] font-[300] text-gray-700 min-w-[32px]">
                                                                                {rank.paid}
                                                                            </span>
                                                                        ) : (
                                                                            <>
                                                                                <span className="text-xs leading-[16px] font-[300] text-gray-700 min-w-[32px]">
                                                                                    {rank.paid}
                                                                                </span>
                                                                                <span className="text-gray-200 text-xs font-300 leading-[16px]">
                                                                                    |
                                                                                </span>
                                                                                <span className="text-xs font-[300] text-gray-700 leading-[16px] min-w-[32px]">
                                                                                    {rank.organic}
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div style={{ height: "12px" }}></div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default RankSnapshot
