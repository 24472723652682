import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import icon from "../../../assets/gifs/new-blinking-logo.gif"
import { useGetExtendedTrackpacks } from "../../../hooks/reactQueryHooks.hook"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { PrimaryText } from "../../elements/primaryText.element"

const ListingAnalyzerOnboardingComponent = () => {
    const dispatch = useDispatch()
    const { userActionData } = useSelector(AccountStateSelector)

    const pathParams = {}

    const queryParams = userActionData ? { as_user: userActionData.id.toString() } : {}

    const { refetch } = useGetExtendedTrackpacks(pathParams, queryParams, false)

    const handleLisitingReportFlow = () => {
        refetch()
        dispatch(
            RuntimeActionCreators.openListingAnalyzerReportModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    return (
        <div
            style={{
                height: "calc(100vh - 262px)",
            }}
            className="bg-white p-[16px] mt-[40px] rounded-[10px] border border-gray-200"
        >
            <div className="border-2 border-[#088AB2] rounded-[4px]">
                <div className="max-w-[660px] w-full mx-auto py-[48px] px-[24px] flex flex-col items-center justify-center gap-[16px]">
                    <div className="w-[60px] h-[54px]">
                        <img src={icon} alt="blinking logo" className="h-full w-full" />
                    </div>
                    <PrimaryText size="sm-medium" weight="medium" className="leading-[24px] text-gray-600">
                        Welcome to my <span className="text=-[16px] font-[500] text-[#088AB2]">Listing Analyzer</span>
                    </PrimaryText>
                    <PrimaryText weight="light" size="small" className="text-center text-gray-600 max-w-[488px]">
                        This powerful tool is designed to help you understand how strong each part of an ASIN’s listing
                        is when compared to the competition.
                    </PrimaryText>
                    <PrimaryText weight="light" size="small" className="text-center text-gray-600 max-w-[400px] w-full">
                        I’ll help you analyze all aspects of any ASIN’s listing so you can determine if it can be
                        improved and how.
                    </PrimaryText>

                    <button
                        onClick={handleLisitingReportFlow}
                        type="button"
                        className=" mt-[16px] py-[8px] px-[16px] rounded-[10px] inline-flex items-center justify-center text-[#088AB2] font-[500] text-[14px] leading-[22px]"
                        style={{
                            background: "linear-gradient(117.03deg, #CFF9FE 0%, #A5F0FC 100%)",
                        }}
                    >
                        Select a Trackpack to get started
                    </button>
                    {/* <PrimaryText weight="light" size="small" className="text-gray-600">
                        <span
                            onClick={handleLisitingReportFlow}
                            className="text-[#088AB2] text-[14px] font-medium leading-[20px] cursor-pointer"
                        >
                            Click here
                        </span>
                        &nbsp;to select a Trackpack and start analyzing.
                    </PrimaryText> */}
                </div>
            </div>
        </div>
    )
}

export default ListingAnalyzerOnboardingComponent
