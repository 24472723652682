import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"

import { OnboardingUserActionCreators } from "../../actions/onBoardingUser.action"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { useInputValidations } from "../../hooks/inputValidations.hook"
import { OnboardingUserStateSelector } from "../../selectors/onBoardingState.selector"
import { BubbleListToTop, BubblesList } from "../elements/bubblesList.element"
import { ButtonElement } from "../elements/button.element"
import { Container } from "../elements/container.element"
import { InputElement } from "../elements/input.element"
import { RadioGroupElement } from "../elements/radioGroup.element"
import { MarketingFooter } from "./marketingFooter.component"

import { Transition } from "@headlessui/react"
import { SelectElement } from "../elements/select.element"
import { AMAZON_DOMAINS } from "./domains.config"
import { MarketingHeader } from "./marketingHeader.component"

export const TrackingChatForm = () => {
    const validationConfig = useInputValidations()

    const { t } = useTranslation("marketing", {
        keyPrefix: "trackingChat.conversation",
    })

    const userOnBoard = useSelector(OnboardingUserStateSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [completed, setCompleted] = useState<boolean>(false)

    const { control, handleSubmit, watch } = useForm<ITrackingChatFormInputs>({
        defaultValues: {
            asinNumber: userOnBoard?.asinNumber ?? "",
            asinBelonging: userOnBoard?.asinBelonging ?? "No",
            amazonTld: userOnBoard?.amazonTld ?? "com",
        },
    })

    const onSubmit: SubmitHandler<ITrackingChatFormInputs> = (data) => {
        dispatch(
            OnboardingUserActionCreators.createOnboardingUser({
                asinBelonging: data.asinBelonging,
                asinNumber: data.asinNumber,
                amazonTld: typeof data.amazonTld === "object" ? Object.keys(data.amazonTld)[0] : data.amazonTld,
            })
        )
        navigate(ROUTES_CONFIG.searchCompetitorChat)
    }

    const asinBelonging = watch("asinBelonging")
    const radioOptions = [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
    ]
    return (
        <>
            <div className="xl:pt-[28px] lg:pt-[28px] md:pt-[20px] sm:pt-[20px] xs:pt-[20px]">
                <div className="flex justify-center 2xl:gap-[16px] xl:gap-[16px] lg:gap-[16px] md:gap-[16px] sm:gap-[0px] xs:gap-[0px]">
                    <MarketingHeader />

                    <div
                        className="flex flex-col justify-start items-start overflow-y-auto md:pr-[20px] lg:pr-[20px] xs:overflow-x-hidden md:overflow-x-auto xs:w-[335px] md:w-[504px] xs:h-xs-calc-tracking-chat-form md:h-md-calc-onboarding lg:h-lg-calc-onboarding xl:h-xl-calc-onboarding"
                        // style={{ height: "calc(100vh - 332px)" }}
                    >
                        <div className={""}>
                            <BubbleListToTop>
                                <BubblesList
                                    interval={500}
                                    onFinish={() => {
                                        setCompleted(true)
                                    }}
                                    stringWaitTime={5}
                                    conversationItems={[
                                        {
                                            text: t("1"),
                                        },
                                        {
                                            text: t("2"),
                                            className: "xs:w-[336px] md:w-[368px]",
                                        },
                                    ]}
                                />
                            </BubbleListToTop>
                        </div>

                        <Transition
                            show={completed}
                            enter="transition ease-out duration-300 transform"
                            enterFrom="translate-y-full opacity-0"
                            enterTo="translate-y-0 opacity-100"
                            leave="transition ease-in duration-200 transform"
                            leaveFrom="translate-y-0 opacity-100"
                            leaveTo="translate-y-full opacity-0"
                        >
                            <div className="flex-1 md:min-w-[349px] xs:min-w-[270px]">
                                <form onSubmit={handleSubmit(onSubmit)} className={"w-full"}>
                                    <Container>
                                        <InputElement
                                            name="asinNumber"
                                            type="text"
                                            defaultValue=""
                                            reactHookControl={control}
                                            reactHookValidations={{
                                                pattern: validationConfig.alphanumericUppercaseOnlyTenChars,
                                                required: validationConfig.required,
                                            }}
                                            placeholder="Add your ASIN Number here..."
                                            className="mb-[24px] md:mb-[28px] xs:w-[240px] md:w-[349px]"
                                        />

                                        <p className="w-fit px-[12px] py-[8px] mb-[15px] rounded-xl xs:bg-gray-200 md:bg-gray-100 text-slate-700 xs:text-[0.875rem]  sm:text-[0.875rem] md:text-[1rem] lg:text-[1rem] font-light leading-7">
                                            Does this ASIN belong to you?
                                        </p>

                                        <div className="mb-[30px]">
                                            <RadioGroupElement
                                                defaultValue={asinBelonging}
                                                name="asinBelonging"
                                                reactHookControl={control}
                                                options={radioOptions}
                                                reactHookValidations={{
                                                    required: validationConfig.required,
                                                }}
                                                className=""
                                            />
                                        </div>

                                        <p className="w-fit px-[12px] py-[8px] mb-[15px] rounded-xl xs:bg-gray-200 md:bg-gray-100 text-slate-700 xs:text-[0.875rem]  sm:text-[0.875rem] md:text-[1rem] lg:text-[1rem] font-light leading-7">
                                            What market are you selling in?
                                        </p>

                                        <SelectElement
                                            reactHookControl={control}
                                            name="amazonTld"
                                            type="check"
                                            displayIconInPlaceholder={true}
                                            reactHookValidations={{
                                                required: validationConfig.required,
                                            }}
                                            defaultValue={"com"}
                                            options={AMAZON_DOMAINS}
                                            className="border-gray-300 rounded-[12px] md:w-[320px]  px-[6px] py-[14px] shadow-none"
                                        />

                                        <ButtonElement
                                            type="submit"
                                            size="large"
                                            className={
                                                "sm:mt-[36px] md:mt-[24px] lg:mt-[24px] xs:mt-[36px] xl:mt-[24px] text-[#0E7090]"
                                            }
                                        >
                                            {t("button")}
                                        </ButtonElement>
                                    </Container>
                                </form>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>

            <MarketingFooter currentStep={5} />
        </>
    )
}
