export const LargeBulletIcon = (props: IArrowProps) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.5 17C6.15456 17 6.5 16.5 6.5 16C6.5 15.5 6.15456 15 5.5 15C4.84544 15 4.5 15.5 4.5 16C4.5 16.5 4.84544 17 5.5 17Z"
                fill={props.color ? props.color : "#0E7090"}
            />
            <path
                d="M5.5 9C6.15456 9 6.5 8.5 6.5 7.99998C6.5 7.5 6.15456 7 5.5 7C4.84544 7 4.5 7.5 4.5 7.99999C4.5 8.5 4.84544 9 5.5 9Z"
                fill={props.color ? props.color : "#0E7090"}
            />
            <path
                d="M5.5 25C6.15456 25 6.5 24.5 6.5 24C6.5 23.5 6.15456 23 5.5 23C4.84544 23 4.5 23.5 4.5 24C4.5 24.5 4.84544 25 5.5 25Z"
                fill={props.color ? props.color : "#0E7090"}
            />
            <path
                d="M28 16H10.6667M28 7.99999H10.6667M28 24H10.6667M6.5 16C6.5 16.5 6.15456 17 5.5 17C4.84544 17 4.5 16.5 4.5 16C4.5 15.5 4.84544 15 5.5 15C6.15456 15 6.5 15.5 6.5 16ZM6.5 7.99998C6.5 8.5 6.15456 9 5.5 9C4.84544 9 4.5 8.5 4.5 7.99999C4.5 7.5 4.84544 7 5.5 7C6.15456 7 6.5 7.5 6.5 7.99998ZM6.5 24C6.5 24.5 6.15456 25 5.5 25C4.84544 25 4.5 24.5 4.5 24C4.5 23.5 4.84544 23 5.5 23C6.15456 23 6.5 23.5 6.5 24Z"
                stroke={props.color ? props.color : "#0E7090"}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
