import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { UtilHelper } from "../../../../helpers/util.helper"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { TooltipIcon } from "../../../common/tooltipIcons.component"
import { TooltipElement } from "../../../elements/tooltip.element"

import { NotificationsActionsCreator } from "../../../../actions/notifications.action"
import { DashboardBoardConfig } from "../../../../config/dashboard.config"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { NotificationStateSelector } from "../../../../selectors/notificationStateSelector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import ErrorTooltipAlert from "../../tooltip-alerts/ErrorTooltipAlert.component"
import WarningTooltipAlert from "../../tooltip-alerts/warningTooltipAlert.component"

export const GraphTooltip = (props: ITooltipProps) => {
    const [defaultGrid, setDefaultGrid] = useState<boolean>(false)
    const { graphConfig } = DashboardBoardConfig
    const dispatch = useDispatch()

    const { selectedProductView, selectedProductResult } = useSelector(ProductStateSelector)
    const { userActionData } = useSelector(AccountStateSelector)

    const { popups } = useSelector(RunTimeStateSelector)
    const { tooltipVisible, tooltip, product, color, id, isFirst } = props

    const isSuccessWarning = product && product.warnings?.includes("success")
    const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    const isWarning = product && product?.warnings?.includes(selectedValue as WarningType)

    useEffect(() => {
        if (!tooltipVisible) {
            setDefaultGrid(false)
        }
    }, [tooltipVisible])

    const totalIcons =
        product &&
        selectedProductView &&
        Object.keys(product).filter((key) => {
            return isFirst
                ? [
                      "actual_asin",
                      "seller_info",
                      "stock",
                      "price",
                      "title",
                      "bullets",
                      "description",
                      "main_image",
                      "carousel_images",
                      "main_video",
                      "videos",
                      //   "keywords"
                  ].includes(key)
                : selectedProductView.selectedViews.includes(key as ProductViewType)
        }).length

    const icons = useMemo(() => {
        return (
            selectedProductView &&
            selectedProductView.selectedViews
                .map((opt, index) => {
                    const iconProps = {
                        key: index,
                        type: opt as ProductViewType,
                        checked: popups?.includes(opt as ProductViewType),
                        totalIcons: totalIcons,
                        color: color,
                        product: product,
                        id: id,
                    }
                    switch (opt) {
                        case "price":
                            return product && product.price && <TooltipIcon {...iconProps} />
                        case "title":
                            return product && product.title && <TooltipIcon {...iconProps} />
                        case "bullets":
                            return product && product.bullets && <TooltipIcon {...iconProps} />
                        case "carousel_images":
                            return product && product.carousel_images && <TooltipIcon {...iconProps} />
                        case "description":
                            return product && product.description && <TooltipIcon {...iconProps} />
                        case "main_image":
                            return product && product.main_image && <TooltipIcon {...iconProps} />
                        case "seller_info":
                            return product && product.seller_info && <TooltipIcon {...iconProps} />
                        case "videos":
                            return (
                                product &&
                                typeof product !== "boolean" &&
                                product.videos && <TooltipIcon {...iconProps} />
                            )
                        case "stock":
                            return (
                                product &&
                                typeof product !== "boolean" &&
                                product.availability && <TooltipIcon {...iconProps} />
                            )
                        case "main_video":
                            return product && product.main_video && <TooltipIcon {...iconProps} />
                        // case "keywords":
                        //     return product && product.keywords && <TooltipIcon {...iconProps} />
                        case "actual_asin":
                            return (
                                product &&
                                typeof product !== "boolean" &&
                                UtilHelper.areIdsSame(product?.actual_asin as unknown as IActualAsin[]) && (
                                    <TooltipIcon {...iconProps} />
                                )
                            )
                        default:
                            return null
                    }
                })
                .filter((v) => v)
        )
    }, [selectedProductView, popups, totalIcons, color, product, id])

    const staticIcons = useMemo(() => {
        return graphConfig.productDifferentiateItems.map((opt, index) => {
            const iconProps = {
                key: index,
                type: opt.value as ProductViewType,
                checked: popups?.includes(opt.value as ProductViewType),
                totalIcons: totalIcons,
                color: color,
                product: product,
                id: id,
            }
            return <TooltipIcon {...iconProps} />
        })
    }, [graphConfig.productDifferentiateItems, popups, totalIcons, color, product, id])

    useEffect(() => {
        if (product && graphConfig.productDifferentiateItems && tooltipVisible) {
            graphConfig.productDifferentiateItems.forEach((opt, index) => {
                if (
                    product &&
                    product[opt.value as ProductViewType] &&
                    product[opt.value as ProductViewType]?.length &&
                    product[opt.value as ProductViewType]!.length > 2
                ) {
                    setDefaultGrid(true)
                }
            })
        }
    }, [graphConfig.productDifferentiateItems, product, tooltipVisible])

    const customClass = `${props.product?.warnings && "warning"} ${
        UtilHelper.areIdsSame(props?.product?.actual_asin as unknown as IActualAsin[]) ? "custom" : ""
    }`

    const showIcons = isFirst ? staticIcons.length : icons?.length

    const { graphNotifiRead } = useSelector(NotificationStateSelector)

    useEffect(() => {
        if (id && product) {
            const productData = graphNotifiRead?.trackpackDictionary[id]
            if (productData && product.timestamp) {
                const matchingNotification = productData.find(
                    (notification: { pd_timestamp: string }) =>
                        notification.pd_timestamp === product.timestamp.split("T")[0]
                )

                if (
                    matchingNotification?.notification_id &&
                    matchingNotification?.read_at === null &&
                    !userActionData
                ) {
                    let payload = {
                        notification_ids: [matchingNotification?.notification_id],
                    }
                    dispatch(NotificationsActionsCreator.markNotificationAsRead(payload))
                }
            }
        }
    }, [id, product, dispatch, userActionData, graphNotifiRead?.trackpackDictionary])

    return (
        <div
            onBlur={() => {
                // over()
                // setSelectedIcon(undefined)
            }}
        >
            {selectedProductView?.selectedViews.length ? (
                <>
                    <TooltipElement
                        show={!!tooltipVisible}
                        backgroundColor={
                            showIcons ? color.default : isSuccessWarning ? "#FEE4E2" : isWarning ? "#FEF0C7" : ""
                        }
                        hookElement={tooltip}
                        warningBoxShadow={true}
                        customClass={customClass}
                    >
                        {showIcons ? (
                            <>
                                <div
                                    id="tooltip-parent-graph"
                                    className={
                                        totalIcons && totalIcons >= 5
                                            ? `grid  ${
                                                  defaultGrid ? "custom-grid" : "grid-cols-6"
                                              }  gap-[2px] justify-center items-center w-max`
                                            : "flex gap-[2px]"
                                    }
                                >
                                    {isFirst ? staticIcons : icons}
                                </div>
                            </>
                        ) : (
                            <>
                                {isSuccessWarning ? (
                                    <ErrorTooltipAlert className="p-0" />
                                ) : (
                                    isWarning && <WarningTooltipAlert className="p-0" />
                                )}
                            </>
                        )}
                    </TooltipElement>
                </>
            ) : (
                <></>
            )}
        </div>
    )
}
