import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { Toaster } from "react-hot-toast"
import { Provider } from "react-redux"
import { RouterProvider } from "react-router"

import { LoadingComponent } from "./components/common/loading.component"
import { router } from "./router"

import reportWebVitals from "./reportWebVitals"
import { store } from "./store"

import "react-day-picker/style.css"
import "./assets/fonts/fonts.style.css"
import "./index.css"

import { useNotificationServiceWorkerHook } from "./hooks/notificationServiceWorker.hook"
import "./i18n"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

const queryClient = new QueryClient()

const App = () => {
    useNotificationServiceWorkerHook()
    return <></>
}
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <Suspense fallback={<LoadingComponent />}>
                    <Toaster />
                    <RouterProvider router={router} />
                    <App />
                </Suspense>
            </Provider>
        </QueryClientProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
