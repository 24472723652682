export const CarouselIconTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            width={props.width ?? "16"}
            height={props.height ?? "16"}
            className={props.className}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 8L7.78534 10.9098C7.86405 10.9494 7.9034 10.9692 7.94468 10.977C7.98124 10.9839 8.01876 10.9839 8.05532 10.977C8.0966 10.9692 8.13595 10.9494 8.21466 10.9098L14 8M2 11.0178L7.78534 13.9277C7.86405 13.9672 7.9034 13.987 7.94468 13.9948C7.98124 14.0017 8.01876 14.0017 8.05532 13.9948C8.0966 13.987 8.13595 13.9672 8.21466 13.9277L14 11.0178M2 4.98219L7.78534 2.07235C7.86405 2.03276 7.9034 2.01297 7.94468 2.00518C7.98124 1.99827 8.01876 1.99827 8.05532 2.00518C8.0966 2.01297 8.13595 2.03276 8.21466 2.07235L14 4.98219L8.21466 7.89203C8.13595 7.93162 8.0966 7.95141 8.05532 7.9592C8.01876 7.9661 7.98124 7.9661 7.94468 7.9592C7.9034 7.95141 7.86405 7.93162 7.78534 7.89203L2 4.98219Z"
                stroke={props.stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
