import { useEffect } from "react"
import { useController } from "react-hook-form"
import Select, { OptionProps, SingleValue, SingleValueProps } from "react-select"
import { PrimaryText } from "./primaryText.element"

const SelectInputElement = ({
    name,
    reactHookControl,
    reactHookValidations,
    defaultValue,
    countries,
    errorMessage,
    placeholder,
    isOptionImage,
    isInputImage,
    optionsMessage,
}: SelectInputOptions) => {
    const { field, fieldState } = useController({
        name,
        control: reactHookControl,
        rules: reactHookValidations,
        defaultValue: defaultValue || null,
    })

    useEffect(() => {
        if (defaultValue && field?.onChange) field.onChange(defaultValue)
    }, [defaultValue, field])

    const CustomOption = (props: OptionProps<InputOptions>) => (
        <div className="py-1.5 px-2 flex items-center gap-2 cursor-pointer" ref={props.innerRef} {...props.innerProps}>
            {isOptionImage && props.data.icon}
            <span className="text-[#344054] text-[16px] font-light">{props.data.label}</span>
        </div>
    )
    const SelectedValue = (props: SingleValueProps<InputOptions>) => (
        <div className="flex items-center gap-2 ">
            {isInputImage && props.data.icon}
            <span>{props.data.label}</span>
        </div>
    )
    // const CustomMenuList = (props: MenuListProps<InputOptions>) => (
    //     <div>
    //         {props?.selectProps?.value === null && (
    //             <div className="py-1.5 px-2 text-[#6B7280] text-[16px] font-light">{optionsMessage}</div>
    //         )}
    //         {props.children}
    //     </div>
    // )
    const handleChange = (selectedOption: SingleValue<InputOptions>) => {
        field.onChange(selectedOption)
    }
    return (
        <>
            <Select
                id="country-input"
                styles={{
                    control: (base) => ({
                        ...base,
                        cursor: "pointer",
                        color: "#344054",
                        fontWeight: 300,
                        padding: "0px 14px 0px 14px",
                        fontSize: "16px",
                        border: "1px solid #D0D5DD",
                        borderRadius: "12px",
                        background: "#FFF",
                        boxShadow: "none",
                        height: "44px",
                        "&:hover": {
                            border: "1px solid #D0D5DD",
                        },
                    }),
                }}
                options={countries}
                value={field.value}
                onChange={handleChange}
                components={{
                    Option: CustomOption,
                    SingleValue: SelectedValue,
                    // MenuList: CustomMenuList,
                    IndicatorSeparator: () => null,
                }}
                isMulti={false}
                isSearchable={false}
                placeholder={placeholder}
            />

            {fieldState.error && (
                <PrimaryText weight={"light"} typography={"small-error"} className="ml-[6px] mt-2 leading-[1]">
                    {errorMessage}
                </PrimaryText>
            )}
        </>
    )
}
export default SelectInputElement
