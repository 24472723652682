import parse from "html-react-parser"
import { useTranslation } from "react-i18next"

import { ReactComponent as LeftArrow } from "../assets/svgs/left-arrow.svg"
import { ENVIRONMENT_VARIABLES } from "../config/common.config"
import { PrimaryText } from "./elements/primaryText.element"

function PrivacyPolicy() {
    const { t } = useTranslation("public", {
        keyPrefix: "privacyPublic",
    })
    const services = [
        parse(t("service1")),
        parse(t("service2")),
        parse(t("service3")),
        parse(t("service4")),
        parse(t("service5")),
        parse(t("service6")),
    ]
    const handleNavigate = () => {
        window.location.href = ENVIRONMENT_VARIABLES.REACT_APP_BASE_URL
    }
    return (
        <div className="flex justify-center mt-[96px]">
            <div className="lg:w-[640px] md:w-[490px] sm:w-[430px] xs:w-[100%] xs:px-2 flex flex-col absolute">
                <div
                    onClick={handleNavigate}
                    className="w-12 relative right-[1.5px] h-[26px] cursor-pointer mb-[24px] py-1 rounded-lg justify-start items-center gap-1 inline-flex"
                >
                    <div className="w-4 h-4">
                        <LeftArrow />
                    </div>
                    <PrimaryText className="text-cyan-500" size="xs" weight="medium">
                        Back
                    </PrimaryText>
                </div>
                <div>
                    <PrimaryText className="text-cyan-500 leading-7" size="lg" weight="medium">
                        Privacy Policy
                    </PrimaryText>
                    <PrimaryText className="text-slate-700 leading-[18px] my-[20px]" size="xs" weight="medium">
                        Effective Date: 2024-03-20
                    </PrimaryText>
                    <div className="mb-[10px]">{parse(t("AboutAmaizing"))}</div>
                    <div className="mb-[10px]">{parse(t("Services"))}</div>
                    <div className="mb-[10px]">{parse(t("ServicesHeading"))}</div>
                    <ul className="mb-[10px] ml-[9px] ">
                        {services.map((el) => (
                            <li className="relative pl-[2px]">
                                <span className="text-slate-700 text-xs font-light font-[F37 Bolton] leading-[18px]">
                                    <div className="flex items-start">
                                        <span className="text-[16px]">&#8226;</span>
                                        <span className="ml-2 text-slate-700 text-xs font-light font-[F37 Bolton] leading-[18px]">
                                            {el}
                                        </span>
                                    </div>
                                </span>
                            </li>
                        ))}
                    </ul>
                    <div className="mb-[10px] ">
                        <PrimaryText className="text-slate-700 leading-[18px]" size="xs" weight="light">
                            For information on third-party interest-based advertising conducted through our Services,
                            please see the{" "}
                            <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                “Targeted Advertising”
                            </span>{" "}
                            section below.
                        </PrimaryText>
                    </div>
                    <div className="mb-[10px] ">
                        <PrimaryText className="text-slate-700 leading-[18px]" size="xs" weight="light">
                            Residents of the European Economic Area (
                            <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                “EEA”
                            </span>
                            ), which includes the member states of the European Union (
                            <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                “EU”
                            </span>
                            ), should consult the sections of this policy relating to the{" "}
                            <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                “EEA Residents”
                            </span>{" "}
                            and{" "}
                            <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                “International Data Transfers”
                            </span>{" "}
                            for provisions that may apply to them.
                        </PrimaryText>
                    </div>
                    <div className="mb-[10px] ">
                        <PrimaryText className="text-slate-700 leading-[18px]" size="xs" weight="light">
                            California residents should consult the section titled{" "}
                            <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                “Your California Privacy Rights”
                            </span>{" "}
                            for rights that apply to them.
                        </PrimaryText>
                    </div>
                    <div className="mb-[35px] ">
                        <PrimaryText className="text-slate-700 leading-[18px]" size="xs" weight="light">
                            We reserve the right to change this Privacy Policy at any time. We may inform you about any
                            revisions. All changes are effective on the date listed at the top of this page and will
                            apply to all information that we have about you. Your continued use of the Services after
                            any change is posted indicates your acceptance of this Privacy Policy and your continued
                            consent to our processing of your Personal Information in the manner set forth herein. If at
                            any point you do not agree to any portion of the Privacy Policy, you should immediately stop
                            using the Services. Because this Privacy Policy contains legal obligations we encourage you
                            to review this Privacy Policy carefully. If you have any questions concerning this Privacy
                            Policy or our information collection, storage and disclosure practices, please contact us at{" "}
                            <span className="text-cyan-500 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                hello@amaizing.io
                            </span>
                        </PrimaryText>
                    </div>
                    <div className="mb-[10px] ">
                        <PrimaryText className="text-cyan-500 leading-normal" size="md" weight="medium">
                            Information we collect
                        </PrimaryText>
                    </div>
                    <ul className="ml-[5px] ">
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Personal Information:
                                        </span>{" "}
                                        “Personal Information” is information that may be used to directly or indirectly
                                        identify an individual (which in some cases, may include certain device
                                        information). The Personal Information we collect may include your (a) name,
                                        postal address, email address, and phone number; (b) Internet Protocol (IP)
                                        address, device ID or other persistent identifiers tied to your computer or
                                        device; and (c) information about your third party accounts (
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            “Third Party Accounts”
                                        </span>
                                        ) you may choose to provide us for integration and analytics purposes. In
                                        general, Personal Information we collect includes, but is not limited to:
                                    </span>
                                </div>

                                <ul className="ml-[25px] w-[95%] ">
                                    <li className="flex items-start  pl-[7px]">
                                        <span className="text-[16px]">&#8226;</span>
                                        <span className="ml-2">
                                            Personal Information needed for you to be able to use the Services,
                                            including for establishing an account, logging in, paying for subscription
                                            to the Services, and linking your Third Party Accounts;
                                        </span>
                                    </li>
                                    <li className="flex items-start  pl-[7px]">
                                        <span className="text-[16px]">&#8226;</span>
                                        <span className="ml-2">
                                            Personal Information to contact and respond to you, including to provide you
                                            with results of our analytics services, reply to your inquiries, and keep in
                                            touch with you regarding features or matters of interest regarding the
                                            Services;
                                        </span>
                                    </li>
                                    <li className="flex items-start  pl-[7px]">
                                        <span className="text-[16px]">&#8226;</span>
                                        <span className="ml-2">
                                            Un-identifiable and aggregated Personal Information pertaining to your
                                            visits to and use of the Services and Site that help us maintain the
                                            appropriate features, functionality and user experience
                                        </span>
                                    </li>
                                </ul>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Usage Data:
                                        </span>{" "}
                                        “Usage Data” is information passively or automatically collected by us through
                                        your use of the Services. Usage Data may be collected using cookies, web
                                        beacons, page tags or similar tools. All Usage Data is anonymous transactional
                                        data that is not associated with any users as individuals. Such Usage Data may
                                        include: your Internet Protocol (IP) address, mobile identifiers, browser type,
                                        and internet service provider (ISP); your operating system; which of our web
                                        pages you access and how frequently you access them; referral or exit pages;
                                        click stream data; and the dates and times that you visit the Services or Site.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Customer Data:
                                        </span>{" "}
                                        In order to provide you with our services, and with your permission (which you
                                        are providing if you link any of your Third Party Platforms to your Company
                                        account), we may collect and process data regarding your customers and their use
                                        of your services (
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            “Customer Data”
                                        </span>
                                        ). You represent and warrant that all Customer Data was collected by you in
                                        accordance with your privacy policy and all applicable laws and that you have
                                        the right to provide us with such Customer Data. For clarity, your Third Party
                                        Platform accounts are governed by their own privacy policies and are not under
                                        the control of Company.
                                    </span>
                                </div>
                            </span>
                        </li>
                    </ul>
                    <div className="mt-[35px] mb-[10px] ">
                        <PrimaryText className="text-cyan-500 leading-normal" size="md" weight="medium">
                            How and when we collect information
                        </PrimaryText>
                    </div>
                    <ul className="ml-[5px] mb-[20px]">
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Personal Information:
                                        </span>{" "}
                                        We collect Personal Information at the time you provide it to us. We collect
                                        Personal Information through sign-up forms and as part of your registration for
                                        an account, product, or service, promotion, or contest from this website. In
                                        addition, we collect personal information from communications with site visitors
                                        as well as from third-party outside sources including data brokers and
                                        aggregators.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Usage Data:
                                        </span>{" "}
                                        We use cookies when you sign in to keep track of your personal session. We also
                                        use cookies to track your activity on the Services, including your interactions
                                        with emails we send, and via social media platforms, third-party platforms,
                                        applications, integrations, and services of our marketing partners to provide
                                        you with the optimal experience. This data may be passively or automatically
                                        collected (that is, gathered without your actively providing the information)
                                        using various analytics and reporting technologies, such as cookies, web
                                        beacons, locally stored objects, and mobile device identifiers and SDKs, and
                                        other similar methodologies as well as similar technologies developed in the
                                        future.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            “Do Not Track” Requests:
                                        </span>{" "}
                                        Some Web browsers have a{" "}
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            “Do Not Track”
                                        </span>{" "}
                                        feature that signals to websites that you do not want to have your online
                                        activity tracked. Because each browser communicates{" "}
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            “Do Not Track”
                                        </span>{" "}
                                        signals differently, we do not respond to{" "}
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            “Do Not Track”
                                        </span>{" "}
                                        signals at this time.
                                    </span>
                                </div>
                            </span>
                        </li>
                    </ul>
                    <div className="mt-[35px] mb-[10px] ">
                        <PrimaryText className="text-cyan-500 leading-normal" size="md" weight="medium">
                            How we use information
                        </PrimaryText>
                    </div>
                    <ul className="ml-[5px] mb-[20px]">
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        We may use your Personal Information and Usage Data to take actions you request
                                        and for the basic purposes of the Services. We may use your Personal Information
                                        in connection with other products or services we may offer, with our internal
                                        reporting for this site (such as security assessments), or to contact you with
                                        promotions regarding other products or services offered by us, our affiliated
                                        entities or our third party partners. We may, for example, use aggregated Usage
                                        Data information to optimize the Services or to evaluate the success of
                                        particular marketing and advertising campaigns or search engine optimization
                                        strategies.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        We may also send you messages related to certain features or your activity on
                                        this site. We may also send you news or updates about changes to our Services.
                                        By default, you will receive these messages via email.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        We may use publicly available Personal Information posted on social media
                                        profile information including photos for purposes of assisting us, and our
                                        marketing partners with marketing and advertising activities and with contact
                                        management.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        We may use Usage Data without restriction in our sole discretion for
                                        administrative and optimization purposes, such as to improve the Services and
                                        personalize ads (as described more particularly below under “Targeted
                                        Advertising”).
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        We may combine and use the Personal Information and Usage Data we collect from
                                        all services and products offered by us and our Affiliated Entities over various
                                        websites to provide, protect, and improve them, and to develop and offer new
                                        services and products. We will treat you as a single user of these combined
                                        services and products.
                                    </span>
                                </div>
                            </span>
                        </li>
                    </ul>
                    <div className="mt-[35px] mb-[10px] ">
                        <PrimaryText className="text-cyan-500 leading-normal" size="md" weight="medium">
                            How and when we share and disclose information
                        </PrimaryText>
                    </div>
                    <ul className="ml-[5px] mb-[20px]">
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            General Disclosure Policy:
                                        </span>{" "}
                                        We may share and disclose your Personal Information as described below. We may
                                        share and disclose Usage Data without restriction, such as in the ways described
                                        below.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Affiliated Entities: “Affiliated Entities”
                                        </span>{" "}
                                        are entities that we legally control (by voting rights) or that control us. We
                                        may provide your Personal Information and Usage Data to any affiliated entities
                                        we may have, including our subsidiaries.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Service Providers
                                        </span>{" "}
                                        We may provide access to your Personal Information and Usage Data to trusted
                                        service providers that assist us with the operation and maintenance of the
                                        Service. For example, we may contract with third parties to facilitate purchases
                                        from the Services, process payments, host our servers, provide security, and
                                        provide production, fulfillment, optimization, analytics, reporting, and
                                        software maintenance and development services. Our service providers will be
                                        given access to your information only as is reasonably necessary to provide the
                                        services for which they are contracted.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Successors
                                        </span>{" "}
                                        If we sell or otherwise transfer part or all of our business or assets to
                                        another organization, such as in the course of an acquisition, merger,
                                        bankruptcy or liquidation, we may transfer your Personal Information and Usage
                                        Data.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Legal Process, Enforcement and Security Notice:
                                        </span>{" "}
                                        We may disclose your Personal Information and Usage Data if we have a good faith
                                        belief that access, use, preservation or disclosure of such information is
                                        reasonably necessary (i) to satisfy any applicable law, regulation, legal
                                        process or enforceable governmental request (such as for example, to comply with
                                        a subpoena or court order), (ii) to detect, prevent, and address fraud or other
                                        illegal activity, and (iii) to investigate, respond to, or enforce violations of
                                        our rights or the security of this site.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            With Your Consent:
                                        </span>{" "}
                                        We may share your Personal Information with other parties with your consent.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Analytics Partners:
                                        </span>{" "}
                                        We may participate with third party analytics partners to monitor and analyze
                                        Web traffic and to keep track of user behavior on this site.
                                    </span>
                                </div>
                            </span>
                        </li>
                        <li className="relative pl-[2px]">
                            <span className="text-slate-700 leading-[18px] text-xs font-light">
                                <div className="flex items-start">
                                    <span className="text-[16px]">&#8226;</span>
                                    <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Transfer Outside Country of Residence:
                                        </span>{" "}
                                        In general, any Personal Information we may collect may be stored and processed
                                        in our servers located in the United States or in any other country in which we,
                                        or our affiliates, subsidiaries, or agents maintain facilities. By using this
                                        site, you consent to any such transfer of Personal Information outside your
                                        country of residence to any such location.
                                    </span>
                                </div>
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="mt-[35px] mb-[10px] ">
                    <PrimaryText className="text-cyan-500 leading-normal" size="md" weight="medium">
                        Targeted Advertising
                    </PrimaryText>
                </div>
                <ul className="ml-[5px] mb-[20px]">
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    We engage certain third-party service providers to serve advertisements on our
                                    behalf across the Internet and to provide analytics services. We may also
                                    participate in third-party affiliate advertising and allow third-party affiliate
                                    links to be encoded on some of our pages. This means that we may earn a commission
                                    when you click on or make purchases via third-party affiliate links.
                                </span>
                            </div>
                        </span>
                    </li>
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    Our advertisers or the ad networks that serve advertisements may utilize cookies or
                                    other similar tracking technologies (including within the ads) to collect anonymous
                                    information from you such as your device identifiers, advertising IDs, and IP
                                    address, web browser, actions you take relating to the ads, any links you click on,
                                    and conversion information. This information may be used by us, our service
                                    providers and their clients in aggregated, anonymous form to, among other things,
                                    analyze and track aggregated data, determine the popularity of certain content or
                                    products, measure the effectiveness of ad campaigns, determine the proper amount of
                                    repeat views of a given ad, and deliver advertising and content targeted to your
                                    interests on our Services and outside of our Services on other websites (also known
                                    as “interest-based advertising”). These service providers are prohibited from
                                    collecting any Personal Data from you and we do not share any of your Personal Data
                                    with them.
                                </span>
                            </div>
                        </span>
                    </li>
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    You have a choice about participating in interest-based advertising. If you wish to
                                    opt out of such participation, you have a few options:
                                </span>
                            </div>
                            <ul className="ml-[25px] ">
                                <li className="relative pl-[14px]">
                                    <div className="flex items-start">
                                        <span className="text-[16px]">&#8226;</span>
                                        <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                            On your mobile device, you can visit{" "}
                                            <span className="text-cyan-500 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                                www.youradchoices.com/appchoices
                                            </span>{" "}
                                            to learn about and download the Digital Advertising Alliance’s opt-out app,
                                            which allows you to opt your mobile device out of interest-based advertising
                                            from participating companies.
                                        </span>
                                    </div>
                                </li>
                                <li className="relative pl-[14px]">
                                    <div className="flex items-start">
                                        <span className="text-[16px]">&#8226;</span>
                                        <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                            Your device settings may allow you to limit the use of information from your
                                            device in interest-based advertising through your browser’s settings (such
                                            as under the{" "}
                                            <span className="text-cyan-500 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                                “Security & Privacy”
                                            </span>{" "}
                                            settings of the Safari app on an iOS device) or an{" "}
                                            <span className="text-cyan-500 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                                “Opt Out of Interest-Based Ads”
                                            </span>{" "}
                                            setting (on an Android device).
                                        </span>
                                    </div>
                                </li>
                                <li className="relative pl-[14px]">
                                    <div className="flex items-start">
                                        <span className="text-[16px]">&#8226;</span>
                                        <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                            You can learn more about advertising networks and interest-based
                                            advertising, and your ability to opt out, by visiting the Digital
                                            Advertising Alliance at{" "}
                                            <span className="text-cyan-500 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                                www.aboutads.info/choices
                                            </span>{" "}
                                            or the Network Advertising Initiative at
                                            <span className="text-cyan-500 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                                www.networkadvertising.org/choices
                                            </span>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </span>
                    </li>
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    In providing you with transparency and choice regarding interest-based advertising,
                                    we are acting in accordance with our commitment to the Digital Advertising
                                    Alliance’s Self-Regulatory Principles. To learn more about these Principles, please
                                    visit{" "}
                                    <span className="text-cyan-500 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                        www.aboutads.info/consumers
                                    </span>
                                </span>
                            </div>
                        </span>
                    </li>
                </ul>
                <div className="mt-[35px] mb-[10px] ">
                    <PrimaryText className="text-cyan-500 leading-normal" size="md" weight="medium">
                        Changing and deleting personal information
                    </PrimaryText>
                </div>
                <ul className="ml-[5px] mb-[20px]">
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    Under certain laws, including as described below with respect to the GDPR and CCPA,
                                    you may have the right to: obtain confirmation that we hold Personal Information
                                    about you, request access to and receive information about the Personal Information
                                    we maintain about you, receive copies of the Personal Information we maintain about
                                    you, update and correct inaccuracies in your Personal Information, object to the
                                    continued processing of your Personal Information, and have the Personal Information
                                    blocked, anonymized or deleted, as appropriate. The right to access Personal
                                    Information may be limited in some circumstances by local law, including as
                                    described above under California law. If you qualify, in order to exercise these
                                    rights, please contact us as described under{" "}
                                    <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                        “Contact Us”
                                    </span>
                                    .
                                </span>
                            </div>
                        </span>
                    </li>
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    You may opt out of promotional communications by sending us an email at the email
                                    address below under
                                    <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                        “Contact Us”
                                    </span>
                                    .
                                </span>
                            </div>
                        </span>
                    </li>
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    We may ask you to provide additional information for identity verification purposes,
                                    or to verify that you are in possession of an applicable email account.{" "}
                                </span>
                            </div>
                        </span>
                    </li>
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    Please understand, however, that we reserve the right to retain an archive of such
                                    Personal Information for a commercially reasonable time to ensure that its deletion
                                    does not affect the integrity of our data; and we further reserve the right to
                                    retain an anonymous version of such Information.
                                </span>
                            </div>
                        </span>
                    </li>
                </ul>
                <div className="mt-[35px] mb-[10px] ">
                    <PrimaryText className="text-cyan-500 leading-normal" size="md" weight="medium">
                        Terms for specific types of users
                    </PrimaryText>
                </div>
                <span className="text-slate-700  text-base font-light font-['F37 Bolton'] underline leading-normal">
                    EEA Residents
                </span>
                <div className="my-[10px]">
                    <span className="text-slate-700 text-xs font-light font-['F37 Bolton'] leading-[18px]">
                        This section of the Privacy Policy is applicable to residents of the EEA. The EEA consists of
                        the member states of the EU, i.e., Austria, Belgium, Bulgaria, Croatia, Republic of Cyprus,
                        Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy,
                        Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia,
                        Slovenia, Spain, Sweden, and the United Kingdom, and Iceland, Liechtenstein, and Norway. This
                        section also applies to residents of Switzerland and the United Kingdom. Residents of the EEA,
                        Switzerland and the United Kingdom are referred to here as{" "}
                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                            “EEA Residents”
                        </span>
                        .
                    </span>
                </div>
                <ul className="ml-[25px] mb-[30px]">
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    Since May 25, 2018, all processing of Personal Information of EEA Residents is
                                    performed by us in accordance with the General Data Protection Regulation (2016/679)
                                    of the European Parliament and of the Council of 27 April 2016 on the protection of
                                    natural persons regarding the processing of Personal Information and on the free
                                    movement of such data. (
                                    <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                        “GDPR”
                                    </span>
                                    ) .
                                </span>
                            </div>
                        </span>
                    </li>
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    Under the GDPR, we are the controller and a processor of the Personal Information of
                                    EEA Residents. Our purpose for collecting and processing Personal Information from
                                    EEA Residents is to obtain contact information, to substantiate such information and
                                    to provide the Services. The legal basis for collecting Personal Information is your
                                    consent. You may withdraw consent for obtaining such communications by following the
                                    <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                        “Unsubscribe”
                                    </span>
                                    instructions on any communication or by contacting us at{" "}
                                    <span className="text-cyan-500 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                        hello@amaizing.io
                                    </span>
                                </span>
                            </div>
                        </span>
                    </li>
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    If you are a resident of the EEA and believe that we have Personal Information about
                                    you, and you wish to access or correct the Personal Information that we have about
                                    you or have any questions relating to the processing of your Personal Information,
                                    please contact us at
                                    <span className="text-cyan-500 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                        hello@amaizing.io
                                    </span>{" "}
                                    with the subject line{" "}
                                    <span className="text-cyan-500 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                        “GDPR Data”
                                    </span>
                                    .
                                </span>
                            </div>
                        </span>
                    </li>
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    International Data Transfers. If you are resident outside the United States,
                                    including in the EEA, we transfer Personal Information provided by you for
                                    processing in the United States. Under the GDPR, we are considered a{" "}
                                    <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                        “controller”
                                    </span>{" "}
                                    and a{" "}
                                    <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                        “co-processor”
                                    </span>{" "}
                                    of the Personal Information of EEA Residents. By providing Personal Information to
                                    us for the purpose of setting up an account, obtaining Services, or adding yourself
                                    to our contact lists, you consent to the transfer of your Personal Information to
                                    the United States. The transfer of your Personal Information to the United States is
                                    necessary for the performance of a contract between you and us for obtaining
                                    Services.
                                </span>
                            </div>
                        </span>
                    </li>
                </ul>
                <span className="text-slate-700  text-base font-light font-['F37 Bolton'] underline leading-normal">
                    California Consumers
                </span>
                <div className="my-[20px]">
                    <span className="text-slate-700 text-xs font-light font-['F37 Bolton'] leading-[18px]">
                        This section pertains to the rights of individuals or households in California (
                        <span className="text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                            “California consumers”
                        </span>
                        ).
                    </span>
                </div>
                <ul className="ml-[5px] mb-[20px]">
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    Under certain circumstances, California Civil Code Section 1798.83 states that, upon
                                    receipt of a request by a California consumer, a business may be required to provide
                                    detailed information regarding how that business has shared that customer’s Personal
                                    Information with third parties for direct marketing purposes. However, the foregoing
                                    does not apply to businesses like ours that do not disclose Personal Information to
                                    third parties for direct marketing purposes.
                                </span>
                            </div>
                        </span>
                    </li>
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    The CCPA (California Civil Code Section 1798.100 et seq.) provides California
                                    consumers with additional rights regarding Personal Information that identifies,
                                    relates to, describes, is capable of being associated with, or could reasonably be
                                    linked, directly or indirectly with a particular consumer or household. The
                                    categories of Personal Information we collect are generally described above but
                                    differ for individual consumers depending on the services used by such consumers.
                                </span>
                            </div>
                        </span>
                    </li>
                    <li className="relative pl-[2px]">
                        <span className="text-slate-700 leading-[18px] text-xs font-light">
                            <div className="flex items-start">
                                <span className="text-[16px]">&#8226;</span>
                                <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                    Under the CCPA, qualifying California consumers may have the following rights:
                                </span>
                            </div>
                        </span>
                        <ul className="ml-[25px]">
                            <li className="relative pl-[2px]">
                                <span className="text-slate-700 leading-[18px] text-xs font-light">
                                    <div className="flex items-start">
                                        <span className="text-[16px]">&#8226;</span>
                                        <span className="ml-2  leading-[18px]  text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Right to Know and Right to Delete
                                        </span>
                                    </div>
                                </span>
                            </li>
                            <ul className="ml-[30px] w-[94%]">
                                <li className="relative pl-[2px]">
                                    <span className="text-slate-700 leading-[18px] text-xs font-light">
                                        <div className="flex items-start">
                                            <span className="text-[16px]">&#8226;</span>
                                            <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                                A California consumer has the right to request that we disclose what
                                                Personal Information we collect, use, disclose and sell. A California
                                                consumer also has the right to submit requests to delete Personal
                                                Information.
                                            </span>
                                        </div>
                                    </span>
                                </li>
                                <li className="relative pl-[2px]">
                                    <span className="text-slate-700 leading-[18px] text-xs font-light">
                                        <div className="flex items-start">
                                            <span className="text-[16px]">&#8226;</span>
                                            <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                                When we receive a valid request to know or delete from a California
                                                consumer, we will confirm receipt of the request within 10 days and
                                                provide information about how we will process the request, including our
                                                verification process. We will respond to such requests within 45 days.
                                            </span>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                            <li className="relative pl-[2px]">
                                <span className="text-slate-700 leading-[18px] text-xs font-light">
                                    <div className="flex items-start">
                                        <span className="text-[16px]">&#8226;</span>
                                        <span className="ml-2  leading-[18px]  text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Right for Disclosure of Information
                                        </span>
                                    </div>
                                </span>
                            </li>
                            <ul className="ml-[30px]  w-[94%]">
                                <li className="relative pl-[2px]">
                                    <span className="text-slate-700 leading-[18px] text-xs font-light">
                                        <div className="flex items-start">
                                            <span className="text-[16px]">&#8226;</span>
                                            <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                                A California consumer may also submit requests that we disclose specific
                                                types or categories of Personal Information that we collect.
                                            </span>
                                        </div>
                                    </span>
                                </li>
                                <li className="relative pl-[2px]">
                                    <span className="text-slate-700 leading-[18px] text-xs font-light">
                                        <div className="flex items-start">
                                            <span className="text-[16px]">&#8226;</span>
                                            <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                                Under certain circumstances, we will not provide such information,
                                                including where the disclosure creates a substantial, articulable and
                                                unreasonable risk to the security of that Personal Information,
                                                customers’ account with us, or the security of our systems or networks.
                                                We also will not disclose California consumers’ social security numbers,
                                                driver’s license numbers or other government-issued identification
                                                numbers, financial account numbers, any health insurance or medical
                                                identification numbers, or account passwords and security questions and
                                                answers.
                                            </span>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                            <li className="relative pl-[2px]">
                                <span className="text-slate-700 leading-[18px] text-xs font-light">
                                    <div className="flex items-start">
                                        <span className="text-[16px]">&#8226;</span>
                                        <span className="ml-2  leading-[18px]  text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Submitting Requests
                                        </span>
                                    </div>
                                </span>
                            </li>
                            <ul className="ml-[30px] mb-[20px] w-[94%]">
                                <li className="relative pl-[2px]">
                                    <span className="text-slate-700 leading-[18px] text-xs font-light">
                                        <div className="flex items-start">
                                            <span className="text-[16px]">&#8226;</span>
                                            <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                                If you are a California consumer and would like to make any requests
                                                under the CCPA, please see the “Changing and Deleting Personal
                                                Information” the section.
                                            </span>
                                        </div>
                                    </span>
                                </li>
                                <li className="relative pl-[2px]">
                                    <span className="text-slate-700 leading-[18px] text-xs font-light">
                                        <div className="flex items-start">
                                            <span className="text-[16px]">&#8226;</span>
                                            <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                                Verifying Requests. If we receive any request we will use a two-step
                                                process for online requests where the California consumer must first,
                                                clearly submit the request and then second, separately confirm the
                                                request. We will use other appropriate measures to verify requests
                                                received by mail or telephone.
                                            </span>
                                        </div>
                                    </span>
                                </li>
                                <li className="relative pl-[2px]">
                                    <span className="text-slate-700 leading-[18px] text-xs font-light">
                                        <div className="flex items-start">
                                            <span className="text-[16px]">&#8226;</span>
                                            <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                                To verify a request, a California consumer must provide a business with
                                                sufficient information to identify the consumer, such as name, e-mail
                                                address, home or work address, or other such information that is on
                                                record with us so that we can match such information to the Personal
                                                Information that we maintain. Do not provide social security numbers,
                                                driver’s license numbers, account numbers, credit or debit card numbers,
                                                medical information or health information with requests. If requests are
                                                unclear or submitted through means other than outline above, we will
                                                provide the California consumer with specific directions on how to
                                                submit the request or remedy any deficiencies. If we cannot verify the
                                                identity of the requestor, we may deny the request.
                                            </span>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                            <li className="relative pl-[2px]">
                                <span className="text-slate-700 leading-[18px] text-xs font-light">
                                    <div className="flex items-start">
                                        <span className="text-[16px]">&#8226;</span>
                                        <span className="ml-2  leading-[18px]  text-slate-700 text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                                            Children under 13
                                        </span>
                                    </div>
                                </span>
                            </li>
                            <ul className="ml-[30px] mb-[20px] w-[94%]">
                                <li className="relative pl-[2px]">
                                    <span className="text-slate-700 leading-[18px] text-xs font-light">
                                        <div className="flex items-start">
                                            <span className="text-[16px]">&#8226;</span>
                                            <span className="ml-2 text-slate-700 leading-[18px] text-xs font-light">
                                                We are committed to preserving online privacy for all of its website
                                                visitors, including children. Consistent with the Children’s Online
                                                Privacy Protection Act (COPPA), we do not knowingly collect any personal
                                                information from children under the age of 13, and we delete any such
                                                information we become aware of. If you are a parent or guardian and you
                                                believe your child has provided us with Personal Information, please
                                                contact us at “please contact our site as provided below under “Contact
                                                Us,” and be sure to include in your message the same login information
                                                that your child submitted.
                                            </span>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </ul>
                    </li>
                </ul>

                <div className=" pt-1 pb-[32px]  rounded-lg justify-start items-center gap-1 inline-flex">
                    <div className="text-right">
                        <span className="text-cyan-500 xs:text-[11px] md:text-xs font-medium font-['F37 Bolton'] leading-[18px]">
                            Got questions about this Privacy Policy?
                        </span>
                        <span className="text-cyan-500 text-xs font-light font-['F37 Bolton'] leading-[18px]"> </span>
                        <span className="text-slate-700 xs:text-[11px] md:text-xs  font-light font-['F37 Bolton'] leading-[18px]">
                            Contact me at hello@amaizing.io
                        </span>
                    </div>
                    <div className="w-4 h-4  ">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.33301 4.66699L6.77629 8.47729C7.21707 8.78583 7.43746 8.94011 7.67718 8.99986C7.88894 9.05265 8.11041 9.05265 8.32217 8.99986C8.56189 8.94011 8.78228 8.78583 9.22306 8.47729L14.6663 4.66699M4.53301 13.3337H11.4663C12.5864 13.3337 13.1465 13.3337 13.5743 13.1157C13.9506 12.9239 14.2566 12.618 14.4484 12.2416C14.6663 11.8138 14.6663 11.2538 14.6663 10.1337V5.86699C14.6663 4.74689 14.6663 4.18683 14.4484 3.75901C14.2566 3.38269 13.9506 3.07673 13.5743 2.88498C13.1465 2.66699 12.5864 2.66699 11.4663 2.66699H4.53301C3.4129 2.66699 2.85285 2.66699 2.42503 2.88498C2.0487 3.07673 1.74274 3.38269 1.55099 3.75901C1.33301 4.18683 1.33301 4.74689 1.33301 5.86699V10.1337C1.33301 11.2538 1.33301 11.8138 1.55099 12.2416C1.74274 12.618 2.0487 12.9239 2.42503 13.1157C2.85285 13.3337 3.4129 13.3337 4.53301 13.3337Z"
                                stroke="#06AED4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
