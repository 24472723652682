import { store } from "../store"

export class RuntimeHelper {
    ////////////////////////////////////////////////////////////////////////////////// PUBLIC USER
    /**
     * Check if ForgotPassword loading
     */
    static isAlphaEmailVerification(): boolean {
        return RuntimeHelper.isLoading("alphaEmailVerificationHandler")
    }
    static isAlphaUserRegister(): boolean {
        return RuntimeHelper.isLoading("alphaUserRegisterHandler")
    }
    static isObtainToken(): boolean {
        return RuntimeHelper.isLoading("getTokenSagaHandler")
    }
    static productLoading(): boolean {
        return RuntimeHelper.isLoading("getProductsByGroupHandler")
    }
    static trackpackLoading(): boolean {
        return RuntimeHelper.isLoading("getDropdownHandler")
    }
    static createTrackpackLoading(): boolean {
        return RuntimeHelper.isLoading("createAccountSagaHandler")
    }
    static archiveTrackpackLoading(): boolean {
        return RuntimeHelper.isLoading("archiveSelectedTrackpackHandler")
    }
    static archiveAsinLoading(): boolean {
        return RuntimeHelper.isLoading("archiveSelectedAsinsHandler")
    }

    static archiveSearchpackLoading(): boolean {
        return RuntimeHelper.isLoading("archiveSearchpackSearchTermHandler")
    }

    static archiveSearchpackSearchTermLoading(): boolean {
        return RuntimeHelper.isLoading("archiveSearchpackSearchTermHandler")
    }

    static addAdditionalAsinLoading(): boolean {
        return RuntimeHelper.isLoading("createAdditionalAccountSagaHandler")
    }
    static alertSettingLoading(): boolean {
        return RuntimeHelper.isLoading("getUserAlertSettingHandler")
    }
    static updateAlertSettingLoading(): boolean {
        return RuntimeHelper.isLoading("updateAlertSettingHandler")
    }
    static resetPasswordLoading(): boolean {
        return RuntimeHelper.isLoading("verifyPasswordEmailSagaHandler")
    }
    static competitorsDataListLoading(): boolean {
        return RuntimeHelper.isLoading("getCompetitorsDataListHandler")
    }
    static trackpackListLoading(): boolean {
        return RuntimeHelper.isLoading("getTrackpackListHandler")
    }
    static reportDataByIDLoading(): boolean {
        return RuntimeHelper.isLoading("getReportDataByIdHandler")
    }
    static reportListLoading(): boolean {
        return RuntimeHelper.isLoading("getReportListHandler")
    }
    static reportSummaryLoading(): boolean {
        return RuntimeHelper.isLoading("getReportSummaryHandler")
    }
    static searchpackListLoading(): boolean {
        return RuntimeHelper.isLoading("getSearchpacksListHandler")
    }
    static claimOwnershipLoading(): boolean {
        return RuntimeHelper.isLoading("claimOwnershipOfAsinHandler")
    }
    static getRanksnapshotDataLoading(): boolean {
        return RuntimeHelper.isLoading("getRanksnapshotDataHandler")
    }
    static getRanktrackerDataLoading(): boolean {
        return RuntimeHelper.isLoading("getSearchpackRankTrackerHandler")
    }
    static getSearchpackKeywordPerformanceHandler(): boolean {
        return RuntimeHelper.isLoading("getSearchpackKeywordPerformanceHandler")
    }

    ////////////////////////////////////////////////////////////////////////////////// PUBLIC USER

    /**
     * Get current state of loading array
     * We are also not looking here if they need preloader or now, value basically
     * We just see if it exists in array
     *
     * @private
     */
    static isLoading(key: string): boolean {
        const currentlyLoading = (store.getState().runTime as IRuntimeState).loading

        return !!currentlyLoading.find((item) => Object.keys(item).includes(key))
    }
}
