export const PaginationBackwardArrow = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8334 10.0003H4.16669M4.16669 10.0003L10 15.8337M4.16669 10.0003L10 4.16699"
                stroke="#344054"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
