import { combineReducers } from "redux"

// App Reducers
import { AccountsReducers } from "./account.reducer"
import { AuthReducer } from "./auth.reducer"
import { ListingAnalyzerReducer } from "./listingAnalyzer.reducer"
import { NotificationsReducer } from "./notifications.reducer"
import { OnBoardingUserReducer } from "./onBoarding.reducer"
import { ProductReducer } from "./product.reducer"
import { RunTimeReducer } from "./runTime.reducer"
import { SearchpackReducer } from "./searchpack.reducer"
import { SettingsReducer } from "./settings.reducer"
import { TrackpackReducer } from "./trackpack.reducer"

export const rootReducer = combineReducers({
    auth: AuthReducer,
    product: ProductReducer,
    runTime: RunTimeReducer,
    onBoarding: OnBoardingUserReducer,
    accounts: AccountsReducers,
    settings: SettingsReducer,
    notifications: NotificationsReducer,
    listingAnalyzer: ListingAnalyzerReducer,
    searchpack: SearchpackReducer,
    trackpack: TrackpackReducer,
})
export type RootState = ReturnType<typeof rootReducer>
