const VideoIcon = ({ strokeColor, className }: { strokeColor?: string; className?: string }) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M3.33203 3.30643C3.33203 2.66825 3.33203 2.34917 3.48417 2.17328C3.6167 2.02004 3.81928 1.92522 4.03857 1.91377C4.29029 1.90062 4.59385 2.07762 5.20096 2.43161L13.1015 7.03827C13.6032 7.33077 13.854 7.47702 13.9414 7.66136C14.0178 7.82252 14.0178 8.00365 13.9414 8.16481C13.854 8.34915 13.6032 8.4954 13.1015 8.7879L5.20096 13.3946C4.59385 13.7486 4.29029 13.9255 4.03857 13.9124C3.81928 13.901 3.6167 13.8061 3.48417 13.6529C3.33203 13.477 3.33203 13.1579 3.33203 12.5197V3.30643Z"
                stroke={strokeColor || "#667085"}
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default VideoIcon
