export const TrackpackTrashIcon = (props: IArrowProps) => {
    return (
        <>
            <svg
                onClick={props.onClick}
                className={props.className}
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill={props.fillColor ?? "none"}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2 3.9983C1.918 3.99618 1.85845 3.99143 1.80491 3.98079C1.40822 3.90188 1.09812 3.59178 1.01921 3.19509C1 3.09849 1 2.98233 1 2.75C1 2.51767 1 2.40151 1.01921 2.30491C1.09812 1.90822 1.40822 1.59812 1.80491 1.51921C1.90151 1.5 2.01767 1.5 2.25 1.5H9.75C9.98233 1.5 10.0985 1.5 10.1951 1.51921C10.5918 1.59812 10.9019 1.90822 10.9808 2.30491C11 2.40151 11 2.51767 11 2.75C11 2.98233 11 3.09849 10.9808 3.19509C10.9019 3.59178 10.5918 3.90188 10.1951 3.98079C10.1416 3.99143 10.082 3.99618 10 3.9983M5 6.5H7M2 4H10V8.1C10 8.94008 10 9.36012 9.83651 9.68099C9.6927 9.96323 9.46323 10.1927 9.18099 10.3365C8.86012 10.5 8.44008 10.5 7.6 10.5H4.4C3.55992 10.5 3.13988 10.5 2.81901 10.3365C2.53677 10.1927 2.3073 9.96323 2.16349 9.68099C2 9.36012 2 8.94008 2 8.1V4Z"
                    stroke={props.color ?? "#667085"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            {/* 
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill={props.fillColor ?? "none"}
                xmlns="http://www.w3.org/2000/svg"
                className={props.className}
            >
                <path
                    d="M2.66671 5.33106C2.55737 5.32824 2.47797 5.32191 2.40659 5.30771C1.87767 5.2025 1.4642 4.78904 1.35899 4.26012C1.33337 4.13132 1.33337 3.97644 1.33337 3.66667C1.33337 3.3569 1.33337 3.20201 1.35899 3.07321C1.4642 2.54429 1.87767 2.13083 2.40659 2.02562C2.53539 2 2.69027 2 3.00004 2H13C13.3098 2 13.4647 2 13.5935 2.02562C14.1224 2.13083 14.5359 2.54429 14.6411 3.07321C14.6667 3.20201 14.6667 3.3569 14.6667 3.66667C14.6667 3.97644 14.6667 4.13132 14.6411 4.26012C14.5359 4.78904 14.1224 5.2025 13.5935 5.30771C13.5221 5.32191 13.4427 5.32824 13.3334 5.33106M6.66671 8.66667H9.33337M2.66671 5.33333H13.3334V10.8C13.3334 11.9201 13.3334 12.4802 13.1154 12.908C12.9236 13.2843 12.6177 13.5903 12.2414 13.782C11.8135 14 11.2535 14 10.1334 14H5.86671C4.7466 14 4.18655 14 3.75873 13.782C3.3824 13.5903 3.07644 13.2843 2.88469 12.908C2.66671 12.4802 2.66671 11.9201 2.66671 10.8V5.33333Z"
                    stroke={props.color ?? "#667085"}
                    strokeWidth="0.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg> */}
        </>
    )
}
