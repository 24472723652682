import { useLocation, useNavigation } from "react-router"

export const useAppNavigating = () => {
    const navigation = useNavigation()
    return navigation.state === "loading"
}

export const useAppRouteReady = (path?: string) => {
    const navigation = useNavigation()
    const location = useLocation()

    if (!path) return navigation.state === "idle"
    return location.pathname === path
}
