const AsinChangeIcon = ({ strokeColor }: { strokeColor?: string }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 11.3333H3M3 11.3333L5.5 8.66667M3 11.3333L5.5 14M3 4.66667H13M13 4.66667L10.5 2M13 4.66667L10.5 7.33333"
                stroke={strokeColor || "#667085"}
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default AsinChangeIcon
