import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"

export const getExtendedTrackpackListAPI = async (
    pathParams?: { [key: string]: string },
    queryParams?: { [key: string]: string }
) => {
    const query = HttpHelper.createQueryString(queryParams)
    const url = HttpHelper.getUrl(`${API_ROUTES.GET_EXTENDED_TRACKPACKS_LIST}?${query}`, { ...pathParams })
    const result = await HttpHelper.sendRequest(url, {}, "GET")
    return HttpHelper.validateResponse<IProductTrackpackList>(result)
}
