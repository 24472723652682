import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { PrimaryText } from "../../elements/primaryText.element"

const KeywordsSkeletonSubCard = ({ length, isStatic, className }: CardSkeletonLoaderProps) => {
    const skeletonBaseColor = "#D0D5DD"
    const skeletonHighlightColor = "#F0F0F0"

    return (
        <>
            {length &&
                Array.from({ length }).map((_, index) => (
                    <div
                        key={index}
                        className={`relative w-[280px] flex bg-white rounded-[10px] px-[8px] py-[8px] -outline-offset-1 outline-1 outline-gray-300 mb-[8px] ${
                            className || ""
                        }`}
                    >
                        <div className="w-full flex sm:md:h-full cursor-pointer">
                            <div className="w-[4px] h-[20px] rounded-[4px] mr-[8px] span flex">
                                <Skeleton
                                    highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                    baseColor="#EAECF0"
                                    count={1}
                                    width={4}
                                    height={20}
                                    borderRadius={4}
                                />
                            </div>
                            <div className="w-full flex flex-col justify-between">
                                <div className="flex justify-between">
                                    <PrimaryText
                                        weight="medium"
                                        className="text-[12px] pt-[2px] text-ellipsis overflow-hidden whitespace-nowrap line-clamp-1 min-h-[13px] text-gray-300 leading-[18px]"
                                    >
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor="#EAECF0"
                                            count={1}
                                            width={220}
                                            height={12}
                                            borderRadius={4}
                                        />
                                    </PrimaryText>
                                    <div className="flex items-center justify-center gap-[4px] span">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor="#EAECF0"
                                            count={1}
                                            width={22}
                                            height={20}
                                            className="rounded-full"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            {!length && (
                <div
                    className={`relative w-[280px] flex bg-white rounded-[10px] px-[8px] py-[8px] -outline-offset-1 outline-1 outline-gray-300 ${
                        className || ""
                    }`}
                >
                    <div className="w-full flex sm:md:h-full cursor-pointer">
                        <div className="w-[4px] h-[20px] rounded-[4px] mr-[8px] span flex">
                            <Skeleton
                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                baseColor="#EAECF0"
                                count={1}
                                width={4}
                                height={20}
                                borderRadius={4}
                            />
                        </div>
                        <div className="w-full flex flex-col justify-between">
                            <div className="flex justify-between">
                                <PrimaryText
                                    weight="medium"
                                    className="text-[12px] pt-[2px] text-ellipsis overflow-hidden whitespace-nowrap line-clamp-1 min-h-[13px] text-gray-300 leading-[18px]"
                                >
                                    <Skeleton
                                        highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                        baseColor="#EAECF0"
                                        count={1}
                                        width={220}
                                        height={12}
                                        borderRadius={4}
                                    />
                                </PrimaryText>
                                <div className="flex items-center justify-center gap-[4px] span">
                                    <Skeleton
                                        className="rounded-full"
                                        highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                        baseColor="#EAECF0"
                                        count={1}
                                        width={22}
                                        height={20}
                                        // borderRadius={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default KeywordsSkeletonSubCard
