export const AccountNewIcon = (props: IArrowProps) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.91675 9.88856C5.92532 9.88856 4.15436 10.8523 3.02687 12.3479C2.78421 12.6698 2.66287 12.8307 2.66684 13.0482C2.66991 13.2163 2.7752 13.4283 2.90712 13.532C3.07788 13.6663 3.31451 13.6663 3.78777 13.6663H12.0457C12.519 13.6663 12.7556 13.6663 12.9264 13.532C13.0583 13.4283 13.1636 13.2163 13.1667 13.0482C13.1706 12.8307 13.0493 12.6698 12.8066 12.3479C11.6791 10.8523 9.90818 9.88856 7.91675 9.88856Z"
                stroke="#7A5AF8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.91675 7.99967C9.47799 7.99967 10.7436 6.73115 10.7436 5.16634C10.7436 3.60153 9.47799 2.33301 7.91675 2.33301C6.35551 2.33301 5.08987 3.60153 5.08987 5.16634C5.08987 6.73115 6.35551 7.99967 7.91675 7.99967Z"
                stroke="#7A5AF8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
