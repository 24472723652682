const StockIcon = ({ strokeColor }: { strokeColor?: string }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.6666 5.12526L7.99998 7.99998M7.99998 7.99998L2.33331 5.12526M7.99998 7.99998L8 13.7833M14 10.4707V5.5293C14 5.32071 14 5.21642 13.9663 5.1234C13.9366 5.04111 13.8879 4.96557 13.8236 4.90184C13.7509 4.8298 13.651 4.77915 13.4514 4.67785L8.51802 2.17516C8.32895 2.07924 8.23442 2.03128 8.1343 2.01248C8.04569 1.99584 7.95431 1.99584 7.8657 2.01248C7.76559 2.03128 7.67105 2.07924 7.48198 2.17516L2.54865 4.67785C2.34896 4.77915 2.24912 4.8298 2.17642 4.90184C2.11211 4.96557 2.06343 5.04111 2.03366 5.1234C2 5.21642 2 5.32071 2 5.5293V10.4707C2 10.6793 2 10.7836 2.03366 10.8766C2.06343 10.9589 2.11211 11.0344 2.17642 11.0982C2.24912 11.1702 2.34897 11.2208 2.54865 11.3221L7.48198 13.8248C7.67105 13.9208 7.76559 13.9687 7.8657 13.9875C7.95431 14.0042 8.04569 14.0042 8.1343 13.9875C8.23442 13.9687 8.32895 13.9208 8.51802 13.8248L13.4514 11.3221C13.651 11.2208 13.7509 11.1702 13.8236 11.0982C13.8879 11.0344 13.9366 10.9589 13.9663 10.8766C14 10.7836 14 10.6793 14 10.4707Z"
                stroke={strokeColor || "#667085"}
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.6654 6.5L5.33203 3.5"
                stroke={strokeColor || "#667085"}
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default StockIcon
