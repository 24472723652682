import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { FilterIcon } from "../../../../assets/svgs/filterIcon.svg"
import { LinkExternalSvg } from "../../../../assets/svgs/link-external.svg"
import { EmptyStar, FilledStar, PartialStar } from "../../../../assets/svgs/rating-icon"
import { RoundBlueTick } from "../../../../assets/svgs/roundBlueTick.svg"
import { RoundEmptyTick } from "../../../../assets/svgs/roundEmptyTick.svg"
import { SemiAutomatedLoader } from "../../../../assets/svgs/semiAutomatedLoader.svg"
import { ReactComponent as TickMarkIcon } from "../../../../assets/svgs/Tick-Icon.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { useLoadingStatus } from "../../../../hooks/useLoadingStatus.hook"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import FilterDropdown from "../../../common/filterDrowpdown.component"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
import ErrorAlertComponent from "../../notfitication-alerts/errorAlert.component"

const SemiAutomatedStepThreeView: React.FC<AddTrackPackProps> = ({
    asinHeaderText,
    asinHeaderDescription,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    isPrevButton,
    handlePrev,
    isDataFecthingError,
}) => {
    const { handleSubmit } = useForm()
    const dispatch = useDispatch()
    const scrollableDivRef = useRef<HTMLDivElement | null>(null)
    const [hasScroll] = useState(false)
    const [selectedBlueChips, setSelectedBlueChips] = useState<string[]>([])
    const [selectedGreenChips, setSelectedGreenChips] = useState<string[]>([])
    const data = useSelector(ProductStateSelector)
    const [isOpen, setIsOpen] = useState(false)
    const { notificationAlerts } = useSelector(RunTimeStateSelector)
    const { competitorsData } = useSelector(ProductStateSelector)
    const [selectedDropdownFilter, setSelectedDropdownFilter] = useState<string>("most_relevant")
    const [selectedAsins, setSelectedAsins] = useState<string[]>([])
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useLoadingStatus(loading, RuntimeHelper.competitorsDataListLoading())

    const [showTopBorder, setShowTopBorder] = useState(false)
    const [showBottomBorder, setShowBottomBorder] = useState(false)

    const getCompetitorsDataListing = useCallback(() => {
        const { total_asins, amazon_tld, keywords } = data?.selectedTrackpackPayload || {}
        if (total_asins && total_asins.length > 0) {
            const asin = total_asins[0]

            dispatch(
                ProductsActionCreator.getCompetitorsList(
                    {},
                    { asin: asin, tld: amazon_tld!, search_phrases: keywords || [] },
                    () => {},
                    (err: any) => {
                        isDataFecthingError && isDataFecthingError(getErrorType(err))
                    }
                )
            )
        }
    }, [dispatch, data?.selectedTrackpackPayload, isDataFecthingError])

    useEffect(() => {
        if (
            isLoading ||
            competitorsData?.bestsellers ||
            !data?.selectedTrackpackPayload ||
            !data?.selectedTrackpackPayload?.total_asins?.length
        ) {
            return
        }

        getCompetitorsDataListing()
    }, [data?.selectedTrackpackPayload, competitorsData?.bestsellers, getCompetitorsDataListing, isLoading])

    useEffect(() => {
        if (competitorsData?.blueChips && competitorsData?.blueChips?.length > 0) {
            setSelectedBlueChips(competitorsData.blueChips)
        }
        if (competitorsData?.greenChips && competitorsData?.greenChips?.length > 0) {
            setSelectedGreenChips(competitorsData.greenChips)
        }
    }, [competitorsData])

    function getErrorType(err: { message: string }) {
        if (err.message.includes("failed to get product data")) {
            return "dataFetchFailed"
        } else if (err.message.includes("product category not found")) {
            return "categoryNotFound"
        } else if (err.message.includes("product not found")) {
            return "productNotFound"
        }
        return "unknownError"
    }

    const onSubmit = () => {
        dispatch(
            ProductsActionCreator.setCompetitorsDataListChipsSelected({
                blueChips: selectedBlueChips,
                greenChips: selectedGreenChips,
            })
        )
        dispatch(ProductsActionCreator.selectedTrackpackPayload({ competitor_asins: selectedAsins }))
        handleNext && handleNext()
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleItemClick = (item: string) => {
        setSelectedDropdownFilter(item)
        setIsOpen(false)
    }

    const dropdownOptions = [
        { label: "Most Relevant", value: "most_relevant" },
        { label: "Price (highest)", value: "price_highest" },
        { label: "Price (lowest)", value: "price_lowest" },
        { label: "Ratings Count (highest)", value: "review_score_highest" },
        { label: "Ratings (lowest)", value: "review_score_lowest" },
        { label: "Review Score (highest)", value: "review_count_highest" },
        { label: "Review Score (Lowest)", value: "review_count_lowest" },
    ]

    useEffect(() => {
        const allAsins = competitorsData?.bestsellers?.map((product: { asin: string }) => product.asin) || []
        setSelectedAsins(allAsins)
    }, [competitorsData])

    const handleCardSelection = (asin: string) => {
        setSelectedAsins((prevSelected) =>
            prevSelected.includes(asin) ? prevSelected.filter((item) => item !== asin) : [...prevSelected, asin]
        )
    }

    const combinedAsinData = useMemo(() => {
        if (
            competitorsData &&
            competitorsData?.bestseller_asins_by_1gram?.length >= 0 &&
            competitorsData?.bestseller_asins_by_2gram?.length >= 0
        ) {
            return [
                ...competitorsData?.bestseller_asins_by_1gram.map((item: { one_gram: string; asins: string[] }) => ({
                    name: item.one_gram,
                    asinCount: item.asins.length,
                    asins: item.asins,
                })),
                ...competitorsData?.bestseller_asins_by_2gram.map((item: { two_gram: string; asins: string[] }) => ({
                    name: item.two_gram,
                    asinCount: item.asins.length,
                    asins: item.asins,
                })),
            ]
        } else {
            return []
        }
    }, [competitorsData])

    combinedAsinData?.sort((a, b) => a.asinCount - b.asinCount)

    const asinsList = useMemo(() => {
        const allAsins = competitorsData?.bestsellers?.map((product: { asin: string }) => product.asin) || []
        let data = structuredClone(competitorsData?.bestsellers)
        let selectedAsins: string[] = []
        function removeDuplicates(arr: string[]) {
            return [...new Set(arr)]
        }

        const findCommonElements = (nestedArrays: string[][][]): string[] => {
            return nestedArrays.reduce<string[]>((acc, curr) => {
                return acc.filter((item) => curr.flat().includes(item))
            }, nestedArrays[0].flat())
        }

        if (selectedGreenChips?.length > 0) {
            let selectedAsinsGreen: any = []
            combinedAsinData.forEach((data) => {
                if (selectedGreenChips.includes(data.name)) {
                    if (selectedAsinsGreen.length === 0) {
                        selectedAsinsGreen.push([data.asins])
                    } else {
                        selectedAsinsGreen.push([data.asins])
                    }
                }
            })

            if (selectedAsinsGreen?.length === 1) {
                selectedAsins = selectedAsinsGreen[0][0]
            } else {
                selectedAsins = findCommonElements(selectedAsinsGreen)
            }
            selectedAsins = removeDuplicates(selectedAsins)
        } else if (selectedBlueChips?.length > 0) {
            combinedAsinData.forEach((data) => {
                if (selectedBlueChips.includes(data.name)) {
                    selectedAsins = [...selectedAsins, ...data.asins]
                }
            })
            selectedAsins = removeDuplicates(selectedAsins)
        } else {
            selectedAsins = allAsins
        }
        selectedAsins = selectedAsins?.filter((item: string) => item !== competitorsData.target_product.asin)
        const filteredData = data?.filter((item: any) => selectedAsins.includes(item.asin))

        setSelectedAsins(selectedAsins ? [...selectedAsins] : [])
        return filteredData ?? []
    }, [
        competitorsData?.bestsellers,
        selectedGreenChips,
        selectedBlueChips,
        combinedAsinData,
        competitorsData?.target_product?.asin,
    ])

    const filteredOptionsList = useMemo(() => {
        let y = structuredClone(asinsList)

        if (selectedDropdownFilter === "most_relevant") {
            y = y.sort((a: any, b: any) => (b.relevance_score || 0) - (a.relevance_score || 0))
        } else if (selectedDropdownFilter === "review_score_highest") {
            y = y.sort((a: any, b: any) => (b.rating || 0) - (a.rating || 0))
        } else if (selectedDropdownFilter === "review_score_lowest") {
            y = y.sort((a: any, b: any) => (a.rating || 0) - (b.rating || 0))
        } else if (selectedDropdownFilter === "review_count_highest") {
            y = y.sort((a: any, b: any) => (b.ratings_total || 0) - (a.ratings_total || 0))
        } else if (selectedDropdownFilter === "review_count_lowest") {
            y = y.sort((a: any, b: any) => (a.ratings_total || 0) - (b.ratings_total || 0))
        } else if (selectedDropdownFilter === "price_highest") {
            y = y.sort((a: any, b: any) => {
                if (a.price?.value == null) return 1
                if (b.price?.value == null) return -1
                return b.price.value - a.price.value
            })
        } else if (selectedDropdownFilter === "price_lowest") {
            y = y.sort((a: any, b: any) => {
                if (a.price?.value == null) return 1
                if (b.price?.value == null) return -1
                return a.price.value - b.price.value
            })
        }
        return y
    }, [asinsList, selectedDropdownFilter])

    // useEffect(() => {
    //     const divElement = scrollableDivRef.current

    //     const checkScrollPosition = () => {
    //         if (divElement) {
    //             const isAtTop = divElement.scrollTop === 0
    //             const isAtBottom = divElement.scrollTop + divElement.clientHeight === divElement.scrollHeight

    //             setShowTopBorder(!isAtTop) // Hide top border if at the top
    //             setShowBottomBorder(!isAtBottom) // Hide bottom border if at the end
    //         }
    //     }

    //     // Initial check in case the content is already scrolled on mount
    //     checkScrollPosition()

    //     window.addEventListener("resize", checkScrollPosition)
    //     divElement?.addEventListener("scroll", checkScrollPosition)

    //     return () => {
    //         window.removeEventListener("resize", checkScrollPosition)
    //         divElement?.removeEventListener("scroll", checkScrollPosition)
    //     }
    // }, [filteredOptionsList])

    useEffect(() => {
        const divElement = scrollableDivRef.current

        const checkScrollPosition = () => {
            if (divElement) {
                const isAtTop = divElement.scrollTop === 0
                const isAtBottom = divElement.scrollTop + divElement.clientHeight === divElement.scrollHeight

                // Remove both borders if at the top or bottom
                if (isAtTop || isAtBottom) {
                    setShowTopBorder(false)
                    setShowBottomBorder(false)
                } else {
                    setShowTopBorder(true)
                    setShowBottomBorder(true)
                }
            }
        }

        // Initial check in case the content is already scrolled on mount
        checkScrollPosition()

        window.addEventListener("resize", checkScrollPosition)
        divElement?.addEventListener("scroll", checkScrollPosition)

        return () => {
            window.removeEventListener("resize", checkScrollPosition)
            divElement?.removeEventListener("scroll", checkScrollPosition)
        }
    }, [filteredOptionsList])

    return isLoading || !competitorsData ? (
        <SemiAutomatedLoaderComponent
            icon={<SemiAutomatedLoader />}
            handleCloseDialog={handleCloseDialog}
            onSuccess={!isLoading}
        />
    ) : (
        <>
            {notificationAlerts?.notificationType === "error_competitor" && (
                <ErrorAlertComponent {...notificationAlerts} />
            )}
            <div className="flex items-center justify-between p-[24px] pb-0">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText
                weight="medium"
                className="text-gray-700 mt-[24px] mx-[24px] pl-[6px] text-[24px] leading-[32px]"
            >
                {asinHeaderText}
            </PrimaryText>
            <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mx-[24px] mt-[8px] px-[6px]">
                {asinHeaderDescription}
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} className="relative mt-[32px]">
                <>
                    <ChipsContainer
                        combinedAsinData={combinedAsinData}
                        selectedBlueChips={selectedBlueChips}
                        selectedGreenChips={selectedGreenChips}
                        setSelectedBlueChips={(val: any) => {
                            setSelectedBlueChips(val)
                        }}
                        setSelectedGreenChips={(val: any) => {
                            setSelectedGreenChips(val)
                        }}
                    />
                    <div className={"flex justify-between items-center mb-[8px] py-[4px] mx-[24px]"}>
                        <PrimaryText size="xs" weight="medium" className="leading-[16px] text-gray-700">
                            Selected ASINs:&nbsp;
                            <PrimaryText size="xs" weight="light" className=" inline leading-[16px]">
                                {selectedAsins.length}
                            </PrimaryText>
                        </PrimaryText>
                        <div className="mr-[4px] relative" onClick={toggleDropdown}>
                            <FilterIcon />
                            {isOpen && (
                                <FilterDropdown
                                    options={dropdownOptions}
                                    selectedItem={selectedDropdownFilter}
                                    handleItemClick={handleItemClick}
                                    className="!w-[192px]"
                                />
                            )}
                        </div>
                    </div>
                    <div className="" style={{ minHeight: "280px", maxHeight: "280px" }}>
                        <div className={`${showTopBorder ? "border-t border-gray-600" : ""} ml-[24px] mr-[29px]`} />

                        <div
                            ref={scrollableDivRef}
                            className={`grid grid-cols-2 gap-[8px] overflow-y-auto semi-automated ml-[24px] ${
                                hasScroll ? "pr-[20px]" : "pr-[24px]"
                            } max-w-[1024px]`}
                            style={{ maxHeight: "280px" }}
                        >
                            {filteredOptionsList?.map((product: any, index: number) => (
                                <CompetitorAsinCards
                                    key={index}
                                    asin={product.asin}
                                    image={product.image_url}
                                    price={product.price?.value}
                                    rating={product.rating}
                                    title={product.title}
                                    count={product.ratings_total}
                                    currency={product.price?.currency}
                                    isSelected={selectedAsins.includes(product.asin)}
                                    onSelect={() => handleCardSelection(product.asin)}
                                    url={product?.url ?? ""}
                                />
                            ))}
                        </div>

                        <div className={`${showBottomBorder ? "border-t border-gray-600" : ""} ml-[24px] mr-[29px]`} />
                    </div>
                </>
                <div className="flex justify-between  items-center mt-[24px] mb-[36px] mx-[22px]">
                    <div className="flex items-center">
                        <BackChevronIcon />
                        <PrimaryText onClick={handlePrev} className="text-[18px]  cursor-pointer text-[#667085]">
                            Back
                        </PrimaryText>
                    </div>
                    <ButtonElement
                        size="large"
                        textClass="text-[18px] leading-[28px]"
                        className={" bg-[#A5F0FC] rounded-[12px] px-[30px] py-[8px] text-[#0E7090] "}
                    >
                        {buttonText}
                    </ButtonElement>
                </div>
            </form>
        </>
    )
}

export default SemiAutomatedStepThreeView

// Chip component that accepts title and count as props
const Chip = ({ title, count, onSingleClick, onDoubleClick, selectedBlueChips, selectedGreenChips }: any) => {
    let clickTimeout: NodeJS.Timeout | null = null
    const handleClick = () => {
        // Set a delay for single-click
        clickTimeout = setTimeout(() => {
            if (clickTimeout) {
                if (selectedGreenChips.includes(title)) {
                    onSingleClick(title)
                    onDoubleClick(title)
                } else {
                    onSingleClick(title)
                }
            }
        }, 250)
    }

    const handleDoubleClick = () => {
        if (clickTimeout) {
            clearTimeout(clickTimeout)
            clickTimeout = null
        }
        if (selectedBlueChips.includes(title)) {
            onSingleClick(title)
        }
        onDoubleClick(title)
    }

    const getBackgroundColor = () => {
        if (selectedGreenChips?.includes(title)) return "#DCFAE6"
        return selectedBlueChips.includes(title) ? "#CFF9FE" : "#EAECF0"
    }
    return (
        <div
            className="flex items-center gap-[8px] justify-between rounded-[6px] px-3 py-0.5 cursor-pointer transition duration-200 ease-in-out no-select"
            style={{
                backgroundColor: getBackgroundColor(),
            }}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
        >
            <PrimaryText
                weight="book"
                size="xs"
                className={`text-gray-700 ${
                    selectedBlueChips.includes(title)
                        ? !selectedGreenChips.includes(title)
                            ? "text-[#0E7090]"
                            : "text-[#067647]"
                        : ""
                }`}
            >
                {title}
            </PrimaryText>
            <PrimaryText
                weight="light"
                size="xs-medium"
                className={`text-gray-700 ${
                    selectedBlueChips.includes(title)
                        ? !selectedGreenChips.includes(title)
                            ? "text-[#0E7090]"
                            : "text-[#067647]"
                        : ""
                }`}
            >
                {count}
            </PrimaryText>
        </div>
    )
}
export interface IChipsContainer {
    combinedAsinData: TcombinedAsinData[]
    selectedBlueChips: any
    selectedGreenChips: any
    setSelectedBlueChips: any
    setSelectedGreenChips: any
}

export type TcombinedAsinData = {
    name: string
    asinCount: number
    asins: string[]
}

const ChipsContainer = (props: IChipsContainer) => {
    const { combinedAsinData, selectedBlueChips, selectedGreenChips, setSelectedBlueChips, setSelectedGreenChips } =
        props
    return (
        // <div className="bg-gray-50 mx-[24px] mb-[24px] mt-[32px] p-[16px]  rounded-[10px]">
        //     <div className="flex items-center justify-center gap-[12px] mb-[16px]">
        //         <div
        //             className={`flex items-center gap-[4px] py-[2px] pl-[4px] pr-[6px] rounded-[6px] border border-[#06AED4] bg-white `}
        //         >
        //             <PrimaryText
        //                 weight="medium"
        //                 className={`text-[#0E7090] text-[9px] leading-[12px] bg-[#CFF9FE] rounded-[3px] py-[1px] px-[4px] whitespace-nowrap`}
        //             >
        //                 SINGLE-CLICK
        //             </PrimaryText>
        //             <PrimaryText weight="light" size="xs" className={`text-gray-700 whitespace-nowrap`}>
        //                 See <span className="font-medium">all</span> ASINs with this term
        //             </PrimaryText>
        //         </div>
        //         <div
        //             className={`flex items-center gap-[4px] py-[2px] pl-[4px] pr-[6px] rounded-[6px] border border-[#17B26A] bg-white `}
        //         >
        //             <PrimaryText
        //                 weight="medium"
        //                 className={`text-[#067647] text-[9px] leading-[12px] bg-[#DCFAE6] rounded-[3px] py-[1px] px-[4px] whitespace-nowrap`}
        //             >
        //                 DOUBLE-CLICK
        //             </PrimaryText>
        //             <PrimaryText weight="light" size="xs" className={`text-gray-700 whitespace-nowrap`}>
        //                 See <span className="font-medium">only</span> ASINs with this term
        //             </PrimaryText>
        //         </div>
        //     </div>

        //     <div
        //         className="flex flex-wrap gap-[4px] justify-center max-w-[908px] w-full m-auto"
        //         style={{
        //             maxHeight: "75px",
        //             overflowY: "scroll",
        //         }}
        //     >
        //         {combinedAsinData?.map((chip, index) => (
        //             <Chip
        //                 key={index}
        //                 title={chip.name}
        //                 count={chip.asinCount}
        //                 selectedBlueChips={selectedBlueChips}
        //                 selectedGreenChips={selectedGreenChips}
        //                 onSingleClick={(val: any) => {
        //                     if (selectedBlueChips.includes(val)) {
        //                         setSelectedBlueChips(selectedBlueChips.filter((item: any) => item !== val))
        //                     } else {
        //                         setSelectedBlueChips([...selectedBlueChips, val])
        //                     }
        //                 }}
        //                 onDoubleClick={(val: any) => {
        //                     if (selectedGreenChips.includes(val)) {
        //                         setSelectedGreenChips(selectedGreenChips.filter((item: any) => item !== val))
        //                     } else {
        //                         setSelectedGreenChips([...selectedGreenChips, val])
        //                     }
        //                 }}
        //             />
        //         ))}
        //     </div>
        // </div>

        <div className="bg-gray-50 mx-[24px] mb-[24px] mt-[32px] p-[16px] rounded-[10px]">
            <div className="flex items-center justify-center gap-[12px] mb-[16px]">
                <div className="flex items-center gap-[4px] py-[2px] pl-[4px] pr-[6px] rounded-[6px] border border-[#06AED4] bg-white">
                    <PrimaryText
                        weight="medium"
                        className="text-[#0E7090] text-[9px] leading-[12px] bg-[#CFF9FE] rounded-[3px] py-[1px] px-[4px] whitespace-nowrap"
                    >
                        SINGLE-CLICK
                    </PrimaryText>
                    <PrimaryText weight="light" size="xs" className="text-gray-700 whitespace-nowrap">
                        See <span className="font-medium">all</span> ASINs with this term
                    </PrimaryText>
                </div>
                <div className="flex items-center gap-[4px] py-[2px] pl-[4px] pr-[6px] rounded-[6px] border border-[#17B26A] bg-white">
                    <PrimaryText
                        weight="medium"
                        className="text-[#067647] text-[9px] leading-[12px] bg-[#DCFAE6] rounded-[3px] py-[1px] px-[4px] whitespace-nowrap"
                    >
                        DOUBLE-CLICK
                    </PrimaryText>
                    <PrimaryText weight="light" size="xs" className="text-gray-700 whitespace-nowrap">
                        See <span className="font-medium">only</span> ASINs with this term
                    </PrimaryText>
                </div>
            </div>

            {/* Wrapper to hold scrollbar outside the gray box */}
            <div className="semi-automated" style={{ maxHeight: "75px", overflowY: "auto", marginRight: "-40px" }}>
                <div
                    className="flex flex-wrap gap-[4px] justify-center w-fit overflow-y-auto"
                    style={{ maxWidth: "970px", width: "100%", margin: "auto", marginRight: "31px" }}
                >
                    {combinedAsinData?.map((chip, index) => (
                        <Chip
                            key={index}
                            title={chip.name}
                            count={chip.asinCount}
                            selectedBlueChips={selectedBlueChips}
                            selectedGreenChips={selectedGreenChips}
                            onSingleClick={(val: any) => {
                                if (selectedBlueChips.includes(val)) {
                                    setSelectedBlueChips(selectedBlueChips.filter((item: any) => item !== val))
                                } else {
                                    setSelectedBlueChips([...selectedBlueChips, val])
                                }
                            }}
                            onDoubleClick={(val: any) => {
                                if (selectedGreenChips.includes(val)) {
                                    setSelectedGreenChips(selectedGreenChips.filter((item: any) => item !== val))
                                } else {
                                    setSelectedGreenChips([...selectedGreenChips, val])
                                }
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

const CompetitorAsinCards = ({
    asin,
    image,
    price,
    rating,
    title,
    count,
    currency,
    isSelected,
    onSelect,
    url,
}: any) => {
    const getStars = () => {
        const stars = []
        const maxStars = 5
        const fullStars = Math.floor(rating!)
        const partialStarPercentage = Math.round((rating! - fullStars) * 100)

        for (let i = 0; i < fullStars; i++) {
            stars.push(<FilledStar key={i} />)
        }

        if (partialStarPercentage > 0 && stars.length < maxStars) {
            stars.push(<PartialStar key={fullStars} fillPercentage={partialStarPercentage} />)
        }

        while (stars.length < maxStars) {
            stars.push(<EmptyStar key={stars.length} />)
        }

        return stars
    }

    const handleProductRedirect = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (url) {
            window.open(url, "_blank")
        }
    }

    return (
        <div
            onClick={onSelect}
            className={`flex h-max p-[8px] border border-gray-700 rounded-[10px] bg-white ${
                !isSelected ? " opacity-[0.32] border-gray-300" : ""
            } cursor-pointer`}
        >
            <div className="w-[68px] h-[68px] overflow-hidden bg-white rounded-[4px] border border-gray-700">
                <img src={image} alt={title} className="w-[68px] h-[68px] object-cover overflow-hidden" />
            </div>

            <div className="ml-[12px] flex-1 mb-[4px]">
                <div className="flex items-center justify-between">
                    <div className=" flex items-center gap-x-[24px]">
                        <div
                            className={`bg-transparent relative cursor-pointer border border-gray-700 rounded-[3px] px-[4px] py-[2.5px] flex gap-[3px] justify-center items-center h-[16px]`}
                        >
                            <p
                                onClick={(e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) =>
                                    handleProductRedirect(e)
                                }
                                className={` text-[11px] tracking-[0.7px] font-[500] text-gray-700 leading-[12px] uppercase`}
                            >
                                {asin}
                            </p>
                            <div onClick={handleProductRedirect} className="cursor-pointer">
                                <LinkExternalSvg color="#344054" className="mb-[0.5px]" />
                            </div>
                        </div>
                        <div className={"flex gap-[10px] items-center justify-between h-[12px] mt-[3px]"}>
                            <PrimaryText className="flex items-start gap-[1px]">
                                <sup className={"text-gray-700 text-[7px]  font-[400] leading-[13px] "}>{currency}</sup>
                                <PrimaryText className={"text-gray-700 text-[13px] font-[500] leading-[13px]"}>
                                    {price}
                                </PrimaryText>
                            </PrimaryText>
                            <div className={"flex gap-[6px] items-start leading-normal"}>
                                <PrimaryText
                                    size={"xs"}
                                    weight={"book"}
                                    className={"flex gap-[6px] items-start text-gray-700"}
                                >
                                    <p className="text-[13px] leading-[13px]  font-normal text-gray-700 tracking-[-0.26px] ">
                                        {rating}
                                    </p>
                                    <span className={"inline-flex items-center"}>
                                        {getStars().map((star, index) => (
                                            <span key={index}>{star}</span>
                                        ))}
                                    </span>
                                    <PrimaryText
                                        weight={"book"}
                                        className={
                                            "text-[13px] leading-[13px] text-gray-700 font-normal tracking-[-0.26px]"
                                        }
                                    >
                                        {count?.toLocaleString()}
                                    </PrimaryText>
                                </PrimaryText>
                            </div>
                        </div>
                    </div>
                    {isSelected ? (
                        <>
                            <RoundBlueTick />
                        </>
                    ) : (
                        <>
                            <RoundEmptyTick />
                        </>
                    )}
                </div>

                <PrimaryText
                    weight="light"
                    size="xs"
                    className="text-gray-700 mt-[10px] line-clamp-2 mb-[2px] ml-[4px] mr-[24px]"
                >
                    {title}
                </PrimaryText>
            </div>
        </div>
    )
}

const SemiAutomatedLoaderComponent: React.FC<AddTrackPackProps> = ({ icon, handleCloseDialog, onSuccess }) => {
    // const steps = [
    //     { label: "Searching Amazon", delay: 5000, width: "105px", left: "-40px" },
    //     { label: "Finding Competitors", delay: 5000, width: "120px", left: "-49px" },
    //     { label: "Analyzing Competitors", delay: 5000, width: "120px", left: "-49px" },
    //     { label: "Preparing Data", delay: 5000, width: "95px", left: "-37px" },
    // ]

    // const [currentStep, setCurrentStep] = useState(0)

    // useEffect(() => {
    //     if (currentStep < steps.length) {
    //         const timeout = setTimeout(() => {
    //             setCurrentStep((prev) => prev + 1)
    //         }, steps[currentStep].delay)

    //         return () => clearTimeout(timeout)
    //     }
    // }, [currentStep])

    const [currentStep, setCurrentStep] = useState(0)
    const steps = [
        ["Searching", "Amazon"],
        ["Finding", "Competitors"],
        ["Analyzing", "Competitors"],
        ["Preparing", "Data"],
    ]

    // useEffect(() => {
    //     // Set interval to update the progress every 5 seconds
    //     const interval = setInterval(() => {
    //         setCurrentStep((prevStep) => (prevStep < steps.length - 1 ? prevStep + 1 : 0))
    //     }, 5000)

    //     // Cleanup interval on component unmount
    //     return () => clearInterval(interval)
    // }, [])

    useEffect(() => {
        if (currentStep < steps.length - 1) {
            const timeout = setTimeout(() => {
                setCurrentStep((prev) => prev + 1)
            }, 5000)

            return () => clearTimeout(timeout)
        }
    }, [currentStep, steps.length])

    return (
        <>
            <div className="mx-[24px] mt-[24px]">
                <div className="flex items-center justify-between">
                    <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">{icon}</div>
                    <div className="cursor-pointer">
                        <CrossNewIcon onClick={handleCloseDialog} />
                    </div>
                </div>
                <PrimaryText className="text-gray-700 text-[24px] leading-[32px] font-medium mt-[24px] ml-[6px]">
                    Hold tight
                </PrimaryText>

                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[8px] ml-[6px]">
                    I’m working hard at gathering your data. Won’t take long... promise!
                </PrimaryText>
            </div>

            <div className="flex items-center justify-center mt-[48px] pb-[60px]">
                <div className="relative w-full flex items-center">
                    {/* Progress Line */}
                    <div className="absolute top-[13px] left-[70px] right-[70px] h-[2px] bg-gray-200 transform -translate-y-1/2">
                        <div
                            className="h-full bg-[#088AB2] transition-all duration-[3000ms]"
                            style={{
                                width: `${(currentStep / (steps.length - 1)) * 100}%`,
                                marginLeft: "4px",
                                marginRight: "4px",
                            }}
                        />
                    </div>

                    {/* Steps */}
                    {steps.map((label, index) => (
                        <div
                            key={index}
                            className="relative flex flex-col items-center text-center"
                            style={{ width: "30%" }}
                        >
                            {/* Step Circle */}
                            <div
                                className={`w-[24px] relative h-[24px] rounded-full flex items-center justify-center border-2 ${
                                    index <= currentStep
                                        ? "border border-[#088AB2] bg-[#ECFDFF]"
                                        : "bg-white border-gray-200"
                                } transition-colors duration-[3000ms]`}
                            >
                                {index < currentStep ? (
                                    <span className="text-[#088AB2] font-bold block absolute top-[50%] transform translate-y-[-50%] left-[50%] translate-x-[-50%]">
                                        <TickMarkIcon />
                                    </span> // Checkmark
                                ) : (
                                    <span
                                        className={`${
                                            index === currentStep ? "bg-[#088AB2]" : "bg-gray-200"
                                        } w-[8px] h-[8px] rounded-full`}
                                    ></span>
                                )}
                            </div>

                            {/* Step Label */}
                            <div className="mt-[12px]">
                                {label.map((line, i) => (
                                    <PrimaryText weight="medium" size="small" className="text-gray-600" key={i}>
                                        {line}
                                    </PrimaryText>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
