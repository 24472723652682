export const PriceIconTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            className={props.className}
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.417 24.4332C12.417 26.8816 14.6804 28.8665 17.4725 28.8665H22.5281C25.3202 28.8665 27.5837 26.8816 27.5837 24.4332C27.5837 21.9847 25.3202 19.9998 22.5281 19.9998H17.4725C14.6804 19.9998 12.417 18.015 12.417 15.5665C12.417 13.118 14.6804 11.1332 17.4725 11.1332H22.5281C25.3202 11.1332 27.5837 13.118 27.5837 15.5665M20.0003 8.9165V31.0832"
                stroke={props.stroke}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
