import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountActionsCreater } from "../../../../actions/account.action";
import { RuntimeActionCreators } from "../../../../actions/runTime.action";
import { SearchpackActionCreator } from "../../../../actions/searchpack.action";
import { RestoreIcon } from "../../../../assets/svgs/Restore.svg";
import { ArchiveIcon } from "../../../../assets/svgs/archiveIcon.svg";
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg";
import { RuntimeHelper } from "../../../../helpers/runtime.helper";
import { useLoadingStatus } from "../../../../hooks/useLoadingStatus.hook";
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector";
import { AccountStateSelector } from "../../../../selectors/accountState.selector";
import { Root } from "../../../../services/product.service";
import { ButtonElement } from "../../../elements/button.element";
import { PrimaryText } from "../../../elements/primaryText.element";

const ArchiveRestoreSearchpackStepOne: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedTrackpacksList } = useSelector(AccountStateSelector) as {
    selectedTrackpacksList: {
      id: number;
      status: "active" | "archived";
      title: string;
    };
  };
  const { loading, archiveRestoreSearchpackModal } =
    useSelector(RunTimeStateSelector);
  const isSearchpackLoading: boolean = useLoadingStatus(
    loading,
    RuntimeHelper.archiveSearchpackLoading(),
  );
  const isSearchpackSearchTermLoading: boolean = useLoadingStatus(
    loading,
    RuntimeHelper.archiveSearchpackLoading(),
  );
  const [isRestore] = useState(
    archiveRestoreSearchpackModal?.status === "active",
  );
  const [isKeyword] = useState(!!archiveRestoreSearchpackModal?.keyword);

  const handleCloseDialog = (refetch = false) => {
    refetch && dispatch(AccountActionsCreater.refetchSearchpacks());
    dispatch(RuntimeActionCreators.closeArchiveRestoreSearchpackModal());
  };

  const handleOpenStepTwo = () => {
    if (archiveRestoreSearchpackModal) {
      dispatch(
        !isKeyword
          ? SearchpackActionCreator.archive(
              {
                id: archiveRestoreSearchpackModal.id,
                status: archiveRestoreSearchpackModal.status,
              },
              (data: Root) => {
                handleCloseDialog(true);
              },
            )
          : SearchpackActionCreator.archiveKeyword(
              {
                packId: archiveRestoreSearchpackModal.id,
                searchTerm: archiveRestoreSearchpackModal.keyword || "",
                status: archiveRestoreSearchpackModal.status,
              },
              (data: Root) => {
                handleCloseDialog(true);
              },
            ),
      );
    }
  };

  return (
    <>
      <div className="flex items-center justify-between">
        {isRestore ? (
          <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">
            <RestoreIcon />
          </div>
        ) : (
          <div className="bg-[#FECDCA] rounded-[12px] p-[10px]">
            <ArchiveIcon />
          </div>
        )}

        <div className="cursor-pointer">
          <CrossNewIcon onClick={handleCloseDialog} />
        </div>
      </div>

      {isRestore && isKeyword ? (
        <PrimaryText
          weight="medium"
          className="text-[24px] text-gray-700 mt-[24px] mb-[16px]"
        >
          Restore Keyword
        </PrimaryText>
      ) : isRestore ? (
        <PrimaryText
          weight="medium"
          className="text-[24px] text-gray-700 mt-[24px] mb-[16px]"
        >
          Restore Searchpack
        </PrimaryText>
      ) : archiveRestoreSearchpackModal?.isSearchpack && isKeyword ? (
        <PrimaryText
          weight="medium"
          className="text-[24px] text-gray-700 mt-[24px] mb-[16px]"
        >
          Archive Keyword
        </PrimaryText>
      ) : archiveRestoreSearchpackModal?.isSearchpack ? (
        <PrimaryText
          weight="medium"
          className="text-[24px] text-gray-700 mt-[24px] mb-[16px]"
        >
          Archive Searchpack
        </PrimaryText>
      ) : (
        ""
      )}

      {isRestore && isKeyword ? (
        <PrimaryText size="sm-medium" className="text-gray-700 inline-block">
          Looks like you want to Restore your Search Term called&nbsp;
          <PrimaryText
            size="sm-medium"
            weight="medium"
            className="inline-block text-gray-700"
          >
            {archiveRestoreSearchpackModal?.keyword || "-"}.
          </PrimaryText>
          <span className="font-[300] text-gray-700 mt-[15px] block">
            Is this right?
          </span>
        </PrimaryText>
      ) : isRestore ? (
        <>
          <PrimaryText size="sm-medium" className="text-gray-700 inline-block">
            Looks like you want to Restore your Searchpack called&nbsp;
            <PrimaryText
              size="sm-medium"
              weight="medium"
              className=" text-gray-700 inline-block"
            >
              ‘{selectedTrackpacksList && selectedTrackpacksList?.title}’.
            </PrimaryText>
          </PrimaryText>
          <span className="font-[300] text-gray-700 mt-[15px] block">
            {" "}
            Is this correct?
          </span>
        </>
      ) : archiveRestoreSearchpackModal?.isSearchpack && isKeyword ? (
        <>
          <PrimaryText size="sm-medium" className="text-gray-700 inline-block">
            Looks like you want to Archive your Search Term called&nbsp;
            <PrimaryText
              size="sm-medium"
              weight="medium"
              className="inline-block text-gray-700"
            >
              ‘{archiveRestoreSearchpackModal.keyword}’.
            </PrimaryText>
          </PrimaryText>
          <span className="font-[300] text-gray-700 mt-[15px] block">
            {" "}
            Is this correct?
          </span>
        </>
      ) : archiveRestoreSearchpackModal?.isSearchpack ? (
        <>
          <PrimaryText size="sm-medium" className="text-gray-700 inline-block">
            Looks like you want to Archive your entire Searchpack called&nbsp;
            <PrimaryText
              size="sm-medium"
              weight="medium"
              className="inline-block text-gray-700"
            >
              ‘{selectedTrackpacksList && selectedTrackpacksList?.title}’.
            </PrimaryText>
          </PrimaryText>
          <span className="font-[300] text-gray-700 block mt-[15px]">
            {" "}
            Are you sure?
          </span>
        </>
      ) : (
        ""
      )}

      <>
        <div className="flex gap-2 items-center justify-center mt-[24px]">
          <div className="flex justify-center items-center ">
            <ButtonElement
              onClick={handleOpenStepTwo}
              size="large"
              viewType="hover-state"
              className={
                "w-[89px] bg-modal-btn-gradient  rounded-[12px] h-[44px] text-white text-[14px]"
              }
              disabled={
                isSearchpackLoading ||
                (isRestore && isSearchpackSearchTermLoading)
              }
            >
              {isSearchpackLoading || isSearchpackSearchTermLoading ? (
                <div className="flex justify-center">
                  <div className="w-8 h-8 rounded-full animate-spin border  border-white border-t-transparent"></div>
                </div>
              ) : (
                "Yes"
              )}
            </ButtonElement>
          </div>
          <div className="flex justify-center items-center">
            <ButtonElement
              onClick={() => handleCloseDialog()}
              size="large"
              viewType="stay-same"
              className={
                "w-[84px] border  border-[#D0D5DD] bg-[#F9FAFB] rounded-[12px] h-[44px] text-[#667085] text-[14px] flex items-center justify-center "
              }
              disabled={
                isSearchpackLoading ||
                (isRestore && isSearchpackSearchTermLoading)
              }
            >
              No
            </ButtonElement>
          </div>
        </div>
      </>
    </>
  );
};

export default ArchiveRestoreSearchpackStepOne;
