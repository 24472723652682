import { useEffect, useMemo, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"

import { PrimaryText } from "../../../elements/primaryText.element"
import CommonTooltip from "../../tooltipItems/commonTooltip.component"

import type { Product, RankData, SearchRankTerm, TrackpackGroup, Trackpacks } from "../../../../@types/app/data"
import { SearchpackActionCreator } from "../../../../actions/searchpack.action"
import { AddIcon } from "../../../../assets/svgs/addIcon.svg"
import { GraphIcon } from "../../../../assets/svgs/graphIcon.svg"
import { TableIcon } from "../../../../assets/svgs/tableIcon.svg"
import { DashboardBoardConfig } from "../../../../config/dashboard.config"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { SearchpackStateSelector } from "../../../../selectors/searchpack.selector"
import SearchpackTrackerOnboarding from "../../../common/skeletonLoader/searchpackTrackerOnboarding.component"
import { ButtonElement } from "../../../elements/button.element"
import ButtonGroups from "../../../elements/buttonGroup.element"
import { SelectElement } from "../../../elements/select.element"

import { RankTrackerGraphView } from "./graph-view/rank-tracker-graph-view.component"
import RankTrackerTableView from "./rank-tracker-table-view.component"

import dateHelper from "../../../../helpers/date"
import { useAppSearchParams } from "../../../../hooks/app/searchpack/useAppSearchParams"
import { useLoadingStatus } from "../../../../hooks/useLoadingStatus.hook"

const RankTracker = (props: ITableData) => {
    const dispatch = useDispatch()
    const { getParamValueAsSet, updateSearchParams } = useAppSearchParams(["sp_id"])
    const keyword = getParamValueAsSet("keyword")

    const [allTrackpacks, setAllTrackpacks] = useState<Trackpacks>()
    const [defaultTrackpack, setDefaultTrackpack] = useState<string | undefined>()
    const { userActionData } = useSelector(AccountStateSelector)
    const [activeFilters, setActiveFilters] = useState<Array<"Paid" | "Organic">>(["Paid", "Organic"])

    const { control } = useForm()
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useLoadingStatus(loading, RuntimeHelper.searchpackListLoading())
    const isGetRankTrackerDataLoading: boolean = useLoadingStatus(loading, RuntimeHelper.getRanktrackerDataLoading())
    const {
        selectedSearchpackLastDataTime,
        selectedSearchpackProductsRank,
        selectedSearchpackWithTerm,
        searchpackRankTracker,
        selectedSearchpackValues,
        selectedKeywordPayload,
    } = useSelector(SearchpackStateSelector)
    const { refetchSearchpacks } = useSelector(AccountStateSelector)

    const [activeIcon, setActiveIcon] = useState<"table" | "graph">("table")

    const [selectedRange, setSelectedRange] = useState<number>(7)
    const { graphConfig } = DashboardBoardConfig

    useEffect(() => {
        if (selectedSearchpackLastDataTime && selectedSearchpackLastDataTime?.id) {
            const options: { as_user?: string } = {}

            if (userActionData) {
                options.as_user = userActionData.id + ""
            }

            dispatch(SearchpackActionCreator.getSearchPackTermData({}, options))
        }
    }, [dispatch, selectedSearchpackLastDataTime, userActionData])

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAddKeywordsModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    useEffect(() => {
        if (selectedSearchpackValues && selectedKeywordPayload?.selectedSearchpackTermID) {
            const options: { as_user?: string; since?: string; until?: string } = {}

            if (selectedRange && selectedRange !== 0) {
                const sinceDate = dateHelper.getSinceDate(selectedRange)
                if (sinceDate) {
                    options.since = sinceDate
                }
            }

            if (userActionData) {
                options.as_user = userActionData.id + ""
            }

            dispatch(
                SearchpackActionCreator.getSearchpackRankTracker(
                    selectedKeywordPayload?.selectedSearchpackTermID,
                    {},
                    options
                )
            )

            // api call for Get rank snapshot listing changes for a SearchPack-SearchTerm and time range.
            dispatch(
                SearchpackActionCreator.getkRankTrackerListingChange(
                    selectedKeywordPayload?.selectedSearchpackTermID,
                    {},
                    options
                )
            )
        }
    }, [
        dispatch,
        selectedKeywordPayload?.selectedSearchpackTermID,
        selectedRange,
        selectedSearchpackValues,
        userActionData,
        refetchSearchpacks,
    ])

    const filteredTrackDropdownData = useMemo(() => {
        if (selectedSearchpackWithTerm) {
            const filteredSelectedSearchpackWithTerm = selectedSearchpackWithTerm?.searchpacks?.filter(
                (term: TrackpackDropdown) => term.id === selectedSearchpackLastDataTime?.id
            )
            return filteredSelectedSearchpackWithTerm
        }
        return []
    }, [selectedSearchpackLastDataTime?.id, selectedSearchpackWithTerm])

    const filteredRankData = useMemo(() => {
        if (searchpackRankTracker) {
            const selectedAsins =
                selectedSearchpackProductsRank?.selectedProducts?.map((product: Product) => {
                    return product.asin
                }) || []

            return searchpackRankTracker?.search_rank_data_by_date?.filter((term: SearchRankTerm) =>
                term.rank_data.some((rank: RankData) => selectedAsins.includes(rank.asin))
            )
        }
        return []
    }, [selectedSearchpackProductsRank, searchpackRankTracker])

    const selectedSearchpackKeywordsData = useMemo(() => {
        if (!filteredRankData) return []

        return filteredRankData?.map(({ date, rank_data }: SearchRankTerm) => {
            const selectedAsins =
                selectedSearchpackProductsRank?.selectedProducts?.map((product: Product) => product.asin) || []

            const asinRanks = selectedAsins?.map((asin: string) => {
                const matchingProduct = selectedSearchpackProductsRank?.selectedProducts?.find(
                    (product: Product) => product.asin === asin
                )

                const paidRank = rank_data.find((rank: RankData) => rank.asin === asin && rank.is_sponsored)?.rank

                const organicRank = rank_data.find((rank: RankData) => rank.asin === asin && !rank.is_sponsored)?.rank

                return {
                    asin,
                    paid: paidRank,
                    organic: organicRank,
                    color: matchingProduct?.color || {
                        default: "",
                        hover: "",
                        active: "",
                        inActive: "",
                        bgHundred: "",
                    },
                }
            })

            return {
                label: date,
                asinRanks,
            }
        })
    }, [filteredRankData, selectedSearchpackProductsRank])

    const isDisabled =
        (selectedSearchpackLastDataTime && selectedSearchpackLastDataTime?.search_data_last_available_at === null) ||
        selectedSearchpackKeywordsData.length < 1

    const handleFilterClick = (filter: "Paid" | "Organic") => {
        setActiveFilters((prev) => {
            if (prev.includes(filter)) {
                const res = prev.filter((f) => f !== filter)
                if (!res.length) return [filter === "Paid" ? "Organic" : "Paid"]
                return res
            } else {
                return [...prev, filter]
            }
        })
    }

    useEffect(() => {
        if (isLoading) return
        if (filteredTrackDropdownData && filteredTrackDropdownData?.length > 0) {
            const transformedResponse = {
                groups: filteredTrackDropdownData[0]?.search_terms?.map((group: SearchTerm) => ({
                    name: group.term,
                    id: group.id,
                    amazon_tld: filteredTrackDropdownData[0].amazon_tld,
                })),
            }

            const defaultGroup =
                transformedResponse?.groups?.find((i) => Number(i.id) === Number(keyword[0] || 0)) ||
                transformedResponse?.groups?.[0]
            setAllTrackpacks(transformedResponse as Trackpacks)
            setDefaultTrackpack(defaultGroup?.id?.toString())
            updateSearchParams({ keyword: `${defaultGroup?.id || ""}` })
        } else {
            setAllTrackpacks({ groups: [] })
            setDefaultTrackpack("")
            updateSearchParams({ keyword: "" })
        }
        // do not include `keyword`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredTrackDropdownData, isLoading, selectedSearchpackWithTerm, updateSearchParams, userActionData])

    useEffect(() => {
        if (
            keyword[0] &&
            (!selectedKeywordPayload?.selectedSearchpackTermID ||
                Number(selectedKeywordPayload.selectedSearchpackTermID) !== Number(keyword[0]))
        ) {
            dispatch(
                SearchpackActionCreator.setSelectedKeywordPayload({
                    selectedSearchpackTermID: Number(keyword[0]),
                })
            )
        }
    }, [dispatch, keyword, selectedKeywordPayload?.selectedSearchpackTermID])

    const handleSelectElementChange = (selectedValue: string) => {
        dispatch(
            SearchpackActionCreator.setSearchpackRankTracker({
                searchpackRankTracker: {
                    search_card_data_with_latest_rank: [],
                    search_rank_data_by_date: [],
                },
            })
        )
        dispatch(
            SearchpackActionCreator.selectedSearchpackProductRank({
                selectedProducts: [],
            })
        )

        const selectedGroup = allTrackpacks?.groups?.find((group: TrackpackGroup) => {
            return group.id === parseInt(selectedValue, 10)
        })

        if (selectedGroup) {
            updateSearchParams({ keyword: `${selectedGroup?.id || ""}` })
            dispatch(
                SearchpackActionCreator.setSelectedKeywordPayload({
                    selectedSearchpackTermID: selectedGroup.id,
                })
            )
        }
    }

    return (
        <div>
            {selectedSearchpackLastDataTime &&
                selectedSearchpackLastDataTime?.search_data_last_available_at === null && (
                    <SearchpackTrackerOnboarding />
                )}

            <div className={"flex justify-between flex-wrap mb-[12px] w-full h-[36px]"}>
                <div className={"flex items-center h-[36px]"}>
                    <div
                        className="cursor-pointer outline-none"
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[60px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Select Keyword" />
                        )}
                    >
                        <div className="w-[250px] relative !mr-[8px]">
                            <SelectElement
                                className="focus:outline-none pr-[8px] !h-[36px] !w-[99%] !border !border-gray-200 focus:outline-0 focus:shadow-none"
                                buttonTextClass="truncate whitespace-nowrap max-w-[220px] "
                                reactHookControl={control}
                                type="check"
                                name="trackpack"
                                disabled={isLoading || selectedSearchpackKeywordsData.length < 1}
                                defaultValue={defaultTrackpack}
                                rightCheckBox={true}
                                isToolitpShow={false}
                                options={
                                    allTrackpacks?.groups?.map((g: TrackpackGroup) => {
                                        return {
                                            value: g?.id.toString(),
                                            label: g?.name,
                                            amazon_tld: g?.amazon_tld,
                                        }
                                    }) ?? []
                                }
                                onChange={handleSelectElementChange}
                            />
                        </div>
                    </div>
                    <div
                        className="cursor-pointer outline-none"
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[38px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Add Keywords" />
                        )}
                    >
                        <ButtonElement
                            onClick={handleOpen}
                            viewType="only-icon"
                            className={`
                                 ${
                                     isDisabled || isGetRankTrackerDataLoading
                                         ? "cursor-not-allowed pointer-events-none bg-gray-200"
                                         : "bg-gradient-to-br from-cyan-100 to-cyan-200"
                                 } 
                                p-[10px]  rounded-[10px] disabled:bg-gray-100
                                                        hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                                                        active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400`}
                        >
                            <AddIcon
                                strokeColor={isDisabled || isGetRankTrackerDataLoading ? "#D0D5DD" : "#088AB2"}
                                strokWidth={"1.2"}
                            />
                        </ButtonElement>
                    </div>
                    <div className=" h-[24px] flex items-center justify-center border-l border-gray-200 mx-[20px]" />

                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[96px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Filter for Paid or Organic" />
                        )}
                        className={`cursor-pointer outline-none px-[16px] py-[8px] h-[36px] inline-flex items-center gap-[12px] bg-white border border-gray-200 rounded-[10px] mr-[8px] ${
                            isDisabled || isGetRankTrackerDataLoading ? " cursor-not-allowed pointer-events-none" : ""
                        } `}
                    >
                        <PrimaryText
                            onClick={() => handleFilterClick("Paid")}
                            className={`text-gray-700 leading-[18px] cursor-pointer mt-[1px] ${
                                !activeFilters.includes("Paid") ? "text-gray-200" : ""
                            }
                                ${
                                    isDisabled || isGetRankTrackerDataLoading
                                        ? " cursor-not-allowed pointer-events-none text-gray-200"
                                        : ""
                                }    
                                `}
                            size="small"
                            weight="light"
                        >
                            Paid
                        </PrimaryText>
                        <PrimaryText
                            onClick={() => handleFilterClick("Organic")}
                            className={`text-gray-700 leading-[18px] cursor-pointer mt-[1px] ${
                                !activeFilters.includes("Organic") ? "text-gray-200" : ""
                            }
                                  ${
                                      isDisabled || isGetRankTrackerDataLoading
                                          ? " cursor-not-allowed pointer-events-none text-gray-200"
                                          : ""
                                  }    
                                `}
                            size="small"
                            weight="light"
                        >
                            Organic
                        </PrimaryText>
                    </div>

                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[92px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Set Time Period" />
                        )}
                        className={`${
                            isDisabled || isGetRankTrackerDataLoading ? "cursor-not-allowed pointer-events-none" : ""
                        }`}
                    >
                        <ButtonGroups
                            onClickHandler={(option) => {
                                dispatch(RuntimeActionCreators.closeAllPopup())
                                return setSelectedRange(option.value as number)
                            }}
                            defaultValue={isDisabled || isGetRankTrackerDataLoading ? "" : selectedRange}
                            options={graphConfig.productRangeUpdated}
                            tooltipLabel="Set Time Period"
                            showTooltip={false}
                            disabled={isDisabled || isGetRankTrackerDataLoading}
                        />
                    </div>
                </div>
                <div className="inline-flex items-center bg-white border overflow-hidden border-gray-200 rounded-[10px]">
                    {/* Table Icon */}
                    <div
                        onClick={() => !(isDisabled || isGetRankTrackerDataLoading) && setActiveIcon("table")}
                        className={`p-[8px] border-r border-gray-200  ${
                            activeIcon === "table" && !(isDisabled || isGetRankTrackerDataLoading)
                                ? "bg-active-buttons-bg"
                                : "bg-white"
                        }`}
                    >
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-offset={2}
                            data-tooltip-class-name={"!ml-[-20px]"}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className="" label="View Table" />
                            )}
                        >
                            <TableIcon
                                color={
                                    isDisabled || isGetRankTrackerDataLoading
                                        ? "#D0D5DD"
                                        : activeIcon === "table"
                                        ? "#0E7090"
                                        : "#667085"
                                }
                            />
                        </div>
                    </div>

                    {/* Graph Icon */}
                    <div
                        onClick={() => !(isDisabled || isGetRankTrackerDataLoading) && setActiveIcon("graph")}
                        className={`p-[8px] ${
                            activeIcon === "graph" && !(isDisabled || isGetRankTrackerDataLoading)
                                ? "bg-active-buttons-bg"
                                : "bg-white"
                        }`}
                    >
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-offset={2}
                            data-tooltip-class-name={"!ml-[26px]"}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className="" label="View Graph" />
                            )}
                        >
                            <GraphIcon
                                color={
                                    isDisabled || isGetRankTrackerDataLoading
                                        ? "#D0D5DD"
                                        : activeIcon === "graph"
                                        ? "#0E7090"
                                        : "#667085"
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-1">
                {activeIcon === "table" && (
                    <RankTrackerTableView
                        selectedSearchpackKeywordsData={selectedSearchpackKeywordsData}
                        selectedRange={selectedRange}
                        tableFilters={activeFilters}
                    />
                )}
                {activeIcon === "graph" && (
                    <RankTrackerGraphView
                        selectedSearchpackKeywordsData={selectedSearchpackKeywordsData}
                        infoVisible={true}
                        selectedRange={selectedRange}
                        graphFilters={activeFilters}
                        loading={isGetRankTrackerDataLoading}
                    />
                )}
            </div>
        </div>
    )
}

export default RankTracker
