import Skeleton from "react-loading-skeleton"
import { PrimaryText } from "../../elements/primaryText.element"

const KeywordsLoadingCard = ({ isStatic }: CardSkeletonLoaderProps) => {
    return (
        <>
            <div
                data-tooltip-content="Hello world!"
                className={`relative w-[280px]  flex bg-white rounded-[10px] px-[8px] py-[8px] -outline-offset-1 outline-1 outline-gray-300`}
            >
                <div className={` w-full flex  sm:md:h-full cursor-pointer `}>
                    <div className={`w-[4px] h-[20px] rounded-[4px]  mr-[8px] span flex`}>
                        <Skeleton baseColor="#EAECF0" count={1} width={4} height={20} borderRadius={4} />
                    </div>
                    <div className="w-full flex flex-col justify-between">
                        <div className={`flex justify-between`}>
                            <PrimaryText
                                weight={"medium"}
                                className={`text-[12px] pt-[2px]  text-ellipsis overflow-hidden whitespace-nowrap line-clamp-1 min-h-[13px] text-gray-300 leading-[18px] 
                                     `}
                            >
                                Search term
                            </PrimaryText>
                            <div className={`flex items-center justify-center gap-[4px]`}>
                                <div className="w-4 h-4 rounded-full animate-spin border  border-gray-300 border-t-transparent"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KeywordsLoadingCard
