import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { CompetitorAsinIcon } from "../../../../assets/svgs/competitorAsinIcon.svg"
import { KeywordsIcon } from "../../../../assets/svgs/keywordsIcon.svg"
import { MarketIcon } from "../../../../assets/svgs/marketIcon.svg"
import { NewAddAsinsIcon } from "../../../../assets/svgs/newAddAsins.svg"
import { ThumbIcon } from "../../../../assets/svgs/thumbIcon.svg"
import { TrackPackError } from "../../../../assets/svgs/trackPackError.svg"
import { TrackPackNameIcon } from "../../../../assets/svgs/trackPackName.svg"
import { Semi_Automated_Keyowrds_Flag } from "../../../../config/dashboard.config"
import { useRefetchSPAfterModal } from "../../../../hooks/app/searchpack/useRefetchSPAfterModal"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import SemiAutomatedSearchPhraseView from "./semiAutomatedSearchPhraseView.component"
import SemiAutomatedStepFiveView from "./semiAutomatedStepFiveView.component"
import SemiAutomatedStepFourView from "./semiAutomatedStepFourView.component"
import SemiAutomatedStepOneView from "./semiAutomatedStepOneView.component"
import SemiAutomatedStepSevenView from "./semiAutomatedStepSevenView.component"
import SemiAutomatedStepSixView from "./semiAutomatedStepSixView.component"
import SemiAutomatedStepThreeView from "./semiAutomatedStepThreeView.component"
import SemiAutomatedStepTwoView from "./semiAutomatedStepTwoView.component"

const SemiAutomatedTrackPackOutlet = () => {
    const { semiAutomatedAddTrackPack } = useSelector(RunTimeStateSelector)
    const dispatch = useDispatch()
    const [isTrackpackError, setIsTrackpackError] = useState(false)
    const [isTrackpackSuccess, setIsTrackpackSuccess] = useState(false)
    const [trackpackName, setTrackpackName] = useState("")
    const [isFetchingDataError, setIsFecthingDataError] = useState(false)
    const [isErrorType, setIsErrorType] = useState("")
    const { refetchSP } = useRefetchSPAfterModal()

    const handleCloseDialog = (success = false) => {
        success && refetchSP()
        dispatch(RuntimeActionCreators.closeSemiAutomatedTrackpackModal())

        dispatch(
            ProductsActionCreator.selectedTrackpackPayload({
                name: "",
                amazon_tld: "",
                competitor_asins: [],
                own_asins: [],
                total_asins: [],
                selected_Asins: {},
                keywords: [],
            })
        )

        setIsTrackpackError(false)
        setIsTrackpackSuccess(false)
        setIsFecthingDataError(false)
    }

    const handleSuccessModal = (name: string) => {
        setIsTrackpackSuccess(true)
        setTrackpackName(name)
        dispatch(
            RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                open: true,
                stepNumber: Semi_Automated_Keyowrds_Flag ? "six" : "five",
            })
        )
    }
    const handleErrorModal = () => {
        setIsTrackpackError(true)
        dispatch(
            RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                open: true,
                stepNumber: Semi_Automated_Keyowrds_Flag ? "seven" : "six",
            })
        )
    }

    const handleErrorFetchingDataModal = (error: any) => {
        setIsFecthingDataError(true)
        setIsErrorType(error)
        dispatch(
            RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                open: true,
                stepNumber: Semi_Automated_Keyowrds_Flag ? "eight" : "seven",
            })
        )
    }

    const getModalBodyWithPhrases = () => {
        if (isTrackpackSuccess && semiAutomatedAddTrackPack?.stepNumber === "six") {
            return (
                <SemiAutomatedStepFiveView
                    icon={<ThumbIcon />}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                open: true,
                                stepNumber: "five",
                            })
                        )
                    }
                    asinHeaderText="Bravo"
                    buttonText="Done"
                    trackpackName={trackpackName}
                />
            )
        }
        if (isTrackpackError && semiAutomatedAddTrackPack?.stepNumber === "seven") {
            return (
                <SemiAutomatedStepSixView
                    icon={<TrackPackError />}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                open: true,
                                stepNumber: "five",
                            })
                        )
                    }
                    asinHeaderText="Error adding new ASINs"
                    asinHeaderDescription="I couldn’t add new ASINs to your Trackpack at this time due to a server communication issue. Please try again later."
                    buttonText="Done"
                />
            )
        }
        if (isFetchingDataError && semiAutomatedAddTrackPack?.stepNumber === "eight") {
            return (
                <SemiAutomatedStepSevenView
                    icon={<TrackPackError />}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                open: true,
                                stepNumber: "one",
                            })
                        )
                    }
                    asinHeaderText="Error creating new Trackpack"
                    buttonText="Done"
                    isErrorType={isErrorType}
                />
            )
        }
        switch (semiAutomatedAddTrackPack?.stepNumber) {
            case "one":
                return (
                    <SemiAutomatedStepOneView
                        icon={<NewAddAsinsIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Starter ASIN"
                        buttonText="Next"
                        isPrevButton={true}
                        handlePrev={() => {
                            dispatch(
                                RuntimeActionCreators.openTrackPackFlowModal({
                                    open: true,
                                })
                            )
                            handleCloseDialog()
                        }}
                        handleNext={(step: string | undefined) => {
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: step,
                                })
                            )
                            dispatch(ProductsActionCreator.setCompetitorsDataList({} as any))
                        }}
                    />
                )
            case "two":
                return (
                    <SemiAutomatedStepTwoView
                        icon={<MarketIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Identify Market"
                        asinHeaderDescription="Please tell me which Amazon market this Trackpack is for."
                        buttonText="Next"
                        isPrevButton={true}
                        handlePrev={() => {
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "one",
                                })
                            )
                        }}
                        handleNext={() => {
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "three",
                                })
                            )
                            dispatch(ProductsActionCreator.setCompetitorsDataList({} as any))
                        }}
                    />
                )
            case "three":
                return (
                    <SemiAutomatedSearchPhraseView
                        icon={<KeywordsIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Add search phrases"
                        buttonText="Next"
                        isPrevButton={true}
                        handlePrev={() => {
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "one",
                                })
                            )
                        }}
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "four",
                                })
                            )
                        }
                    />
                )
            case "four":
                return (
                    <SemiAutomatedStepThreeView
                        icon={<CompetitorAsinIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Competitor ASINs Identified"
                        asinHeaderDescription="Refine your new Trackpack by selecting the shared terms you value most or remove ASINs manually."
                        buttonText="Confirm Trackpack"
                        isPrevButton={true}
                        handlePrev={() => {
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "one",
                                })
                            )
                            dispatch(RuntimeActionCreators.closeNotificaitonAlert())
                        }}
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "five",
                                })
                            )
                        }
                        isDataFecthingError={handleErrorFetchingDataModal}
                    />
                )
            case "five":
                return (
                    <SemiAutomatedStepFourView
                        icon={<TrackPackNameIcon />}
                        handleCloseDialog={handleCloseDialog}
                        handlePrev={() =>
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "four",
                                })
                            )
                        }
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "six",
                                })
                            )
                        }
                        asinHeaderText="Trackpack Name"
                        asinHeaderDescription="Let’s give your new Trackpack a name."
                        buttonText="Next"
                        isError={handleErrorModal}
                        isSuccess={(name) => handleSuccessModal(name)}
                    />
                )

            default:
                return <></>
        }
    }
    const getModalBody = () => {
        if (isTrackpackSuccess && semiAutomatedAddTrackPack?.stepNumber === "five") {
            return (
                <SemiAutomatedStepFiveView
                    icon={<ThumbIcon />}
                    handleCloseDialog={() => handleCloseDialog(true)}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                open: true,
                                stepNumber: "four",
                            })
                        )
                    }
                    asinHeaderText="Bravo"
                    buttonText="Done"
                    trackpackName={trackpackName}
                />
            )
        }
        if (isTrackpackError && semiAutomatedAddTrackPack?.stepNumber === "six") {
            return (
                <SemiAutomatedStepSixView
                    icon={<TrackPackError />}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                open: true,
                                stepNumber: "four",
                            })
                        )
                    }
                    asinHeaderText="Error adding new ASINs"
                    asinHeaderDescription="I couldn’t add new ASINs to your Trackpack at this time due to a server communication issue. Please try again later."
                    buttonText="Done"
                />
            )
        }
        if (isFetchingDataError && semiAutomatedAddTrackPack?.stepNumber === "seven") {
            return (
                <SemiAutomatedStepSevenView
                    icon={<TrackPackError />}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                open: true,
                                stepNumber: "one",
                            })
                        )
                    }
                    asinHeaderText="Error creating new Trackpack"
                    buttonText="Done"
                    isErrorType={isErrorType}
                />
            )
        }
        switch (semiAutomatedAddTrackPack?.stepNumber) {
            case "one":
                return (
                    <SemiAutomatedStepOneView
                        icon={<NewAddAsinsIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Starter ASIN"
                        buttonText="Next"
                        isPrevButton={true}
                        handlePrev={() => {
                            dispatch(
                                RuntimeActionCreators.openTrackPackFlowModal({
                                    open: true,
                                })
                            )
                            handleCloseDialog()
                        }}
                        handleNext={(step: string | undefined) => {
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: step,
                                })
                            )
                            dispatch(ProductsActionCreator.setCompetitorsDataList({} as any))
                        }}
                    />
                )
            case "two":
                return (
                    <SemiAutomatedStepTwoView
                        icon={<MarketIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Identify Market"
                        asinHeaderDescription="Please tell me which Amazon market this Trackpack is for."
                        buttonText="Next"
                        isPrevButton={true}
                        handlePrev={() => {
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "one",
                                })
                            )
                        }}
                        handleNext={() => {
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "three",
                                })
                            )
                            dispatch(ProductsActionCreator.setCompetitorsDataList({} as any))
                        }}
                    />
                )
            case "three":
                return (
                    <SemiAutomatedStepThreeView
                        icon={<CompetitorAsinIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Competitor ASINs Identified"
                        asinHeaderDescription="Refine your new Trackpack by selecting the shared terms you value most or remove ASINs manually."
                        buttonText="Confirm Trackpack"
                        isPrevButton={true}
                        handlePrev={() => {
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "one",
                                })
                            )
                            dispatch(RuntimeActionCreators.closeNotificaitonAlert())
                        }}
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "four",
                                })
                            )
                        }
                        isDataFecthingError={handleErrorFetchingDataModal}
                    />
                )
            case "four":
                return (
                    <SemiAutomatedStepFourView
                        icon={<TrackPackNameIcon />}
                        handleCloseDialog={handleCloseDialog}
                        handlePrev={() =>
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "three",
                                })
                            )
                        }
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                                    open: true,
                                    stepNumber: "five",
                                })
                            )
                        }
                        asinHeaderText="Trackpack Name"
                        asinHeaderDescription="Let’s give your new Trackpack a name."
                        buttonText="Next"
                        isError={handleErrorModal}
                        isSuccess={(name) => handleSuccessModal(name)}
                    />
                )

            default:
                return <></>
        }
    }

    return (
        <>
            {semiAutomatedAddTrackPack?.open && Semi_Automated_Keyowrds_Flag ? (
                <>{getModalBodyWithPhrases()}</>
            ) : semiAutomatedAddTrackPack?.open && !Semi_Automated_Keyowrds_Flag ? (
                <>{getModalBody()}</>
            ) : null}
        </>
    )
}

export default SemiAutomatedTrackPackOutlet
