import { call, put, takeLatest } from "typed-redux-saga"
import { EXTENDED_TRACKPACK_LIST, GET_EXTENDED_TRACKPACK_LIST } from "../@store"
import { setExtendedTrackpackList } from "../actions"
import { apiSaga } from "../helpers/saga.helper"
import { getExtendedTrackpackListAPI } from "../services/trackpack.service"

/**
 * Handlers
 */
function* _getExtendedTrackpackListHandler<T extends ISagaAction>(action: T) {
    const { pathParams, queryParams } = action.payload
    const result = yield* call(getExtendedTrackpackListAPI, pathParams, queryParams)

    if (result) {
        yield put(setExtendedTrackpackList(result))
    }
}

/**
 * Binds handlers to sagas
 */
export function* getExtendedTrackpackListSaga() {
    yield takeLatest(GET_EXTENDED_TRACKPACK_LIST, apiSaga(EXTENDED_TRACKPACK_LIST, _getExtendedTrackpackListHandler))
}
