import GraphSkeletonImage from "../images/graphSkelton.png"
export const GraphSkeleton = () => {
    return (
        <>
            <div className="w-full" style={{ height: "calc(100vh - 288px)" }}>
                {/* <GrapahSkeletonImage className="w-full h-full" /> */}
                <img src={GraphSkeletonImage} alt="" className="h-full w-full" />
            </div>
        </>
    )
}
