import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { SettingsActionsCreater } from "../../../../actions/settings.action"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { useLoadingStatus } from "../../../../hooks/useLoadingStatus.hook"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { SettingStateSelector } from "../../../../selectors/settingState.selector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
import { RadioGroupButton } from "../../../elements/radioGroupButton.element"

export const AlertSettings = () => {
    const options = ["daily", "weekly", "off"]
    const dispatch = useDispatch()

    const { handleSubmit } = useForm()

    const { selectedAlertSetting } = useSelector(SettingStateSelector)
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useLoadingStatus(loading, RuntimeHelper.alertSettingLoading())
    const isUpdateLoading: boolean = useLoadingStatus(loading, RuntimeHelper.updateAlertSettingLoading())

    const [selectedOption, setSelectedOption] = useState(options[0])
    const [hasChanged, setHasChanged] = useState(false)

    useEffect(() => {
        dispatch(SettingsActionsCreater.getAlertSetting())
    }, [dispatch])

    useEffect(() => {
        if (selectedAlertSetting?.change_summary_email_frequency) {
            setSelectedOption(selectedAlertSetting?.change_summary_email_frequency?.toLowerCase())
        }
    }, [selectedAlertSetting])

    const onSubmit = () => {
        const payload = {
            change_summary_email_frequency: selectedOption,
        }

        dispatch(
            SettingsActionsCreater.updateAlertSetting({ ...payload }, () => {
                dispatch(SettingsActionsCreater.getAlertSetting())
            })
        )
        setHasChanged(false)
    }

    const handleOptionChange = (value: string) => {
        setSelectedOption(value)
        if (value !== selectedAlertSetting?.change_summary_email_frequency) {
            setHasChanged(true)
        } else {
            setHasChanged(false)
        }
    }

    const handleCancel = () => {
        if (selectedAlertSetting?.change_summary_email_frequency) {
            setSelectedOption(selectedAlertSetting.change_summary_email_frequency)
            setHasChanged(false)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className={"w-full"}>
                <div className="border-b-[1px] border-gray-200  pb-[20px]">
                    <PrimaryText weight="medium" size="lg" className="text-gray-700 mb-[4px]">
                        Alert settings
                    </PrimaryText>
                    <PrimaryText color="text-gray-500" weight="light" size="small" className="">
                        We may still send you important notifications about your account outside of your alert settings
                    </PrimaryText>
                </div>
                <div className=" border-b-[1px] border-gray-200 pb-[48px] pt-[24px]">
                    <PrimaryText weight="medium" size="small" className="text-gray-700 mb-[4px]">
                        Email Alerts
                    </PrimaryText>
                    <div className="flex items-center justify-between">
                        <PrimaryText color="text-gray-500" weight="light" size="small" className="">
                            Select what you want to be notified about and how often.
                        </PrimaryText>

                        <div className="flex gap-x-[32px] items-center">
                            <PrimaryText color="text-gray-500" weight="light" size="small" className="">
                                When there are changes to ASINs in my Trackpacks
                            </PrimaryText>
                            <div>
                                <RadioGroupButton
                                    options={options}
                                    selectedOption={selectedOption}
                                    onChange={handleOptionChange}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-3 justify-end  pt-[12px]">
                    <ButtonElement
                        size="medium"
                        textClass="font-500 text-[14px]"
                        className="bg-transparent w-[89px] h-[36px] rounded-[8px] text-gray-500  text-[14px] font-[500] leading-[24px] hover:bg-transparent"
                        viewType="button-text"
                        disabled={!hasChanged || isUpdateLoading}
                        type="button"
                        onClick={handleCancel}
                    >
                        {"Cancel"}
                    </ButtonElement>
                    <ButtonElement
                        size="medium"
                        textClass="font-[500] text-[14px] pt-[1px]"
                        className="bg-gradient-to-r from-[#CFF9FE] to-[#A5F0FC] w-[55px] h-[36px] rounded-[8px] text-[#0E7090] text-[16px] font-[500] leading-[24px] "
                        disabled={!hasChanged || isUpdateLoading}
                    >
                        {"Save"}
                    </ButtonElement>
                </div>
            </form>
        </>
    )
}
