import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { PrimaryText } from "../../../elements/primaryText.element"

import { ReactComponent as LeftScrollArrow } from "../../../../assets/svgs/leftScrollArrow.svg"
import { ReactComponent as RightScrollArrow } from "../../../../assets/svgs/rightScrollArrow.svg"
import { ASIN_AMAZON_LINK_BASE } from "../../../../config/routes.config"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { getColorBasedOnRank, SEATTLE_TIMEZONE } from "../../../../helpers/util.helper"
import { useLoadingStatus } from "../../../../hooks/useLoadingStatus.hook"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../../selectors/searchpack.selector"
import { dateUtils } from "../../../../utils/date"
import { extendedDayjs } from "../../../../utils/dayjs"
import KeywordPerformanceTableSkeleton from "../../../common/skeletonLoader/keywordPerformanceTableSkeleton.component"

interface KeywordPerformanceTableProps {
    selectedRange?: number
    selectedBSR?: string[]
    selectedDisabledFilter: ("Paid" | "Organic")[]
}

export interface IRankData {
    rank: number
    is_sponsored: boolean
    date: string
    term_id: number
}

interface Term {
    term: string
    id: number
    status: string
    created_at: string
    last_updated_at: string
}

interface SelectedProduct {
    term: Term
    rankData: IRankData[]
}

interface BsrData {
    date: string
    bsr_large: number | null
    bsr_small: number | null
}

const KeywordPerformanceTable = ({
    selectedRange,
    selectedBSR,
    selectedDisabledFilter,
}: KeywordPerformanceTableProps) => {
    const parentRef = useRef<HTMLDivElement>(null)
    const trackRef = useRef<HTMLDivElement>(null)
    const isDragging = useRef(false)
    const startDragX = useRef(0)
    const [canScrollLeft, setCanScrollLeft] = useState(false)
    const [canScrollRight, setCanScrollRight] = useState(false)
    const [thumbPosition, setThumbPosition] = useState(0)
    const [thumbWidth, setThumbWidth] = useState(0)

    const dispatch = useDispatch()
    const { loading } = useSelector(RunTimeStateSelector)

    const { selectedDropdownList } = useSelector(ProductStateSelector)
    const { searchpackKeywordPerformanceBsr, selectedSearchpackKeywordPerformanceRank, searchpackKeywordPerformance } =
        useSelector(SearchpackStateSelector)

    const isKeywordPerformanceLoading: boolean = useLoadingStatus(
        loading,
        RuntimeHelper.getSearchpackKeywordPerformanceHandler()
    )

    const selectedTrackpackID = localStorage.getItem("selectedTrackpackID")

    const selectedObject = selectedDropdownList?.trackpacks?.find(
        (item: { metadata: { id: number | string } }) => item.metadata.id == selectedTrackpackID
    )

    const amazonTld = selectedObject?.metadata?.amazon_tld ?? "com"
    const url = ASIN_AMAZON_LINK_BASE.replace("com", amazonTld)

    useEffect(() => {
        const updateScrollState = () => {
            const parent = parentRef.current
            if (parent) {
                const { scrollWidth, clientWidth, scrollLeft } = parent
                const track = trackRef.current

                if (track) {
                    const thumbWidthRatio = clientWidth / scrollWidth
                    setThumbWidth(track.clientWidth * thumbWidthRatio)
                    setThumbPosition((scrollLeft / scrollWidth) * track.clientWidth)
                }

                setCanScrollLeft(scrollLeft > 0)
                setCanScrollRight(scrollLeft + clientWidth < scrollWidth)
            }
        }

        const parent = parentRef.current
        if (parent) {
            updateScrollState()
            parent.addEventListener("scroll", updateScrollState)
        }

        window.addEventListener("resize", updateScrollState)

        return () => {
            if (parent) parent.removeEventListener("scroll", updateScrollState)
            window.removeEventListener("resize", updateScrollState)
        }
    }, [selectedSearchpackKeywordPerformanceRank?.selectedProducts])

    useEffect(() => {
        const updateScrollState = () => {
            const parent = parentRef.current
            if (parent) {
                const { scrollWidth, clientWidth } = parent
                const track = trackRef.current

                if (track) {
                    const thumbWidthRatio = clientWidth / scrollWidth
                    setThumbWidth(track.clientWidth * thumbWidthRatio)
                    setThumbPosition((parent.scrollLeft / scrollWidth) * track.clientWidth)
                }

                setCanScrollLeft(parent.scrollLeft > 0)
                setCanScrollRight(parent.scrollLeft + clientWidth < scrollWidth)
            }
        }

        const observer = new MutationObserver(updateScrollState)
        if (parentRef.current) {
            observer.observe(parentRef.current, { childList: true, subtree: true })
        }

        updateScrollState() //Initial calculation

        return () => observer.disconnect()
    }, [selectedSearchpackKeywordPerformanceRank?.selectedProducts])

    const handleScroll = useCallback(
        (direction: "left" | "right") => {
            const parent = parentRef.current
            if (parent) {
                const { clientWidth } = parent
                const scrollAmount = clientWidth / 2
                const newScrollLeft =
                    direction === "left"
                        ? Math.max(parent.scrollLeft - scrollAmount, 0)
                        : Math.min(parent.scrollLeft + scrollAmount, parent.scrollWidth - clientWidth)

                parent.scrollTo({ left: newScrollLeft, behavior: "smooth" })
            }
        },
        [selectedSearchpackKeywordPerformanceRank?.selectedProducts]
    )

    const handleThumbDragStart = (e: React.MouseEvent | React.TouchEvent) => {
        isDragging.current = true
        startDragX.current = "touches" in e ? e.touches[0].clientX : e.clientX
    }

    const handleThumbDragMove = (e: MouseEvent | TouchEvent) => {
        if (!isDragging.current) return

        const parent = parentRef.current
        const track = trackRef.current

        if (parent && track) {
            const currentX = "touches" in e ? e.touches[0].clientX : e.clientX
            const deltaX = currentX - startDragX.current
            startDragX.current = currentX

            const trackWidth = track.clientWidth
            const { scrollWidth } = parent

            const newThumbPosition = Math.min(Math.max(thumbPosition + deltaX, 0), trackWidth - thumbWidth)

            setThumbPosition(newThumbPosition)

            const newScrollLeft = (newThumbPosition / trackWidth) * scrollWidth
            parent.scrollTo({ left: newScrollLeft })
        }
    }

    const handleThumbDragEnd = () => {
        isDragging.current = false
    }

    useEffect(() => {
        if (isDragging.current) {
            document.addEventListener("mousemove", handleThumbDragMove)
            document.addEventListener("touchmove", handleThumbDragMove)
            document.addEventListener("mouseup", handleThumbDragEnd)
            document.addEventListener("touchend", handleThumbDragEnd)
        }

        return () => {
            document.removeEventListener("mousemove", handleThumbDragMove)
            document.removeEventListener("touchmove", handleThumbDragMove)
            document.removeEventListener("mouseup", handleThumbDragEnd)
            document.removeEventListener("touchend", handleThumbDragEnd)
        }
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thumbPosition, selectedSearchpackKeywordPerformanceRank?.selectedProducts])

    const rankData = useMemo(
        () =>
            searchpackKeywordPerformance?.search_rank_data_by_asin?.filter?.(
                (n) => n.asin === searchpackKeywordPerformanceBsr?.asin
            ) || [],
        [searchpackKeywordPerformanceBsr?.asin]
    )

    const dateRange = useMemo(() => {
        const bsrDates: string[] =
            searchpackKeywordPerformanceBsr?.bsr?.reduce((dates: string[], d: BsrData) => dates.concat([d.date]), []) ||
            []

        const rankDates =
            rankData.reduce(
                (dates: string[], next) =>
                    dates.concat(
                        next.date_term_rank_data.reduce((rd: string[], rank) => rd.concat([rank.date]), []) || []
                    ),
                []
            ) || []

        const today = extendedDayjs().tz(SEATTLE_TIMEZONE)
        const hasToday = Array.from(new Set(bsrDates.concat(rankDates))).includes(today.clone().format("YYYY-MM-DD"))
        const formedDateRange = Array.from({ length: selectedRange || 0 }).map((_, i) =>
            today.clone().subtract(i, "day").format("YYYY-MM-DD")
        )

        if (!hasToday) {
            formedDateRange.shift()
            formedDateRange.push(
                today
                    .clone()
                    .subtract(selectedRange || 0, "day")
                    .format("YYYY-MM-DD")
            )
        }
        return dateUtils.sortDateArrayDesc(formedDateRange)
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchpackKeywordPerformanceBsr, selectedSearchpackKeywordPerformanceRank])

    return (
        <>
            <div className=" rounded-lg">
                {isKeywordPerformanceLoading ? (
                    <KeywordPerformanceTableSkeleton />
                ) : (searchpackKeywordPerformance &&
                      searchpackKeywordPerformance?.search_rank_data_by_asin?.length === 0) ||
                  (selectedSearchpackKeywordPerformanceRank?.selectedProducts &&
                      selectedSearchpackKeywordPerformanceRank?.selectedProducts?.length === 0) ? (
                    <KeywordPerformanceTableSkeleton isStatic={true} />
                ) : (
                    selectedSearchpackKeywordPerformanceRank?.selectedProducts &&
                    selectedSearchpackKeywordPerformanceRank?.selectedProducts?.length > 0 && (
                        <>
                            <div
                                className={"overflow-auto top-scroll -mr-[28px]"}
                                onScroll={() => {
                                    dispatch(RuntimeActionCreators.closeAllPopup())
                                }}
                            >
                                <div
                                    className=""
                                    style={{
                                        maxHeight: "calc(100vh - 350px)",
                                        width: "calc(100% - 28px)",
                                    }}
                                >
                                    <div className="rounded-[10px] h-full  border border-gray-200 bg-gray-200 rounded-bl-[10px] rounded-br-[10px] overflow-hidden ">
                                        <div className="flex items-center pt-[12px] gap-x-[32px] pr-[12px] pl-[34px]  ">
                                            <div className="min-w-[297px]"></div>

                                            <div className="text-black flex items-center flex-1 gap-[10px]">
                                                {/* Left Arrow */}
                                                <button
                                                    onClick={() => handleScroll("left")}
                                                    disabled={!canScrollLeft}
                                                    className={` ${
                                                        !canScrollLeft ? " cursor-not-allowed" : "cursor-pointer"
                                                    }`}
                                                >
                                                    <LeftScrollArrow />
                                                </button>

                                                <div
                                                    ref={trackRef}
                                                    className="relative w-full bg-gray-200 h-[4px] my-[3px]"
                                                >
                                                    <div
                                                        className="bg-[#D0D5DD] h-[4px] absolute rounded-[8px] cursor-pointer"
                                                        style={{ width: `${thumbWidth}px`, left: `${thumbPosition}px` }}
                                                        onMouseDown={handleThumbDragStart}
                                                        onTouchStart={handleThumbDragStart}
                                                    ></div>
                                                </div>

                                                {/* Right Arrow */}
                                                <button
                                                    onClick={() => handleScroll("right")}
                                                    disabled={!canScrollRight}
                                                    className={` ${
                                                        !canScrollRight ? " cursor-not-allowed" : "cursor-pointer"
                                                    }`}
                                                >
                                                    <RightScrollArrow />
                                                </button>
                                            </div>
                                        </div>
                                        <div ref={parentRef} className={` scroll-hidden overflow-x-auto `}>
                                            <div className="bg-gray-200">
                                                <div
                                                    className={
                                                        "flex py-[16px] min-w-fit  rounded-tr-[8px] rounded-tl-[8px] z-20 relative"
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            " pl-[24px] flex items-end sticky left-0 bg-gray-200 justify-start min-w-[376px]"
                                                        }
                                                    >
                                                        <PrimaryText
                                                            size={"small"}
                                                            weight={"medium"}
                                                            className={"w-[352px] text-gray-700 leading-[20px]"}
                                                        >
                                                            Date
                                                        </PrimaryText>
                                                    </div>
                                                    {dateRange.map((date: string, index: number) => {
                                                        const formattedDate = dayjs(date)
                                                        const dayName = formattedDate.format("ddd") //e.g., "Mon"
                                                        const dayNumber = formattedDate.format("D") //e.g., "26"
                                                        const monthName = formattedDate.format("MMM") //e.g., "Nov"

                                                        return (
                                                            <div
                                                                key={index}
                                                                className="block mx-[8px] last:mr-0 last:pr-[32px]"
                                                            >
                                                                <div className="flex flex-col items-center text-center">
                                                                    <div className="p-[10px] bg-white rounded-[6px] min-w-[64px]">
                                                                        <PrimaryText
                                                                            className="text-[#088AB2] uppercase mb-[2px] leading-[10px]"
                                                                            size="xs-medium"
                                                                            weight="book"
                                                                        >
                                                                            {dayName}
                                                                        </PrimaryText>
                                                                        <PrimaryText
                                                                            className="text-[#344054] uppercase text-[36px] mb-[2px] leading-[36px] tracking-[0.2px]"
                                                                            weight="light"
                                                                        >
                                                                            {dayNumber}
                                                                        </PrimaryText>
                                                                        <PrimaryText
                                                                            className="text-[#344054] uppercase leading-[10px]"
                                                                            size="xs-medium"
                                                                            weight="book"
                                                                        >
                                                                            {monthName}
                                                                        </PrimaryText>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                {/* Sub-header for Keywords and PAID | ORG. */}
                                                {selectedBSR && selectedBSR?.includes("BSR L") && (
                                                    <div className="flex border-y  border-[#EEE] min-w-fit bg-gray-100 z-10 relative">
                                                        <div
                                                            className={
                                                                "py-[16px] bg-gray-100 pl-[24px] flex items-center sticky left-0 min-w-[376px]  justify-start"
                                                            }
                                                        >
                                                            <PrimaryText
                                                                size={"xs"}
                                                                weight={"medium"}
                                                                className={"text-gray-700 w-[352px]"}
                                                            >
                                                                BSR L Cat.
                                                            </PrimaryText>
                                                        </div>

                                                        {dateRange.map((date, index) => {
                                                            const data = searchpackKeywordPerformanceBsr?.bsr?.find(
                                                                (d) => d.date === date
                                                            )
                                                            return (
                                                                <div
                                                                    key={`paid-org-header-${index}`}
                                                                    className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px]"
                                                                >
                                                                    <PrimaryText
                                                                        weight="medium"
                                                                        size="xs"
                                                                        className="leading-[16px] text-gray-600 text-center"
                                                                    >
                                                                        {data?.bsr_large ? data?.bsr_large : "-"}
                                                                    </PrimaryText>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )}
                                                {selectedBSR && selectedBSR?.includes("BSR S") && (
                                                    <div className="flex border-b border-[#EEE] min-w-fit bg-gray-100 z-10 relative">
                                                        <div
                                                            className={
                                                                "py-[16px] bg-gray-100 pl-[24px] flex items-center sticky left-0 min-w-[376px] justify-start "
                                                            }
                                                        >
                                                            <PrimaryText
                                                                size={"xs"}
                                                                weight={"medium"}
                                                                className={"text-gray-700 w-[352px]"}
                                                            >
                                                                BSR S Cat.
                                                            </PrimaryText>
                                                        </div>
                                                        {dateRange.map((date, index) => {
                                                            const data = searchpackKeywordPerformanceBsr?.bsr?.find(
                                                                (d) => d.date === date
                                                            )
                                                            return (
                                                                <div
                                                                    key={`paid-org-header-${index}`}
                                                                    className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px]"
                                                                >
                                                                    <PrimaryText
                                                                        weight="medium"
                                                                        size="xs"
                                                                        className="leading-[16px] text-gray-600 text-center"
                                                                    >
                                                                        {data?.bsr_small ? data?.bsr_small : "-"}
                                                                    </PrimaryText>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )}

                                                {/* Data Rows */}
                                                {selectedSearchpackKeywordPerformanceRank &&
                                                    selectedSearchpackKeywordPerformanceRank?.selectedProducts?.length >
                                                        0 &&
                                                    selectedSearchpackKeywordPerformanceRank?.selectedProducts?.map(
                                                        (item: SelectedProduct, rowIndex: number) => {
                                                            const { term, rankData } = item
                                                            return (
                                                                <div
                                                                    key={`keyword-row-${rowIndex}`}
                                                                    className="flex border-b border-[#EEE] min-w-fit bg-white z-10 relative last:border-b-0 last:rounded-br-[8px] last:rounded-bl-[8px]"
                                                                >
                                                                    {/* Keywords Column */}
                                                                    <div
                                                                        className={
                                                                            "py-[20px] pl-[24px] flex items-center bg-white sticky left-0 min-w-[376px]  justify-start"
                                                                        }
                                                                    >
                                                                        <PrimaryText
                                                                            size={"xs"}
                                                                            weight={"light"}
                                                                            className={"text-gray-700 w-[352px]"}
                                                                        >
                                                                            {term.term}
                                                                        </PrimaryText>
                                                                    </div>

                                                                    {dateRange.map((date, index) => {
                                                                        const rank = rankData.find(
                                                                            (rank: IRankData) =>
                                                                                rank.date === date &&
                                                                                ((selectedDisabledFilter.includes(
                                                                                    "Paid"
                                                                                ) &&
                                                                                    rank.is_sponsored) ||
                                                                                    (selectedDisabledFilter.includes(
                                                                                        "Organic"
                                                                                    ) &&
                                                                                        !rank.is_sponsored))
                                                                        )
                                                                        return (
                                                                            <div
                                                                                key={`paid-org-${rowIndex}-${index}`}
                                                                                className="min-w-[64px] flex items-center h-[32px] mx-[8px] justify-center rounded-[6px] my-[12px] last:mr-[42px]"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        getColorBasedOnRank(rank?.rank)
                                                                                            ?.bg,
                                                                                    color: getColorBasedOnRank(
                                                                                        rank?.rank
                                                                                    )?.text,
                                                                                }}
                                                                            >
                                                                                <PrimaryText weight="medium" size="xs">
                                                                                    {rank ? rank.rank : "-"}
                                                                                </PrimaryText>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div style={{ height: "12px" }}></div> */}
                                </div>
                            </div>
                            <div className="flex rounded-[10px] mt-[10px] border border-[#EEE] bg-white relative items-center justify-between w-full">
                                {/* Keywords Column */}
                                <div className={"py-[16px] pl-[24px] flex items-center  min-w-[302px]  justify-start"}>
                                    <PrimaryText size={"xs"} weight={"medium"} className={"text-gray-700 w-[352px]"}>
                                        Keyword Strength
                                    </PrimaryText>
                                </div>

                                <div className="py-[16px] flex-1">
                                    <div className=" flex items-center ml-[16px]">
                                        {/* Gradient background */}
                                        <PrimaryText
                                            size="xs"
                                            weight="medium"
                                            className="text-[#A48AFB]  leading-[16px]"
                                        >
                                            Very low
                                        </PrimaryText>
                                        <div
                                            className="h-[8px] mx-[12px] rounded-[4px] "
                                            style={{
                                                width: "calc(100% - 144px)",
                                                background:
                                                    "linear-gradient(90deg,  #BDB4FE 0%, #FDE272 25%, #F7B27A 50%, #FEA3B4 75%,  #75E0A7 100%)",
                                            }}
                                        ></div>
                                        <PrimaryText
                                            size="xs"
                                            weight="medium"
                                            className="text-[#47CD89] leading-[16px]"
                                        >
                                            Very high
                                        </PrimaryText>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                )}
            </div>
        </>
    )
}

export default KeywordPerformanceTable
