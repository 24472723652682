import parse from "html-react-parser"
import { PropsWithChildren, useEffect, useRef, useState } from "react"

import { useSelector } from "react-redux"
import { TrackPackChatIcon } from "../../assets/svgs/trackpackchatIcon.svg"
import { UtilHelper } from "../../helpers/util.helper"
import { AuthStateSelector } from "../../selectors/authState.selector"
import { Container } from "./container.element"
import { PrimaryText } from "./primaryText.element"

export const BubbleListToTop = (props: PropsWithChildren) => {
    useEffect(() => {
        // Call scrollTopPage when component mounts
        UtilHelper.scrollTopPage()
    }, [])

    return (
        <div className="relative">
            <div>
                <div className="flex flex-col-reverse h-full">{props.children}</div>
            </div>
        </div>
    )
}

export const BubblesList = (props: IBubblesListProps) => {
    const { getAccessLevel } = useSelector(AuthStateSelector)

    const loginUser =
        getAccessLevel?.first_name && getAccessLevel?.last_name
            ? getAccessLevel?.first_name?.charAt(0)?.toUpperCase()
            : getAccessLevel && getAccessLevel.username?.charAt(0).toUpperCase()

    const intervalRef = useRef<() => Promise<void>>()
    const listRef = useRef<HTMLDivElement>(null)

    const { modal } = props

    const [displayedConversation, setDisplayedConversation] = useState<IConversationItem[]>([])
    const [sleepTime] = useState({ min: props.minSleep || 1500, max: props.maxSleep || 3000 })

    const stringWaitTime = props.stringWaitTime || 35

    useEffect(() => {
        if (!intervalRef.current) {
            intervalRef.current = async () => {
                let waitTime = 0

                for await (const object of props.conversationItems) {
                    if (object.text) {
                        const stringLength = (object.text?.replace(/<[^>]*>/g, " ").match(/\b\w+\b/g) + "").length
                        waitTime = stringLength * stringWaitTime
                        waitTime = Math.max(sleepTime.min, Math.min(waitTime, sleepTime.max))
                    } else {
                        waitTime = 1500
                    }
                    displayedConversation.push(object)
                    setDisplayedConversation([...displayedConversation])

                    await UtilHelper.sleep(waitTime)
                }
            }

            intervalRef.current().finally(() => {
                props.onFinish && props.onFinish()
            })
        }
    }, [displayedConversation, props, sleepTime.max, sleepTime.min, stringWaitTime])

    useEffect(() => {
        setTimeout(() => {
            listRef.current?.lastElementChild?.scrollIntoView({ behavior: "smooth", block: "end" })
        }, 500)
    }, [displayedConversation])

    return (
        <div ref={listRef} className={props.className}>
            {displayedConversation
                .map((item) => {
                    if (item.text) {
                        return (
                            <div
                                className={`${
                                    item.trackType === "sender" || item.isFirstMultiAsin ? "flex justify-end " : ""
                                }`}
                            >
                                <div
                                    className={`${item.trackType === "sender" ? "flex items-center gap-2 " : ""} ${
                                        item.isIcon ? "flex  gap-2 items-start" : ""
                                    }`}
                                >
                                    {item.isIcon && <TrackPackChatIcon />}
                                    <PrimaryText
                                        typography="p:round-onboarding-responsive"
                                        style={{ lineHeight: modal ? "24px" : "24px" }}
                                        className={` ${
                                            item.trackType === "sender" || item.isFirstMultiAsin
                                                ? `bg-[#A5F0FC] !text-[#164C63] h-[36px] flex items-center px-[12px] py-[6px] pt-[8px]`
                                                : "bg-gray-100"
                                        } ${modal ? "py-[6px] px-[12px] " : "py-[6px] px-[12px]"} text-[#344054]  
                                ${item.className || ""}`}
                                    >
                                        {parse(item.text)}
                                    </PrimaryText>
                                    {item.trackType === "sender" && (
                                        <PrimaryText
                                            weight="light"
                                            size="md"
                                            className="text-[#ffff] p-[6px] bg-gray-500 rounded-[10px] pl-[7px] flex items-center justify-center bg-gradient-to-r from-[#475467] to-[#667085] h-[36px] w-[36px]"
                                        >
                                            {loginUser}
                                        </PrimaryText>
                                    )}
                                </div>
                            </div>
                        )
                    } else if (item.src && item.srcType === "small") {
                        return <img src={item.src} className={"h-[56px] w-[60px]"} alt={""} />
                    } else if (item.src && item.srcType === "component") {
                        return item.src
                    } else if (item.src && item.srcType === "medium") {
                        return <img src={item.src} className={"rounded-xl w-[332px] h-full"} alt={""} />
                    } else if (item.src && item.srcType === "custom") {
                        return <img src={item.src} className={`rounded-xl ${item.className}`} alt={""} />
                    }
                    return null
                })
                .map((item, index) => {
                    return (
                        <Container
                            key={index}
                            className={` 
                             comeInAnimation fadeInBottom ${
                                 modal ? "mb-[20px] " : "xl:mb-[12px] lg:mb-[6px] md:mb-[6px] sm:mb-[12px] xs:mb-[12px]"
                             }`}
                        >
                            {item}
                        </Container>
                    )
                })}
        </div>
    )
}
