import { ReactComponent as Logo } from "../../assets/svgs/error-logo.svg"
import { PrimaryText } from "../elements/primaryText.element"
import { AppEnsureAuth } from "./appEnsureAuth.component"

export const AppCrashScreen = () => {
    const handleRefresh = () => {
        window.location.reload()
    }

    const handleContactUs = () => {
        const emailSubject = encodeURIComponent("Uh-oh! Looks like something went wrong")
        window.location.href = `mailto:hello@amaizing.io?subject=${emailSubject}`
    }

    return (
        <AppEnsureAuth>
            <div className="h-screen flex justify-center bg-[linear-gradient(180deg,#F9FAFB_0%,#EDF0F3_100%)]">
                <div className="flex w-[720px] h-[360px] mt-[270px] flex-col items-center justify-center gap-1 rounded-[20px] border-2 border-[var(--Purple-600,#6938EF)] text-[var(--Gray-600,#475467)]">
                    <Logo aria-label="Error logo" />

                    <PrimaryText
                        weight="medium"
                        className="mt-[32px] text-[24px] text-gray-600 text-center leading-[32px]"
                    >
                        Uh-oh! Looks like something went wrong.
                    </PrimaryText>

                    <PrimaryText
                        weight="light"
                        size="sm-medium"
                        className="mt-4  text-[16px] font-light text-gray-600 "
                    >
                        <span onClick={handleRefresh} className="text-[#6938EF] font-[500] cursor-pointer">
                            Refresh Page
                        </span>
                        &nbsp;or&nbsp;
                        <span onClick={handleContactUs} className="text-[#6938EF] font-[500] cursor-pointer">
                            Contact Us
                        </span>
                        &nbsp;to report the issue
                    </PrimaryText>
                </div>
            </div>
        </AppEnsureAuth>
    )
}
