import { createBrowserRouter, Navigate, Outlet } from "react-router"

import { ForgotPassword } from "./components/auth/forgotPassword.component"
import { Login } from "./components/auth/login.component"
import { ResetPassword } from "./components/auth/resetPassword.component"
import { SignUp } from "./components/auth/signup.component"
import { PerformanceTracker } from "./components/dashboard/performance-tracker/performanceTracker.component"
import { ASINTrackerChatComponent } from "./components/marketing/asinTrackerChat.component"
import { EmailChatForm } from "./components/marketing/emailChat.component"
import { FreeTrailForm } from "./components/marketing/freeTrialChat.component"
import { SignupForm } from "./components/marketing/signupForm.component"
// import { HomeContent } from "./components/marketing/home.component"
import { AuthSignup } from "./components/auth/authSignup.component"
import { CheckEmail } from "./components/auth/checkEmail.component"
import { VerifiedEmail } from "./components/auth/verifiedEmail.component"
import { TrackpackCentral } from "./components/dashboard/trackpack-central/trackpackCentral.component"
import { JobRoleForm } from "./components/marketing/jobRoleForm.component"
import { ListingManageForm } from "./components/marketing/listingManageForm.component"
import { SearchCompetitorChat } from "./components/marketing/searchCompitatorChat.component"
import { TrackingChatForm } from "./components/marketing/trackingChatForm.component"
import PrivacyPolicy from "./components/privacyPolicy.component"
import { ROUTES_CONFIG } from "./config/routes.config"
import { DashboardContainer } from "./containers/dashboard.container"
import { GeneralContainer } from "./containers/general.container"
import { MarketingContainer } from "./containers/marketing.container"
// user account below
import { AppCrashScreen } from "./components/common/appCrashScreen.component"
import { AppEnsureAuth } from "./components/common/appEnsureAuth.component"
import ListingAnalyzerComponent from "./components/dashboard/listing-analyzer/listingAnalyzer.component"
import { ListingAnalyzerLayoutComponent } from "./components/dashboard/listing-analyzer/listingAnalyzerLayout.component"
import ReportTable from "./components/dashboard/listing-analyzer/reportTable.component"
import { SearchpackHubCentral } from "./components/dashboard/searchpack-hub/searchpackHubCentral.component"
import KeywordPerformance from "./components/dashboard/searchpack-tracker/keyword-performance/keywordPerformance.component"
import RankTracker from "./components/dashboard/searchpack-tracker/rank-tracker/rank-tracker.component"
import RankSnapshot from "./components/dashboard/searchpack-tracker/rankSnapshot.component"
import { SearchpackTracker } from "./components/dashboard/searchpack-tracker/searchpackTracker.component"
import { UserAccounts } from "./components/dashboard/user-accounts/userAccounts.component"
import { AlertSettings } from "./components/dashboard/user-settings/alert-settings/alertSettings.component"
import { UserSettings } from "./components/dashboard/user-settings/userSettings.component"

export const router = createBrowserRouter([
    {
        element: <Outlet />,
        ErrorBoundary: AppCrashScreen,
        hasErrorBoundary: true,
        children: [
            {
                path: "/",
                element: <Navigate to={ROUTES_CONFIG.welcome} replace />,
            },
            {
                path: ROUTES_CONFIG.marketing,
                element: <MarketingContainer />,
                children: [
                    // {
                    //     path: true,
                    //     element: <HomeContent />,
                    // },
                    {
                        index: true,
                        element: <FreeTrailForm />,
                    },
                    {
                        path: ROUTES_CONFIG.emailChat,
                        element: <EmailChatForm />,
                    },
                    {
                        path: ROUTES_CONFIG.trackingChatForm,
                        element: <TrackingChatForm />,
                    },
                    {
                        path: ROUTES_CONFIG.searchCompetitorChat,
                        element: <SearchCompetitorChat />,
                    },
                    {
                        path: ROUTES_CONFIG.asinTrackerChat,
                        element: <ASINTrackerChatComponent />,
                    },
                    {
                        path: ROUTES_CONFIG.listingManageForm,
                        element: <ListingManageForm />,
                    },
                    {
                        path: ROUTES_CONFIG.jobRoleForm,
                        element: <JobRoleForm />,
                    },
                    {
                        path: ROUTES_CONFIG.signup,
                        element: <SignUp />,
                    },
                    {
                        path: ROUTES_CONFIG.signUpForm,
                        element: <SignupForm />,
                    },
                ],
            },
            {
                path: ROUTES_CONFIG.privacyPolicy,
                element: <GeneralContainer />,
                children: [
                    {
                        index: true,
                        element: <PrivacyPolicy />,
                    },
                ],
            },
            {
                path: ROUTES_CONFIG.auth,
                element: <GeneralContainer />,

                children: [
                    {
                        path: ROUTES_CONFIG.login,
                        element: <Login />,
                    },
                    {
                        path: ROUTES_CONFIG.welcome,
                        element: <Login />,
                    },
                    {
                        path: ROUTES_CONFIG.forgotPassword,
                        element: <ForgotPassword />,
                    },
                    {
                        path: ROUTES_CONFIG.resetPassword,
                        element: <ResetPassword />,
                    },
                    {
                        path: ROUTES_CONFIG.authSignup,
                        element: <AuthSignup />,
                    },
                    {
                        path: ROUTES_CONFIG.verifiedEmail,
                        element: <VerifiedEmail />,
                    },
                ],
            },
            {
                path: ROUTES_CONFIG.dashboard,
                element: (
                    <AppEnsureAuth>
                        <DashboardContainer />
                    </AppEnsureAuth>
                ),
                children: [
                    {
                        index: true,
                        element: <PerformanceTracker />,
                    },
                    {
                        path: ROUTES_CONFIG.trackpackCentral,
                        element: <TrackpackCentral />,
                    },
                    {
                        path: ROUTES_CONFIG.userAccounts,
                        element: <UserAccounts />,
                    },
                    {
                        path: ROUTES_CONFIG.userSetting,
                        element: <UserSettings />,
                        children: [
                            {
                                path: ROUTES_CONFIG.userSettingAlerts,
                                element: <AlertSettings />,
                            },
                        ],
                    },
                    {
                        path: ROUTES_CONFIG.reports,
                        element: <ListingAnalyzerLayoutComponent />,
                        children: [
                            {
                                index: true,
                                element: <ListingAnalyzerComponent />,
                            },
                            {
                                path: ROUTES_CONFIG.reportSummary,
                                element: <ReportTable />,
                            },
                        ],
                    },
                    {
                        path: ROUTES_CONFIG.searchpackTracker,
                        element: <SearchpackTracker />,
                        children: [
                            {
                                index: true,
                                path: ROUTES_CONFIG.searchpackTrackerRankSnapshot,
                                element: <RankSnapshot infoVisible={false} />,
                            },
                            {
                                path: ROUTES_CONFIG.searchpackTrackerRankTracker,
                                element: <RankTracker infoVisible={false} />,
                            },
                            {
                                path: ROUTES_CONFIG.searchpackTrackerKeywordPerformance,
                                element: <KeywordPerformance infoVisible={false} />,
                            },
                        ],
                    },
                    {
                        path: ROUTES_CONFIG.searchpackhub,
                        element: <SearchpackHubCentral />,
                    },
                ],
            },
            {
                path: ROUTES_CONFIG.verifySignup,
                element: <GeneralContainer />,
                children: [
                    {
                        path: ROUTES_CONFIG.checkEmail,
                        element: <CheckEmail isRecoverPassword={false} />,
                    },
                ],
            },
            {
                path: ROUTES_CONFIG.verifyResetPassword,
                element: <GeneralContainer />,
                children: [
                    {
                        path: ROUTES_CONFIG.resetPasswordCheckEmail,
                        element: <CheckEmail isRecoverPassword={true} />,
                    },
                ],
            },
        ],
    },
])
