export const AlertArrowIconSvg = (props: IArrowProps) => {
    return (
        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 3.99967H10C11.6569 3.99967 13 5.34282 13 6.99967C13 8.65653 11.6569 9.99967 10 9.99967H7M1 3.99967L3.66667 1.33301M1 3.99967L3.66667 6.66634"
                stroke="#7A5AF8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
