import { memo, useEffect, useRef, useState } from "react"
import { DayPicker, getDefaultClassNames } from "react-day-picker"
import ReactDOMServer from "react-dom/server"
import { Control, Controller } from "react-hook-form"
import { CalendarChevrondownIcon } from "../../assets/svgs/calendarChevrondownIcon.svg"
import { CalendarIcon } from "../../assets/svgs/calendarIcon.svg"
import { LeftChevron } from "../../assets/svgs/leftChevron.svg"
import { RightChevron } from "../../assets/svgs/rightChevron.svg"
import { extendedDayjs } from "../../utils/dayjs"
import CommonTooltip from "../dashboard/tooltipItems/commonTooltip.component"

// ---------------------------------------------------------------------------
// 1. Create a custom Navbar component for the DayPicker
//    This component displays the left arrow, the month label in the center,
//    and the right arrow.

interface CustomNavbarProps {
    nextMonth?: Date
    previousMonth?: Date
    onPreviousClick?: React.MouseEventHandler<HTMLButtonElement>
    onNextClick?: React.MouseEventHandler<HTMLButtonElement>
    className?: string
}

const CustomNavbar = ({ nextMonth, previousMonth, onPreviousClick, onNextClick, className }: CustomNavbarProps) => {
    // Compute the current month from the provided props.
    // If previousMonth is provided, the current month is the month after it.
    // Otherwise, if nextMonth is provided, subtract one month.

    const currentMonth = previousMonth
        ? extendedDayjs(previousMonth).add(1, "month")
        : nextMonth
        ? extendedDayjs(nextMonth).subtract(1, "month")
        : extendedDayjs()

    return (
        <div className={`flex justify-between items-center w-full mb-[8px] h-[36px] ${className}`}>
            {/* Previous Button */}
            <button
                onClick={onPreviousClick} // Calls the DayPicker's onPreviousClick handler
                disabled={!previousMonth} // Disable if there's no previous month
                className="disabled:opacity-50 disabled:cursor-not-allowed"
            >
                <LeftChevron color={previousMonth ? "#344054" : "#D1D5DB"} />
            </button>

            {/* Current Month Display */}
            <span className="text-[16px] font-[500] leading-[24px] text-gray-700 capitalize">
                {currentMonth.format("MMMM YYYY").toLowerCase()}
            </span>

            {/* Next Button */}
            <button
                onClick={onNextClick} // Calls the DayPicker's onNextClick handler
                disabled={!nextMonth} // Disable if there's no next month
                className="disabled:opacity-50 disabled:cursor-not-allowed"
            >
                <RightChevron color={nextMonth ? "#344054" : "#D1D5DB"} />
            </button>
        </div>
    )
}

interface DatePickerProps {
    className?: string
    selectedSearchpackLastDataTime?: ISearchpackLastDataTime
    onDateChange?: (date: Date) => void
    name: string
    control?: Control
    isDisabled?: boolean
    defaultDate?: Date
}

const DatePicker = ({
    control,
    name,
    selectedSearchpackLastDataTime,
    onDateChange,
    isDisabled,
    defaultDate,
}: DatePickerProps) => {
    const defaultClassNames = getDefaultClassNames()
    const calendarRef = useRef<HTMLDivElement>(null) // Ref to track the calendar wrapper
    const [show, setShow] = useState(false)
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(defaultDate)

    const [currentMonth, setCurrentMonth] = useState<Date>() // Track the visible month
    const [showTooltip, setShowTooltip] = useState(false)

    const createdAt = extendedDayjs(selectedSearchpackLastDataTime?.created_at)
        .utc()
        .startOf("day")
        .toDate()
    const searchDataLastAvailableAt = extendedDayjs(selectedSearchpackLastDataTime?.search_data_last_available_at)
        .utc()
        .startOf("day")
        .toDate()

    useEffect(() => {
        if (!show) setShowTooltip(false)
    }, [show])

    // Updated due to Warning

    useEffect(() => {
        // Set the default month only on component mount
        setCurrentMonth(selectedDate || searchDataLastAvailableAt || extendedDayjs().toDate())
    }, []) // Empty dependency array for initialization

    useEffect(() => {
        // Update current month only when date selection changes
        if (selectedDate) {
            setCurrentMonth(selectedDate)
        }
    }, [selectedDate])

    const handleClose = () => {
        setShow(false)
        setShowTooltip(false)
    }

    const handleInputClick = () => {
        setShow(!show) // Show or hide the calendar
    }

    const handleDateChange = (date: Date) => {
        setSelectedDate(date)
        setCurrentMonth(date) // Update the visible month to the selected date
        onDateChange?.(date)
        handleClose()
    }

    const disableDate = (date: Date) => {
        const createdAtDayjs = extendedDayjs(createdAt)
        const searchDataLastAvailableAtDayjs = extendedDayjs(searchDataLastAvailableAt)
        return (
            extendedDayjs(date).isBefore(createdAtDayjs, "day") ||
            extendedDayjs(date).isAfter(searchDataLastAvailableAtDayjs, "day")
        )
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
                setShow(false) // Close the calendar if the click is outside
            }
        }

        if (show) {
            document.addEventListener("mousedown", handleClickOutside)
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [show])

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value }, fieldState: { error } }: IIndexable) => (
                <>
                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[89px]"}
                        data-tooltip-html={
                            !show && showTooltip
                                ? ReactDOMServer.renderToStaticMarkup(
                                      <CommonTooltip className="" label="Select Date" />
                                  )
                                : undefined
                        }
                        onMouseEnter={() => !show && setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        className="flex items-center justify-between border border-gray-200 px-[12px] py-2 w-[169px] h-[36px] relative bg-white rounded-[10px]"
                    >
                        <div className="flex items-center gap-2 w-full">
                            <div
                                className={`cursor-pointer`}
                                onClick={() => {
                                    setShow(!show)
                                }}
                            >
                                <CalendarIcon color={isDisabled ? "#d0d5dd" : "#344054"} />
                            </div>
                            <input
                                type="text"
                                value={
                                    selectedDate
                                        ? extendedDayjs(selectedDate).format("MMM-DD-YYYY").toLocaleUpperCase()
                                        : searchDataLastAvailableAt
                                        ? extendedDayjs(searchDataLastAvailableAt)
                                              .format("MMM-DD-YYYY")
                                              .toLocaleUpperCase()
                                        : ""
                                }
                                onClick={handleInputClick}
                                readOnly
                                className={`outline-none w-full text-[14px] !font-[300] leading-[20px] pt-[3px] uppercase cursor-pointer 
                                    ${
                                        isDisabled
                                            ? "cursor-not-allowed pointer-events-none !text-gray-200"
                                            : !selectedDate && !searchDataLastAvailableAt
                                            ? "text-gray-200"
                                            : "text-gray-700"
                                    }`}
                                placeholder="Select a date"
                            />
                            <div
                                className={`cursor-pointer`}
                                onClick={() => {
                                    setShow(!show)
                                }}
                            >
                                <CalendarChevrondownIcon color={isDisabled ? "#d0d5dd" : "#344054"} />
                            </div>
                        </div>
                    </div>

                    {/* calendar */}
                    <div>
                        {show && (
                            <div
                                ref={calendarRef}
                                className="absolute top-[40px] left-0 z-50 bg-white rounded-[10px] border border-gray-200 px-[8px] pt-[8px] pb-[12px]"
                                style={{
                                    boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
                                }}
                            >
                                <DayPicker
                                    mode="single"
                                    showOutsideDays
                                    selected={selectedDate || searchDataLastAvailableAt || undefined}
                                    month={currentMonth} // Ensure the calendar opens on the correct month
                                    onMonthChange={(newMonth) => {
                                        setCurrentMonth(newMonth) // Update visible month
                                    }}
                                    onSelect={(date) => {
                                        if (date) handleDateChange(date) // Ensure date is not undefined
                                    }}
                                    disabled={disableDate}
                                    components={{
                                        Nav: CustomNavbar,
                                        CaptionLabel: () => <></>,
                                    }}
                                    classNames={{
                                        day: "text-gray-700 !text-[14px] !font-[400] hover:bg-gray-100 hover:text-gray-700 rounded-full",
                                        day_disabled: "!text-gray-300 cursor-not-allowed",
                                        today: " text-gray-700",
                                        selected:
                                            "bg-[#CFF9FE] hover:!bg-[#CFF9FE]  border border-transparent rounded-full !text-[#0E7090]",
                                        root: `${defaultClassNames.root}`,
                                        chevron: `${defaultClassNames.chevron} fill-amber-500`,
                                        outside: "text-gray-700",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        />
    )
}

export default memo(DatePicker)
