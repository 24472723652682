import { Outlet, useLocation, useNavigate } from "react-router"

import { useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { AuthActionCreator } from "../actions/auth.action"
import { NotificationsActionsCreator } from "../actions/notifications.action"
import { ProductsActionCreator } from "../actions/products.action"
import { DashboardHeader } from "../components/dashboard/dashboardHeader.component"
import AddAsinToTrackpackFlowMain from "../components/dashboard/modals/addAsinToTrackpackFlowModal/addAsinToTrackpackFlowMain.component"
import AddAsinToTrackpackMainModal from "../components/dashboard/modals/addAsinTotrackpackModals/addAsinToTrackpackMainModal.component"
import AddKeywordMainModal from "../components/dashboard/modals/addKeywordsModals/addKeywordsMainModal.component"
import AddSearchpackMainModal from "../components/dashboard/modals/addSearchpackModals/addSearchpackMainModal.component"
import AddTrackPackMain from "../components/dashboard/modals/addTrackPackModalSteps/addTrackPackMainModal"
import AdditionalTrackPackMain from "../components/dashboard/modals/additionalTrackPackModals/additionalTrackPackMain.component"
import ArchiveMainModal from "../components/dashboard/modals/archiveModals/archiveMainModal.component"
import ArchiveRestoreSearchpackMainModal from "../components/dashboard/modals/archiveRestoreSearchpackModals/archiveRestoreSearchpackMainModal.component"
import ClaimOwnershipMainModal from "../components/dashboard/modals/claimOwnershipModals/claimOwnershipMainModal.component"
import FlowSelectionMain from "../components/dashboard/modals/flowSelectionModal/flowSelectionMain.component"
import ListingAnalyzerModalMain from "../components/dashboard/modals/lisitingAnalyzerModals/lisitingAnalyzerFlowModal.component"
import PerformanceTrackerQuickTourModalComponent from "../components/dashboard/modals/quickTourModals/performanceTrackerQuickTour/index.component"
import TrackpackHubQuickTourModalComponent from "../components/dashboard/modals/quickTourModals/trackpackHubQuickTour/index.component"
import SemiAutomatedAddTrackPackMain from "../components/dashboard/modals/semiAutomatedAddTrackpackModals/semiAutomatedFlow.component"
import NotificationAlerComponent from "../components/dashboard/notfitication-alerts/index.component"
import { SideNavigation } from "../components/dashboard/sideNavigation.component"
import { ROUTES_CONFIG } from "../config/routes.config"
import { checkArchivedStatusAndNavigate } from "../helpers/util.helper"
import { AccountStateSelector } from "../selectors/accountState.selector"
import { ProductStateSelector } from "../selectors/product.selector"
import { urlUtils } from "../utils/url"

export const DashboardContainer = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { userActionData } = useSelector(AccountStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)
    const { pathname, search } = location
    const params = new URLSearchParams(search)
    const asins = params.get("asins")
    const id = params.get("id")
    const [isAuthChecked, setIsAuthChecked] = useState(false)
    // @ts-ignore : DO NOT REMOVE
    const { t } = useTranslation("tooltip")

    const notifyError = (text: string) => toast.error(text)
    const dispatch = useDispatch()

    const gettrackpackList = useCallback(() => {
        if (userActionData !== undefined) {
            dispatch(
                ProductsActionCreator.getTrackpackList(
                    {},
                    { as_user: userActionData && userActionData?.id + "", status: "active" }
                )
            )
        } else {
            dispatch(ProductsActionCreator.getTrackpackList({}, { status: "active" }))
        }
    }, [dispatch, userActionData])

    const getFirstTrackpackDataa = useCallback(() => {
        const options: { as_user?: string } = {}

        if (userActionData) {
            options.as_user = userActionData.id + ""
        }

        if (userActionData !== undefined) {
            dispatch(ProductsActionCreator.getFirstTrackpackData({}, options))
        } else {
            dispatch(ProductsActionCreator.getFirstTrackpackData({}, options))
        }
    }, [dispatch, userActionData])

    useEffect(() => {
        const TEN_MINUTES = 10 * 60 * 1000
        const lastMeCall = localStorage.getItem("lastMeCallTimestamp")
        const now = Date.now()

        if (!lastMeCall || now - parseInt(lastMeCall, 10) > TEN_MINUTES) {
            dispatch(
                AuthActionCreator.getAccessLevel(
                    () => {
                        localStorage.setItem("lastMeCallTimestamp", now.toString())
                        setIsAuthChecked(true)
                    },
                    (res: { message: string }) => {
                        notifyError(res.message)
                        setIsAuthChecked(true)
                    }
                )
            )
        } else {
            setIsAuthChecked(true)
        }
    }, [isAuthChecked, dispatch])

    useEffect(() => {
        if (!isAuthChecked) return
        if (userActionData !== undefined) {
            dispatch(
                NotificationsActionsCreator.getAllNotifications(
                    {},
                    { as_user: userActionData && userActionData?.id + "" }
                )
            )
        } else dispatch(NotificationsActionsCreator.getAllNotifications())
    }, [userActionData, isAuthChecked, dispatch])

    useEffect(() => {
        if (!isAuthChecked) return

        gettrackpackList()
        if (id) {
            const options: { as_user?: string; only_active_trackings: boolean } = {
                only_active_trackings: true,
            }

            if (userActionData) {
                options.as_user = userActionData.id + ""
            }
            dispatch(ProductsActionCreator.getExtendedTrackingData(String(id), {}, options))
        } else {
            getFirstTrackpackDataa()
        }
    }, [isAuthChecked, dispatch, gettrackpackList, getFirstTrackpackDataa, id])

    useEffect(() => {
        if (!isAuthChecked) return

        const asinsArray = asins?.split(",")

        const isArchived = checkArchivedStatusAndNavigate(
            selectedDropdownList && selectedDropdownList?.trackpacks,
            String(id),
            asinsArray!
        )
        if (isArchived) {
            navigate(urlUtils.formURL(ROUTES_CONFIG.trackpackCentral, { tp_id: `${id || ""}`, filter: "archived" }), {
                replace: true,
            })
        } else {
            if (id) {
                if (asins) {
                    dispatch(
                        NotificationsActionsCreator.notifiSelection({
                            notifiAsin: asinsArray,
                            selectedTrackpackID: id,
                        })
                    )
                    dispatch(
                        ProductsActionCreator.setSelectedTrackPackValues({
                            selectedTrackpackID: String(id),
                            defualtSelectedAsin: asinsArray,
                        })
                    )
                }

                if (pathname) {
                    navigate(urlUtils.formURL(ROUTES_CONFIG.dashboard, { tp_id: `${id || ""}`, filter: "tracking" }), {
                        replace: true,
                    })
                }
            }
        }
    }, [location, dispatch, navigate, selectedDropdownList, isAuthChecked, asins, id, pathname])

    return (
        <div className="bg-userAccount-custom-gradient pt-[32px] pr-[28px] pb-[24px] pl-[24px] ">
            <DashboardHeader />
            <div className="flex flex-row items-start gap-[20px] w-full " style={{ height: "calc(100vh - 165px)" }}>
                <SideNavigation />

                {/* <div className="bg-white rounded-tl-2xl rounded-bl-2xl flex-1 h-full"> */}
                <div className="h-full" style={{ width: "calc(100% - 80px)" }}>
                    <Outlet />
                </div>
            </div>
            <AddTrackPackMain />
            <AdditionalTrackPackMain />
            <ArchiveMainModal />
            <PerformanceTrackerQuickTourModalComponent />
            <TrackpackHubQuickTourModalComponent />
            <NotificationAlerComponent />
            <FlowSelectionMain />
            <SemiAutomatedAddTrackPackMain />
            <ListingAnalyzerModalMain />
            <AddSearchpackMainModal />
            <AddKeywordMainModal />
            <AddAsinToTrackpackFlowMain />
            <AddAsinToTrackpackMainModal />
            <ArchiveRestoreSearchpackMainModal />
            <ClaimOwnershipMainModal />
        </div>
    )
}
