export const VideoIcons = (props: IArrowProps) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.66602 7.29837C6.66602 6.09293 6.66602 5.49022 6.95127 5.15797C7.19977 4.86853 7.57961 4.68942 7.99078 4.66779C8.46276 4.64296 9.03192 4.97729 10.1703 5.64594L24.9838 14.3474C25.9244 14.8999 26.3947 15.1762 26.5586 15.5244C26.7018 15.8288 26.7018 16.1709 26.5586 16.4753C26.3947 16.8235 25.9244 17.0998 24.9838 17.6523L10.1702 26.3537C9.03192 27.0224 8.46276 27.3567 7.99078 27.3319C7.57961 27.3103 7.19977 27.1311 6.95127 26.8417C6.66602 26.5095 6.66602 25.9067 6.66602 24.7013V7.29837Z"
                stroke={props.color ? props.color : "#0E7090"}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
