import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { ListingAnalyzerActionCreator } from "../../../../actions/listingAnalyzer.action"
import { PaginationLeftArrow } from "../../../../assets/svgs/paginationLeftArrow.svg"
import { PaginationRightArrow } from "../../../../assets/svgs/paginationRightArrow.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { img_url } from "../../../../config/dashboard.config"
import StepNavigator from "../../../common/stepsNavigator.component"
import { PrimaryText } from "../../../elements/primaryText.element"

const ListingReportModalStepFourView: React.FC<ReportModalFlowProps> = ({
    asinHeaderText,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    isPrevButton,
    handlePrev,
    totalSteps,
    currentStep,
    asinHeaderDescription,
    previousStep,
    questions,
    currentStepData,
    updateStepData,
    mainImageFilenames,
}) => {
    const { handleSubmit, setValue } = useForm({
        defaultValues: currentStepData,
    })
    const [selectedScore, setSelectedScore] = useState<number | null>(currentStepData?.score || null)
    const [reason, setReason] = useState<string>(currentStepData?.reason || "")
    const [suggestion, setSuggestion] = useState<string>(currentStepData?.suggestion || "")
    const dispatch = useDispatch()
    // const { reportDataByID, reportPayload } = useSelector(ListingAnalyzerStateSelector)

    const onSubmit = () => {
        const hasChanges =
            selectedScore !== currentStepData?.score ||
            reason !== currentStepData?.reason ||
            suggestion !== currentStepData?.suggestion

        if (hasChanges && selectedScore) {
            dispatch(
                ListingAnalyzerActionCreator.submitQuestionResponse(
                    {
                        score: selectedScore,
                        reason: reason,
                        suggestion: suggestion,
                        reportID: questions?.reportID,
                        reportQuestionID: questions?.report_question_id,
                    },
                    () => {
                        updateStepData({ score: selectedScore, reason, suggestion })
                        handleNext && handleNext()
                    },
                    (res: { message: string }) => {
                        console.error("Error submitting response", res.message)
                    }
                )
            )
        } else if (selectedScore) {
            handleNext && handleNext()
        }
    }

    const handleStepClick = (score: number) => {
        setSelectedScore(score)
        setValue("score", score)
    }

    const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const updatedReason = e.target.value
        setReason(updatedReason)
    }

    const handleSuggestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const updatedSuggestion = e.target.value
        setSuggestion(updatedSuggestion)
    }

    const { score_label_low, score_label_high } = questions

    return (
        <>
            <div className="flex justify-between">
                <div className=" rounded-[12px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <>
                    <div
                        className={`grid grid-cols-3 gap-[16px] mt-[36px] bg-gray-100 border border-gray-200 p-[28px] rounded-[16px] `}
                        // style={{ maxHeight: "288px" }}
                    >
                        {mainImageFilenames.map((image: string, index: number) => (
                            <div
                                className={`rounded-[10px] w-[200px] h-[200px] bg-white flex items-center justify-center overflow-hidden `}
                                style={{
                                    outline: index === 0 ? " 2px solid #088AB2" : "1px solid #EAECF0 ",
                                    boxShadow:
                                        index === 0 ? "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814" : "",
                                }}
                            >
                                <img
                                    src={`${img_url}${image}`}
                                    alt={`Main ${image}`}
                                    className="object-contain bg-white overflow-hidden w-full h-full rounded-[9px]"
                                />
                            </div>
                        ))}
                    </div>
                </>
                <PrimaryText
                    size="sm-medium"
                    weight="light"
                    className="text-gray-600 justify-center flex mt-[36px] text-center max-w-[685px] w-full mx-auto"
                >
                    {questions?.score_prompt}
                </PrimaryText>

                <div className="flex items-center max-w-[516px] w-full mx-auto mt-[20px] mb-[82px]">
                    {[1, 2, 3, 4, 5].map((val, index) => (
                        <React.Fragment key={index}>
                            <div className="flex flex-col items-center w-[60px] relative">
                                <div
                                    className={`w-[28px] h-[28px] pt-[2px] text-sm font-[400] flex justify-center items-center rounded-full border cursor-pointer ${
                                        selectedScore === val
                                            ? "border-[#06AED4] text-[#06AED4] bg-[#CFF9FE]"
                                            : "border-gray-500 text-gray-500 bg-white"
                                    }`}
                                    onClick={() => handleStepClick(val)}
                                >
                                    {val}
                                </div>
                                {index === 0 && (
                                    <span className="text-sm font-[400] text-gray-500 w-[40px] text-center absolute bottom-[-48px]">
                                        {score_label_low}
                                    </span>
                                )}
                                {index === 4 && (
                                    <span className="text-sm font-[400] text-gray-500 w-[40px] text-center absolute bottom-[-48px]">
                                        {score_label_high}
                                    </span>
                                )}
                            </div>
                            {/* Line */}
                            {index !== 4 && <div className="h-px bg-[#22CCEE] w-[54px]"></div>}
                        </React.Fragment>
                    ))}
                </div>

                <div className="flex space-x-[12px]">
                    <div className="flex-1 bg-gray-100 rounded-[12px] border border-gray-200 p-[12px] relative">
                        <PrimaryText weight="book" size="xs" className="text-[#088AB2] mb-[4px] line-clamp-1">
                            {questions?.reason_prompt}
                        </PrimaryText>
                        <textarea
                            value={reason}
                            onChange={handleReasonChange}
                            className="w-full border-none resize-none focus:outline-none focus:ring-0 focus:none bg-transparent text-sm text-gray-500 placeholder:text-[16px] placeholder:font-light placeholder:italic placeholder:text-gray-400"
                            placeholder="Type something..."
                        />
                    </div>

                    <div className="flex-1 bg-gray-100 rounded-[12px] border border-gray-200 p-[12px] relative">
                        <PrimaryText weight="book" size="xs" className="text-[#088AB2] mb-[4px] line-clamp-1">
                            {questions?.suggestion_prompt}
                        </PrimaryText>
                        <textarea
                            value={suggestion}
                            onChange={handleSuggestionChange}
                            className="w-full border-none resize-none focus:outline-none focus:ring-0 focus:none bg-transparent text-sm text-gray-500 placeholder:text-[16px] placeholder:font-light placeholder:italic placeholder:text-gray-400"
                            placeholder="Type something..."
                        />
                    </div>
                </div>

                <div className=" flex justify-center gap-[12px] items-center mt-[36px]">
                    <button disabled={true} className="flex items-center">
                        <PrimaryText
                            onClick={handlePrev}
                            className={`bg-[#ECFDFF] border border-[#67E3F9] rounded-[6px] p-[5px] cursor-not-allowed`}
                        >
                            <PaginationLeftArrow stroke={"#06AED4"} />
                        </PrimaryText>
                    </button>

                    <button type="submit" className="flex items-center">
                        <PrimaryText
                            className={` ${
                                selectedScore ? "bg-[#A5F0FC] border border-transparent" : "bg-gray-100"
                            }  rounded-[6px] p-[5px] cursor-pointer`}
                        >
                            <PaginationRightArrow stroke={selectedScore ? "#0E7090" : "#D0D5DD"} />
                        </PrimaryText>
                    </button>
                </div>
            </form>
            <div className="mt-10">
                <StepNavigator
                    currentStep={currentStep}
                    previousStep={previousStep}
                    totalSteps={totalSteps}
                    strokeColor="#667085"
                />
            </div>
        </>
    )
}

export default ListingReportModalStepFourView
