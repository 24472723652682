import { useMemo } from "react"
import { useSelector } from "react-redux"
import { RunTimeStateSelector } from "../selectors/RunTimeStateSelector"

export function useLoadingStatus(loading: Array<Record<string, boolean>>, additionalCondition: boolean): boolean {
    return useMemo(() => {
        const isAnyLoading = loading.some((item) => Object.values(item)[0])
        return additionalCondition || isAnyLoading
    }, [loading, additionalCondition])
}

export function useLoadingState(loadingState: () => boolean): boolean {
    const { loading } = useSelector(RunTimeStateSelector)

    return useMemo(() => loadingState(), [loading])
}
