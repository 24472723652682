export const AddToTrackPackIcon = (props: IArrowProps) => {
    return (
        <>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_18979_491446)">
                    <path
                        d="M8.66683 4.69596L7.92314 3.20857C7.7091 2.7805 7.60207 2.56645 7.44241 2.41007C7.30122 2.27178 7.13105 2.16661 6.94421 2.10217C6.73294 2.0293 6.49363 2.0293 6.01502 2.0293H3.46683C2.72009 2.0293 2.34672 2.0293 2.06151 2.17462C1.81063 2.30245 1.60665 2.50643 1.47882 2.75731C1.3335 3.04253 1.3335 3.41589 1.3335 4.16263V4.69596M1.3335 4.69596H11.4668C12.5869 4.69596 13.147 4.69596 13.5748 4.91395C13.9511 5.1057 14.2571 5.41166 14.4488 5.78798C14.6668 6.21581 14.6668 6.77586 14.6668 7.89596V10.8293C14.6668 11.9494 14.6668 12.5095 14.4488 12.9373C14.2571 13.3136 13.9511 13.6196 13.5748 13.8113C13.147 14.0293 12.5869 14.0293 11.4668 14.0293H4.5335C3.41339 14.0293 2.85334 14.0293 2.42552 13.8113C2.04919 13.6196 1.74323 13.3136 1.55148 12.9373C1.3335 12.5095 1.3335 11.9494 1.3335 10.8293V4.69596ZM8.00016 11.3626V7.36263M6.00016 9.36263H10.0002"
                        stroke="#98A2B3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_18979_491446">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.0292969)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
