import { SubmitHandler, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { Link, useLocation, useNavigate } from "react-router"

import { useDispatch, useSelector } from "react-redux"
import { AuthActionCreator } from "../../actions/auth.action"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { RuntimeHelper } from "../../helpers/runtime.helper"
import { useInputValidations } from "../../hooks/inputValidations.hook"
import { useLoadingStatus } from "../../hooks/useLoadingStatus.hook"
import { RunTimeStateSelector } from "../../selectors/RunTimeStateSelector"
import { FrontBubbleIcon } from "../common/frontBubble.component"
import { AuthInputElement } from "../elements/authInput.element copy"
import { ButtonElement } from "../elements/button.element"
import { Container } from "../elements/container.element"
import { PrimaryText } from "../elements/primaryText.element"

export const Login = () => {
    const { control, handleSubmit, setError } = useForm<ILoginInputProps>()
    const { loading } = useSelector(RunTimeStateSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const validationConfig = useInputValidations()

    const isLoading: boolean = useLoadingStatus(loading, RuntimeHelper.isObtainToken())
    const notifyError = (text: string) => toast.error(text)
    let location = useLocation()

    const onSubmit: SubmitHandler<ILoginInputProps> = (data, event?: React.BaseSyntheticEvent) => {
        event?.preventDefault()

        const payload: { username: string; password: string } = {
            username: data.email as string,
            password: data.password as string,
        }
        dispatch(
            AuthActionCreator.getToken(
                payload,
                () => {
                    navigate("/dashboard")
                },
                (res: { message: string }) => {
                    setError("password", { type: "manual", message: "Password does not match - Please try again" })
                    notifyError(res.message)
                }
            )
        )
    }

    return (
        <div className="w-[400px] bg-[#ECFDFF] p-[40px] pb-[32px] m-auto rounded-[16px] mt-[96px] custom-shadow">
            <div className="flex justify-center items-center">
                <FrontBubbleIcon size="large" />
            </div>
            <div className="mt-[24px] flex justify-center mb-[8px]">
                {location.pathname === "/auth/login" ? (
                    <PrimaryText typography="3xl" className="leading-[38px] text-gray-900">
                        Log in
                    </PrimaryText>
                ) : (
                    <PrimaryText typography="3xl" className="leading-[38px] text-gray-900">
                        Welcome
                    </PrimaryText>
                )}
            </div>
            <div className="flex justify-center items-center">
                {location.pathname === "/auth/login" ? (
                    <PrimaryText size="md" className="text-gray-600">
                        Welcome! Enter your details to get started
                    </PrimaryText>
                ) : (
                    <PrimaryText size="md" className="text-gray-600">
                        Please enter your details to log in.
                    </PrimaryText>
                )}
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-[24px]">
                <Container>
                    <AuthInputElement
                        name="email"
                        type="text"
                        reactHookControl={control}
                        reactHookValidations={{
                            required: validationConfig.email.message,
                            // pattern: validationConfig.email,
                        }}
                        placeholder="Email"
                        className={"mb-[20px]"}
                    />

                    <AuthInputElement
                        name="password"
                        type="password"
                        defaultValue=""
                        reactHookControl={control}
                        reactHookValidations={{ required: validationConfig.required }}
                        placeholder="••••••••"
                        // postfix={<PasswordIcon />}
                        className={"mb-[24px]"}
                    />
                    <div className=" my-[24px]">
                        <Link to={ROUTES_CONFIG.forgotPassword} viewTransition>
                            <PrimaryText weight="book" size="small" className="text-[#06AED4]">
                                Forgot password
                            </PrimaryText>
                        </Link>
                    </div>
                    <ButtonElement
                        textClass="text-[16px]"
                        type="submit"
                        loading={isLoading}
                        disabled={isLoading}
                        viewType={isLoading ? "loading-button" : "primary"}
                        size="medium"
                        className={
                            "!w-full mb-[24px] bg-gradient-to-br from-[#22CCEE] to-[#06AED4] rounded-[8px] !h-[44px] text-white text-[16px]"
                        }
                    >
                        Log in
                    </ButtonElement>
                </Container>
                <div className="flex justify-center items-center gap-[4px] h-[20px]">
                    <PrimaryText size="small" weight="light">
                        Don’t have an account?
                    </PrimaryText>
                    <Link to={ROUTES_CONFIG.authSignup} viewTransition>
                        <ButtonElement
                            viewType="only-text"
                            size="medium"
                            textClass="chrome:mb-[4px] safari:mb-[4px]"
                            className=""
                        >
                            Sign up
                        </ButtonElement>
                    </Link>
                </div>
            </form>
        </div>
    )
}
