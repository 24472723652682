import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"

import { OnboardingUserActionCreators } from "../../actions/onBoardingUser.action"
import { JOB_ROLES, competitorAsins } from "../../config/onBoarding.config"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { useInputValidations } from "../../hooks/inputValidations.hook"
import { OnboardingUserStateSelector } from "../../selectors/onBoardingState.selector"
import { BubbleListToTop, BubblesList } from "../elements/bubblesList.element"
import { ButtonElement } from "../elements/button.element"
import { Container } from "../elements/container.element"
import { InputElement } from "../elements/input.element"
import { MarketingFooter } from "./marketingFooter.component"

import { Transition } from "@headlessui/react"
import { RuntimeHelper } from "../../helpers/runtime.helper"
import { UtilHelper } from "../../helpers/util.helper"
import { useLoadingStatus } from "../../hooks/useLoadingStatus.hook"
import { RunTimeStateSelector } from "../../selectors/RunTimeStateSelector"
import { MarketingHeader } from "./marketingHeader.component"

export const SearchCompetitorChat = () => {
    const { t } = useTranslation("marketing", {
        keyPrefix: "searchCompetitor.conversation",
    })
    const validationConfig = useInputValidations()

    const userOnBoard = useSelector(OnboardingUserStateSelector)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [completed, setCompleted] = useState(false)
    // const [isLoading, setIsLoading] = useState(false)
    const { loading } = useSelector(RunTimeStateSelector)

    const isLoading: boolean = useLoadingStatus(loading, RuntimeHelper.isAlphaUserRegister())

    const notifyError = (text: string) => toast.error(text)

    const { control, handleSubmit } = useForm<ISearchCompitatorCharFormInputs>({
        defaultValues: {
            competitorAsin: userOnBoard?.searchedCompitator?.competitorAsin
                ? Object.values(userOnBoard.searchedCompitator.competitorAsin).join(",")
                : "",
        },
    })
    const onSubmit: SubmitHandler<ISearchCompitatorCharFormInputs> = (data) => {
        if (userOnBoard) {
            const jobRoles = JOB_ROLES.filter((jobRole) => jobRole.type !== "jobRole5")
                .filter((jobRole) => userOnBoard?.selectedJobRoles?.includes(jobRole.type))
                .map((jobRole) => jobRole.label)

            //check if other job role is selected also remove other from job roles
            const temp = JOB_ROLES.find((jobRole) => jobRole.type === "jobRole5")
            if (userOnBoard?.selectedJobRoles?.includes("jobRole5") && temp && userOnBoard?.customJobRole) {
                jobRoles.filter((el) => el !== "Other")
                jobRoles.push(userOnBoard?.customJobRole)
            }

            const payload: Partial<AlphaUser> = {
                email: userOnBoard.email,
                name: userOnBoard.name,
                job_role_list: jobRoles,
                utm_source: sessionStorage.getItem("utm_source") || "",
                managed_listings: userOnBoard.listingsManage ? parseInt(userOnBoard.listingsManage) : 0,
                tracks_competitors: userOnBoard.istrackCompetitor === "Yes",
                competitors_tracked: userOnBoard.listingsTrack ? parseInt(userOnBoard.listingsTrack) : 0,
                main_asin: userOnBoard.asinNumber,
                owns_main_asin: userOnBoard.asinBelonging === "Yes",
                amazon_tld: userOnBoard?.amazonTld,
                competitor_asin_list: data.competitorAsin
                    ? UtilHelper.removeSpacesFromStringArray(data.competitorAsin?.split(","))
                    : [],
            }
            dispatch(
                OnboardingUserActionCreators.alphaUserRegister(
                    payload,
                    () => {
                        navigate(ROUTES_CONFIG.asinTrackerChat)
                    },
                    (res: { message: string }) => {
                        notifyError(res.message)
                    }
                )
            )
        }
    }

    return (
        <>
            <div className="xl:pt-[28px] lg:pt-[28px] md:pt-[20px] sm:pt-[20px] xs:pt-[20px]">
                <div className="flex justify-center 2xl:gap-[16px] xl:gap-[16px] lg:gap-[16px] md:gap-[16px] sm:gap-[0px] xs:gap-[0px]">
                    <MarketingHeader />

                    <div
                        className="flex flex-col justify-start items-start hidden-scrollbar overflow-y-auto md:pr-[20px] lg:pr-[20px] w-[504px] md:h-md-calc-onboarding lg:h-lg-calc-onboarding xl:h-xl-calc-onboarding"
                        // style={{ height: "calc(100vh - 204px)" }}
                    >
                        <div className={""}>
                            <BubbleListToTop>
                                <BubblesList
                                    interval={500}
                                    maxSleep={500}
                                    onFinish={() => {
                                        setCompleted(true)
                                    }}
                                    conversationItems={[
                                        {
                                            text: t("1"),
                                        },
                                        {
                                            text: t("2"),
                                            className: "xs:w-[282px] md:w-[408px]",
                                        },
                                        {
                                            text: t("3"),
                                            className: "xs:w-[292px] md:w-[328px]",
                                        },
                                    ]}
                                />
                            </BubbleListToTop>
                        </div>

                        <Transition
                            show={completed}
                            enter="transition ease-out duration-300 transform"
                            enterFrom="translate-y-full opacity-0"
                            enterTo="translate-y-0 opacity-100"
                            leave="transition ease-in duration-200 transform"
                            leaveFrom="translate-y-0 opacity-100"
                            leaveTo="translate-y-full opacity-0"
                        >
                            <div className="flex-1 xl:min-w-[375px] lg:min-w-[375px] md:min-w-[375px] sm:min-w-[320px] xs:min-w-[320px] sm:max-w-[320px] xs:max-w-[320px]">
                                <form onSubmit={handleSubmit(onSubmit)} className={"w-full"}>
                                    <Container>
                                        {competitorAsins.map((asins, index) => (
                                            <InputElement
                                                key={index}
                                                name={asins.name}
                                                type="text"
                                                defaultValue={asins.defaultValue}
                                                reactHookControl={control}
                                                reactHookValidations={{
                                                    pattern: validationConfig.upToFiveAlphanumericUppercaseASINs,
                                                    required: t("competitorAsinRequired"),
                                                }}
                                                showErrorInPlaceholder={false}
                                                placeholder={asins.placeholder}
                                                className={
                                                    index === competitorAsins.length - 1
                                                        ? "compitator-input"
                                                        : "xl:mb-[12px] lg:mb-[8px] md:mb-[8px] sm:mb-[12px] compitator-input"
                                                }
                                            />
                                        ))}

                                        <ButtonElement
                                            type="submit"
                                            loading={isLoading}
                                            size="large"
                                            className={
                                                "sm:mt-[36px] md:mt-[24px] lg:mt-[24px] xs:mt-[36px] xl:mt-[24px] text-[#0E7090]"
                                            }
                                        >
                                            {t("button")}
                                        </ButtonElement>
                                    </Container>
                                </form>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>

            <MarketingFooter currentStep={6} />
        </>
    )
}
