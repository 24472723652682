import { all, call, fork, put, select, takeLatest } from "typed-redux-saga"
import { RuntimeActionCreators } from "../actions/runTime.action"
import { SearchpackAction, SearchpackActionCreator } from "../actions/searchpack.action"
import { ASIN_PRODUCTS_COLOR } from "../config/colors.config"
import { mergeColorsWithData, sortedSearchCardDataWithLatestRank, sortSnapShotData } from "../helpers/util.helper"
import { RootState } from "../reducers/root.reducer"
import { SearchpackService } from "../services/searchpack.service"
import { extendedDayjs } from "../utils/dayjs"

// const cache = new LRUCache({
//     max: 50, // Number of items
//     maxSize: 50_000_000, // Allow ~50MB
//     sizeCalculation: (value, key) => JSON.stringify(value).length, // Store based on response size
//     ttl: 5 * 60 * 1000, // Keep cached data for 5 min
//     updateAgeOnGet: true,
// })

function* createSearchpack() {
    yield takeLatest(SearchpackAction.CREATE_SEARCHPACK_ACCOUNT, createSearchpackHandler)
}

function* archiveSearchpack() {
    yield takeLatest(SearchpackAction.ARCHIVE_SEARCHPACK, archiveSearchpackHandler)
}

function* archiveSearchpackSearchTerm() {
    yield takeLatest(SearchpackAction.ARCHIVE_SEARCHPACK_SEARCH_TERM, archiveSearchpackSearchTermHandler)
}

function* createSearchpackHandler<T extends ISagaAction & { payload: Partial<ISelectedTrackpackPayload> }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("createSearchpackHandler", !action.ignorePreloader))

        const result = yield* call(SearchpackService.createSearchpack, action.payload)
        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("createSearchpackHandler"))

        action.onFinally && action.onFinally()
    }
}

function* archiveSearchpackHandler<T extends ISagaAction & { payload: Partial<ISelectedTrackpackPayload> }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("archiveSearchpackHandler", !action.ignorePreloader))

        const result = yield* call(SearchpackService.archiveSearchpack, action.payload)
        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("archiveSearchpackHandler"))

        action.onFinally && action.onFinally()
    }
}

function* archiveSearchpackSearchTermHandler<T extends ISagaAction & { payload: Partial<ISelectedTrackpackPayload> }>(
    action: T
) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("archiveSearchpackSearchTermHandler", !action.ignorePreloader))

        const result = yield* call(SearchpackService.archiveSearchpackSearchTerm, action.payload)
        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("archiveSearchpackSearchTermHandler"))

        action.onFinally && action.onFinally()
    }
}

function* getSearchpacksList() {
    yield takeLatest(SearchpackAction.GET_SEARCHPACKS_LIST, getSearchpacksListHandler)
}

function* getSearchpacksListHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getSearchpacksListHandler", !action.ignorePreloader))
        yield put(SearchpackActionCreator.resetSearchpacksList())
        yield put(SearchpackActionCreator.resetRanksnapshotData())

        const { pathParams, queryParams, select } = action.payload
        const result = yield* call(SearchpackService.getSearchpacksList, pathParams, queryParams)

        if (result) {
            yield put(SearchpackActionCreator.setSearchpacksList(result as any))
        }

        yield put(SearchpackActionCreator.setSelectedSearchpack(select || null))
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpacksListHandler"))
        action.onFinally && action.onFinally()
    }
}

function* addKeywordsToSearchpack() {
    yield takeLatest(SearchpackAction.ADD_KEYWORDS_TO_SEARCHPACK, addKeywordToSearchpackHandler)
}

function* addKeywordToSearchpackHandler<T extends ISagaAction & { payload: Partial<ISelectedTrackpackPayload> }>(
    action: T
) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("addKeywordToSearchpackHandler", !action.ignorePreloader))

        const { id, keywords } = action.payload
        const payload = {
            search_terms: keywords,
        }
        const result = yield* call(SearchpackService.addKeywordToSearchpack, id, payload)
        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError()
    } finally {
        yield put(RuntimeActionCreators.stopLoading("addKeywordToSearchpackHandler"))

        action.onFinally && action.onFinally()
    }
}

function* getSearchpackWithLastDataTime() {
    yield takeLatest(SearchpackAction.GET_SEARCHPACK_WITH_LAST_DATA_TIME, getSearchpackWithLastDataTimeHandler)
}

function* getSearchpackWithLastDataTimeHandler<T extends ISagaAction & { payload: { searchpackId: number } }>(
    action: T
) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("getSearchpackWithLastDataTimeHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(
            SearchpackService.getSearchpackWithLastDataTime,
            action.payload.searchpackId,
            pathParams,
            queryParams
        )
        if (result) {
            yield put(SearchpackActionCreator.setSearchpackLastDataTime(result as ISearchpackLastDataTime))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpackWithLastDataTimeHandler"))

        action.onFinally && action.onFinally()
    }
}

function* getRanksnapshotData() {
    yield takeLatest(SearchpackAction.GET_RANKSNAPSHOT_DATA, getRanksnapshotDataHandler)
}

function* getRanksnapshotDataHandler<T extends ISagaAction & { payload: { searchpackId: number; date: string } }>(
    action: T
) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("getRanksnapshotDataHandler", !action.ignorePreloader))

        const state = (yield* select(
            (state: RootState) => state?.searchpack?.selectedSearchpackLastDataTime
        )) as unknown as ISearchpackLastDataTime

        const { searchpackId, date, pathParams, queryParams } = action.payload
        if (state?.id !== searchpackId) return put(RuntimeActionCreators.stopLoading("getRanksnapshotDataHandler"))
        const givenDate =
            date ||
            extendedDayjs(state?.search_data_last_available_at || extendedDayjs().format("YYYY-MM-DD"))
                .utc()
                .format("YYYY-MM-DD")

        const result = yield* call(
            SearchpackService.getRanksnapshotData,
            searchpackId,
            givenDate,
            pathParams,
            queryParams
        ) // here
        if (result) {
            const enrichedData = mergeColorsWithData(result.search_card_data, ASIN_PRODUCTS_COLOR)

            const updatedResult = {
                ...result,
                search_card_data: enrichedData,
            } as IRankSnapshotData

            const sortedResult = sortSnapShotData(updatedResult)

            yield put(SearchpackActionCreator.setRanksnapshotData(sortedResult as IRankSnapshotData))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getRanksnapshotDataHandler"))

        action.onFinally && action.onFinally()
    }
}

function* getSearchpackWithTerm() {
    yield takeLatest(SearchpackAction.GET_SEARCHPACK_WITHSEARCHTERM, getSearchpackWithTermHandler)
}
function* getSearchpackWithTermHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getSearchpackWithTermHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(SearchpackService.getSearchpacksWithTerm, pathParams, queryParams)

        if (result) {
            yield put(SearchpackActionCreator.setSelectedSearchPackTermData(result as IProductSearchpackWithTerm))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpackWithTermHandler"))
        action.onFinally && action.onFinally()
    }
}

function* getSearchpackTermWithId() {
    yield takeLatest(SearchpackAction.GET_SEARCHPACK_SEARCHTERM_WITH_ID, getSearchpackTermWithIdHandler)
}
function* getSearchpackTermWithIdHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getSearchpackTermWithIdHandler", !action.ignorePreloader))
        const { packId, pathParams, queryParams } = action.payload
        const result = yield* call(SearchpackService.getSearchpackTermWithId, packId, pathParams, queryParams)

        if (result) {
            yield put(SearchpackActionCreator.setSelectedSearchPackTermData(result as IProductSearchpackWithTerm))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpackTermWithIdHandler"))
        action.onFinally && action.onFinally()
    }
}

function* getSearchpackRankTracker() {
    yield takeLatest(SearchpackAction.GET_SEARCHPACK_RANK_TRACKER, getSearchpackRankTrackerHandler)
}
function* getSearchpackRankTrackerHandler<
    T extends ISagaAction & {
        payload: { packTermId: number }
        pathParams: {}
        queryParams: { as_user?: string; since?: string; until?: string }
    },
>(action: T) {
    try {
        const { packTermId, pathParams, queryParams } = action.payload
        yield put(RuntimeActionCreators.startLoading("getSearchpackRankTrackerHandler", !action.ignorePreloader))
        const result = yield* call(SearchpackService.getSearchpackTermTracker, packTermId, pathParams, queryParams)

        if (result) {
            const sortedData = sortedSearchCardDataWithLatestRank(result?.search_card_data_with_latest_rank)
            console.table(sortedData)
            const enrichedData = mergeColorsWithData(sortedData, ASIN_PRODUCTS_COLOR)
            const updatedResult = {
                ...result,
                search_card_data_with_latest_rank: enrichedData,
            }
            yield put(SearchpackActionCreator.setSearchpackRankTracker(updatedResult as any))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpackRankTrackerHandler"))
        action.onFinally && action.onFinally()
    }
}

function* getSearchpackKeywordPerformance() {
    yield takeLatest(SearchpackAction.GET_SEARCHPACK_KEYWORD_PERFORMANCE, getSearchpackKeywordPerformanceHandler)
}
function* getSearchpackKeywordPerformanceHandler<T extends ISagaAction & { payload: { packId: number } }>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getSearchpackKeywordPerformanceHandler", !action.ignorePreloader))
        const { packId, pathParams, queryParams } = action.payload
        const result = yield* call(SearchpackService.getSearchpackKeywordPerformance, packId, pathParams, queryParams)

        if (result) {
            const sortedData = sortedSearchCardDataWithLatestRank(result?.search_card_data_with_latest_rank)

            const enrichedData = mergeColorsWithData(sortedData, ASIN_PRODUCTS_COLOR)
            const updatedResult = {
                ...result,
                search_card_data_with_latest_rank: enrichedData,
            }
            yield put(SearchpackActionCreator.setSearchpackKeywordPerformance(updatedResult as any))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpackKeywordPerformanceHandler"))
        action.onFinally && action.onFinally()
    }
}

/* Cached Version getSearchpackKeywordPerformanceHandler
function* getSearchpackKeywordPerformanceHandler<T extends ISagaAction & { payload: { packId: number } }>(action: T) {
    try {
        const { packId, pathParams, queryParams } = action.payload

        const sanitizedQueryParams = { ...queryParams }
        delete sanitizedQueryParams.since

        const cacheKey = `searchpack_${packId}_${JSON.stringify(sanitizedQueryParams)}`
        let cachedResult: any = cache.get(cacheKey)

        if (cachedResult) {
            const sortedData = sortedSearchCardDataWithLatestRank(cachedResult?.search_card_data_with_latest_rank)
            const enrichedData = mergeColorsWithData(sortedData, ASIN_PRODUCTS_COLOR)
            const updatedCachedResult = {
                ...cachedResult,
                search_card_data_with_latest_rank: enrichedData,
            }
            yield put(SearchpackActionCreator.setSearchpackKeywordPerformance(updatedCachedResult as any))
        } else {
            yield put(
                RuntimeActionCreators.startLoading("getSearchpackKeywordPerformanceHandler", !action.ignorePreloader)
            )
        }

        const freshResult = yield* call(
            SearchpackService.getSearchpackKeywordPerformance,
            packId,
            pathParams,
            queryParams
        )

        if (freshResult) {
            cache.set(cacheKey, freshResult)

            const sortedFreshData = sortedSearchCardDataWithLatestRank(freshResult?.search_card_data_with_latest_rank)
            const enrichedFreshData = mergeColorsWithData(sortedFreshData, ASIN_PRODUCTS_COLOR)
            const updatedFreshResult = {
                ...freshResult,
                search_card_data_with_latest_rank: enrichedFreshData,
            }
            yield put(SearchpackActionCreator.setSearchpackKeywordPerformance(updatedFreshResult as any))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpackKeywordPerformanceHandler"))
        action.onFinally && action.onFinally()
    }
}

*/

function* getRankTrackerListingChange() {
    yield takeLatest(SearchpackAction.GET_RANK_TRACKER_LISTING_CHANGE, getRankTrackerListingChangeHandler)
}
function* getRankTrackerListingChangeHandler<T extends ISagaAction & { payload: { packTermId: number } }>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getRankTrackerListingChangeHandler", !action.ignorePreloader))
        const { packTermId, pathParams, queryParams } = action.payload
        const result = yield* call(SearchpackService.getTermTrackerListingChange, packTermId, pathParams, queryParams)

        if (result) {
            yield put(SearchpackActionCreator.setRankTrackerListingChange(result))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getRankTrackerListingChangeHandler"))
        action.onFinally && action.onFinally()
    }
}

function* getSearchpackKeywordPerformanceBsr() {
    yield takeLatest(SearchpackAction.GET_SEARCHPACK_KEYWORD_PERFORMANCE_BSR, getSearchpackKeywordPerformanceBsrHandler)
}
function* getSearchpackKeywordPerformanceBsrHandler<
    T extends ISagaAction & { payload: { packId: number; asin: string } },
>(action: T) {
    try {
        yield put(
            RuntimeActionCreators.startLoading("getSearchpackKeywordPerformanceBsrHandler", !action.ignorePreloader)
        )
        const { packId, asin, pathParams, queryParams } = action.payload
        const result = yield* call(
            SearchpackService.getSearchpackKeywordPerformanceBsr,
            packId,
            asin,
            pathParams,
            queryParams
        )

        if (result) {
            yield put(SearchpackActionCreator.setSearchpackKeywordPerformanceBsr(result as any))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpackKeywordPerformanceBsrHandler"))
        action.onFinally && action.onFinally()
    }
}

/* Cached Version getSearchpackKeywordPerformanceBsrHandler
function* getSearchpackKeywordPerformanceBsrHandler<
    T extends ISagaAction & { payload: { packId: number; asin: string } },
>(action: T) {
    try {
        const { packId, asin, pathParams, queryParams } = action.payload

        const sanitizedQueryParams = { ...queryParams }
        delete sanitizedQueryParams.since

        const cacheKey = `searchpack_${packId}_${asin}_${JSON.stringify(sanitizedQueryParams)}`
        let cachedResult: any = cache.get(cacheKey)

        if (cachedResult) {
            yield put(SearchpackActionCreator.setSearchpackKeywordPerformanceBsr(cachedResult as any))
        } else {
            yield put(
                RuntimeActionCreators.startLoading("getSearchpackKeywordPerformanceBsrHandler", !action.ignorePreloader)
            )
        }

        const freshResult = yield* call(
            SearchpackService.getSearchpackKeywordPerformanceBsr,
            packId,
            asin,
            pathParams,
            queryParams
        )

        if (freshResult) {
            cache.set(cacheKey, freshResult)
            yield put(SearchpackActionCreator.setSearchpackKeywordPerformanceBsr(freshResult as any))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpackKeywordPerformanceBsrHandler"))
        action.onFinally && action.onFinally()
    }
}
*/

function* getKeywordPerformanceListingChange() {
    yield takeLatest(SearchpackAction.GET_KEYWORD_PERFORMANCE_LISTING_CHANGE, getKeywordPerformanceListingChangeHandler)
}
function* getKeywordPerformanceListingChangeHandler<
    T extends ISagaAction & { payload: { packTermId: number; asin: string; since?: string } },
>(action: T) {
    try {
        yield put(
            RuntimeActionCreators.startLoading("getKeywordPerformanceListingChangeHandler", !action.ignorePreloader)
        )

        const { packTermId, asin, since } = action.payload
        const result = yield* call(SearchpackService.getKeywordPerformanceListingChange, packTermId, asin, since)

        if (result) {
            yield put(SearchpackActionCreator.setKeywordPerformanceListingChange(result))
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getKeywordPerformanceListingChangeHandler"))
        action.onFinally && action.onFinally()
    }
}

export default function* rootSaga() {
    yield all([
        fork(createSearchpack),
        fork(archiveSearchpack),
        fork(archiveSearchpackSearchTerm),
        fork(getSearchpacksList),
        fork(addKeywordsToSearchpack),
        fork(getSearchpackWithLastDataTime),
        fork(getRanksnapshotData),
        fork(getSearchpackWithTerm),
        fork(getSearchpackRankTracker),
        fork(getRankTrackerListingChange),
        fork(getSearchpackKeywordPerformance),
        fork(getSearchpackTermWithId),
        fork(getSearchpackKeywordPerformanceBsr),
        fork(getKeywordPerformanceListingChange),
    ])
}
