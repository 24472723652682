import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import InnerModalHeader from "../../common/innerModalHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import { ChartsWrapper } from "./chartsWrapper.component"

import { useProductHistory } from "../../../hooks/productHistory.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"

import ArrowModalActions from "../../common/arrowModalActions.component"
import { StringDiffElement } from "../../elements/stringDiff.element"

const BulletModal = (data: IDialogOptionsProps) => {
    const { modal } = useSelector(RunTimeStateSelector)
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    const { active, history, setActive, total } = useProductHistory(data?.data as IProductData, "bullets")

    // const bulletIndex = modal?.index

    function getUniqueValues(pastArray: string[] | undefined, presentArray: string[] | undefined) {
        let uniqueInPast = history?.past?.value
        let uniqueInPresent = history?.present?.value

        if (presentArray && pastArray) {
            let uniqueInPast: string[] | undefined = pastArray.filter(
                (pastItem, index) => pastItem !== presentArray[index]
            )
            let uniqueInPresent: string[] | undefined = presentArray.filter(
                (presentItem, index) => presentItem !== pastArray[index]
            )

            if (data?.data && data?.data?.bullets?.length > 1) {
                const maxLength = Math.max(uniqueInPast?.length, uniqueInPresent.length)
                const paddedUniqueInPast = uniqueInPast.concat(Array(maxLength - uniqueInPast.length).fill(""))
                const paddedUniqueInPresent = uniqueInPresent.concat(Array(maxLength - uniqueInPresent.length).fill(""))
                return { uniqueInPast: paddedUniqueInPast, uniqueInPresent: paddedUniqueInPresent }
            }
        }
        return { uniqueInPast, uniqueInPresent }
    }

    const { uniqueInPast, uniqueInPresent } = getUniqueValues(history.past?.value, history.present?.value)

    const pastBullets = uniqueInPast
    const presentBullets = uniqueInPresent

    // const reorderItems = (items: any, index: number | undefined) => {
    //     if (!items || items?.length === 0) return []
    //     const reordered = [...items]
    //     const matchedItemIndex = items.findIndex((_: any, i: number) => i === index)
    //     if (matchedItemIndex !== -1) {
    //         const [firstItem] = reordered.splice(matchedItemIndex, 1)
    //         reordered.unshift(firstItem)
    //     }
    //     return reordered
    // }

    // const reorderedPastBullets = reorderItems(pastBullets, bulletIndex)
    // const reorderedPresentBullets = reorderItems(presentBullets, bulletIndex)

    const isFirst = data?.data?.isFirst === true

    return (
        <>
            <ChartsWrapper
                className={`p-[48px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] m-auto ${
                    isFirst ? "w-[528px]" : pastBullets?.length && presentBullets?.length ? "w-[972px]" : "w-[528px]"
                } shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
            >
                <InnerModalHeader color={modal?.color} type="bullets" />

                <PrimaryText className="text-[#344054] ml-[6px] text-[24px] font-[500] leading-[32px] mb-[4px]">
                    Bullets
                </PrimaryText>

                <div className={`ml-[8px] flex items-center gap-[10px]`}>
                    <ArrowModalActions total={total} active={active} setActive={setActive} />
                    <PrimaryText className="text-[12px] uppercase leading-[12px] text-[#667085] font-[400]">
                        {tCommon("timestamp", {
                            timestamp: dayjs(history?.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                        })}
                    </PrimaryText>
                </div>

                <div className="mt-[40px]">
                    <div className="flex gap-[12px]">
                        {!isFirst && pastBullets && (
                            <div className="bg-[#F2F4F7] rounded-[16px] p-4 w-[432px] pb-[20px]">
                                <PrimaryText className="w-[32px] py-[5.5px] px-[6px] bg-[#F04438] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                    Was
                                </PrimaryText>
                                {pastBullets.every((item: string) => item.trim() === "") ? (
                                    <PrimaryText className="text-[12px] italic leading-[16px] font-[300] pr-[4px] max-h-[296px] text-[#344054] ml-[6px]">
                                        No bullets
                                    </PrimaryText>
                                ) : (
                                    <PrimaryText className="text-[12px] leading-[16px] font-[300] max-h-[414px] pr-[4px] modal-scrollbar-pr overflow-y-auto text-[#344054]">
                                        <ul className="list-disc pl-5">
                                            {pastBullets
                                                ?.filter((item: string) => item.trim() !== "")
                                                .map((item: string, index: number) => (
                                                    <li key={index} className="text-gray-700 leading-[18px]">
                                                        <StringDiffElement
                                                            pastValue={item}
                                                            presentValue={presentBullets?.[index] || ""}
                                                            showType="removed"
                                                        />
                                                    </li>
                                                ))}
                                        </ul>
                                    </PrimaryText>
                                )}
                            </div>
                        )}

                        {!isFirst && presentBullets && (
                            <div className="bg-[#F2F4F7] rounded-[16px] p-4 w-[432px] pb-[20px]">
                                <PrimaryText className="w-[32px] py-[5.5px] px-[6px] bg-[#47CD89] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                    Now
                                </PrimaryText>
                                {presentBullets.every((item: string) => item.trim() === "") ? (
                                    <PrimaryText className="text-[12px] italic leading-[16px] font-[300] pr-[4px] max-h-[296px] text-[#344054] ml-[6px]">
                                        No bullets
                                    </PrimaryText>
                                ) : (
                                    <PrimaryText className="text-[12px] leading-[16px] font-[300] pr-[4px] max-h-[414px] modal-scrollbar-pr overflow-y-auto text-[#344054]">
                                        <ul className="list-disc pl-5">
                                            {presentBullets
                                                ?.filter((item: string) => item.trim() !== "")
                                                .map((item: string, index: number) => (
                                                    <li key={index} className="text-gray-700 leading-[18px]">
                                                        <StringDiffElement
                                                            pastValue={pastBullets?.[index] || ""}
                                                            presentValue={item}
                                                            showType="added"
                                                        />
                                                    </li>
                                                ))}
                                        </ul>
                                    </PrimaryText>
                                )}
                            </div>
                        )}

                        {isFirst && presentBullets && (
                            <div className="bg-[#F2F4F7] rounded-[16px] p-4 min-w-[432px] pb-[20px]">
                                <PrimaryText className="w-[32px] py-[5.5px] px-[6px] bg-[#47CD89] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                    Now
                                </PrimaryText>
                                {presentBullets.every((item: string) => item.trim() === "") ? (
                                    <PrimaryText className="text-[12px] italic leading-[16px] font-[300] pr-[4px] max-h-[296px] text-[#344054] ml-[6px]">
                                        No bullets
                                    </PrimaryText>
                                ) : (
                                    <PrimaryText className="text-[12px] leading-[16px] font-[300] pr-[4px] max-h-[414px] modal-scrollbar-pr overflow-y-auto text-[#344054]">
                                        <ul className="list-disc pl-5">
                                            {presentBullets
                                                .filter((item: string) => item.trim() !== "")
                                                .map((item: string, index: number) => (
                                                    <li className="text-gray-700 leading-[18px]" key={index}>
                                                        {item}
                                                    </li>
                                                ))}
                                        </ul>
                                    </PrimaryText>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </ChartsWrapper>
        </>
    )
}

export default BulletModal
