import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useDispatch, useSelector } from "react-redux"
import type { SelectedSearchpack } from "../../../@types/app/data"
import { AccountActionsCreater } from "../../../actions/account.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { SearchpackActionCreator } from "../../../actions/searchpack.action"
import { FlipArrowIcon } from "../../../assets/svgs/flipBackwardIcon.svg"
import { ReactComponent as AddIcon } from "../../../assets/svgs/new-add-icon.svg"
import { SearchPackHubIcon } from "../../../assets/svgs/searchpackHub.svg"
import { TrackpackTrashIcon } from "../../../assets/svgs/trashIcon.svg"
import { getPossibleSpStatus } from "../../../helpers/searchpack/searchpack"
import { useAppSearchParams } from "../../../hooks/app/searchpack/useAppSearchParams"
import { ConvertedSearchpack, useGetExtendedSearchpacks } from "../../../hooks/reactQueryHooks.hook"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { SearchpackStateSelector } from "../../../selectors/searchpack.selector"
import SearchpackHubCentralSkeletonLoader from "../../common/skeletonLoader/searchpackHubCardSkeletonLoader.component"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

interface TrackpackProps {
    setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>
    setIsLoading: (isLoading: boolean) => void
    setDataLength?: (dataLength: number) => void
}

export const SearchpackHubOptions = ({ setSelectedRows, setIsLoading, setDataLength }: TrackpackProps) => {
    const { searchParams, getParamValue, updateSearchParams } = useAppSearchParams()
    const filter = getParamValue("filter", "active") as "active" | "archived"
    const spId = getParamValue("sp_id", "0", Number)
    const dispatch = useDispatch()

    const { userActionData } = useSelector(AccountStateSelector)
    const { selectedSearchpack } = useSelector(SearchpackStateSelector)

    const [, setIsScrollable] = useState(false)
    const containerRef = useRef(null)
    const selectedSearchpackMap = useRef(new Map<number, boolean>())
    const pathParams = {}
    const queryParams = userActionData ? { as_user: userActionData.id.toString() } : {}
    const { data, isLoading, isFetching, length, convertedSearchpacks } = useGetExtendedSearchpacks(
        pathParams,
        queryParams,
        filter
    )

    useEffect(() => {
        setIsLoading && setIsLoading(isFetching)
        setDataLength && setDataLength(length)
    }, [isFetching, setIsLoading, length, setDataLength])

    const searchpackTypeListView =
        filter === "active"
            ? convertedSearchpacks &&
              convertedSearchpacks?.filter((val: { status: string | string[] }) => {
                  return val?.status?.includes("ACTIVE") || val?.status?.includes("AWAITING_INITIAL_DATA")
              })
            : convertedSearchpacks &&
              convertedSearchpacks?.filter((val: { status: string | string[] }) => {
                  return val?.status?.includes("ARCHIVED") || val?.status?.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
              })

    useEffect(() => {
        if (convertedSearchpacks?.length > 0 && searchParams) {
            let indexToSelect = 0
            let filterToSelect: "archived" | "active" = filter

            if (!!spId) {
                const foundIndex = convertedSearchpacks.findIndex((trackpack: any) => trackpack.id === Number(spId))
                indexToSelect = foundIndex !== -1 ? foundIndex : 0

                const matchingTrackpack = convertedSearchpacks.find(
                    (val: { id: unknown }) => Number(val.id) === Number(spId)
                )

                if (matchingTrackpack) {
                    const trackpackStatus = matchingTrackpack.status

                    if (trackpackStatus === "ACTIVE" || trackpackStatus === "AWAITING_INITIAL_DATA") {
                        filterToSelect = "active"
                    } else if (trackpackStatus === "ARCHIVED" || trackpackStatus === "ARCHIVED_DURING_PACK_ARCHIVAL") {
                        updateSearchParams({ filter: "archived" })
                        filterToSelect = "archived"
                    }
                }
            } else {
                const possibleStatus = getPossibleSpStatus(filter)
                const foundIndex = convertedSearchpacks.findIndex((tp: any) => possibleStatus.includes(tp.status))
                indexToSelect = foundIndex !== -1 ? foundIndex : 0
            }

            handleSearchpackSelect(convertedSearchpacks[indexToSelect], filterToSelect)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, convertedSearchpacks, filter, spId])

    useEffect(() => {
        const checkScrollability = () => {
            if (containerRef.current) {
                const { scrollHeight, clientHeight } = containerRef.current
                setIsScrollable(scrollHeight > clientHeight)
            }
        }

        checkScrollability()
        const currentContainer = containerRef.current
        const resizeObserver = new ResizeObserver(checkScrollability)
        if (currentContainer) {
            resizeObserver.observe(currentContainer)
        }

        return () => {
            if (currentContainer) {
                resizeObserver.unobserve(currentContainer)
            }
        }
    }, [])

    const handleSelectedOptions = useCallback(
        (selectedOptions: SelectedSearchpack) => {
            dispatch(AccountActionsCreater.setSelectedTrackpackList(selectedOptions))
        },
        [dispatch]
    )

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAddSearchpackModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const handleOpenArchiveSearchpack = (id: number, status: "archived" | "active") => {
        dispatch(
            RuntimeActionCreators.openArchiveRestoreSearchpackModal({
                id,
                status,
                open: true,
                stepNumber: "one",
                isSearchpack: true,
            })
        )
    }

    const hasOnlyActiveOrAwaiting = (trackpacks: ConvertedSearchpack[]) => {
        return (
            trackpacks &&
            trackpacks?.every((val) => val.status.includes("ACTIVE") || val.status.includes("AWAITING_INITIAL_DATA"))
        )
    }

    const isArchiveDisabled = useMemo(() => {
        return hasOnlyActiveOrAwaiting(convertedSearchpacks)
    }, [convertedSearchpacks])

    const hasOnlyArchivedOrAwaiting = (trackpacks: ConvertedSearchpack[]) => {
        return (
            trackpacks &&
            trackpacks?.every(
                (val) => val.status.includes("ARCHIVED") || val.status.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
            )
        )
    }

    const isTrackingDisabled = useMemo(() => {
        return hasOnlyArchivedOrAwaiting(convertedSearchpacks)
    }, [convertedSearchpacks])

    const searchpackRefs = useRef<(HTMLDivElement | null)[]>([])

    useEffect(() => {
        if (spId && convertedSearchpacks) {
            const item = searchpackRefs.current?.find((i) => Number(i?.getAttribute("data-spId") || "0") === spId)
            item?.scrollIntoView({ behavior: "smooth", block: "center" })
        }
    }, [convertedSearchpacks, filter, spId])

    const handleSearchpackSelect = useCallback(
        (product?: any, updatedFilter?: "active" | "archived") => {
            if (product) handleSelectedOptions(product)
            setSelectedRows([])

            updateSearchParams({
                ...(product?.id && Number(spId) !== Number(product?.id) ? { sp_id: product?.id } : {}),
                ...(updatedFilter ? { filter: updatedFilter } : {}),
            })
        },
        [handleSelectedOptions, setSelectedRows, spId, updateSearchParams]
    )

    const setSearchpackType = useCallback(
        (filterToSelect: "active" | "archived") => {
            const possibleStatus = getPossibleSpStatus(filterToSelect)
            const items = convertedSearchpacks.filter((tp: any) => possibleStatus.includes(tp.status))
            const product = items.find((i) => i.id === spId) || items[0]
            handleSearchpackSelect(product, filterToSelect)
        },
        [convertedSearchpacks, handleSearchpackSelect, spId]
    )

    useEffect(() => {
        if (isArchiveDisabled) {
            setSearchpackType("active")
        } else if (isTrackingDisabled) {
            setSearchpackType("archived")
        }
    }, [isArchiveDisabled, isTrackingDisabled, setSearchpackType, updateSearchParams])

    useEffect(() => {
        if (
            selectedSearchpack &&
            selectedSearchpackMap &&
            searchpackTypeListView &&
            searchpackTypeListView[0] &&
            searchpackTypeListView[0].id === selectedSearchpack &&
            handleSearchpackSelect
        ) {
            if (selectedSearchpackMap.current.has(selectedSearchpack)) return
            selectedSearchpackMap.current.set(selectedSearchpack, true)
            const product = searchpackTypeListView[0]
            handleSearchpackSelect(product)
            dispatch(SearchpackActionCreator.setSelectedSearchpack(null))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSearchpack, searchpackTypeListView])

    return (
        <>
            <div
                className="w-[304px]  p-[12px] pb-0 rounded-[16px] bg-gray-50 h-calc-163"
                style={{
                    border: "1px solid",
                    borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                }}
            >
                <div>
                    <div className="flex justify-between ">
                        <div className="flex gap-[4px] items-center ml-[8px]">
                            <PrimaryText weight="medium" size="sm-medium" className="text-[#088AB2] leading-normal">
                                Searchpacks
                            </PrimaryText>
                            {/* <InfoLabel /> */}
                        </div>
                        <div
                            className="cursor-pointer outline-none"
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-offset={-10}
                            data-tooltip-class-name={"!ml-[38px]"}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className="" label="Add Searchpack" />
                            )}
                        >
                            <ButtonElement
                                onClick={handleOpen}
                                viewType="only-icon"
                                className={`p-[10px] cursor-pointer bg-gradient-to-br from-cyan-100 to-cyan-200 rounded-[10px] disabled:bg-gray-100
                                    hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                                    active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400`}
                            >
                                <AddIcon />
                            </ButtonElement>
                        </div>
                    </div>
                    <div className="w-full mt-[20px] mb-[12px] relative">
                        <div
                            className={`flex ml-[8px] border ${
                                isLoading || data?.trackpacks?.length === 0 ? "border-gray-200" : "border-[#A5F0FC]"
                            }  rounded-[4px] w-[94px] h-[16px]`}
                        >
                            <div
                                className={`overflow-hidden rounded-tl-[3px] rounded-bl-[3px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] border-r h-full ${
                                    isLoading || data?.trackpacks?.length === 0
                                        ? "border-gray-200 bg-white"
                                        : filter === "active"
                                        ? "border-[#A5F0FC] bg-[#CFF9FE]"
                                        : "border-[#A5F0FC] bg-white"
                                }`}
                            >
                                <PrimaryText
                                    onClick={() => {
                                        if (!isTrackingDisabled && !isLoading && data?.trackpacks?.length > 0) {
                                            setSearchpackType("active")
                                            dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                            setSelectedRows([])
                                        }
                                    }}
                                    weight={
                                        filter === "active" && !isLoading && data?.trackpacks?.length > 0
                                            ? "medium"
                                            : "light"
                                    }
                                    size="xs-small"
                                    className={`leading-[12px] h-full align-middle ${
                                        isLoading || data?.trackpacks?.length === 0 || isTrackingDisabled
                                            ? "text-gray-300 cursor-not-allowed pointer-events-none"
                                            : filter === "active"
                                            ? "text-[#0E7090] cursor-pointer"
                                            : "text-gray-700 cursor-pointer"
                                    }`}
                                >
                                    ACTIVE
                                </PrimaryText>
                            </div>

                            <div
                                className={`overflow-hidden rounded-tr-[3px] rounded-br-[3px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] ${
                                    isLoading || data?.trackpacks?.length === 0
                                        ? "bg-white"
                                        : filter === "archived"
                                        ? "bg-[#CFF9FE]"
                                        : "bg-white"
                                }`}
                            >
                                <PrimaryText
                                    onClick={() => {
                                        if (!isArchiveDisabled && !isLoading && data?.trackpacks?.length > 0) {
                                            setSearchpackType("archived")
                                            dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                            setSelectedRows([])
                                        }
                                    }}
                                    weight={
                                        filter === "archived" && !isLoading && data?.trackpacks?.length > 0
                                            ? "medium"
                                            : "light"
                                    }
                                    size="xs-small"
                                    className={`leading-[12px] h-full align-middle ${
                                        isLoading || data?.trackpacks?.length === 0 || isArchiveDisabled
                                            ? "text-gray-300 cursor-not-allowed pointer-events-none"
                                            : filter === "archived"
                                            ? "text-[#0E7090] cursor-pointer"
                                            : "text-gray-700 cursor-pointer"
                                    }`}
                                >
                                    ARCHIVED
                                </PrimaryText>
                            </div>
                        </div>
                    </div>
                    <div
                        ref={containerRef}
                        className={`overflow-y-scroll -mr-[25px]  min-h-[calc(100vh-263px)] h-[calc(100vh-263px)]`}
                    >
                        {isLoading ? (
                            <SearchpackHubCentralSkeletonLoader isStatic={false} length={10} isTrackpack={true} />
                        ) : data?.trackpacks?.length === 0 ? (
                            <SearchpackHubCentralSkeletonLoader isStatic={true} length={10} isTrackpack={true} />
                        ) : (
                            <div
                                className="flex-col gap-[8px] inline-flex w-full"
                                style={{
                                    width: "calc(100% - 20px)",
                                }}
                            >
                                {searchpackTypeListView &&
                                    searchpackTypeListView?.map((product: SelectedSearchpack, index: number) => {
                                        return (
                                            <div
                                                key={product.id}
                                                data-spId={product.id}
                                                ref={(el) => (searchpackRefs.current[index] = el)}
                                                onClick={() => {
                                                    handleSearchpackSelect(product)
                                                }}
                                                className={`${
                                                    product.id === spId
                                                        ? "!border-[2px] !border-[#22CCEE] bg-[#ECFDFF]"
                                                        : "bg-white border border-gray-300"
                                                } last-child p-[8px] mb-0  rounded-[12px] cursor-pointer max-w-[280px] w-auto !-outline-offset-2`}
                                            >
                                                <div className="justify-between flex w-full">
                                                    <div className="flex gap-[8px]">
                                                        <div
                                                            className={`w-[40px] h-[40px]  ${
                                                                product.id === spId ? "bg-[#22CCEE]" : "bg-gray-400"
                                                            }  rounded-[6px] flex items-center justify-center`}
                                                        >
                                                            <SearchPackHubIcon
                                                                color={product.id === spId ? "#A5F0FC" : "#D0D5DD"}
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <PrimaryText
                                                                className={`leading-[20px] my-[2px]
                                                                    line-clamp-1 max-w-[21ch]
                                                                      ${
                                                                          product.id === spId
                                                                              ? "text-[#0E7090]"
                                                                              : "text-[#344054]"
                                                                      }
                                                                    `}
                                                                weight="medium"
                                                                size="small"
                                                            >
                                                                {product?.title}
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                className={` leading-[12px] mb-[4px]
                                                                ${
                                                                    product.id === spId
                                                                        ? "text-[#06AED4]"
                                                                        : "text-[#667085]"
                                                                }
                                                                `}
                                                                weight="light"
                                                                size="xs"
                                                            >
                                                                {product?.keywordCount} Keywords
                                                            </PrimaryText>
                                                        </div>
                                                    </div>
                                                    {product.status === "ACTIVE" ? (
                                                        <div
                                                            className="cursor-pointer outline-none"
                                                            data-tooltip-id="dashboard-tooltip"
                                                            data-tooltip-place="bottom"
                                                            data-tooltip-offset={-14}
                                                            data-tooltip-class-name={"!ml-[45px]"}
                                                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                                <CommonTooltip
                                                                    className=""
                                                                    label="Archive Searchpack"
                                                                />
                                                            )}
                                                        >
                                                            <div
                                                                className={`cursor-pointer w-[24px] h-[24px] ${
                                                                    product.id === spId
                                                                        ? "bg-[#CFF9FE]"
                                                                        : "bg-[#EAECF0]"
                                                                }   rounded-[15px] justify-center items-center flex`}
                                                                onClick={() =>
                                                                    handleOpenArchiveSearchpack(product.id, "archived")
                                                                }
                                                            >
                                                                <TrackpackTrashIcon
                                                                    color={product.id === spId ? "#06AED4" : "#667085"}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="cursor-pointer outline-none"
                                                            data-tooltip-id="dashboard-tooltip"
                                                            data-tooltip-place="bottom"
                                                            data-tooltip-offset={-14}
                                                            data-tooltip-class-name={"!ml-[45px]"}
                                                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                                <CommonTooltip
                                                                    className=""
                                                                    label="Restore Searchpack"
                                                                />
                                                            )}
                                                        >
                                                            <div
                                                                className={`cursor-pointer w-[24px] h-[24px] ${
                                                                    product.id === spId ? "bg-[#CFF9FE]" : "bg-gray-200"
                                                                }   rounded-[15px] justify-center items-center flex`}
                                                                onClick={() =>
                                                                    handleOpenArchiveSearchpack(product.id, "active")
                                                                }
                                                            >
                                                                <FlipArrowIcon
                                                                    color={product.id === spId ? "#06AED4" : "#667085"}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
