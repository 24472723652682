import { useMemo } from "react"
import { DotProps } from "recharts"
import { LineDot } from "recharts/types/cartesian/Line"

export type IProductWithColor = {
    color: TAsinProductColor
    product: {
        [key: string]: IListingChanges[]
    }
}

export type IRankTrackerGraphDotOnClick = {
    cx: number
    cy: number
    color: TAsinProductColor
    changes: IListingChanges[]
    asin: string
}

export type IRankTrackerGraphDotProps = LineDot &
    DotProps & {
        dataKey: string
        product?: IProductWithColor
        payload: { label: string }
        customMoveClick: (data: IRankTrackerGraphDotOnClick) => void
    }
export const RankTrackerGraphDot = (props: IRankTrackerGraphDotProps) => {
    const {
        product,
        customMoveClick,
        dataKey,
        payload: { label },
    } = props

    const hasChanges: boolean = useMemo(() => {
        if (!product?.product[label] || !Array.isArray(product?.product[label])) return false

        return product.product[label].some(
            (change: IListingChanges) =>
                change.id_changes.length > 0 ||
                !!change.price_change ||
                (change.video_list_change &&
                    (change.video_list_change.new_video_ids.length > 0 ||
                        change.video_list_change.old_video_ids.length > 0))
        )
    }, [product, label])

    if (!hasChanges || !product) return null

    const handleMouseOver = (event: any) => {
        event?.stopPropagation()

        customMoveClick({
            cx: props.cx!,
            cy: props.cy!,
            color: product.color,
            changes: product.product[label],
            asin: product.product[label][0].asin,
        })
    }

    return (
        <>
            <svg
                id={dataKey.split("-")[0]}
                x={props.cx! - 8}
                y={props.cy! - 7.5}
                width={33}
                height={33}
                viewBox={"0 0 200 200"}
                onClick={handleMouseOver}
                className={`cursor-pointer hover:${product.color.default}`}
            >
                <circle cx={"46"} cy={"46"} r={"25"} strokeWidth={"8"} fill={product.color.default} stroke={"white"} />
            </svg>
        </>
    )
}
