import { useCallback, useEffect, useMemo, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"

import { PrimaryText } from "../../../elements/primaryText.element"
import CommonTooltip from "../../tooltipItems/commonTooltip.component"

import { SearchpackActionCreator } from "../../../../actions/searchpack.action"
import { GraphIcon } from "../../../../assets/svgs/graphIcon.svg"
import { TableIcon } from "../../../../assets/svgs/tableIcon.svg"
import { DashboardBoardConfig } from "../../../../config/dashboard.config"
import dateHelper from "../../../../helpers/date"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { useAppSearchParams } from "../../../../hooks/app/searchpack/useAppSearchParams"
import { useLoadingStatus } from "../../../../hooks/useLoadingStatus.hook"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../../selectors/searchpack.selector"
import SearchpackTrackerOnboarding from "../../../common/skeletonLoader/searchpackTrackerOnboarding.component"
import ButtonGroups from "../../../elements/buttonGroup.element"
import { KeywordPerformanceGraphView } from "./keywordPerformanceGraphView.component"
import KeywordPerformanceTable from "./keywordPerformanceTable.component"

const KeywordPerformance = (props: ITableData) => {
    const dispatch = useDispatch()
    const { searchParams } = useAppSearchParams(["sp_id"])
    const [activeIcon, setActiveIcon] = useState<"table" | "graph">("table")
    const [selectedRange, setSelectedRange] = useState<number>(7)

    const [disabledFilter, setDisabledFilter] = useState<("Paid" | "Organic")[]>(
        activeIcon === "table" ? ["Paid"] : ["Paid", "Organic"]
    )

    const [selectedBSR, setSelectedBSR] = useState<("BSR L" | "BSR S")[]>(["BSR L", "BSR S"])

    const { userActionData, refetchSearchpacks } = useSelector(AccountStateSelector)
    const { selectedSearchpackKeywordPerformance, selectedSearchpackLastDataTime } =
        useSelector(SearchpackStateSelector)

    const { loading } = useSelector(RunTimeStateSelector)

    const isKeywordPerformanceLoading = useLoadingStatus(
        loading,
        RuntimeHelper.getSearchpackKeywordPerformanceHandler()
    )

    const isDisabled =
        (selectedSearchpackLastDataTime && selectedSearchpackLastDataTime?.search_data_last_available_at === null) ||
        !selectedSearchpackKeywordPerformance?.selectedProducts?.asin

    const { keywordPerfromanceConfig } = DashboardBoardConfig

    const handleBSRSelection = useCallback(
        (selected: "BSR L" | "BSR S") => {
            if (activeIcon === "graph") {
                setSelectedBSR([selected])
            } else {
                setSelectedBSR((prev) => {
                    return prev.includes(selected) ? prev.filter((item) => item !== selected) : [...prev, selected]
                })
            }
        },
        [activeIcon]
    )

    useEffect(() => {
        if (activeIcon === "graph") {
            setSelectedBSR(["BSR L"])
        }

        if (
            activeIcon === "table" &&
            ["Paid", "Organic"].every((filter) => disabledFilter.includes(filter as "Paid" | "Organic"))
        ) {
            setDisabledFilter(["Paid"])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIcon])

    const sinceDate = useMemo(() => {
        return dateHelper.getSinceDate(selectedRange)
    }, [selectedRange])

    useEffect(() => {
        if (
            !isKeywordPerformanceLoading &&
            searchParams?.sp_id &&
            selectedSearchpackKeywordPerformance?.selectedProducts?.asin
        ) {
            const options: { as_user?: string; since?: string; until?: string } = {}

            if (selectedRange && selectedRange !== 0) {
                if (sinceDate) {
                    options.since = sinceDate
                }
            }

            if (userActionData) {
                options.as_user = userActionData.id + ""
            }

            dispatch(
                SearchpackActionCreator.getSearchpackKeywordPerformanceBsr(
                    Number(searchParams.sp_id || "0"),
                    selectedSearchpackKeywordPerformance.selectedProducts.asin,
                    {},
                    options
                )
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchParams?.sp_id,
        selectedSearchpackKeywordPerformance?.selectedProducts?.asin,
        selectedRange,
        userActionData,
    ])

    useEffect(() => {
        if (searchParams?.sp_id) {
            const options: { as_user?: string; since?: string; until?: string } = {}

            if (selectedRange && selectedRange !== 0) {
                if (sinceDate) {
                    options.since = sinceDate
                }
            }

            if (userActionData) {
                options.as_user = userActionData.id + ""
            }

            dispatch(
                SearchpackActionCreator.getSearchpackKeywordPerformance(Number(searchParams.sp_id || "0"), {}, options)
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams?.sp_id, selectedRange, userActionData, refetchSearchpacks])

    const handleFilterClick = (filter: "Paid" | "Organic") => {
        if (activeIcon === "graph") {
            setDisabledFilter((prev) => {
                if (prev.includes(filter)) {
                    // Don't remove if it's the last item
                    if (prev.length === 1) return prev
                    return prev.filter((f) => f !== filter)
                }
                return [...prev, filter]
            })
        } else {
            // In table view, only allow one selection
            setDisabledFilter([filter])
        }
    }
    return (
        <div>
            {selectedSearchpackLastDataTime &&
            selectedSearchpackLastDataTime?.search_data_last_available_at === null ? (
                <SearchpackTrackerOnboarding />
            ) : null}

            <div className={"flex justify-between flex-wrap mb-[12px] w-full h-[36px]"}>
                <div className={"flex gap-x-[12px]  items-center h-[36px]"}>
                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[96px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Filter for Paid or Organic" />
                        )}
                        className={`cursor-pointer outline-none px-[16px] py-[8px] h-[36px] inline-flex items-center gap-[12px] bg-white border border-gray-200 rounded-[10px] ${
                            isDisabled || isKeywordPerformanceLoading ? "cursor-not-allowed pointer-events-none" : ""
                        } `}
                    >
                        <PrimaryText
                            onClick={() => handleFilterClick("Paid")}
                            className={`text-gray-700 leading-[18px] cursor-pointer mt-[1px] ${
                                disabledFilter.includes("Paid") ? "text-gray-700" : "text-gray-200"
                            }
                            ${
                                isDisabled || isKeywordPerformanceLoading
                                    ? "cursor-not-allowed pointer-events-none text-gray-200"
                                    : ""
                            }    
                            `}
                            size="small"
                            weight="light"
                        >
                            Paid
                        </PrimaryText>
                        <PrimaryText
                            onClick={() => handleFilterClick("Organic")}
                            className={`text-gray-700 leading-[18px] cursor-pointer mt-[1px] ${
                                disabledFilter.includes("Organic") ? "text-gray-700" : "text-gray-200"
                            }
                              ${
                                  isDisabled || isKeywordPerformanceLoading
                                      ? "cursor-not-allowed pointer-events-none text-gray-200"
                                      : ""
                              }     
                            `}
                            size="small"
                            weight="light"
                        >
                            Organic
                        </PrimaryText>
                    </div>
                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[45px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Filter Keywords" />
                        )}
                        className={`cursor-pointer outline-none px-[16px] py-[8px] h-[36px] inline-flex items-center gap-[12px] bg-white border border-gray-200 rounded-[10px]
                            ${
                                isDisabled || isKeywordPerformanceLoading
                                    ? " cursor-not-allowed pointer-events-none"
                                    : ""
                            }
                        `}
                    >
                        <PrimaryText
                            onClick={() => handleBSRSelection("BSR L")}
                            className={`leading-[18px] cursor-pointer mt-[2px] ${
                                selectedBSR.includes("BSR L") ? "text-gray-700" : "text-gray-200"
                            }
                              ${
                                  isDisabled || isKeywordPerformanceLoading
                                      ? "cursor-not-allowed pointer-events-none text-gray-200"
                                      : ""
                              }     
                            `}
                            size="small"
                            weight="light"
                        >
                            BSR L
                        </PrimaryText>
                        <PrimaryText
                            onClick={() => handleBSRSelection("BSR S")}
                            className={`leading-[18px] cursor-pointer mt-[2px] ${
                                selectedBSR.includes("BSR S") ? "text-gray-700" : "text-gray-200"
                            }
                              ${
                                  isDisabled || isKeywordPerformanceLoading
                                      ? "cursor-not-allowed pointer-events-none text-gray-200"
                                      : ""
                              }     
                            `}
                            size="small"
                            weight="light"
                        >
                            BSR S
                        </PrimaryText>
                    </div>

                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[48px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Set Time Period" />
                        )}
                        className={`${
                            isDisabled || isKeywordPerformanceLoading ? " cursor-not-allowed pointer-events-none" : ""
                        }`}
                    >
                        <ButtonGroups
                            onClickHandler={(option) => {
                                dispatch(RuntimeActionCreators.closeAllPopup())
                                return setSelectedRange(option.value as number)
                            }}
                            // disabled={isLoading}
                            defaultValue={isDisabled || isKeywordPerformanceLoading ? 0 : selectedRange}
                            options={keywordPerfromanceConfig.rankingRange}
                            tooltipLabel="Set Time Period"
                            showTooltip={false}
                        />
                    </div>
                </div>
                <div
                    data-tooltip-id="dashboard-tooltip"
                    data-tooltip-place="bottom"
                    data-tooltip-offset={-10}
                    data-tooltip-class-name={"!ml-[38px]"}
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        <CommonTooltip className="" label="Filter Keywords" />
                    )}
                    className={`cursor-pointer outline-none ${isDisabled ? "pointer-events-none" : ""}`}
                >
                    <div className="inline-flex items-center bg-white border overflow-hidden border-gray-200 rounded-[10px]">
                        {/* Table Icon */}
                        <div
                            onClick={() => !(isDisabled || isKeywordPerformanceLoading) && setActiveIcon("table")}
                            className={`p-[8px] border-r border-gray-200  ${
                                activeIcon === "table" && !(isDisabled || isKeywordPerformanceLoading)
                                    ? "bg-active-buttons-bg"
                                    : "bg-white"
                            }`}
                        >
                            <div
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-place="bottom"
                                data-tooltip-offset={2}
                                data-tooltip-class-name={"!ml-[-20px]"}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip className="" label="View Table" />
                                )}
                            >
                                <TableIcon
                                    color={
                                        isDisabled || isKeywordPerformanceLoading
                                            ? "#D0D5DD"
                                            : activeIcon === "table"
                                            ? "#0E7090"
                                            : "#667085"
                                    }
                                />
                            </div>
                        </div>

                        {/* Graph Icon */}
                        <div
                            onClick={() => {
                                if (!isKeywordPerformanceLoading && !isDisabled) {
                                    setActiveIcon("graph")
                                    setDisabledFilter(["Paid", "Organic"])
                                }
                            }}
                            className={`p-[8px] ${
                                activeIcon === "graph" && !(isDisabled || isKeywordPerformanceLoading)
                                    ? "bg-active-buttons-bg"
                                    : "bg-white"
                            }`}
                        >
                            <div
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-place="bottom"
                                data-tooltip-offset={2}
                                data-tooltip-class-name={"!ml-[26px]"}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip className="" label="View Graph" />
                                )}
                            >
                                <GraphIcon
                                    color={
                                        isDisabled || isKeywordPerformanceLoading
                                            ? "#D0D5DD"
                                            : activeIcon === "graph"
                                            ? "#0E7090"
                                            : "#667085"
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-1">
                {activeIcon === "table" && (
                    <KeywordPerformanceTable
                        selectedRange={selectedRange}
                        selectedBSR={selectedBSR}
                        selectedDisabledFilter={disabledFilter}
                    />
                )}
                {activeIcon === "graph" && (
                    <KeywordPerformanceGraphView
                        selectedFilter={disabledFilter}
                        selectedBSR={selectedBSR}
                        selectedRange={selectedRange}
                        loading={isKeywordPerformanceLoading}
                    />
                )}
            </div>
        </div>
    )
}

export default KeywordPerformance
