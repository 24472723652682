export const ListingAnalyzerIcon = (props: IArrowProps) => {
    return (
        <>
            <svg
                className={props.className}
                width={props.width ?? "20"}
                height={props.height ?? "20"}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.3334 9.99999C18.3334 14.6024 14.6024 18.3333 10 18.3333M18.3334 9.99999C18.3334 5.39762 14.6024 1.66666 10 1.66666M18.3334 9.99999H15M10 18.3333C5.39765 18.3333 1.66669 14.6024 1.66669 9.99999M10 18.3333V15M1.66669 9.99999C1.66669 5.39762 5.39765 1.66666 10 1.66666M1.66669 9.99999H5.00002M10 1.66666V4.99999"
                    stroke={props.color}
                    strokeWidth={props.strokeWidth ?? "1.2"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}
