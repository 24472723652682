export const ModalVideoUnavailable = () => {
    return (
        <>
            <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.334 18.333C12.2589 18.333 7.33398 23.2579 7.33398 29.333V58.6663C7.33398 64.7415 12.2589 69.6663 18.334 69.6663H51.334C56.2937 69.6663 60.4868 66.3839 61.8593 61.8728M62.334 43.9997L75.6598 30.6739C77.2305 29.1031 78.0159 28.3178 78.6901 28.2647C79.2752 28.2187 79.8469 28.4555 80.2281 28.9017C80.6673 29.416 80.6673 30.5267 80.6673 32.748V55.2513C80.6673 57.4726 80.6673 58.5833 80.2281 59.0976C79.8469 59.5439 79.2752 59.7807 78.6901 59.7346C78.0159 59.6816 77.2305 58.8962 75.6598 57.3255L62.334 43.9997ZM62.334 43.9997V35.933C62.334 29.7724 62.334 26.6921 61.1351 24.3391C60.0804 22.2693 58.3977 20.5865 56.3279 19.5319C53.9748 18.333 50.8946 18.333 44.734 18.333H34.834M7.33398 7.33301L80.6673 80.6663"
                    stroke="#475467"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}
