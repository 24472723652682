import { useCallback, useEffect, useState } from "react"

const useDebouncedInput = (initialValue: string, delay: number) => {
    const [inputValue, setInputValue] = useState(initialValue)
    const [debouncedValue, setDebouncedValue] = useState(initialValue)
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event?.target?.value ?? event
            setInputValue(value)

            const newTimeoutId = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)
            setTimeoutId(newTimeoutId)
        },
        [delay]
    )

    // Clear timeout on component unmount
    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [timeoutId])

    return { inputValue, debouncedValue, handleChange }
}

export default useDebouncedInput
