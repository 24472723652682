import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import icon from "../../../assets/gifs/new-blinking-logo.gif"
import { KeywordPerformanceIcon } from "../../../assets/svgs/keywordPerformance.svg"
import { RankSnapShotIcon } from "../../../assets/svgs/rankSnapShot.svg"
import { RankTrackerIcon } from "../../../assets/svgs/RankTracker.svg"
import { PrimaryText } from "../../elements/primaryText.element"

const SearchpackSkeletonLoader = () => {
    const dispatch = useDispatch()

    const handleAddSearchpack = () => {
        dispatch(
            RuntimeActionCreators.openAddSearchpackModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    return (
        <div
            className="bg-gray-50 p-[12px]  rounded-[16px] w-full "
            style={{
                height: "calc(100vh - 165px)",
                border: "1px solid",
                borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
            }}
        >
            <div className="border-2 border-[#088AB2] rounded-[4px]">
                <div className="max-w-[690px] w-full mx-auto py-[48px] flex flex-col items-center justify-center gap-[16px]">
                    <div className="w-[60px] h-[54px]">
                        <img src={icon} alt="blinking logo" className="h-full w-full" />
                    </div>
                    <PrimaryText size="sm-medium" weight="medium" className="leading-[24px] text-gray-600">
                        Welcome to my <span className="text=-[16px] font-[500] text-[#088AB2]">Search Tracker</span>
                    </PrimaryText>
                    <PrimaryText weight="medium" size="sm-medium" className="text-center text-gray-600 max-w-[600px]">
                        Three uniquely powerful tools that allow you to analyze keyword performance alongside listing
                        change and market performance data
                    </PrimaryText>

                    <div className="grid grid-cols-3 gap-x-[24px] mt-[16px]">
                        <div className="flex flex-col items-center justify-center bg-[#EAECF0] rounded-[10px] p-[20px]">
                            <div className="p-[8px] inline-flex items-center justify-center rounded-[12px] bg-[#A5F0FC]">
                                <RankSnapShotIcon />
                            </div>
                            <PrimaryText className="text-[#088AB2] text-center mt-[16px]" weight="medium" size="small">
                                Rank Snapshot
                            </PrimaryText>
                            <PrimaryText weight="light" size="small" className="text-gray-600 mt-[4px] text-center">
                                Get an instant day-by-day view of keyword rankings
                            </PrimaryText>
                        </div>
                        <div className="flex flex-col items-center justify-center bg-[#EAECF0] rounded-[10px] p-[20px]">
                            <div className="p-[8px] inline-flex items-center justify-center rounded-[12px] bg-[#A5F0FC]">
                                <RankTrackerIcon />
                            </div>
                            <PrimaryText className="text-[#088AB2] mt-[16px] text-center" weight="medium" size="small">
                                Rank Tracker
                            </PrimaryText>
                            <PrimaryText weight="light" size="small" className="text-gray-600 mt-[4px] text-center">
                                Analyze keyword rankings over time for multiple ASINs
                            </PrimaryText>
                        </div>
                        <div className="bg-[#EAECF0] rounded-[10px] p-[20px] flex flex-col items-center justify-center">
                            <div className="p-[8px] inline-flex items-center justify-center rounded-[12px] bg-[#A5F0FC]">
                                <KeywordPerformanceIcon />
                            </div>
                            <PrimaryText className="text-[#088AB2] text-center mt-[16px]" weight="medium" size="small">
                                Keyword Performance
                            </PrimaryText>
                            <PrimaryText weight="light" size="small" className="text-gray-600 mt-[4px] text-center">
                                Monitor search rankings and BSR ASIN-by-ASIN
                            </PrimaryText>
                        </div>
                    </div>

                    <button
                        onClick={handleAddSearchpack}
                        type="button"
                        className=" mt-[16px] py-[8px] px-[16px] rounded-[10px] inline-flex items-center justify-center text-[#088AB2] font-[500] text-[14px] leading-[22px]"
                        style={{
                            background: "linear-gradient(117.03deg, #CFF9FE 0%, #A5F0FC 100%)",
                        }}
                    >
                        Create your first Searchpack
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SearchpackSkeletonLoader
