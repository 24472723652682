export const VideoIconTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
        >
            <path
                d="M5.83301 6.38589C5.83301 5.33113 5.83301 4.80376 6.0826 4.51304C6.30005 4.25978 6.6324 4.10306 6.99218 4.08414C7.40516 4.06241 7.90317 4.35495 8.89921 4.94002L21.8611 12.5538C22.6841 13.0372 23.0956 13.279 23.239 13.5836C23.3644 13.85 23.3644 14.1494 23.239 14.4157C23.0956 14.7204 22.6841 14.9621 21.8611 15.4455L8.89921 23.0593C7.90317 23.6444 7.40516 23.9369 6.99218 23.9152C6.6324 23.8963 6.30005 23.7396 6.0826 23.4863C5.83301 23.1956 5.83301 22.6682 5.83301 21.6135V6.38589Z"
                stroke={props.stroke}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
