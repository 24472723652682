export const SearchPackHubIcon = (props: IPropsIcon) => {
    return (
        <>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.1666 8.16667L13.8652 5.56374C13.4906 4.8146 13.3033 4.44002 13.0239 4.16636C12.7768 3.92435 12.479 3.7403 12.1521 3.62753C11.7823 3.5 11.3636 3.5 10.526 3.5H6.06665C4.75986 3.5 4.10646 3.5 3.60734 3.75432C3.16829 3.97802 2.81134 4.33498 2.58763 4.77402C2.33331 5.27315 2.33331 5.92654 2.33331 7.23333V8.16667M2.33331 8.16667H20.0666C22.0268 8.16667 23.0069 8.16667 23.7556 8.54814C24.4142 8.8837 24.9496 9.41913 25.2852 10.0777C25.6666 10.8264 25.6666 11.8065 25.6666 13.7667V18.9C25.6666 20.8602 25.6666 21.8403 25.2852 22.589C24.9496 23.2475 24.4142 23.783 23.7556 24.1185C23.0069 24.5 22.0268 24.5 20.0666 24.5H7.93331C5.97313 24.5 4.99304 24.5 4.24435 24.1185C3.58578 23.783 3.05035 23.2475 2.71479 22.589C2.33331 21.8403 2.33331 20.8602 2.33331 18.9V8.16667ZM18.0833 20.4167L16.3333 18.6667M17.5 15.75C17.5 18.0052 15.6718 19.8333 13.4166 19.8333C11.1615 19.8333 9.33331 18.0052 9.33331 15.75C9.33331 13.4948 11.1615 11.6667 13.4166 11.6667C15.6718 11.6667 17.5 13.4948 17.5 15.75Z"
                    stroke={props.color ?? "#D0D5DD"}
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}
