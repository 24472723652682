import { useMemo } from "react"
import dateHelper from "../../../../helpers/date"
import { SEATTLE_TIMEZONE } from "../../../../helpers/util.helper"
import { dateUtils } from "../../../../utils/date"
import { extendedDayjs } from "../../../../utils/dayjs"

type TTypeHint = "paid" | "organic"

type TAsinRank = IAsinRank & { typeHint: TTypeHint }

type TDataItem = {
    timestamp: number
    label: string
    asinRanks: TAsinRank[]
}

type TData = TDataItem[]

type TASINData = {
    color:
        | {
              default: string
              hover: string
              active: string
              inActive: string
              bgHundred: string
          }
        | {
              default: string
          }
    asin: string
    data: {
        typeHint: "paid" | "organic"
        start: string
        end: string
        data: {
            label: string
            timestamp: number
            value: number
        }[]
    }[]
}[]

type TChangeData = {
    asin: string

    color: {
        default: string
        hover: string
        active: string
        inActive: string
        bgHundred: string
    }

    product: {
        product: {
            [day: string]: IListingChanges[]
        }
        color: {
            default: string
            hover: string
            active: string
            inActive: string
            bgHundred: string
        }
    }

    data: { timestamp: number; label: string; value: number }[]
}

const groupASINData = (data: TData) => {
    return data
        .reduce<
            {
                asin: string
                data: {
                    typeHint: "paid" | "organic"
                    start: string
                    end: string
                    data: { timestamp: number; value: number; label: string }[]
                }[]
            }[]
        >((asins, item) => {
            return item.asinRanks.reduce((acc, asinRank) => {
                let asin = acc.find((i) => i.asin === asinRank.asin)
                if (!asin) {
                    asin = { asin: asinRank.asin, data: [] }
                    acc.push(asin)
                }

                let range = asin.data.find(
                    (i) =>
                        extendedDayjs(i.end).isSame(extendedDayjs(item.label).subtract(1, "day"), "day") &&
                        i.typeHint === asinRank.typeHint
                )

                if (!range) {
                    range = { typeHint: asinRank.typeHint, start: item.label, end: item.label, data: [] }
                    asin.data.push(range)
                }

                range.data.push({
                    value: parseInt(`${asinRank[asinRank.typeHint] || "0"}`),
                    timestamp: item.timestamp,
                    label: item.label,
                })
                range.end = item.label

                return acc
            }, asins)
        }, [])
        .map((item) => {
            item.data = item.data.map((next) => {
                if (next.data.length === 1)
                    next.data = [
                        next.data[0],
                        {
                            ...next.data[0],
                            timestamp: next.data[0].timestamp + 9000,
                        },
                    ]
                return next
            })

            return item
        })
}

const useChangeData = (
    asinData: TASINData,
    maxValue: number,
    uniqueAsins: {
        asin: string
        color: {
            default: string
            hover: string
            active: string
            inActive: string
            bgHundred: string
        }
    }[],
    changesData?: IGroupedListingChanges,
    dateRange?: number[]
) =>
    useMemo(() => {
        const groupedChanges: TChangeData[] = []
        const availableChanges = changesData?.groupedListingChanges || {}

        Object.keys(availableChanges).reduce((acc, asin) => {
            const asinChanges = availableChanges[asin]
            const uniqueAsin = uniqueAsins.find((i) => i.asin === asin)
            const product = changesData?.groupedListingChanges[asin]
            if (!asinChanges || !uniqueAsin || !product) return acc

            Object.keys(asinChanges).forEach((changeKey) => {
                const changes = asinChanges[changeKey]
                if (!changes) return

                changes.forEach((change) => {
                    const label = extendedDayjs(change.timestamp).startOf("day").format("YYYY-MM-DD")
                    const timestamp = extendedDayjs(change.timestamp).startOf("day").unix()
                    if (dateRange && !dateRange.includes(timestamp)) return

                    let groupedChange = groupedChanges.find((i) => i.asin === asin)
                    if (!groupedChange) {
                        groupedChange = {
                            ...uniqueAsin,
                            product: { product, color: uniqueAsin.color },
                            data: [],
                        }

                        groupedChanges.push(groupedChange)
                    }

                    const changeObj = groupedChange.data.find((i: any) => i.timestamp === timestamp)
                    if (!changeObj) {
                        const value = asinData.reduce(
                            (acc, thisAsinData) =>
                                thisAsinData.data.reduce((rank, ranks) => {
                                    const item = ranks.data.find(
                                        (item) => thisAsinData.asin === asin && item.timestamp === timestamp
                                    )

                                    if (item && item.value > rank) return item.value
                                    return rank
                                }, acc),
                            0
                        )

                        groupedChange.data.push({
                            timestamp,
                            label,
                            value: value || maxValue,
                        })
                    }
                })
            })

            return acc
        }, [] as TChangeData[])

        return groupedChanges
    }, [asinData, changesData?.groupedListingChanges, dateRange, maxValue, uniqueAsins])

const useRange = (asinData: TASINData, formedRange: string[], range: number) =>
    useMemo(() => {
        if (range) {
            const today = extendedDayjs().tz(SEATTLE_TIMEZONE).format("YYYY-MM-DD")
            const hasToday = !!asinData.find(
                (asinRanks) => !!asinRanks.data.find((asinRank) => asinRank.data.find((i) => i.label === today))
            )

            return dateHelper.alterRangeForToday(formedRange, hasToday).map((i) => extendedDayjs(i).unix())
        }

        let startDate: string = ""
        let endDate: string = ""

        asinData.forEach((asinRanks) =>
            asinRanks.data.forEach((asinRank) => {
                if (!asinRank.start || !asinRank.end) return // Malformed
                let start = extendedDayjs(asinRank.start)
                const end = extendedDayjs(asinRank.end)

                if (start.isSame(end, "day")) {
                    if (!startDate || start.isBefore(extendedDayjs(startDate))) startDate = asinRank.start
                    if (!endDate || start.isAfter(extendedDayjs(endDate))) endDate = asinRank.start
                    return
                }

                if (start.isAfter(end, "day")) throw new Error("Invalid data!")

                do {
                    const formattedDate = start.format("YYYY-MM-DD")
                    if (!startDate || start.isBefore(extendedDayjs(startDate))) startDate = formattedDate
                    if (!endDate || start.isAfter(extendedDayjs(endDate))) endDate = formattedDate
                    start = start.add(1, "day")
                } while (!start.isAfter(end, "day"))
            })
        )

        const rangeArray =
            startDate === endDate ? (!!startDate ? [startDate] : []) : dateUtils.calendar.formRange(startDate, endDate)

        return rangeArray.map((d) => extendedDayjs(d).unix()).sort((a, b) => a - b)
    }, [asinData, formedRange, range])

export const useRankTrackerGraphData = (
    keywords?: ISearchpackKeywordData[],
    asinSelection?: ISelectedSearchpacProductRank,
    changesData?: IGroupedListingChanges,
    range?: number,
    over?: () => void
) => {
    const formedRange = useMemo(
        () => (!range ? [] : dateUtils.calendar.formRangeFromToday(range, false, true, SEATTLE_TIMEZONE)),
        [range]
    )

    const uniqueAsins = useMemo(() => {
        return (
            asinSelection?.selectedProducts?.map?.((product) => {
                return {
                    asin: product.asin,
                    color: product.color,
                }
            }) || []
        )
    }, [asinSelection])

    const sortedData = useMemo(() => {
        const today = extendedDayjs().tz(SEATTLE_TIMEZONE).format("YYYY-MM-DD")
        let hasToday = false

        return keywords
            ? dateUtils
                  .sortObjectsByDate("label", keywords, "YYYY-MM-DD", "asc")
                  .map((item) => {
                      const timestamp = extendedDayjs(item.label).unix()
                      hasToday = item.label === today
                      const duplicates: (AsinRank & {
                          duplicate: boolean
                          typeHint: TTypeHint
                          label: string
                      })[] = []

                      const updateItem = {
                          ...item,
                          timestamp,
                          asinRanks: (
                              item.asinRanks as unknown as (AsinRank & {
                                  duplicate: boolean
                                  typeHint: TTypeHint
                                  label: string
                              })[]
                          )
                              .filter((rank) => Number.isInteger(rank.organic) || Number.isInteger(rank.paid))
                              .map((rank) => {
                                  if (rank.duplicate) return rank

                                  const updatedRank = {
                                      ...rank,
                                      label: item.label,
                                      timestamp,
                                      typeHint: (Number.isInteger(rank.organic) ? "organic" : "paid") as TTypeHint,
                                      duplicate: Number.isInteger(rank.organic) && Number.isInteger(rank.paid),
                                  }

                                  if (updatedRank.duplicate) duplicates.push({ ...updatedRank, typeHint: "paid" })

                                  return updatedRank
                              }),
                      }

                      updateItem.asinRanks = updateItem.asinRanks.concat(duplicates)
                      return updateItem
                  })
                  .filter((item) =>
                      !range ? true : dateHelper.alterRangeForToday(formedRange, hasToday).includes(item.label)
                  )
            : []
    }, [formedRange, keywords, range])

    const asinData = groupASINData(sortedData as unknown as TData).map((item) => ({
        ...item,
        color: uniqueAsins.find((i) => i.asin === item.asin)?.color || { default: "#000" },
    }))

    const maxValue = useMemo(
        () =>
            asinData.reduce(
                (max, asin) =>
                    asin.data.reduce(
                        (max, line) => line.data.reduce((max, item) => (item.value > max ? item.value : max), max),
                        max
                    ),
                0
            ),
        [asinData]
    )

    const dateRange = useRange(asinData, formedRange, range || 0)
    const changeData = useChangeData(asinData, maxValue, uniqueAsins, changesData, dateRange)
    const rankTrackerGraphData = { changeData, dateRange, asinData }

    // debug purpose
    console.log("rankTrackerGraphData -:-", rankTrackerGraphData)

    return rankTrackerGraphData
}
