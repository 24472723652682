import Skeleton from "react-loading-skeleton"
import { ReactComponent as LeftScrollArrow } from "../../../assets/svgs/leftScrollArrow.svg"
import { ReactComponent as RightScrollArrow } from "../../../assets/svgs/rightScrollArrow.svg"

const KeywordPerformanceTableSkeleton = ({ isStatic }: CardSkeletonLoaderProps) => {
    const skeletonBaseColor = "#EAECF0"
    const skeletonHighlightColor = "#D0D5DD"
    const lightSkeletonHighlightColor = "#EAECF0"
    return (
        <>
            <div className={"overflow-auto top-scroll -mr-[28px]"}>
                <div
                    className=""
                    style={{
                        maxHeight: "calc(100vh - 350px)",
                        width: "calc(100% - 28px)",
                    }}
                >
                    <div className="rounded-[10px] h-full  border border-gray-200 bg-gray-200 rounded-bl-[10px] rounded-br-[10px] overflow-hidden ">
                        <div className="flex items-center pt-[12px] gap-x-[32px] pr-[12px] pl-[34px]  ">
                            <div className="min-w-[297px]"></div>

                            <div className="text-black flex items-center flex-1 gap-[10px]">
                                {/* Left Arrow */}
                                <button className="cursor-not-allowed">
                                    <LeftScrollArrow />
                                </button>

                                <div className="relative w-full bg-gray-200 h-[4px] my-[3px]">
                                    <div className="bg-[#D0D5DD] h-[4px] absolute rounded-[8px] cursor-pointer [&>span]:flex [&>span]:items-center [&>span]:h-[4px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={136}
                                            height={4}
                                        />
                                    </div>
                                </div>

                                {/* Right Arrow */}
                                <button className={`  cursor-not-allowed`}>
                                    <RightScrollArrow />
                                </button>
                            </div>
                        </div>
                        <div className={` scroll-hidden overflow-x-auto `}>
                            <div className="bg-gray-200">
                                <div
                                    className={
                                        "flex py-[16px] min-w-fit  rounded-tr-[8px] rounded-tl-[8px] z-20 relative"
                                    }
                                >
                                    <div
                                        className={
                                            " pl-[24px] flex items-end sticky left-0 bg-gray-200 justify-start min-w-[376px] [&>span]:flex [&>span]:items-end"
                                        }
                                    >
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={152}
                                            height={12}
                                        />
                                    </div>

                                    <div className="block mx-[8px] last:mr-0 last:pr-[32px]">
                                        <div className="p-[10px] keyword_loading_card bg-white rounded-[6px] min-w-[64px]">
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px] !h-full"
                                                count={1}
                                                width={44}
                                                height={32}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                        </div>
                                    </div>
                                    <div className="block mx-[8px] last:mr-0 last:pr-[32px]">
                                        <div className="p-[10px] keyword_loading_card bg-white rounded-[6px] min-w-[64px]">
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px] !h-full"
                                                count={1}
                                                width={44}
                                                height={32}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                        </div>
                                    </div>
                                    <div className="block mx-[8px] last:mr-0 last:pr-[32px]">
                                        <div className="p-[10px] keyword_loading_card bg-white rounded-[6px] min-w-[64px]">
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px] !h-full"
                                                count={1}
                                                width={44}
                                                height={32}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                        </div>
                                    </div>
                                    <div className="block mx-[8px] last:mr-0 last:pr-[32px]">
                                        <div className="p-[10px] keyword_loading_card bg-white rounded-[6px] min-w-[64px]">
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px] !h-full"
                                                count={1}
                                                width={44}
                                                height={32}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                        </div>
                                    </div>
                                    <div className="block mx-[8px] last:mr-0 last:pr-[32px]">
                                        <div className="p-[10px] keyword_loading_card bg-white rounded-[6px] min-w-[64px]">
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px] !h-full"
                                                count={1}
                                                width={44}
                                                height={32}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                        </div>
                                    </div>
                                    <div className="block mx-[8px] last:mr-0 last:pr-[32px]">
                                        <div className="p-[10px] keyword_loading_card bg-white rounded-[6px] min-w-[64px]">
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px] !h-full"
                                                count={1}
                                                width={44}
                                                height={32}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                        </div>
                                    </div>
                                    <div className="block mx-[8px] last:mr-0 last:pr-[32px]">
                                        <div className="p-[10px] keyword_loading_card bg-white rounded-[6px] min-w-[64px]">
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px] !h-full"
                                                count={1}
                                                width={44}
                                                height={32}
                                            />
                                            <Skeleton
                                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                                baseColor={skeletonBaseColor}
                                                className="rounded-[6px]"
                                                count={1}
                                                width={28}
                                                height={8}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Sub-header for Keywords and PAID | ORG. */}
                                <div className="flex border-y  border-[#EEE] min-w-fit bg-gray-100 z-10 relative">
                                    <div
                                        className={
                                            "py-[16px] bg-gray-100 pl-[24px] flex items-center sticky left-0 min-w-[376px]  justify-start [&>span]:flex [&>span]:items-center [&>span]:h-[8px] "
                                        }
                                    >
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={152}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                </div>
                                <div className="flex border-b border-[#EEE] min-w-fit bg-gray-100 z-10 relative">
                                    <div
                                        className={
                                            "py-[16px] bg-gray-100 pl-[24px] flex items-center sticky left-0 min-w-[376px]  justify-start [&>span]:flex [&>span]:items-center [&>span]:h-[8px] "
                                        }
                                    >
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={152}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                    <div className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:h-[8px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonHighlightColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={32}
                                            height={8}
                                        />
                                    </div>
                                </div>
                                {/* Data Rows */}
                                <div className="flex border-b border-[#EEE] min-w-fit bg-white z-10 relative last:border-b-0 last:rounded-br-[8px] last:rounded-bl-[8px]">
                                    {/* Keywords Column */}
                                    <div
                                        className={
                                            "py-[20px] pl-[24px] flex items-center bg-white sticky left-0 min-w-[376px]  justify-start [&>span]:flex [&>span]:items-center [&>span]:h-[12px] "
                                        }
                                    >
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonBaseColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={152}
                                            height={12}
                                        />
                                    </div>
                                    <div className="min-w-[64px] flex items-center h-[32px] mx-[8px] justify-center rounded-[6px] my-[12px] last:mr-[42px] [&>span]:flex [&>span]:items-center [&>span]:h-[12px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonBaseColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={64}
                                            height={32}
                                        />
                                    </div>
                                    <div className="min-w-[64px] flex items-center h-[32px] mx-[8px] justify-center rounded-[6px] my-[12px] last:mr-[42px] [&>span]:flex [&>span]:items-center [&>span]:h-[12px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonBaseColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={64}
                                            height={32}
                                        />
                                    </div>
                                    <div className="min-w-[64px] flex items-center h-[32px] mx-[8px] justify-center rounded-[6px] my-[12px] last:mr-[42px] [&>span]:flex [&>span]:items-center [&>span]:h-[12px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonBaseColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={64}
                                            height={32}
                                        />
                                    </div>
                                    <div className="min-w-[64px] flex items-center h-[32px] mx-[8px] justify-center rounded-[6px] my-[12px] last:mr-[42px] [&>span]:flex [&>span]:items-center [&>span]:h-[12px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonBaseColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={64}
                                            height={32}
                                        />
                                    </div>
                                    <div className="min-w-[64px] flex items-center h-[32px] mx-[8px] justify-center rounded-[6px] my-[12px] last:mr-[42px] [&>span]:flex [&>span]:items-center [&>span]:h-[12px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonBaseColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={64}
                                            height={32}
                                        />
                                    </div>
                                    <div className="min-w-[64px] flex items-center h-[32px] mx-[8px] justify-center rounded-[6px] my-[12px] last:mr-[42px] [&>span]:flex [&>span]:items-center [&>span]:h-[12px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonBaseColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={64}
                                            height={32}
                                        />
                                    </div>
                                    <div className="min-w-[64px] flex items-center h-[32px] mx-[8px] justify-center rounded-[6px] my-[12px] last:mr-[42px] [&>span]:flex [&>span]:items-center [&>span]:h-[12px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonBaseColor}
                                            className="rounded-[6px]"
                                            count={1}
                                            width={64}
                                            height={32}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div style={{ height: "12px" }}></div> */}
                </div>
            </div>

            <div className="flex rounded-[10px] mt-[10px] border border-[#EEE] bg-white relative items-center justify-between w-full">
                {/* Keywords Column */}
                <div
                    className={
                        "py-[16px] pl-[24px] flex items-center  min-w-[376px]  justify-start [&>span]:flex [&>span]:items-center [&>span]:h-[12px]"
                    }
                >
                    <Skeleton
                        highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                        baseColor={skeletonBaseColor}
                        className="rounded-[6px]"
                        count={1}
                        width={152}
                        height={12}
                    />
                </div>

                <div className="py-[16px] flex-1">
                    <div className=" flex items-center mx-[16px] [&>span]:flex [&>span]:items-center [&>span]:h-[8px]">
                        {/* Gradient background */}
                        <Skeleton
                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                            baseColor={skeletonBaseColor}
                            className="rounded-[6px]"
                            count={1}
                            width={48}
                            height={8}
                        />
                        <div
                            className=" mx-[12px] [&>span]:flex [&>span]:items-center [&>span]:h-[8px] [&>span]:w-full"
                            style={{
                                width: "calc(100% - 144px)",
                                // background:
                                //     "linear-gradient(90deg,  #BDB4FE 0%, #FDE272 25%, #F7B27A 50%, #FEA3B4 75%,  #75E0A7 100%)",
                            }}
                        >
                            <Skeleton
                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                baseColor={skeletonBaseColor}
                                className="rounded-[6px] w-full"
                                count={1}
                                // width={407}
                                height={8}
                            />
                        </div>
                        <div className="[&>span]:flex [&>span]:items-center [&>span]:h-[8px]">
                            <Skeleton
                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                baseColor={skeletonBaseColor}
                                className="rounded-[6px]"
                                count={1}
                                width={48}
                                height={8}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KeywordPerformanceTableSkeleton
