import dayjs from "dayjs"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { UtilHelper } from "../helpers/util.helper"
import { ProductStateSelector } from "../selectors/product.selector"
type TGraphData = {
    [key: string]: IProductData
} & { timestamp: string }
export const useGraphData = () => {
    const { selectedProductResult, selectedProducts, selectedProductRange } = useSelector(ProductStateSelector)

    const range = useMemo(() => {
        return selectedProductRange?.selectedRange || 0
    }, [selectedProductRange?.selectedRange])

    const selectedResults = useMemo(() => {
        return selectedProductResult?.selectedResults?.value || "bsr_large"
    }, [selectedProductResult?.selectedResults?.value])

    const graphData: TGraphData[] | undefined = useMemo(() => {
        let productData = selectedProducts?.selectedProducts?.length ? selectedProducts?.selectedProducts : []
        let productDataTemp: any = {}
        productData = UtilHelper.deepCopyObject(productData)
        productData?.forEach((p) => {
            p?.product_data?.forEach((pd) => {
                productDataTemp[pd.timestamp] = {
                    ...productDataTemp[pd.timestamp],
                    timestamp: pd.timestamp,
                    [p?.actual_asin]: pd,
                }
            })
        })
        const data = (Object.values(productDataTemp) as TGraphData[])?.sort((a, b) => {
            return new Date(b.timestamp) < new Date(a.timestamp) ? 1 : -1
        })
        return data
    }, [selectedProducts?.selectedProducts])

    const rangeData = useMemo(() => {
        let startDate: dayjs.Dayjs
        const endDate: dayjs.Dayjs = dayjs().startOf("day")
        startDate = endDate.subtract(range, "day").startOf("day")
        return range === 0
            ? graphData
            : graphData.filter((record) => {
                  const timestamp: dayjs.Dayjs = dayjs(record.timestamp)
                  return timestamp.isAfter(startDate) && timestamp.isBefore(endDate.add(1, "day"))
              })
    }, [range, graphData])

    const getIndice = (data: any[], asin: string, selectedResultValue: string) => {
        return data.findIndex((el: any) => el[asin] && el[asin].hasOwnProperty(selectedResultValue))
    }

    const dataWithFilledGaps: TGraphData[] = useMemo(() => {
        const fillMissingData = (
            updatedData: any[],
            currentIndex: number,
            asin: string,
            selectedResultValue: string,
            productData: any,
            firstMissingData: boolean
        ) => {
            const nextIndex =
                getIndice(updatedData.slice(currentIndex + 1), asin, selectedResults) !== -1
                    ? getIndice(updatedData.slice(currentIndex + 1), asin, selectedResults) + currentIndex + 1
                    : -1
            const prevIndex = getIndice(updatedData.slice(0, currentIndex).reverse(), asin, selectedResultValue)
            if (nextIndex > currentIndex && prevIndex !== -1) {
                const prevData = updatedData[currentIndex - prevIndex - 1][asin][selectedResultValue]
                const nextData = updatedData[nextIndex][asin][selectedResultValue]
                const averageData = (prevData + nextData) / 2
                productData[asin][selectedResultValue] = averageData
                productData[asin]["was_filled"] = true
            }
            if (nextIndex === -1 && prevIndex !== -1) {
                const prevData = updatedData[currentIndex - prevIndex - 1][asin][selectedResultValue]
                productData[asin][selectedResultValue] = prevData
                productData[asin]["was_filled"] = true
            }
            //If the product starts from no graph value (BSR/Rating/--reset) at the start but contains data at future dates,
            // Will fill all the data points with the next available graph value and show changes if required.
            if (currentIndex === 0 && nextIndex !== -1 && prevIndex === -1) {
                const nextData = updatedData[nextIndex][asin]?.[selectedResultValue]
                productData[asin][selectedResultValue] = nextData
                productData[asin]["was_filled"] = true
            }
            // if the product has no graph value and contains other products,
            // will mock data with 10% low than the lowest from other products from the same trackpack.
            if (selectedProducts!?.selectedProducts.length > 1 && nextIndex === -1 && prevIndex === -1) {
                let lowestValue = 0
                selectedProducts?.selectedProducts?.forEach((product: IGroupProductData) => {
                    updatedData?.forEach((productData: any) => {
                        const productValue = productData[product?.actual_asin]?.[selectedResultValue]
                        if (lowestValue < productValue) {
                            lowestValue = productValue
                        }
                    })
                })
                productData[asin][selectedResultValue] = lowestValue + lowestValue * 0.1
                productData[asin]["was_filled"] = true
            }
            // if product have no graph value and no other product
            // will mock data with bsr larg=500 bsr small=100 rating=3 view count = 100
            if (selectedProducts!?.selectedProducts.length === 1 && nextIndex === -1 && prevIndex === -1) {
                if (selectedResultValue === "bsr_large") {
                    productData[asin][selectedResultValue] = 500
                } else if (selectedResultValue === "bsr_small") {
                    productData[asin][selectedResultValue] = 100
                } else if (selectedResultValue === "ratings_count") {
                    productData[asin][selectedResultValue] = 3
                } else if (selectedResultValue === "rating") {
                    productData[asin][selectedResultValue] = 100
                }
                productData[asin]["was_filled"] = true
            }
            return productData
        }

        const updatedData = UtilHelper.deepCopyObject(graphData)
        selectedProducts?.selectedProducts.forEach((product: IGroupProductData) => {
            let firstMissingData = true
            updatedData.forEach((productData: any, currentIndex: number) => {
                const selectedResultValue = selectedProductResult?.selectedResults?.value || "bsr_large"
                if (!productData[product?.actual_asin]) {
                    return
                }
                const hasMissingData = !productData[product?.actual_asin].hasOwnProperty(selectedResultValue)
                // const showWarning = productData[product.actual_asin].success.every((v: any) => v.value === false)
                // if (showWarning) {
                //     if (firstMissingData) {
                //         productData[product.actual_asin].showWarning = showWarning
                //         firstMissingData = false
                //     }
                // } else {
                //     firstMissingData = true
                // }
                if (hasMissingData) {
                    fillMissingData(
                        updatedData,
                        currentIndex,
                        product?.actual_asin,
                        selectedResultValue,
                        productData,
                        firstMissingData
                    )
                }
            })
        })
        return updatedData
    }, [graphData, selectedProductResult?.selectedResults?.value, selectedProducts, selectedResults])

    const rangeDataWithFilledGaps = useMemo(() => {
        let startDate: dayjs.Dayjs
        const endDate: dayjs.Dayjs = dayjs().startOf("day")
        startDate = endDate.subtract(range, "day").startOf("day")
        return range === 0
            ? dataWithFilledGaps
            : dataWithFilledGaps.filter((record) => {
                  const timestamp: dayjs.Dayjs = dayjs(record.timestamp)
                  return timestamp.isAfter(startDate) && timestamp.isBefore(endDate.add(1, "day"))
              })
    }, [range, dataWithFilledGaps])
    return { rangeDataWithFilledGaps, rangeData, selectedResults, graphData }
}
