import { useSelector } from "react-redux"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useLoadingStatus } from "../../../hooks/useLoadingStatus.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../selectors/searchpack.selector"
import SearchpackSkeletonLoader from "../../common/skeletonLoader/searchpackSkeletonLoader.component"
import { SearchpackSelection } from "./searchpackSelection.component"
import SearchpackSelectionTracker from "./searchpackSelectionTracker.component"

export const SearchpackTracker = () => {
    const { searchpackDropdownList } = useSelector(SearchpackStateSelector)
    const { loading } = useSelector(RunTimeStateSelector)
    const isGetRankSnapshotDataLoading: boolean = useLoadingStatus(loading, RuntimeHelper.getRanksnapshotDataLoading())

    return (
        <>
            <div
                className={"flex gap-[20px] items-start w-full "}
                // style={{ width: "calc(100% - 60px)" }}
            >
                <SearchpackSelection />
                {!isGetRankSnapshotDataLoading && searchpackDropdownList?.searchpacks?.length === 0 ? (
                    <SearchpackSkeletonLoader />
                ) : (
                    searchpackDropdownList?.searchpacks &&
                    searchpackDropdownList?.searchpacks?.length > 0 && <SearchpackSelectionTracker />
                )}
            </div>
        </>
    )
}
