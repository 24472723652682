export const LogAsinChangeIcon = (props: IIconTooltipProps) => {
    return (
        <>
            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11 10.3333H1M1 10.3333L3.5 7.66667M1 10.3333L3.5 13M1 3.66667H11M11 3.66667L8.5 1M11 3.66667L8.5 6.33333"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}
