export const NewLogoIcon = () => {
    return (
        <>
            <svg width="54" height="48" viewBox="0 0 54 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_10049_372408)">
                    <path
                        d="M45.8249 48C45.6626 48 45.508 47.9298 45.396 47.8051L42.8538 44.6133C42.2704 43.9585 41.4321 43.5805 40.5628 43.5805H35.0071C32.2447 43.5805 30 41.3162 30 38.5297V31.0508C30 28.2643 32.2447 26 35.0071 26H48.9929C51.7553 26 54 28.2643 54 31.0508V38.5297C54 41.3162 51.7553 43.5805 48.9929 43.5805H47.4784C46.895 43.5805 46.4198 44.0599 46.4198 44.6484V47.4076C46.4198 47.7779 46.1185 47.9961 45.8326 47.9961L45.8249 48Z"
                        fill="url(#paint0_linear_10049_372408)"
                    />
                    <path
                        d="M17.7262 44C17.0707 44 16.3959 43.5022 16.3959 42.6661V37.4315C16.3959 36.1909 15.3933 35.1798 14.1632 35.1798H10.8856C4.8856 35.1798 0 30.2563 0 24.2051V10.9786C0 4.92735 4.8856 0 10.8856 0H37.1182C43.1183 0 48 4.92346 48 10.9747V24.2051C48 30.2563 43.1183 35.1798 37.1182 35.1798H29.1093C27.2391 35.1798 25.4499 35.9848 24.1967 37.3887L18.6941 43.5567C18.4396 43.8405 18.0964 44 17.7262 44Z"
                        fill="url(#paint1_linear_10049_372408)"
                    />
                    <path
                        d="M36.5 19C35.1207 19 34 17.8893 34 16.5222V11.4778C34 10.1107 35.1207 9 36.5 9C37.8793 9 39 10.1107 39 11.4778V16.5222C39 17.8893 37.8793 19 36.5 19Z"
                        fill="url(#paint2_linear_10049_372408)"
                    />
                    <path
                        d="M38 12.5012C38 12.7788 37.7736 13 37.5 13C37.2264 13 37 12.7741 37 12.5012V11.4988C37 11.2212 37.2264 11 37.5 11C37.7736 11 38 11.2259 38 11.4988V12.5012Z"
                        fill="white"
                    />
                    <path
                        d="M11.5 19C10.1207 19 9 17.8893 9 16.5222V11.4778C9 10.1107 10.1207 9 11.5 9C12.8793 9 14 10.1107 14 11.4778V16.5222C14 17.8893 12.8793 19 11.5 19Z"
                        fill="url(#paint3_linear_10049_372408)"
                    />
                    <path
                        d="M13 12.5012C13 12.7788 12.7736 13 12.5 13C12.2264 13 12 12.7741 12 12.5012V11.4988C12 11.2212 12.2264 11 12.5 11C12.7736 11 13 11.2259 13 11.4988V12.5012Z"
                        fill="white"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M25.9962 23.9981C25.9962 25.1016 25.1016 25.9962 23.9981 25.9962C22.8946 25.9962 22 25.1016 22 23.9981C22 22.8946 22.8946 22 23.9981 22C25.1016 22 25.9962 22.8946 25.9962 23.9981ZM22.7676 25.2211C23.0913 25.5447 23.5315 25.7328 23.9981 25.7328C24.4647 25.7328 24.905 25.5447 25.2286 25.2211C25.2775 25.1684 25.2775 25.0818 25.2286 25.0329C24.905 24.7056 24.4647 24.5174 23.9981 24.5174C23.5315 24.5174 23.0913 24.7056 22.7676 25.0329C22.7187 25.0856 22.7187 25.1722 22.7676 25.2211Z"
                        fill="#D92D20"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_10049_372408"
                        x1="29.9999"
                        y1="36.9632"
                        x2="48.498"
                        y2="26.8749"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#1D2939" />
                        <stop offset="1" stop-color="#475467" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_10049_372408"
                        x1="0"
                        y1="0"
                        x2="43.834"
                        y2="47.8189"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#FDA29B" />
                        <stop offset="1" stop-color="#FECDCA" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_10049_372408"
                        x1="34"
                        y1="13.9833"
                        x2="38.7059"
                        y2="12.807"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#1D2939" />
                        <stop offset="1" stop-color="#475467" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_10049_372408"
                        x1="8.99997"
                        y1="13.9833"
                        x2="13.7059"
                        y2="12.807"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#1D2939" />
                        <stop offset="1" stop-color="#475467" />
                    </linearGradient>
                    <clipPath id="clip0_10049_372408">
                        <path d="M0 0H54V48H0V0Z" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
