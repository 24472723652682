import { Link, useLocation, useNavigate } from "react-router"

import AmaizingLogo from "../assets/svgs/new-logo.svg"
import { ROUTES_CONFIG } from "../config/routes.config"

export const Header = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location

    return (
        <div className="h-[80px]">
            <div className="flex justify-between flex-row items-center py-[24px] px-[26px]">
                <Link to={ROUTES_CONFIG.login}>
                    <img className="" src={AmaizingLogo} alt="amaizing-logo for header" />
                </Link>
                {pathname !== ROUTES_CONFIG.login && pathname !== ROUTES_CONFIG.authSignup && (
                    <div className="inline-block">
                        <button
                            onClick={() => navigate(ROUTES_CONFIG.welcome)}
                            type="submit"
                            className={
                                "w-full font-[500] leading-[24px] text-[#0E7090] bg-gradient-to-br from-[#CFF9FE] to-[#A5F0FC] rounded-[8px] text-[16px] px-[16px] py-[8px]"
                            }
                        >
                            Log in
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
