import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router"

import { useDispatch } from "react-redux"
import { AuthActionCreator } from "../../actions/auth.action"
import { BackArrow } from "../../assets/svgs/backArrow.svg"
import { ForgotPasswordIcons } from "../../assets/svgs/forgotPasswordIcon.svg"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { useInputValidations } from "../../hooks/inputValidations.hook"
import { AuthInputElement } from "../elements/authInput.element copy"
import { ButtonElement } from "../elements/button.element"
import { PrimaryText } from "../elements/primaryText.element"

export const ForgotPassword = () => {
    const validationConfig = useInputValidations()

    const [isLoading, setIsLoading] = useState(false)
    const { control, handleSubmit } = useForm<IInputsExampleProps>()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const onSubmit: SubmitHandler<IInputsExampleProps> = (data) => {
        const payload: Partial<IInputsExampleProps> = {
            email: data.email as string,
        }
        dispatch(
            AuthActionCreator.resetPassword(payload, () => {
                navigate(ROUTES_CONFIG.resetPasswordCheckEmail)
            })
        )
        setIsLoading(true)
    }

    return (
        <>
            <div className="w-[400px] bg-[#ECFDFF] p-[40px] pb-[32px] m-auto rounded-[16px] mt-[96px] custom-shadow">
                <div className="flex justify-center items-center">
                    <ForgotPasswordIcons />
                </div>
                <div className="mt-[24px] flex justify-center  mb-[8px]">
                    <PrimaryText typography={"3xl"} className="leading-[38px] text-gray-900">
                        Forgot your password?
                    </PrimaryText>
                </div>
                <div className="flex justify-center items-center">
                    <PrimaryText size={"md"} weight={"light"} className="text-gray-600">
                        Let me know where to send instructions.
                    </PrimaryText>
                </div>

                <div className="flex justify-center items-center mt-[32px]">
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                        <AuthInputElement
                            type="email"
                            name={"email"}
                            reactHookControl={control}
                            reactHookValidations={{
                                required: validationConfig.required,
                                pattern: validationConfig.email,
                            }}
                            placeholder="Enter your email"
                            className="mb-[24px]"
                        />
                        <ButtonElement
                            textClass="text-[16px]"
                            type="submit"
                            loading={isLoading}
                            disabled={isLoading}
                            viewType={isLoading ? "loading-button" : "primary"}
                            size="medium"
                            className="!w-full mb-[24px] bg-gradient-to-br from-[#22CCEE] to-[#06AED4] rounded-[8px] !h-[44px] text-white text-[16px]"
                        >
                            Send{" "}
                        </ButtonElement>
                        {/* </Link> */}

                        <Link
                            to={ROUTES_CONFIG.welcome}
                            className="flex items-center justify-center gap-[8px] cursor-pointer"
                        >
                            <BackArrow />
                            <PrimaryText weight="medium" size="small" className="text-[#06AED4]">
                                Back to Log in
                            </PrimaryText>
                        </Link>
                    </form>
                </div>
            </div>
        </>
    )
}
