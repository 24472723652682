import dayjs from "dayjs"
import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import Skeleton from "react-loading-skeleton"
import { useDispatch, useSelector } from "react-redux"
import type { KeywordData } from "../../../@types/app/data"
import { AccountActionsCreater } from "../../../actions/account.action"
import { ProductsActionCreator } from "../../../actions/products.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { FlipArrowIcon } from "../../../assets/svgs/flipBackwardIcon.svg"
import { InputArrow } from "../../../assets/svgs/inputArrow.svg"
import { NewEditIcon } from "../../../assets/svgs/newEditIcon.svg"
import { PlusIcon } from "../../../assets/svgs/plus.svg"
import { TrackpackTrashIcon } from "../../../assets/svgs/trashIcon.svg"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { ProductStateSelector } from "../../../selectors/product.selector"
import SearchpackHubOnboardingComponent from "../../common/skeletonLoader/searchpackHubCentralOnboarding.component"
import SearchpackHubCentralSkeletonLoader from "../../common/skeletonLoader/searchpackHubCentralSkeletonLoader.component"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import CommonTooltip from "../tooltipItems/commonTooltip.component"
import { SearchpackHubOptions } from "./searchpackHubOptions.component"

export const SearchpackHubCentral = () => {
    const dispatch = useDispatch()
    const { selectedTrackpacksList, trackpackType, selectedAsinsLength } = useSelector(AccountStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)
    const [selectedRows, setSelectedRows] = useState<string[]>([])
    const intermediateCheckboxRef = useRef<HTMLInputElement>(null)
    const containerRef = useRef<HTMLInputElement>(null)
    const [isEditing, setIsEditing] = useState(false)
    const [text, setText] = useState(selectedTrackpacksList?.title || "")
    const pathID = localStorage.getItem("trackpackHubId")
    const trackpackHubAsin = localStorage.getItem("trackpackHubAsin")
    const [isLoading, setIsLoading] = useState(false)
    const [dataLength, setDataLength] = useState<number | null>(null)

    useEffect(() => {
        // @ts-ignore
        if (selectedAsinsLength?.data === 0 && selectedRows?.length !== 0) {
            setSelectedRows([])
        }
    }, [selectedAsinsLength, selectedRows?.length])

    useEffect(() => {
        selectedAsinsValues()
        if (intermediateCheckboxRef && intermediateCheckboxRef.current) {
            if (selectedRows?.length > 0 && selectedRows?.length < selectedSearchpacksListData?.length) {
                setTimeout(() => {
                    // @ts-ignore
                    if (intermediateCheckboxRef && intermediateCheckboxRef.current) {
                        intermediateCheckboxRef.current.indeterminate = true
                    }
                }, 1)
            } else {
                intermediateCheckboxRef.current.indeterminate = false
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows?.length, selectedTrackpacksList?.searchpackChildrenList?.length])

    const selectedAsinsValues = () => {
        dispatch(AccountActionsCreater.setSelectedAsinsLength(selectedRows?.length, selectedRows))
    }

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAddKeywordsModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const handleOpenArchiveSearchpack = (packId: number, keyword: string, status: "active" | "archived") => {
        dispatch(
            RuntimeActionCreators.openArchiveRestoreSearchpackModal({
                id: packId,
                keyword,
                status,
                open: true,
                stepNumber: "one",
                isSearchpack: true,
            })
        )
    }

    const handleEditClick = () => {
        setText(selectedTrackpacksList?.title)
        setIsEditing(true)
    }

    const handleSaveClick = () => {
        setIsEditing(false)
        selectedTrackpacksList.title = text

        dispatch(
            ProductsActionCreator.updateSearchpackName({
                id: selectedTrackpacksList.id,
                name: text,
                status: "active",
            })
        )
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleSaveClick()
        }
    }

    const selectedSearchpacksListData =
        trackpackType === "tracking"
            ? selectedTrackpacksList?.searchpackChildrenList?.filter((val: KeywordData) => {
                  return val.Status.includes("ACTIVE") || val.Status.includes("AWAITING_INITIAL_DATA")
              })
            : selectedTrackpacksList?.searchpackChildrenList?.filter((val: KeywordData) => {
                  return val.Status.includes("ARCHIVED") || val.Status.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
              })

    const Table = () => {
        return isLoading ? (
            <SearchpackHubCentralSkeletonLoader />
        ) : selectedSearchpacksListData?.length === 0 ? (
            <SearchpackHubCentralSkeletonLoader isStatic={true} />
        ) : (
            <table className="w-full bg-white rounded-[10px]">
                <thead className="bg-gray-200 border-b border-gray-200">
                    <tr>
                        <th className="pl-[20px] py-[12px] text-left text-gray-500 text-xs font-normal  leading-[18px]">
                            <td className="flex-1">
                                <div className="">
                                    <PrimaryText size="xs" weight="medium" className="text-gray-700">
                                        Keywords
                                    </PrimaryText>
                                </div>
                            </td>
                        </th>
                        <th className="w-[120px] text-center">
                            <td className="w-[120px] text-center">
                                <PrimaryText size="xs" weight="medium" className="text-gray-700">
                                    {trackpackType === "tracking" ? "Date Added" : "Time to Deletion"}
                                </PrimaryText>
                            </td>
                        </th>
                        <th className="w-[66px] text-center">
                            <td className="w-[66px] text-center"></td>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {selectedSearchpacksListData &&
                        selectedSearchpacksListData?.length > 0 &&
                        selectedSearchpacksListData?.map((row: KeywordData, rowIndex: number) => {
                            return (
                                <>
                                    <tr
                                        key={rowIndex}
                                        className="border-b last:border-b-0  border-gray-200 hover:bg-gray-100 group"
                                    >
                                        <td className="pl-[20px] py-[12px] text-left text-gray-700 text-[12px]  font-[300] leading-[18px]">
                                            <div className="flex gap-[30px] items-center mt-[2px]">
                                                {row?.ASINs?.keywordName}
                                            </div>
                                        </td>

                                        <td className="px-[20px] py-[12px] text-center text-gray-700 text-[12px] font-[300] leading-[18px]">
                                            {row.Status.includes("AWAITING_INITIAL_DATA") ? (
                                                <Skeleton baseColor="#D0D5DD" count={1} width={64} height={16} />
                                            ) : trackpackType === "tracking" ? (
                                                dayjs(row["Date Added"])
                                                    .format("MMM-DD-YYYY")
                                                    ?.replace(/^\w+/, (month) => month.toUpperCase())
                                            ) : (
                                                <>{`${row?._timeToDelete} days `}</>
                                            )}
                                        </td>
                                        <td className="  w-[66px] py-[12px] text-center text-gray-700 text-[12px] font-[300] leading-[18px] flex items-center justify-center">
                                            {row.Status.includes("ACTIVE") ? (
                                                <div
                                                    className="cursor-pointer outline-none"
                                                    data-tooltip-id="dashboard-tooltip"
                                                    data-tooltip-place="bottom"
                                                    data-tooltip-offset={1}
                                                    data-tooltip-class-name={"!ml-[35px]"}
                                                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                        <CommonTooltip className="" label="Archive Keyword" />
                                                    )}
                                                >
                                                    <div
                                                        onClick={() =>
                                                            handleOpenArchiveSearchpack(
                                                                selectedTrackpacksList.id,
                                                                row?.ASINs?.keywordName,
                                                                "archived"
                                                            )
                                                        }
                                                        className={`cursor-pointer w-[24px] opacity-0 group-hover:opacity-[1] transition-all h-[24px] bg-white rounded-[15px] justify-center items-center flex z-10`}
                                                    >
                                                        <TrackpackTrashIcon width={16} height={16} />
                                                    </div>
                                                </div>
                                            ) : !!row?.Status?.find?.((item) => item?.startsWith?.("ARCHIVED")) ? (
                                                <div
                                                    className="cursor-pointer outline-none"
                                                    data-tooltip-id="dashboard-tooltip"
                                                    data-tooltip-place="bottom"
                                                    data-tooltip-offset={1}
                                                    data-tooltip-class-name={"!ml-[35px]"}
                                                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                        <CommonTooltip className="" label="Restore Keyword" />
                                                    )}
                                                >
                                                    <div
                                                        onClick={() =>
                                                            handleOpenArchiveSearchpack(
                                                                selectedTrackpacksList.id,
                                                                row?.ASINs?.keywordName,
                                                                "active"
                                                            )
                                                        }
                                                        className={`cursor-pointer z-10 w-[24px] opacity-0 group-hover:opacity-[1] transition-all h-[24px] bg-white rounded-[15px] justify-center items-center flex`}
                                                    >
                                                        <FlipArrowIcon width={16} height={16} />
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                </tbody>
            </table>
        )
    }

    const hasOnlyActiveOrAwaiting = (searchpack: KeywordData[]) => {
        return (
            searchpack &&
            searchpack?.every(
                (val) => val?.Status?.includes("ACTIVE") || val?.Status?.includes("AWAITING_INITIAL_DATA")
            )
        )
    }

    const isArchiveDisabled = useMemo(() => {
        return hasOnlyActiveOrAwaiting(selectedTrackpacksList?.searchpackChildrenList)
    }, [selectedTrackpacksList?.searchpackChildrenList])

    const hasOnlyArchivedOrAwaiting = (searchpack: KeywordData[]) => {
        return (
            searchpack &&
            searchpack?.every(
                (val) => val?.Status?.includes("ARCHIVED") || val?.Status?.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
            )
        )
    }

    const isTrackingDisabled = useMemo(() => {
        return hasOnlyArchivedOrAwaiting(selectedTrackpacksList?.searchpackChildrenList)
    }, [selectedTrackpacksList?.searchpackChildrenList])

    useEffect(() => {
        if (selectedSearchpacksListData) {
            if (isTrackingDisabled) {
                dispatch(AccountActionsCreater.setTrackpackType("archived"))
            } else if (isArchiveDisabled) {
                dispatch(AccountActionsCreater.setTrackpackType("tracking"))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isArchiveDisabled, isTrackingDisabled])

    useEffect(() => {
        if (pathID && trackpackHubAsin) {
            const selectedObject = selectedDropdownList?.trackpacks?.find(
                (item: { metadata: { id: number | string } }) => item.metadata.id === pathID
            )

            const matchingTracking: Tracking | undefined = selectedObject?.trackings.find((tracking: Tracking) => {
                return trackpackHubAsin.includes(tracking.asin)
            })

            if (matchingTracking && matchingTracking.status === "ARCHIVED") {
                dispatch(AccountActionsCreater.setTrackpackType("archived"))
            }
            setTimeout(() => {
                localStorage.removeItem("trackpackHubAsin")
            }, 6000)
        } else if (pathID && selectedTrackpacksList) {
            const matchingTrackpack = selectedTrackpacksList?.id === pathID
            if (matchingTrackpack) {
                if (
                    (selectedTrackpacksList.status === "ACTIVE" ||
                        selectedTrackpacksList.status === "AWAITING_INITIAL_DATA") &&
                    !isTrackingDisabled
                ) {
                    dispatch(AccountActionsCreater.setTrackpackType("tracking"))
                } else if (
                    selectedTrackpacksList.status === "ARCHIVED" ||
                    selectedTrackpacksList.status === "ARCHIVED_DURING_PACK_ARCHIVAL"
                ) {
                    dispatch(AccountActionsCreater.setTrackpackType("archived"))
                }
            }
        }
    }, [
        pathID,
        selectedTrackpacksList,
        dispatch,
        isTrackingDisabled,
        selectedDropdownList?.trackpacks,
        trackpackHubAsin,
    ])

    const [isScrollBottom, setIsScrollBottom] = useState(false)

    useEffect(() => {
        const container = containerRef.current

        const handleScroll = () => {
            if (container) {
                const { scrollTop, scrollHeight, clientHeight } = container
                const isBottom = scrollHeight - scrollTop === clientHeight
                setIsScrollBottom(isBottom)
            }
        }

        if (container) {
            container.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll)
            }
        }
    }, [containerRef])

    return (
        <>
            <div className={"flex gap-[20px] items-start w-full h-full"}>
                <SearchpackHubOptions
                    setSelectedRows={setSelectedRows}
                    setIsLoading={setIsLoading}
                    setDataLength={setDataLength}
                />
                {!isLoading && dataLength !== null && dataLength === 0 ? (
                    <SearchpackHubOnboardingComponent />
                ) : (
                    <div
                        className="p-[12px] rounded-[16px] bg-gray-50 pb-0 flex-1 pr-0"
                        style={{
                            border: "1px solid",
                            borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                        }}
                    >
                        <>
                            <div className="w-full mb-[20px] justify-between items-center inline-flex pr-[12px]">
                                <div className="w-full ml-[10px] py-[6px] justify-start items-center gap-1 flex">
                                    <div className="w-full flex items-center gap-4">
                                        {isEditing ? (
                                            <div className="flex items-center gap-2">
                                                <input
                                                    type="text"
                                                    className="min-w-full max-w-auto border-0 bg-[#F9FAFB] border-b border-[#088AB2]  text-[#088AB2] text-base font-medium leading-normal placeholder:text-[16px] placeholder:font-[400] placeholder:text-[#06AED4]"
                                                    value={text}
                                                    placeholder="Insert name..."
                                                    onChange={(e) => setText(e.target.value)}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <span
                                                    className="cursor-pointer text-cyan-500 text-xs font-light leading-[18px]"
                                                    onClick={handleSaveClick}
                                                >
                                                    <InputArrow />
                                                </span>
                                            </div>
                                        ) : (
                                            <>
                                                <span className="text-[#088AB2] text-base font-medium leading-normal">
                                                    {selectedTrackpacksList && selectedTrackpacksList?.title}
                                                </span>
                                                <span
                                                    className="cursor-pointer text-cyan-500 text-xs font-light leading-[18px]"
                                                    onClick={handleEditClick}
                                                >
                                                    <NewEditIcon />
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <ButtonElement
                                        onClick={handleOpen}
                                        size="medium"
                                        className={`px-[12px] py-[8px] pt-[10px] rounded-[10px] w-[111px] h-[36px] flex items-center ${
                                            isLoading ? "bg-gray-100" : " bg-button-gradient"
                                        }`}
                                        parentClass="justify-start items-center gap-2 inline-flex"
                                        textClass={`text-[#088AB2] flex item-center gap-[8px] ${
                                            isLoading ? "text-gray-400" : "text-[#088AB2]"
                                        }`}
                                        disabled={isLoading} // Disable the button when loading
                                    >
                                        <PlusIcon color={isLoading ? "#98A2B3" : "#088AB2"} />
                                        Keywords
                                    </ButtonElement>
                                </div>
                            </div>
                            <div className="ml-[14px] mb-[12px]">
                                <div className="flex gap-4 items-center">
                                    <div
                                        className={`
                                        ${isLoading ? "border-gray-200" : "border-[#A5F0FC]"}
                                        flex border  rounded-[4px] w-[97px] h-[16px]`}
                                    >
                                        <div
                                            className={`overflow-hidden rounded-tl-[3px] rounded-bl-[3px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] border-r border-[#A5F0FC] h-full ${
                                                isLoading
                                                    ? "bg-white" // Force white background when loading
                                                    : trackpackType === "tracking"
                                                    ? "bg-[#CFF9FE]" // Default tracking background
                                                    : isTrackingDisabled
                                                    ? "bg-white" // Disabled state background
                                                    : "bg-white" // Default background
                                            }`}
                                        >
                                            <PrimaryText
                                                onClick={() => {
                                                    !isTrackingDisabled &&
                                                        dispatch(AccountActionsCreater.setTrackpackType("tracking"))
                                                    dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                                    setSelectedRows([])
                                                }}
                                                weight={trackpackType === "tracking" ? "medium" : "light"}
                                                size="xs-small"
                                                className={`${
                                                    isTrackingDisabled
                                                        ? "!cursor-not-allowed !text-gray-700"
                                                        : "cursor-pointer"
                                                } ${
                                                    trackpackType === "tracking" ? "text-[#0E7090]" : "text-gray-700"
                                                } leading-[12px] h-full align-middle ${
                                                    isLoading ? "text-gray-300" : "" // Gray text when loading
                                                }`}
                                            >
                                                ACTIVE
                                            </PrimaryText>
                                        </div>
                                        <div
                                            className={`overflow-hidden rounded-tr-[3px] rounded-br-[3px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] ${
                                                isLoading
                                                    ? "bg-white" // Force white background when loading
                                                    : trackpackType === "archived"
                                                    ? "bg-[#CFF9FE]" // Default archived background
                                                    : isArchiveDisabled
                                                    ? "bg-white" // Disabled state background
                                                    : "bg-white" // Default background
                                            }`}
                                        >
                                            <PrimaryText
                                                onClick={() => {
                                                    !isArchiveDisabled &&
                                                        dispatch(AccountActionsCreater.setTrackpackType("archived"))
                                                    dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                                    setSelectedRows([])
                                                }}
                                                weight={trackpackType === "archived" ? "medium" : "light"}
                                                size="xs-small"
                                                className={`${
                                                    trackpackType === "archived" ? "text-[#0E7090]" : "text-[#344054]"
                                                } ${
                                                    isArchiveDisabled
                                                        ? "!cursor-not-allowed text-gray-700"
                                                        : "cursor-pointer"
                                                } leading-[12px] h-full align-middle ${
                                                    isLoading ? "text-gray-300" : "" // Gray text when loading
                                                }`}
                                            >
                                                ARCHIVED
                                            </PrimaryText>
                                        </div>
                                    </div>

                                    {selectedRows?.length > 0 && (
                                        <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full bg-gray-200 cursor-pointer">
                                            {trackpackType === "tracking" ? (
                                                <div
                                                    onClick={(e: React.SyntheticEvent) => {
                                                        e.stopPropagation()
                                                        handleOpenArchiveSearchpack(0, "", "active")
                                                    }}
                                                >
                                                    <TrackpackTrashIcon />
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={(e: React.SyntheticEvent) => {
                                                        e.stopPropagation()
                                                        handleOpenArchiveSearchpack(0, "", "active")
                                                    }}
                                                >
                                                    <FlipArrowIcon />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                ref={containerRef}
                                className={`overflow-y-scroll -mr-[17px] pb-[12px]
                                    ${isScrollBottom ? "pb-[12px]" : "pb-0"}
                                    
                                    `}
                                style={{ height: "calc(100vh - 264px)" }}
                            >
                                <div
                                    className="bg-white rounded-[16px] border border-gray-200 overflow-hidden"
                                    style={{ width: "calc(100% - 24px)" }}
                                >
                                    <Table />
                                </div>
                                <div className={``}></div>
                            </div>
                        </>
                    </div>
                )}
            </div>
        </>
    )
}
