export const ToolsIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.5 8.5L8.5 3.5M8.5 3.5H3.5M8.5 3.5V8.5"
                stroke="#B54708"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
