import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { AccountActionsCreater } from "../../../../actions/account.action"
import { SearchpackActionCreator } from "../../../../actions/searchpack.action"
import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { useInputValidations } from "../../../../hooks/inputValidations.hook"
import { useLoadingStatus } from "../../../../hooks/useLoadingStatus.hook"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../../selectors/searchpack.selector"
import { ButtonElement } from "../../../elements/button.element"
import { InputElement } from "../../../elements/input.element"
import { PrimaryText } from "../../../elements/primaryText.element"
const SearchpackStepTwoView: React.FC<AddTrackPackProps> = ({
    asinHeaderText,
    asinHeaderDescription,
    icon,
    handleCloseDialog,
    handlePrev,
    buttonText,
    isError,
    isSuccess,
}) => {
    const { control, handleSubmit, watch, setValue } = useForm<Partial<ISelectedTrackpackPayload>>()
    const validationConfig = useInputValidations()
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useLoadingStatus(loading, RuntimeHelper.createTrackpackLoading())

    const { userActionData } = useSelector(AccountStateSelector)

    const dispatch = useDispatch()
    const watchName = watch("name")
    const data = useSelector(SearchpackStateSelector)
    const name = typeof watchName === "string" ? watchName.trim() : ""
    const onSubmit = () => {
        let keywords: string[] = []
        keywords = data?.selectedSearchpackPayload?.keywords || []
        const payload = {
            name: name,
            amazon_tld: data?.selectedSearchpackPayload?.amazon_tld,
            search_terms: keywords?.map((val: string) => val) ?? [],
        }

        dispatch(
            SearchpackActionCreator.createSearchpack(
                { ...payload },
                (data) => {
                    isSuccess && isSuccess(name)
                    if (userActionData !== undefined) {
                        dispatch(
                            SearchpackActionCreator.getSearchpackList(
                                {},
                                { as_user: userActionData && userActionData?.id + "", active_only: "true" },
                                undefined,
                                undefined,
                                data.id
                            )
                        )
                    } else
                        dispatch(
                            SearchpackActionCreator.getSearchpackList(
                                {},
                                { active_only: "true" },
                                undefined,
                                undefined,
                                data.id
                            )
                        )

                    dispatch(AccountActionsCreater.refetchSearchpacks({ select: data.id }))
                },
                (res: { message: string }) => {
                    if (isError) {
                        isError()
                    }
                }
            )
        )
    }
    useEffect(() => {
        if (data?.selectedSearchpackPayload?.name) {
            setValue("name", data?.selectedSearchpackPayload?.name)
        }
    }, [data, setValue])

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-[#344054] text-[24px] font-medium mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>
            <PrimaryText className="text-[16px] font-[300] mt-[16px] text-gray-700 ml-[6px] ">
                {asinHeaderDescription}
            </PrimaryText>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-[36px]">
                <>
                    <InputElement
                        name="name"
                        type="text"
                        reactHookControl={control}
                        reactHookValidations={{
                            required: validationConfig.searchpackName.message,
                            // pattern: validationConfig.searchpackName,
                        }}
                        placeholder="Start typing..."
                        className="placeholder:itali placeholder:text-[#D0D5DD] !rounded-[12px]"
                        placeholderStyle={true}
                        shadowClass={true}
                    />
                    <div className="flex justify-between  items-center mt-[48px]">
                        <div className="flex items-center">
                            <BackChevronIcon />
                            <PrimaryText onClick={handlePrev} className="text-[18px]  cursor-pointer text-[#667085]">
                                Back
                            </PrimaryText>
                        </div>

                        <ButtonElement
                            type="submit"
                            size="large"
                            textClass="text-[18px] leading-[28px]"
                            className={"w-[99px] bg-[#A5F0FC] rounded-[12px] px-[30px] py-[8px] text-[#0E7090] "}
                            loading={isLoading}
                            disabled={isLoading}
                            viewType={isLoading ? "loading-button" : "primary"}
                        >
                            {buttonText}
                        </ButtonElement>
                    </div>
                </>
            </form>
        </>
    )
}
export default SearchpackStepTwoView
