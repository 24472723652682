/**
 * A helper function that forms a new URL by adding given queryParams on top of the given `url`.
 */
const formURL = (url: string, queryParams: Record<string, string> = {}): string => {
    let urlObject: URL
    let relative = true

    if (url.startsWith("http")) {
        urlObject = new URL(url)
        relative = false
    } else {
        urlObject = new URL(`${window.location.origin}${!url.startsWith("/") ? "/" : ""}${url}`)
    }

    Object.entries(queryParams).forEach(([key, value]) => {
        urlObject.searchParams.append(key, value)
    })

    return relative ? urlObject.href.replace(urlObject.origin, "") : urlObject.toString()
}

export const urlUtils = {
    formURL,
}
