import { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import { useSelector } from "react-redux"
import { ReactComponent as AlertIcon } from "../../assets/svgs/alert-icon.svg"
import { TagCloseIcon } from "../../assets/svgs/tagCloseIcon.svg"
import { REGEX_CONFIG } from "../../config/regex.config"
import { ProductStateSelector } from "../../selectors/product.selector"
import { PrimaryText } from "./primaryText.element"

const SemiAutomatedInputElement = (props: ITagInputElementProps) => {
    const [tag, setTag] = useState<{ asin: string; url?: string } | null>(null)
    const [inputValue, setInputValue] = useState("")
    const [originalUrl, setOriginalUrl] = useState<string | null>(null)
    const { addAsinToTrackpack } = useSelector(ProductStateSelector)

    useEffect(() => {
        if (addAsinToTrackpack?.asin) {
            const { asin } = addAsinToTrackpack
            const newTag = { asin }

            if (isValidASINOrURL(asin)) {
                setTag(newTag)
                props.reactHookControl.setValues(props.name, newTag, { shouldValidate: true })
            }
        }
    }, [addAsinToTrackpack, props.reactHookControl, props.name])

    const isValidASINOrURL = (asin: string, url?: string) => {
        const asinRegex = /^[A-Z0-9]{10}$/

        if (url) {
            return REGEX_CONFIG.amazonAsinRegex.test(url)
        }

        return asinRegex.test(asin)
    }

    const truncateURL = (url: string) => {
        const asinRegex = /(?:\/dp\/|\/gp\/product\/|\/ASIN\/)([A-Z0-9]{10})(?:[/?]|$)/
        const match = url.match(asinRegex)
        const asin = match ? match[1] : null

        if (asin) {
            const domain = url.match(/amazon\.[a-z.]+/i)?.[0] || ""
            return `${domain}/.../${asin}`
        }

        return url
    }

    const validateTag = () => {
        if (!tag) {
            return "Please enter an ASIN number or URL to continue"
        }

        const { asin, url } = tag

        if (asin.length !== 10) {
            return "ASIN numbers must contain 10 characters"
        }

        if (!isValidASINOrURL(asin, url)) {
            return "Please enter a valid ASIN or Amazon URL to continue"
        }

        return true
    }

    const handleTagDelete = () => {
        setTag(null)
        setInputValue("")
        setOriginalUrl(null)
        props.reactHookControl.setValues(props.name, {}, { shouldValidate: true })
    }

    return (
        <Controller
            rules={{ validate: validateTag }}
            control={props.reactHookControl}
            name={props.name}
            render={({ field: { onChange, name, ref }, fieldState: { error } }) => {
                const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                    const input = event.target.value.trim()

                    if (input.startsWith("http")) {
                        const truncatedURL = truncateURL(input)
                        setInputValue(truncatedURL)
                        setOriginalUrl(input)
                    } else {
                        setInputValue(input)
                        setOriginalUrl(null)
                    }

                    event.preventDefault()
                }

                const handleInputSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === "Enter" || event.key === "Tab") {
                        event.preventDefault()

                        const parts = inputValue.split("/")
                        const asin = parts[parts.length - 1]

                        const newTag = { asin, url: originalUrl || undefined }
                        setTag(newTag)
                        onChange(newTag)
                        setInputValue("")
                        setOriginalUrl(null)
                    }
                }

                const handleBlur = (event: any) => {
                    event.preventDefault()
                    const parts = inputValue.split("/")
                    const asin = parts[parts.length - 1]

                    if (asin.length === 10) {
                        const newTag = { asin, url: originalUrl || undefined }
                        setTag(newTag)
                        onChange(newTag)
                        setInputValue("")
                        setOriginalUrl(null)
                    }
                }

                return (
                    <div className="relative">
                        <div
                            className={`flex items-center border rounded-[12px] px-[10px] py-[9px] w-full relative ${
                                error ? "border-red-600" : "border-gray-300"
                            }`}
                        >
                            {tag && (
                                <div className="tag flex items-center justify-between gap-[8px] bg-gray-100 py-[2px] pl-[6px] pr-[4px] rounded-[6px]">
                                    <PrimaryText size="small" weight="book" className="text-gray-700">
                                        {tag.asin} {/* Only showing ASIN */}
                                    </PrimaryText>
                                    <TagCloseIcon onClick={handleTagDelete} className="my-[2px] cursor-pointer" />
                                </div>
                            )}

                            <input
                                ref={ref}
                                name={name}
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleInputSubmit}
                                onBlur={handleBlur}
                                placeholder={!tag ? props.placeholder : ""}
                                className="flex-grow focus:outline-none"
                                disabled={props.disabled}
                                style={{ minWidth: "100px" }}
                            />
                        </div>

                        {error && (
                            <div className="flex gap-1 items-center mt-1">
                                <AlertIcon />
                                <span className="text-red-600 text-xs">{error.message}</span>
                            </div>
                        )}
                    </div>
                )
            }}
        />
    )
}

export default SemiAutomatedInputElement
