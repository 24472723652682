import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { PlacesType, Tooltip } from "react-tooltip"
import { RunTimeStateSelector } from "../../selectors/RunTimeStateSelector"
import { AsinChange } from "../dashboard/tooltipItems/asinChange.component"
import { Carousel } from "../dashboard/tooltipItems/carousel.component"
import { DescriptionTooltip } from "../dashboard/tooltipItems/description.component"
import { MainImage } from "../dashboard/tooltipItems/mainImage.component"
import { PriceInfo } from "../dashboard/tooltipItems/priceInfo.component"
import { SellerInfo } from "../dashboard/tooltipItems/sellerInfo.component"
import { Stock } from "../dashboard/tooltipItems/stock.component"
import { TooltipBullets } from "../dashboard/tooltipItems/tooltipBullets.component"
import { TooltipTitle } from "../dashboard/tooltipItems/tooltipTitle.component"
import { TooltipVideo } from "../dashboard/tooltipItems/tooltipVideo.component"

export const CustomTooltip = (prop: ICustomTooltipProps) => {
    const { popups } = useSelector(RunTimeStateSelector)

    const [productId, setProductId] = useState<string>()
    const [, setPositionStyle] = useState<{ top: string; left: string } | null>(null)
    const [tooltipPlacement, setTooltipPlacement] = useState<PlacesType | undefined>("bottom-start")
    const [activeAnchor, setActiveAnchor] = useState<HTMLElement | null>(null)

    const setTooltipPosition = useCallback((anchor: HTMLElement) => {
        if (anchor) {
            const rect = anchor.getBoundingClientRect()
            const tooltipWidth = 200
            const margin = 20

            const isNearLeft = rect.left < margin + tooltipWidth
            const isNearRight = rect.right > window.innerWidth - (margin + tooltipWidth)
            const isAboveCenter = rect.top < window.innerHeight / 2

            const top = isAboveCenter ? rect.bottom : rect.top
            const left = rect.left < window.innerWidth / 2 ? rect.right : rect.left

            let placement: PlacesType

            if (isNearLeft) {
                placement = isAboveCenter ? "bottom-start" : "top-start"
            } else if (isNearRight) {
                placement = isAboveCenter ? "bottom-end" : "top-end"
            } else {
                placement = isAboveCenter ? "bottom" : "top"
            }

            setTooltipPlacement(placement)
            setPositionStyle({ top: `${top}px`, left: `${left}px` })
        }
    }, [])

    useEffect(() => {
        if (activeAnchor) {
            setTooltipPosition(activeAnchor)
        }
    }, [activeAnchor, popups, productId, setTooltipPosition])

    const TooltipContent = (obj: ITooltipContent) => {
        setProductId(obj.id + obj.data.timestamp)

        switch (obj.type) {
            case "carousel_images":
                return <Carousel color={obj.color} data={obj.data} id={obj.id} />
            case "main_image":
                return <MainImage color={obj.color} data={obj.data} id={obj.id} />
            case "price":
                return <PriceInfo color={obj.color} data={obj.data} id={obj.id} />
            case "description":
                return <DescriptionTooltip color={obj.color} data={obj.data} id={obj.id} />
            case "bullets":
                return <TooltipBullets color={obj.color} data={obj.data} id={obj.id} />
            case "videos":
                return <TooltipVideo color={obj.color} data={obj.data} id={obj.id} />
            case "title":
                return <TooltipTitle color={obj.color} data={obj.data} id={obj.id} />
            case "actual_asin":
                return <AsinChange color={obj.color} data={obj.data} id={obj.id} />
            case "seller_info":
                return <SellerInfo color={obj.color} data={obj.data} id={obj.id} />
            case "stock":
                return <Stock color={obj.color} data={obj.data} id={obj.id} />
            default:
                return null
        }
    }

    return (
        <Tooltip
            id={prop.id}
            clickable
            globalCloseEvents={{
                escape: true,
                scroll: true,
                resize: true,
                clickOutsideAnchor: false,
            }}
            positionStrategy="absolute"
            place={tooltipPlacement}
            openOnClick
            delayShow={200}
            isOpen={popups?.includes(prop.id + productId)}
            className="tooltip-style"
            classNameArrow="custom-arrow blue"
            render={({ content, activeAnchor }) => {
                if (!content) return null
                if (activeAnchor !== null) {
                    setActiveAnchor(activeAnchor)
                }
                const obj = JSON.parse(content)
                return TooltipContent(obj)
            }}
        />
    )
}
