export const SearchPackName = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.1667 8.16667L13.8652 5.56374C13.4907 4.8146 13.3034 4.44002 13.024 4.16636C12.7769 3.92435 12.4791 3.7403 12.1521 3.62753C11.7824 3.5 11.3636 3.5 10.526 3.5H6.06671C4.75992 3.5 4.10652 3.5 3.6074 3.75432C3.16835 3.97802 2.8114 4.33498 2.58769 4.77402C2.33337 5.27315 2.33337 5.92654 2.33337 7.23333V8.16667M2.33337 8.16667H20.0667C22.0269 8.16667 23.007 8.16667 23.7557 8.54814C24.4142 8.8837 24.9497 9.41913 25.2852 10.0777C25.6667 10.8264 25.6667 11.8065 25.6667 13.7667V18.9C25.6667 20.8602 25.6667 21.8403 25.2852 22.589C24.9497 23.2475 24.4142 23.783 23.7557 24.1185C23.007 24.5 22.0269 24.5 20.0667 24.5H7.93337C5.97319 24.5 4.9931 24.5 4.24441 24.1185C3.58584 23.783 3.05041 23.2475 2.71485 22.589C2.33337 21.8403 2.33337 20.8602 2.33337 18.9V8.16667ZM14 19.8333V12.8333M10.5 16.3333H17.5"
                stroke="#088AB2"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
