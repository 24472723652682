import { ReactElement, useEffect, useState } from "react"
import { API_ROUTES, ROUTES_CONFIG } from "../../config/routes.config"
import { HttpHelper } from "../../helpers/http.helper"
import { LoadingComponent } from "./loading.component"

const hasLogin = async () => {
    try {
        await HttpHelper.sendRequest(`${API_ROUTES.GET_ACCESS_LEVEL}`, {}, "GET", {}, false, false, true)
    } catch (err) {
        console.error(err)
        throw err
    }
}

export const AppEnsureAuth = ({ children }: { children: ReactElement }) => {
    const [init, setInit] = useState(false)

    useEffect(() => {
        if (!init) {
            hasLogin()
                .then((res) => {
                    setInit(true)
                })
                .catch((err) => {
                    window.location.pathname = ROUTES_CONFIG.welcome
                })
        }
    }, [init])

    if (!init) return <LoadingComponent />
    return <>{children}</>
}
