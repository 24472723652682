import { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router"
import sessionStorage from "redux-persist/es/storage/session"
import { NotificationsActionsCreator } from "../../actions/notifications.action"
import { ProductsActionCreator } from "../../actions/products.action"
import { RuntimeActionCreators } from "../../actions/runTime.action"
import { LinkExternalSvg } from "../../assets/svgs/link-external.svg"
import { ReactComponent as NoImage } from "../../assets/svgs/no-image.svg"
import { NotificationGreyIcon } from "../../assets/svgs/notificationGreyIcon.svg"
import { NotificationGreyIconHover } from "../../assets/svgs/notificationGreyIconHover.svg"
import { NotificationRedIcon } from "../../assets/svgs/notificationRedDot.svg"
import { NotificationRedIconHover } from "../../assets/svgs/notificationRedIconHover.svg"
import { SuccessIcon } from "../../assets/svgs/successIcon.svg"
import { AsinChangeTooltip } from "../../assets/svgs/tooltips/asin-change.svg"
import { CarouselIconTooltip } from "../../assets/svgs/tooltips/carousel.svg"
import { DescriptionIconTooltip } from "../../assets/svgs/tooltips/description.svg"
import { MainImageTooltip } from "../../assets/svgs/tooltips/main-image.svg"
import { PriceIconTooltip } from "../../assets/svgs/tooltips/price-icon.svg"
import { ProductVideosIconTooltip } from "../../assets/svgs/tooltips/productVideos.svg"
import { SellerInfoIconTooltip } from "../../assets/svgs/tooltips/seller-info.svg"
import { StockTooltipIcon } from "../../assets/svgs/tooltips/stock.svg"
import { TitleIconTooltip } from "../../assets/svgs/tooltips/title.svg"
import { BulletsIconTooltip } from "../../assets/svgs/tooltips/tooltip-bullets.svg"
import { VideoIconTooltip } from "../../assets/svgs/tooltips/video.svg"
import { WarningIcon } from "../../assets/svgs/warningIcon.svg"
import { AsinIssuesList, img_url } from "../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../config/routes.config"
import {
    checkArchivedStatusAndNavigate,
    countOccurrences,
    getTooltipMessage,
    getUpdatedViews,
    handleAmazonProductRedirect,
} from "../../helpers/util.helper"
import { AccountStateSelector } from "../../selectors/accountState.selector"
import { ProductStateSelector } from "../../selectors/product.selector"
import { urlUtils } from "../../utils/url"
import { PrimaryText } from "../elements/primaryText.element"

const NotificationAsinCard = (props: ProductNotification) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location
    const [hoveredIcon, setHoveredIcon] = useState<number | null>(null)
    const { userActionData } = useSelector(AccountStateSelector)
    const { selectedDropdownList, selectedProductView } = useSelector(ProductStateSelector)

    const tooltipMessage = getTooltipMessage(props?.type!)
    const additionalMessage =
        props?.type! === "LISTING_CHANGE" ||
        props?.type! === "DIFFERENT_ASIN_RETURNED" ||
        props?.type! === "CHILD_ASIN_RETURNED"
            ? " returned"
            : props?.type! === "DATA_UNAVAILABLE"
            ? " unavailable"
            : props?.type! === "DIFFERENT_LARGE_CATEGORY"
            ? "Different"
            : ""
    const handleProductRedirect = (requested_asin: string) => {
        handleAmazonProductRedirect(props?.amazon_tld!, requested_asin!)
    }

    const handleNotificationRead = (
        read_at: string,
        id: number | number[],
        requested_asin: string,
        trackpackId?: number,
        pd_timestamp = "",
        created_at = "",
        listingChanges?: any[]
    ) => {
        dispatch(RuntimeActionCreators.openPopup(""))
        if (!userActionData && read_at === null) {
            let payload = {
                notification_ids: Array.isArray(id) ? id : [id],
            }

            dispatch(NotificationsActionsCreator.markNotificationAsRead({ ...payload }))
        }

        const isArchived = checkArchivedStatusAndNavigate(
            selectedDropdownList?.trackpacks,
            trackpackId!,
            requested_asin
        )

        if (isArchived) {
            return navigate(
                urlUtils.formURL(ROUTES_CONFIG.trackpackCentral, { tp_id: `${trackpackId || ""}`, filter: "archived" }),
                {
                    replace: true,
                }
            )
        } else {
            dispatch(
                NotificationsActionsCreator.notifiSelection({
                    notifiAsin: [requested_asin],
                    selectedTrackpackID: trackpackId!?.toString(),
                    timeStamp: pd_timestamp,
                    createdAt: created_at,
                })
            )
            sessionStorage.setItem("timeStamp", pd_timestamp)

            if (listingChanges && selectedProductView) {
                const mappedViews = getUpdatedViews(listingChanges, selectedProductView.selectedViews)

                if (mappedViews.length > 0) {
                    const updatedSelectedViews = [...selectedProductView.selectedViews, ...mappedViews]
                    dispatch(
                        ProductsActionCreator.selectedProductView({
                            selectedViews: updatedSelectedViews,
                        })
                    )
                }
            }

            const options: { as_user?: string; only_active_trackings: boolean } = {
                only_active_trackings: true,
            }

            if (userActionData) {
                options.as_user = userActionData.id + ""
            }

            dispatch(ProductsActionCreator.getExtendedTrackingData(String(trackpackId), {}, options))
            dispatch(
                ProductsActionCreator.setSelectedTrackPackValues({
                    selectedTrackpackID: String(trackpackId),
                    defualtSelectedAsin: requested_asin,
                })
            )

            navigate(urlUtils.formURL(ROUTES_CONFIG.dashboard, { tp_id: `${trackpackId || ""}`, filter: "tracking" }), {
                replace: true,
            })

            props?.handleMenuStateChange(props?.notificationNumber!)
        }

        props.onClose!()
    }

    const renderIcon = (key: string, count: number | null) => {
        switch (key) {
            case "actual_asin_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <AsinChangeTooltip width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px] `} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-[1px] text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "seller_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <SellerInfoIconTooltip
                                width={"14"}
                                height={"14"}
                                stroke={"#667085"}
                                className={`m-[1px] `}
                            />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-[1px] text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "availability_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <StockTooltipIcon width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px] `} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-[1px]  text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "price":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <PriceIconTooltip width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px]`} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-[1px] text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "title_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <TitleIconTooltip width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px] `} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-[1px]  text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "bullets_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <BulletsIconTooltip width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px] `} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-[1px] text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "description_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <DescriptionIconTooltip
                                width={"14"}
                                height={"14"}
                                stroke={"#667085"}
                                className={`m-[1px] `}
                            />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-[1px]  text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "main_image_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <MainImageTooltip width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px] `} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-[1px] text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "carousel_images_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <CarouselIconTooltip width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px] `} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-[1px]  text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "main_video":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <VideoIconTooltip width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px] `} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-[1px]  text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "videos":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <ProductVideosIconTooltip
                                width={"14"}
                                height={"14"}
                                stroke={"#667085"}
                                className={`m-[1px] `}
                            />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-[1px]  text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )

            default:
                return null
        }
    }

    const mergedNotifications = useMemo(() => {
        const merged =
            props?.listing_changes &&
            props?.listing_changes?.reduce(
                (acc: any[], change: { requested_asin: any; changes: any; notification_id: any }) => {
                    const existingNotification = acc.find(
                        (notif: { requested_asin: any }) => notif.requested_asin === change.requested_asin
                    )

                    if (existingNotification) {
                        // Merge changes and notification_id
                        existingNotification.changes.push(...change.changes)
                        existingNotification.notification_id.push(change.notification_id)
                    } else {
                        // Add new notification with changes and notification_id as arrays
                        acc.push({
                            ...change,
                            changes: [...change.changes],
                            notification_id: [change.notification_id],
                        })
                    }

                    return acc
                },
                []
            )

        return merged
    }, [props?.listing_changes])

    const iconOrder = [
        "actual_asin_id",
        "seller_id",
        "availability_id",
        "price",
        "title_id",
        "bullets_id",
        "description_id",
        "main_image_id",
        "carousel_images_id",
        "main_video",
        "videos",
    ]

    const renderIconsInOrder = (changes: string[], changeCounts: Record<string, number>) => {
        const sortedChanges = changes.sort((a, b) => iconOrder.indexOf(a) - iconOrder.indexOf(b))

        return sortedChanges.map((change, index) => (
            <span key={index}>{renderIcon(change, changeCounts[change] > 1 ? changeCounts[change] : null)}</span>
        ))
    }

    const handleAsinNotificationRead = (e: React.MouseEvent<HTMLDivElement>, notifications: any) => {
        e.stopPropagation()
        if (!userActionData) {
            let payload = {
                notification_ids: Array.isArray(notifications.notification_id)
                    ? notifications.notification_id
                    : [notifications.notification_id],
            }

            if (notifications.read_at !== null) {
                dispatch(NotificationsActionsCreator.markNotificationAsUnRead({ ...payload, isUnread: true }))
            } else {
                dispatch(NotificationsActionsCreator.markNotificationAsRead(payload))
            }
        }
    }

    return (
        <>
            {props?.islistingChange === true &&
                mergedNotifications.map(
                    (listing: any, index: number) =>
                        listing?.changes?.length > 0 && (
                            <div className="flex gap-[8px] mb-[8px] bg-white p-[8px] pr-[10px] rounded-[4px] ml-[50px] last:mb-0">
                                {listing?.main_image_filename !== null ? (
                                    <div className="min-h-[44px] max-h-[44px] min-w-[44px] flex-1 max-w-[44px] border border-gray-300 overflow-hidden rounded-[6px] bg-white">
                                        <img
                                            src={`${img_url}${listing?.main_image_filename}`}
                                            alt="product images"
                                            className="h-full w-full object-contain"
                                        />
                                    </div>
                                ) : (
                                    <div className="min-h-[44px] max-h-[44px] min-w-[44px] flex-1 flex items-center justify-center border border-gray-300 overflow-hidden rounded-[4px] object-contain bg-gray-800">
                                        <NoImage />
                                    </div>
                                )}
                                <div
                                    key={index}
                                    className="w-full cursor-pointer"
                                    onClick={() =>
                                        handleNotificationRead(
                                            listing?.read_at,
                                            listing?.notification_id! && listing?.notification_id,
                                            listing.requested_asin!,
                                            props?.trackpackId!,
                                            listing?.pd_timestamp,
                                            listing?.created_at,
                                            listing?.changes
                                        )
                                    }
                                >
                                    <div className=" flex items-center justify-between w-full">
                                        <div className="flex items-center gap-x-[4px] mt-[2px]">
                                            <SuccessIcon />
                                            <PrimaryText
                                                size="xs-medium"
                                                weight="medium"
                                                className="leading-[12px] flex text-gray-700 my-[2px]"
                                            >
                                                {listing?.changes?.length}{" "}
                                                {listing?.changes?.length === 1 ? "change" : "changes"} to&nbsp;
                                                <PrimaryText
                                                    weight="medium"
                                                    size="xs-medium"
                                                    className="text-[#088AB2] uppercase inline-flex gap-x-[2px] leading-[12px]"
                                                    onClick={() => handleProductRedirect(listing?.requested_asin!)}
                                                >
                                                    {listing?.requested_asin}
                                                    <LinkExternalSvg color={"#088AB2"} className="mb-[1px]" />
                                                </PrimaryText>
                                            </PrimaryText>
                                        </div>

                                        {listing?.read_at === null ? (
                                            <div
                                                className="relative group"
                                                onMouseOver={() => setHoveredIcon(listing?.notification_id!)}
                                                onMouseLeave={() => setHoveredIcon(null)}
                                                onClick={(e) => handleAsinNotificationRead(e, listing)}
                                            >
                                                {hoveredIcon === listing?.notification_id! ? (
                                                    <NotificationRedIconHover />
                                                ) : (
                                                    <NotificationRedIcon />
                                                )}

                                                <div
                                                    style={{
                                                        boxShadow:
                                                            "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                                    }}
                                                    className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                                                >
                                                    Mark as read
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className="relative group"
                                                onMouseOver={() => setHoveredIcon(listing?.notification_id!)}
                                                onMouseLeave={() => setHoveredIcon(null)}
                                                onClick={(e) => handleAsinNotificationRead(e, listing)}
                                            >
                                                {hoveredIcon === listing?.notification_id! ? (
                                                    <NotificationGreyIconHover />
                                                ) : (
                                                    <NotificationGreyIcon />
                                                )}

                                                <div
                                                    style={{
                                                        boxShadow:
                                                            "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                                    }}
                                                    className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                                                >
                                                    Mark as unread
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex items-center gap-[2px] mt-[6px] flex-wrap mb-[2px]">
                                        {listing?.changes &&
                                            (() => {
                                                const changeCounts = countOccurrences(listing?.changes)
                                                return renderIconsInOrder(Object.keys(changeCounts), changeCounts)
                                            })()}
                                    </div>
                                </div>
                            </div>
                        )
                )}

            {props?.islistingChange === false && (
                <div className="flex gap-[8px] mb-[8px] bg-white p-[8px] pr-[10px] rounded-[4px] ml-[50px] last:mb-0">
                    {props?.main_image_filename !== null ? (
                        <img
                            src={`${img_url}${props?.main_image_filename}`}
                            alt="product images"
                            className="min-w-[44px] max-w-[44px] h-[44px] border border-gray-300 overflow-hidden object-contain rounded-[6px] bg-white"
                        />
                    ) : (
                        <div className="min-h-[44px] max-h-[44px] min-w-[44px] flex items-center justify-center border border-gray-300 overflow-hidden rounded-[4px] object-contain bg-gray-800">
                            <NoImage />
                        </div>
                    )}
                    <div
                        className="w-full cursor-pointer"
                        onClick={() =>
                            handleNotificationRead(
                                props?.read_at! && props?.read_at,
                                props?.notification_id! && props?.notification_id,
                                props.requested_asin!,
                                props?.trackpackId!
                            )
                        }
                    >
                        <div className=" flex items-center justify-between w-full">
                            {AsinIssuesList.includes(props.type!) ? (
                                <div className="flex items-center gap-x-[4px] mt-[2px]">
                                    <WarningIcon />
                                    <PrimaryText size="xs-medium" weight="medium" className="leading-[12px] my-[2px]">
                                        {tooltipMessage} {additionalMessage}
                                    </PrimaryText>
                                </div>
                            ) : (
                                <div className="flex items-center gap-x-[4px] mt-[2px]">
                                    <SuccessIcon />
                                    <PrimaryText size="xs-medium" weight="medium" className="leading-[12px] my-[2px]">
                                        ASIN added{" "}
                                    </PrimaryText>
                                </div>
                            )}

                            {props?.read_at === null ? (
                                <div
                                    className="relative group"
                                    onMouseOver={() => setHoveredIcon(props?.notification_id!)}
                                    onMouseLeave={() => setHoveredIcon(null)}
                                    onClick={(e) => handleAsinNotificationRead(e, props)}
                                >
                                    {hoveredIcon === props?.notification_id! ? (
                                        <NotificationRedIconHover />
                                    ) : (
                                        <NotificationRedIcon />
                                    )}
                                    <div
                                        style={{
                                            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                        }}
                                        className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                                    >
                                        Mark as read
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="relative group"
                                    onMouseOver={() => setHoveredIcon(props?.notification_id!)}
                                    onMouseLeave={() => setHoveredIcon(null)}
                                    onClick={(e) => handleAsinNotificationRead(e, props)}
                                >
                                    {hoveredIcon === props?.notification_id! ? (
                                        <NotificationGreyIconHover />
                                    ) : (
                                        <NotificationGreyIcon />
                                    )}
                                    <div
                                        style={{
                                            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                        }}
                                        className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                                    >
                                        Mark as unread
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="bg-transparent border-[#088AB2] border w-fit mt-[6px] mb-[2px] relative cursor-pointer rounded-[3px] px-[4px] py-[2.5px] h-4 flex gap-[3px] justify-center items-center">
                            <PrimaryText
                                className="text-[#088AB2] uppercase tracking-[0.7px]"
                                weight="medium"
                                size="xs-medium"
                                onClick={() => handleProductRedirect(props?.requested_asin!)}
                            >
                                {props?.requested_asin}
                            </PrimaryText>
                            <div
                                className="cursor-pointer"
                                onClick={() => handleProductRedirect(props?.requested_asin!)}
                            >
                                <LinkExternalSvg color={"#088AB2"} className="mb-[1px]" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default NotificationAsinCard
