import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { trackpackStore } from "../@store"
import { SearchpackActionCreator } from "../actions/searchpack.action"
import { RuntimeHelper } from "../helpers/runtime.helper"
import { calculateDays, getASINCount } from "../helpers/util.helper"
import { AccountStateSelector } from "../selectors/accountState.selector"
import { RunTimeStateSelector } from "../selectors/RunTimeStateSelector"
import { getExtendedSearchpacks } from "../services/getExtendedSearchpack"
import { extendedDayjs } from "../utils/dayjs"
import { memoizedRetentionTime } from "../utils/product"
import { useLoadingStatus } from "./useLoadingStatus.hook"

// useGetExtendedTrackpacks hook
export const useGetExtendedTrackpacks = (
    pathParams: Record<string, string | undefined>,
    queryParams: Record<string, string | undefined>,
    enabled = false,
    filter: "tracking" | "archived" = "tracking"
) => {
    const data = useSelector(trackpackStore.EXTENDED_TRACKPACKS_LIST.selector)
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useLoadingStatus(
        loading,
        RuntimeHelper.isLoading(trackpackStore.EXTENDED_TRACKPACKS_LIST.key)
    )

    const dispatch = useDispatch()

    const refetch = useCallback(
        () => dispatch(trackpackStore.EXTENDED_TRACKPACKS_LIST.action(pathParams, queryParams)),
        [dispatch, pathParams, queryParams]
    )

    const convertedTrackpacks = useMemo(() => {
        let trackpacks = []

        if (Array.isArray(data)) {
            trackpacks = data
        } else if (data?.trackpacks) {
            trackpacks = data.trackpacks
        } else {
            return []
        }

        return trackpacks.map((trackpack: any) => {
            const id = trackpack.metadata.id
            const title = trackpack.metadata.name
            const status = trackpack.metadata.status
            const imageSources = trackpack.trackings
                .filter((tracking: any) => tracking.status !== "ARCHIVED")
                .map((tracking: any) => tracking?.latest_data?.main_image_filename)
            const amazonDomain = trackpack.metadata.amazon_tld

            let userASINs = 0
            let competitorASINs = 0
            let archived = 0
            if (filter === "archived") {
                userASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        tracking.status === "ARCHIVED_DURING_PACK_ARCHIVAL" && tracking.own_product === true
                ).length

                competitorASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        tracking.status === "ARCHIVED_DURING_PACK_ARCHIVAL" && tracking.own_product === false
                ).length

                archived = trackpack.trackings.filter((tracking: any) => tracking.status === "ARCHIVED").length
            } else {
                userASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        (tracking.status === "ACTIVE" || tracking.status === "AWAITING_INITIAL_DATA") &&
                        tracking.own_product === true
                ).length

                competitorASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        (tracking.status === "ACTIVE" || tracking.status === "AWAITING_INITIAL_DATA") &&
                        tracking.own_product === false
                ).length
                archived = trackpack.trackings.filter(
                    (tracking: any) =>
                        tracking.status === "ARCHIVED" || tracking.status === "ARCHIVED_DURING_PACK_ARCHIVAL"
                ).length
            }

            const trackpackChildrenList = trackpack.trackings.map((tracking: any) => {
                const ASINs = {
                    asinNo: tracking.asin,
                    asinText: tracking?.latest_data?.title,
                    imgUrl: tracking?.latest_data?.main_image_filename,
                }

                const Status = [tracking.status]
                const currentIssue = tracking.current_issue

                let Owner = ""

                if (tracking.own_product) {
                    const firstName = trackpack?.metadata?.user_first_name
                    const initials = `${firstName?.charAt(0)}`
                    Owner = initials
                }

                const DateAdded = tracking.datetime_from.substring(0, 10)
                const timeDeletion = calculateDays(tracking.datetime_from)
                const amazon_tld = trackpack?.metadata?.amazon_tld

                return {
                    ASINs,
                    Status,
                    Owner,
                    "Date Added": DateAdded,
                    time_deletion: timeDeletion,
                    amazon_tld,
                    currentIssue,
                }
            })

            const actionCount = getASINCount(trackpackChildrenList?.length)

            return {
                title,
                userASINs,
                competitorASINs,
                actionCount,
                trackpackChildrenList,
                id,
                status,
                archived,
                imageSources,
                amazonDomain,
            }
        })
    }, [data, filter])

    useEffect(() => {
        if (enabled && refetch) refetch()
        // force fetch once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { isLoading, data, convertedTrackpacks, refetch }
}

export type ConvertedSearchpack = {
    title: string
    keywordCount: number
    searchpackChildrenList: any[]
    id: number
    status: "ACTIVE" | "ARCHIVED" | "AWAITING_INITIAL_DATA"
    amazonDomain: string
}

// useGetAnotherEndpoint hook
export const useGetExtendedSearchpacks = (
    pathParams: any,
    queryParams: any,
    filter: "active" | "archived" = "active"
) => {
    const { refetchSearchpacks } = useSelector(AccountStateSelector)
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const queryKey = ["extended_searchpacks", pathParams, queryParams]

    const fetchData = async () => {
        refetchSearchpacks?.select && (await queryClient.invalidateQueries({ queryKey }))
        await refetch()
        refetchSearchpacks?.select &&
            dispatch(SearchpackActionCreator.setSelectedSearchpack(refetchSearchpacks.select || null))
    }

    const { refetch, ...query } = useQuery({
        queryKey,
        queryFn: () => getExtendedSearchpacks(pathParams, queryParams),
        enabled: false,
        refetchOnWindowFocus: true,
    })

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchSearchpacks])

    const data = useMemo(() => {
        const currentTime = extendedDayjs().utc().toISOString()

        return {
            ...query.data,
            trackpacks: Array.from(query?.data?.trackpacks || []).map((current) => ({
                ...current,

                search_terms: current.search_terms.map((term) => ({
                    ...term,
                    _timeToDelete:
                        term.status === "ARCHIVED" || term.status === "ARCHIVED_DURING_PACK_ARCHIVAL"
                            ? memoizedRetentionTime(term.last_updated_at, 90, currentTime)
                            : 0,
                })),

                _timeToDelete:
                    current.status === "ARCHIVED" ? memoizedRetentionTime(current.last_updated_at, 90, currentTime) : 0,
            })),
        }
    }, [query.data])

    const { convertedSearchpacks, length } = useMemo(() => {
        let searchpack: TSearchpack[] = []
        let convertedSearchpacks: ConvertedSearchpack[] = []
        let length = 0

        if (Array.isArray(data)) {
            searchpack = data
            length = data?.length || 0
        } else if (data?.trackpacks) {
            searchpack = data.trackpacks
            length = data.trackpacks?.length || 0
        } else {
            return { convertedSearchpacks, length }
        }

        if (searchpack && searchpack?.length > 0) {
            convertedSearchpacks = searchpack?.map((searchpack: TSearchpack) => {
                const id = searchpack.id
                const title = searchpack.name
                const status = searchpack.status
                const amazonDomain = searchpack.amazon_tld
                const keywordCount = searchpack.search_terms?.length

                const searchpackChildrenList = searchpack.search_terms.map((tracking) => {
                    const ASINs = {
                        keywordId: tracking.id,
                        keywordName: tracking.term,
                    }

                    const Status = [tracking.status]
                    const DateAdded = tracking.created_at.substring(0, 10)

                    return {
                        ASINs,
                        Status,
                        "Date Added": DateAdded,
                        _timeToDelete: tracking._timeToDelete,
                    }
                })

                return {
                    title,
                    keywordCount,
                    searchpackChildrenList,
                    id,
                    status,
                    amazonDomain,
                    timeToDelete: searchpack._timeToDelete,
                } as ConvertedSearchpack
            })
        }

        return { convertedSearchpacks, length }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, filter])

    return {
        ...query,
        data,
        convertedSearchpacks,
        length,
    }
}

// You can add more hooks following the same pattern
