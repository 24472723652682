import { GraphData } from "../@types/app/searchpack"
import { dateUtils } from "../utils/date"
import { extendedDayjs } from "../utils/dayjs"

export const groupTermRankByDateRangeAndType = (data: GraphData) => {
    const sorted = dateUtils.sortObjectsByDate("label", data, "YYYY-MM-DD", "asc")
    return sorted.reduce<{ typeHint: "paid" | "organic"; start: string; end: string; data: GraphData }[]>(
        (accData, item) => {
            if (!item.typeHint) return accData
            let existingRange = accData.find(
                (range) =>
                    extendedDayjs(range.end).isSame(extendedDayjs(item.label).subtract(1), "day") &&
                    range.typeHint === item.typeHint
            )

            if (!existingRange) {
                existingRange = { typeHint: item.typeHint, start: item.label, end: item.label, data: [] }
                accData.push(existingRange)
            }

            existingRange.data.push(item)
            existingRange.end = item.label

            return accData
        },
        []
    )
}

export const rectifySingleDataPoint = (data: ReturnType<typeof groupTermRankByDateRangeAndType>) => {
    return data.map((range) => {
        if (range.data.length !== 1 || !range.data[0]) return range
        const first = range.data[0]

        range.data.push({
            ...first,
            timestamp: first.timestamp + 9000,
        })

        return range
    })
}
