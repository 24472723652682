export const LogBulletsIcon = (props: IIconTooltipProps) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.25861 8.66683C3.58589 8.66683 3.8512 8.36835 3.8512 8.00016C3.8512 7.63197 3.58589 7.3335 3.25861 7.3335C2.93133 7.3335 2.66602 7.63197 2.66602 8.00016C2.66602 8.36835 2.93133 8.66683 3.25861 8.66683Z"
                    fill={props.color}
                />
                <path
                    d="M3.25861 4.66683C3.58589 4.66683 3.8512 4.36835 3.8512 4.00016C3.8512 3.63197 3.58589 3.3335 3.25861 3.3335C2.93133 3.3335 2.66602 3.63197 2.66602 4.00016C2.66602 4.36835 2.93133 4.66683 3.25861 4.66683Z"
                    fill={props.color}
                />
                <path
                    d="M3.25861 12.6668C3.58589 12.6668 3.8512 12.3684 3.8512 12.0002C3.8512 11.632 3.58589 11.3335 3.25861 11.3335C2.93133 11.3335 2.66602 11.632 2.66602 12.0002C2.66602 12.3684 2.93133 12.6668 3.25861 12.6668Z"
                    fill={props.color}
                />
                <path
                    d="M13.3327 8.00016L6.22157 8.00016M13.3327 4.00016L6.22157 4.00016M13.3327 12.0002L6.22157 12.0002M3.8512 8.00016C3.8512 8.36835 3.58589 8.66683 3.25861 8.66683C2.93133 8.66683 2.66602 8.36835 2.66602 8.00016C2.66602 7.63197 2.93133 7.3335 3.25861 7.3335C3.58589 7.3335 3.8512 7.63197 3.8512 8.00016ZM3.8512 4.00016C3.8512 4.36835 3.58589 4.66683 3.25861 4.66683C2.93133 4.66683 2.66602 4.36835 2.66602 4.00016C2.66602 3.63197 2.93133 3.3335 3.25861 3.3335C3.58589 3.3335 3.8512 3.63197 3.8512 4.00016ZM3.8512 12.0002C3.8512 12.3684 3.58589 12.6668 3.25861 12.6668C2.93133 12.6668 2.66602 12.3684 2.66602 12.0002C2.66602 11.632 2.93133 11.3335 3.25861 11.3335C3.58589 11.3335 3.8512 11.632 3.8512 12.0002Z"
                    stroke={props.color}
                    strokeWidth="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}
