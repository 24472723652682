export const RectangleIcon = (props: IPropsIcon) => {
    return (
        <svg
            onClick={props.onClick}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.2041 28H26.5365C27.8172 28 28.4575 28 28.8105 27.73C29.118 27.4948 29.3083 27.1353 29.3313 26.7461C29.3577 26.2994 29.0025 25.7607 28.2921 24.6833L24.3223 18.6626C23.7353 17.7723 23.4418 17.3272 23.0719 17.1721C22.7484 17.0365 22.385 17.0365 22.0615 17.1721C21.6916 17.3272 21.3981 17.7723 20.8111 18.6626L19.8297 20.1509M25.2041 28L15.0705 13.2002C14.4877 12.3491 14.1963 11.9235 13.8323 11.7739C13.5139 11.643 13.1575 11.643 12.8391 11.7739C12.4751 11.9235 12.1837 12.3491 11.6009 13.2002L3.75944 24.6524C3.01697 25.7367 2.64573 26.2789 2.66687 26.7298C2.68528 27.1225 2.87414 27.4869 3.18299 27.7258C3.53764 28 4.18983 28 5.49422 28H25.2041ZM27.8416 8C27.8416 10.2091 26.0703 12 23.8854 12C21.7005 12 19.9293 10.2091 19.9293 8C19.9293 5.79086 21.7005 4 23.8854 4C26.0703 4 27.8416 5.79086 27.8416 8Z"
                stroke={props.color ? props.color : "#0E7090"}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
