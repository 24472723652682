export const PlusIcon = (props: IArrowProps) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.99967 3.3335V12.6668M3.33301 8.00016H12.6663"
                    stroke={props.color ?? "#088AB2"}
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
