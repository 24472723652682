import { useMemo } from "react"
import {
    BSR,
    GraphData,
    GraphDataItem,
    IAppSearchpackKeywordRank,
    ISearchpackKeywordPerformanceRank,
    ISearchpackKeywordTerm,
} from "../../../../@types/app/searchpack"
import dateHelper from "../../../../helpers/date"
import { groupTermRankByDateRangeAndType, rectifySingleDataPoint } from "../../../../helpers/searchpack"
import { SEATTLE_TIMEZONE } from "../../../../helpers/util.helper"
import { dateUtils } from "../../../../utils/date"
import { extendedDayjs } from "../../../../utils/dayjs"

type YAxis = "left" | "right"

type GraphDataResultTerm = {
    term: ISearchpackKeywordTerm
    data: { typeHint: "organic" | "paid"; start: string; end: string; data: GraphData }[]
}

type GraphDataResult = {
    data: GraphData
    changes: GraphData
    range: number[]
    terms: GraphDataResultTerm[]
    changesAxis: YAxis
}

class GraphMap extends Map<string, GraphDataItem> {
    private asin: string

    constructor(asin: string) {
        super()
        this.asin = asin
    }

    getByDate(date: string) {
        if (this.has(date)) return this.get(date) as GraphDataItem

        const item: GraphDataItem = {
            asin: this.asin,
            label: date,
            timestamp: extendedDayjs(date).startOf("day").unix(),
        }

        this.set(date, item)
        return item
    }

    setParams(date: string, params: Partial<GraphDataItem>) {
        const item = this.getByDate(date)

        Object.entries(params).forEach(([key, value]) => {
            if (value !== undefined) {
                ;(item as Record<string, unknown>)[key] = value
            }
        })
    }

    getMaxBSR() {
        let max = { value: 0, type: "large" as "large" | "small" }

        for (const item of this.values()) {
            if (Number.isInteger(item.bsr_large) && (item.bsr_large || 0) > max.value)
                max = { value: item.bsr_large || 0, type: "large" }
            if (Number.isInteger(item.bsr_small) && (item.bsr_small || 0) > max.value)
                max = { value: item.bsr_small || 0, type: "small" }
        }

        return max
    }

    getMaxRank() {
        let max = { value: 0, type: "paid" as "paid" | "organic" }

        for (const item of this.values()) {
            if (Number.isInteger(item.paid) && (item.paid || 0) > max.value)
                max = { value: item.paid || 0, type: "paid" }
            if (Number.isInteger(item.organic) && (item.organic || 0) > max.value)
                max = { value: item.organic || 0, type: "organic" }
        }

        return max
    }
}

const deriveGraphData = (
    searchpack?: SelectedProducts,
    selectedBSR?: "BSR L" | "BSR S",
    typeFilter?: ("Paid" | "Organic")[],
    selectedTerms?: ISearchpackKeywordPerformanceRank[],
    selectedRange?: number,
    bsrData?: BSR[],
    rankData?: IAppSearchpackKeywordRank[],
    changesData?: IGroupedListingChanges,
    over?: () => void
) => {
    if (!searchpack?.asin) return { data: [], changes: [], range: [], terms: [], changesAxis: "left" as YAxis }
    over && over()

    const graphMap = new GraphMap(searchpack.asin)
    const large = selectedBSR === "BSR L"
    const small = selectedBSR === "BSR S"
    const paid = typeFilter?.includes("Paid")
    const organic = typeFilter?.includes("Organic")
    let max = 0
    let maxAxis: "left" | "right" = "left"

    let data: GraphData =
        (bsrData || []).map((item) => {
            const d = {
                ...item,
                label: item.date,
                change_number: large ? item.bsr_large : small ? item.bsr_small : 0,
                asin: searchpack.asin,
                term_id: 0,
                timestamp: 0,
            }

            if (large && d.change_number > max) {
                max = d.change_number
                maxAxis = "right"
            }

            if (small && d.change_number > max) {
                max = d.change_number
                maxAxis = "right"
            }

            graphMap.setParams(item.date, {
                yAxisId: "right",
                ...(large ? { bsr_large: d.bsr_large } : { bsr_small: d.bsr_small }),
            })
            return d
        }) || []

    if ((rankData?.length || 0) > 0) {
        const terms = selectedTerms || []

        data = rankData
            ? data.concat(
                  rankData.reduce<typeof data>((acc, keyword) => {
                      const term = terms.find((i) => i.term.term_id === keyword.term_id)
                      if (!term) return acc // Ignore change

                      const baseData = {
                          label: keyword.label,
                          asin: searchpack.asin,
                          term_id: keyword.term_id,
                      }

                      if ("paid" in keyword && paid && keyword.paid !== undefined) {
                          const d = {
                              ...baseData,
                              paid: keyword.paid,
                              change_number: keyword.paid,
                              timestamp: 0,
                          }

                          if (d.change_number > max) {
                              max = d.change_number
                              maxAxis = "left"
                          }

                          acc.push({ ...d, typeHint: "paid" })
                          graphMap.setParams(keyword.label, { paid: d.change_number, yAxisId: "left" })
                      }

                      if ("organic" in keyword && organic && keyword.organic !== undefined) {
                          const d = {
                              ...baseData,
                              organic: keyword.organic,
                              change_number: keyword.organic,
                              timestamp: 0,
                          }

                          if (d.change_number > max) {
                              max = d.change_number
                              maxAxis = "left"
                          }

                          acc.push({ ...d, typeHint: "organic" })
                          graphMap.setParams(keyword.label, { organic: d.change_number, yAxisId: "left" })
                      }

                      return acc
                  }, [])
              )
            : data
    }

    // Process changes
    data.forEach((next) => {
        next.timestamp = next.timestamp || extendedDayjs(next.label).unix()
    })

    // Derive graph date range
    const todayStr = extendedDayjs().tz(SEATTLE_TIMEZONE).format("YYYY-MM-DD")
    const hasToday = !!data.find((i) => i.label === todayStr) || !!rankData?.find((i) => i.label === todayStr)

    const range = dateHelper
        .alterRangeForToday(
            dateUtils.calendar.formRangeFromToday(selectedRange || 7, false, true, SEATTLE_TIMEZONE),
            hasToday
        )
        .map((i) => extendedDayjs(i).unix())

    data = data.filter((i) => range.includes(i.timestamp)).sort((a, b) => a.timestamp - b.timestamp)

    // Terms data
    const unqiueTerms = Array.from(new Set(data.map((i) => i.term_id)))
    const terms =
        selectedTerms
            ?.filter((i) => unqiueTerms.includes(i.term.term_id))
            .map((term) => ({
                term: term.term,
                data: rectifySingleDataPoint(
                    groupTermRankByDateRangeAndType(data.filter((i) => i.term_id && i.term_id === term.term.term_id))
                ),
            })) || []

    const originalChanges = changesData?.groupedListingChanges[searchpack.asin]
    const changes: GraphData = []
    if (originalChanges) {
        Object.keys(originalChanges)
            .filter((key) => range.includes(extendedDayjs(key).unix()))
            .forEach((key) => {
                const graphItem = graphMap.getByDate(key)
                const { change_number, yAxisId } = (() => {
                    return large && graphItem.bsr_large
                        ? { change_number: graphItem.bsr_large, yAxisId: graphItem.yAxisId }
                        : small && graphItem.bsr_small
                        ? { change_number: graphItem.bsr_small, yAxisId: graphItem.yAxisId }
                        : paid && graphItem.paid
                        ? { change_number: graphItem.paid, yAxisId: graphItem.yAxisId }
                        : organic && graphItem.organic
                        ? { change_number: graphItem.organic, yAxisId: graphItem.yAxisId }
                        : { change_number: max, yAxisId: maxAxis }
                })()

                changes.push({
                    label: key,
                    change_number,
                    yAxisId,
                    is_change: true,
                    asin: searchpack.asin,
                    timestamp: extendedDayjs(key).unix(),
                    term_id: 0,
                })
            })

        const maxBSR = graphMap.getMaxBSR().value
        const maxChange = changes.reduce((v: number, i) => ((i.change_number || 0) > v ? i.change_number || 0 : v), 0)
        if (maxBSR && maxChange <= maxBSR) maxAxis = "right"
    }

    data = data.filter((i) => !i.typeHint) // Since typed data (Paid | Organic) goes to `terms` object

    // Handle a special case of changes

    const out = {
        data,
        changes,
        range,
        terms,
        changesAxis: maxAxis,
    }

    // debug purpose
    console.log("graphData:", out)

    return out
}

export const usePerformanceKeywordGraphData = (
    searchpack?: SelectedProducts,
    selectedBSR?: "BSR L" | "BSR S",
    typeFilter?: ("Paid" | "Organic")[],
    selectedTerms?: ISearchpackKeywordPerformanceRank[],
    selectedRange?: number,
    bsrData?: BSR[],
    rankData?: IAppSearchpackKeywordRank[],
    changesData?: IGroupedListingChanges,
    over?: () => void
): GraphDataResult => {
    const result = useMemo(
        () =>
            deriveGraphData(
                searchpack,
                selectedBSR === "BSR L" ? "BSR L" : "BSR S",
                typeFilter,
                selectedTerms,
                selectedRange,
                bsrData,
                rankData,
                changesData,
                over
            ),
        [bsrData, changesData, over, rankData, searchpack, selectedBSR, selectedTerms, selectedRange, typeFilter]
    )

    return result
}
