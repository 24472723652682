export const OwnerIcon = (props: IPropsIcon) => {
    return (
        <>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.8333 19.7771C11.8504 19.7771 8.30847 21.7046 6.0535 24.6958C5.56817 25.3395 5.3255 25.6614 5.33344 26.0964C5.33957 26.4325 5.55015 26.8566 5.814 27.0641C6.15551 27.3327 6.62877 27.3327 7.57529 27.3327H24.0912C25.0377 27.3327 25.511 27.3327 25.8525 27.0641C26.1164 26.8566 26.3269 26.4325 26.3331 26.0964C26.341 25.6614 26.0983 25.3395 25.613 24.6958C23.358 21.7046 19.8161 19.7771 15.8333 19.7771Z"
                    stroke="#088AB2"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.8333 15.9993C18.9557 15.9993 21.487 13.4623 21.487 10.3327C21.487 7.20307 18.9557 4.66602 15.8333 4.66602C12.7108 4.66602 10.1795 7.20307 10.1795 10.3327C10.1795 13.4623 12.7108 15.9993 15.8333 15.9993Z"
                    stroke="#088AB2"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}
