export const DescriptionIcon = (props: IArrowProps) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.3612 26.7468H16.9795M4.66602 27.3332L11.1737 24.8302C11.5899 24.6701 11.7981 24.5901 11.9928 24.4856C12.1657 24.3927 12.3306 24.2856 12.4857 24.1652C12.6603 24.0297 12.818 23.8721 13.1333 23.5567L26.3612 10.3289C27.6565 9.03352 27.6565 6.93335 26.3612 5.63801C25.0658 4.34267 22.9657 4.34267 21.6703 5.63801L8.44247 18.8659C8.12712 19.1812 7.96945 19.3389 7.83396 19.5135C7.71362 19.6686 7.60647 19.8334 7.51363 20.0064C7.40911 20.2011 7.32906 20.4092 7.16897 20.8255L4.66602 27.3332ZM4.66602 27.3332L7.07959 21.0579C7.25231 20.6089 7.33866 20.3844 7.48678 20.2815C7.61623 20.1916 7.7764 20.1577 7.93119 20.1872C8.10831 20.221 8.27841 20.3911 8.61861 20.7313L11.2679 23.3806C11.6081 23.7208 11.7782 23.8909 11.812 24.068C11.8416 24.2228 11.8076 24.383 11.7177 24.5124C11.6148 24.6606 11.3903 24.7469 10.9413 24.9196L4.66602 27.3332Z"
                stroke={props.color ? props.color : "#0E7090"}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
