import { getExtendedTrackpackList } from "../actions"
import { getExtendedTrackpackListSaga } from "../sagas/trackpack.handlers"
import { extendedTrackpackList } from "../selectors/trackpack.selector"
import { EXTENDED_TRACKPACK_LIST } from "./keys"

export const trackpackStore = {
    /**
     * Trackpacks
     *
     * This store configuration object is used to manage the extended trackpack list.
     */
    [EXTENDED_TRACKPACK_LIST]: {
        key: EXTENDED_TRACKPACK_LIST,
        action: getExtendedTrackpackList,
        selector: extendedTrackpackList,
        saga: getExtendedTrackpackListSaga,
    },
}
