export const AppColor = {
    brandBase: "#FFE600",
}

export const KEYWORD_TERM_COLOR_RANGE: {
    color: {bg: string, text: string}
    rank: {
        from: number
        to: number
    }
}[] = [
    {
        color: { bg: "#75E0A7", text: "#085D3A" },
        rank: { from: 0, to: 5 },
    },
    {
        color: { bg: "#ABEFC6", text: "#067647" },
        rank: { from: 6, to: 10 },
    },
    {
        color: { bg: "#DCFAE6", text: "#079455" },
        rank: { from: 11, to: 15 },
    },
    {
        color: { bg: "#ECFDF3", text: "#17B26A" },
        rank: { from: 16, to: 20 },
    },
    {
        color: { bg: "#FDE272", text: "#713B12" },
        rank: { from: 21, to: 25 },
    },
    {
        color: { bg: "#FEEE95", text: "#854A0E" },
        rank: { from: 26, to: 30 },
    },
    {
        color: { bg: "#FEF7C3", text: "#A15C07" },
        rank: { from: 31, to: 35 },
    },
    {
        color: { bg: "#FEF7C3", text: "#CA8504" },
        rank: { from: 36, to: 40 },
    },
    {
        color: { bg: "#F7B27A", text: "#932F19" },
        rank: { from: 41, to: 45 },
    },
    {
        color: { bg: "#F9DBAF", text: "#B93815" },
        rank: { from: 46, to: 50 },
    },
    {
        color: { bg: "#FDEAD7", text: "#E04F16" },
        rank: { from: 51, to: 55 },
    },
    {
        color: { bg: "#FDEAD7", text: "#EF6820" },
        rank: { from: 56, to: 60 },
    },
    {
        color: { bg: "#FEA3B4", text: "#A11043" },
        rank: { from: 61, to: 65 },
    },
    {
        color: { bg: "#FECDD6", text: "#C01048" },
        rank: { from: 66, to: 70 },
    },
    {
        color: { bg: "#FFE4E8", text: "#E31B54" },
        rank: { from: 71, to: 75 },
    },
    {
        color: { bg: "#FFF1F3", text: "#F63D68" },
        rank: { from: 76, to: 80 },
    },
    {
        color: { bg: "#BDB4FE", text: "#4A1FB8" },
        rank: { from: 81, to: 85 },
    },
    {
        color: { bg: "#D9D6FE", text: "#5925DC" },
        rank: { from: 86, to: 90 },
    },
    {
        color: { bg: "#EBE9FE", text: "#6938EF" },
        rank: { from: 91, to: 95 },
    },
    {
        color: { bg: "#F4F3FF", text: "#7A5AF8" },
        rank: { from: 96, to: 100 },
    },
    {
        color: { bg: "#D0D5DD", text: "#1D2939" },
        rank: { from: 101, to: +Infinity },
    },
];

// export const ASIN_PRODUCTS_COLOR: TAsinProductColor[] = [
//     // Primary Pairings below
//     {
//         primary: "#06AED4", // 500
//         light: "#67E3F9", // 300
//         accent: "#164C63", // 900
//         badge: "#CFF9FE", // 100
//         hover: "#0E7090", //700
//         disabled: "#088AB2", // 600
//         extralight: "#A5F0FC", // 200
//     },
//     {
//         primary: "#66C61C", // 500
//         light: "#A6EF67", // 300
//         accent: "#2B5314", // 900
//         badge: "#E4FBCC", // 100
//         hover: "#3B7C0F", //700
//         disabled: "#4CA30D", // 600
//         extralight: "#D0F8AB", // 200
//     },
//     {
//         primary: "#EAAA08", // 500
//         light: "#FDE272", // 300
//         accent: "#713B12", // 900
//         badge: "#FEF7C3", // 100
//         hover: "#A15C07", //700
//         disabled: "#CA8504", // 600
//         extralight: "#FEEE95", //200
//     },
//     {
//         primary: "#EE46BC", // 500
//         light: "#FAA7E0", // 300
//         accent: "#851651", // 900
//         badge: "#FCE7F6", // 100
//         hover: "#C11574", //700
//         disabled: "#DD2590", // 600
//         extralight: "#FCCEEE", // 200
//     },
//     {
//         primary: "#7A5AF8", // 500
//         light: "#BDB4FE", // 300
//         accent: "#3E1C96", // 900
//         badge: "#EBE9FE", // 100
//         hover: "#5925DC", //700
//         disabled: "#6938EF", // 600
//         extralight: "#D9D6FE", //200
//     },
//     {
//         primary: "#EF6820", // 500
//         light: "#F7B27A", // 300
//         accent: "#772917", // 900
//         badge: "#FDEAD7", // 100
//         hover: "#B93815", //700
//         disabled: "#E04F16", // 600
//         extralight: "#F9DBAF", //200
//     },
//     {
//         primary: "#2E90FA", // 500
//         light: "#84CAFF", // 300
//         accent: "#194185", // 900
//         badge: "#D1E9FF", // 100
//         hover: "#175CD3", //700
//         disabled: "#1570EF", // 600
//         extralight: "#B2DDFF", //200
//     },
//     {
//         primary: "#F63D68", // 500
//         light: "#FEA3B4", // 300
//         accent: "#89123E", //900
//         badge: "#FFE4E8", // 100
//         hover: "#C01048", //700
//         disabled: "#E31B54", // 600
//         extralight: "#FECDD6", //200
//     },
//     {
//         primary: "#16B364", // 500
//         light: "#73E2A3", // 300
//         accent: "#084C2E", // 900
//         badge: "#D3F8DF", // 100
//         hover: "#087443", //700
//         disabled: "#099250", // 600
//         extralight: "#AAF0C4", // 200
//     },
//     {
//         primary: "#4E5BA6", // 500
//         light: "#B3B8DB", // 300
//         accent: "#101323", // 900
//         badge: "#EAECF5", // 100
//         hover: "#363F72", //700
//         disabled: "#3E4784", // 600
//         extralight: "#D5D9EB", //200
//     },
//     {
//         primary: "#79716B", //500
//         light: "#D7D3D0", // 300
//         accent: "#1C1917", // 900
//         badge: "#F5F5F4", // 100
//         hover: "#44403C", //700
//         disabled: "#57534E", // 600
//         extralight: "#E7E5E4", //200
//     },
//     {
//         primary: "#15B79E", // 500
//         light: "#5FE9D0", // 300
//         accent: "#134E48", // 900
//         badge: "#CCFBEF", // 100
//         hover: "#107569", //700
//         disabled: "#0E9384", // 600
//         extralight: "#99F6E0", //200
//     },
//     {
//         primary: "#F04438", // 500
//         light: "#FDA29B", // 300
//         accent: "#7A271A", // 900
//         badge: "#FEE4E2", // 100
//         hover: "#B42318", //700
//         disabled: "#D92D20", // 600
//         extralight: "#FECDCA", // 200
//     },
//     {
//         primary: "#D444F1", // 500
//         light: "#EEAAFD", // 300
//         accent: "#6F1877", // 900
//         badge: "#FBE8FF", // 100
//         hover: "#9F1AB1", //700
//         disabled: "#BA24D5", // 600
//         extralight: "#F6D0FE", //200
//     },
//     {
//         primary: "#669F2A", // 500
//         light: "#ACDC79", // 300
//         accent: "#2B4212", // 900
//         badge: "#E6F4D7", // 100
//         hover: "#3F621A", //700
//         disabled: "#4F7A21", // 600
//         extralight: "#CEEAB0", //200
//     },
//     {
//         primary: "#2970FF", // 500
//         light: "#84ADFF", // 300
//         accent: "#00359E", // 900
//         badge: "#D1E0FF", // 100
//         hover: "#004EEB", //700
//         disabled: "#155EEF", // 600
//         extralight: "#B2CCFF", //200
//     },
//     {
//         primary: "#FF4405", // 500
//         light: "#FF9C66", // 300
//         accent: "#97180C", // 800
//         badge: "#FFE6D5", // 100
//         hover: "#BC1B06", //700
//         disabled: "#E62E05", // 600
//         extralight: "#FFD6AE", // 200
//     },
//     {
//         primary: "#875BF7", // 500
//         light: "#C3B5FD", // 300
//         accent: "#5720B7", // 800
//         badge: "#ECE9FE", // 100
//         hover: "#6927DA", //700
//         disabled: "#7839EE", // 600
//         extralight: "#DDD6FE", //200
//     },
//     // Secondary Pairings below
//     {
//         primary: "#0E7090", // 700
//         light: "#06AED4", // 500
//         accent: "#0D2D3A", // 950
//         badge: "#CFF9FE", // 100
//         hover: "#164C63", //900
//         extralight: "#A5F0FC", //200
//     },
//     {
//         primary: "#3B7C0F", // 700
//         light: "", // 300
//         accent: "#15290A", // 950
//         badge: "#E4FBCC", // 100
//         hover: "#2B5314", //900
//         extralight: "#D0F8AB", //200
//     },
//     {
//         primary: "#A15C07", // 700
//         light: "", // 300
//         accent: "#542C0D", // 950
//         badge: "#FEF7C3", // 100
//         hover: "#713B12", //900
//         extralight: "#FEEE95", //200
//     },
//     {
//         primary: "#C11574", // 700
//         light: "", // 300
//         accent: "#4E0D30", // 950
//         badge: "#FCE7F6", // 100
//         hover: "#851651", //900
//         extralight: "#FCCEEE", //200
//     },
//     {
//         primary: "#5925DC", // 700
//         light: "", // 300
//         accent: "#27115F", // 950
//         badge: "#EBE9FE", // 100
//         hover: "#3E1C96", //900
//         extralight: "#D9D6FE", //200
//     },
//     {
//         primary: "#B93815", // 700
//         light: "", // 300
//         accent: "#511C10", // 950
//         badge: "#FDEAD7", // 100
//         hover: "#772917", //900
//         extralight: "#F9DBAF", //200
//     },
//     {
//         primary: "#175CD3", // 700
//         light: "", // 300
//         accent: "#102A56", // 950
//         badge: "#D1E9FF", // 100
//         hover: "#194185", //900
//         extralight: "#B2DDFF", //200
//     },
//     {
//         primary: "#C01048",
//         light: "", // 300
//         accent: "#510B24",
//         badge: "#FFE4E8", // 100
//         hover: "#0E7090", //700
//         extralight: "#FECDD6", //200
//     },
//     {
//         primary: "#087443", // 700
//         light: "", // 300
//         accent: "#052E1C", // 950
//         badge: "#D3F8DF", // 100
//         hover: "#89123E", //900
//         extralight: "#AAF0C4", // 200
//     },
//     {
//         primary: "#363F72", // 700
//         light: "", // 300
//         accent: "#0D0F1C", // 950
//         badge: "#EAECF5", // 100
//         hover: "#101323", //900
//         extralight: "#D5D9EB", //200
//     },
//     {
//         primary: "#44403C", // 700
//         light: "", // 300
//         accent: "#171412", // 950
//         badge: "#F5F5F4", // 100
//         hover: "#1C1917", //900
//         extralight: "#E7E5E4", //200
//     },
//     {
//         primary: "#107569", // 700
//         light: "", // 300
//         accent: "#0A2926", // 950
//         badge: "#CCFBEF", // 100
//         hover: "#134E48", //900
//         extralight: "#99F6E0", //200
//     },
//     {
//         primary: "#B42318", // 700
//         light: "", // 300
//         accent: "#55160C", // 950
//         badge: "#FEE4E2", // 100
//         hover: "#7A271A", //900
//         extralight: "#FECDCA", //200
//     },
//     {
//         primary: "#9F1AB1", // 700
//         light: "", // 300
//         accent: "#47104C", // 950
//         badge: "#FBE8FF", // 100
//         hover: "#6F1877", //900
//         extralight: "#F6D0FE", //200
//     },
//     {
//         primary: "#3F621A", // 700
//         light: "", // 300
//         accent: "#1A280B", // 950
//         badge: "#E6F4D7", // 100
//         hover: "#2B4212", //900
//         extralight: "#CEEAB0", //200
//     },
//     {
//         primary: "#004EEB", // 700
//         light: "", // 300
//         accent: "#002266", // 950
//         badge: "#D1E0FF", // 100
//         hover: "#00359E", //900
//         extralight: "#B2CCFF", //200
//     },
//     {
//         primary: "#BC1B06", // 700
//         light: "", // 300
//         accent: "#57130A", // 950
//         badge: "#FFE6D5", // 100
//         hover: "#771A0D", //900
//         extralight: "#FFD6AE", //200
//     },
//     {
//         primary: "#6927DA", // 700
//         light: "", // 300
//         accent: "#2E125E", // 950
//         badge: "#ECE9FE", // 100
//         hover: "#491C96", //900
//         extralight: "#DDD6FE", //200
//     },
//     // tertiary pairings
//     {
//         primary: "#A5F0FC", // 200
//         light: "", // 300
//         accent: "#06AED4", // 500
//         badge: "#CFF9FE", // 100
//         hover: "#22CCEE", //400
//         extralight: "",
//     },
//     {
//         primary: "#D0F8AB", // 200
//         light: "", // 300
//         accent: "#66C61C", // 500
//         badge: "#E4FBCC", // 100
//         hover: "#85E13A", //400
//         extralight: "#",
//     },
//     {
//         primary: "#FEEE95", // 200
//         light: "", // 300
//         accent: "#EAAA08", // 500
//         badge: "#FEF7C3", // 100
//         hover: "#FAC515", //400
//         extralight: "#",
//     },
//     {
//         primary: "#FCCEEE", // 200
//         light: "", // 300
//         accent: "#EE46BC", // 500
//         badge: "#FCE7F6", // 100
//         hover: "#F670C7", //400
//         extralight: "#",
//     },
//     {
//         primary: "#D9D6FE", // 200
//         light: "", // 300
//         accent: "#7A5AF8", // 500
//         badge: "#EBE9FE", // 100
//         hover: "#9B8AFB", //400
//         extralight: "#",
//     },
//     {
//         primary: "#F9DBAF", // 200
//         light: "", // 300
//         accent: "#EF6820", // 500
//         badge: "#FDEAD7", // 100
//         hover: "#F38744", //400
//         extralight: "#",
//     },
//     {
//         primary: "#B2DDFF", // 200
//         light: "", // 300
//         accent: "#2E90FA", // 500
//         badge: "#D1E9FF", // 100
//         hover: "#53B1FD", //400
//         extralight: "#",
//     },
//     {
//         primary: "#FECDD6", // 200
//         light: "", // 300
//         accent: "#F63D68", // 500
//         badge: "#FFE4E8", // 100
//         hover: "#FD6F8E", //400
//         extralight: "#",
//     },
//     {
//         primary: "#AAF0C4", // 200
//         light: "", // 300
//         accent: "#16B364", // 500
//         badge: "#D3F8DF", // 100
//         hover: "#3CCB7F", //400
//         extralight: "#",
//     },
//     {
//         primary: "#D5D9EB", //200
//         light: "", // 300
//         accent: "#4E5BA6", // 500
//         badge: "#EAECF5", // 100
//         hover: "#717BBC", //400
//         extralight: "#",
//     },
//     {
//         primary: "#E7E5E4", // 200
//         light: "", // 300
//         accent: "#79716B", // 500
//         badge: "#F5F5F4", // 100
//         hover: "#A9A29D", //400
//         extralight: "#",
//     },
//     {
//         primary: "#99F6E0", // 200
//         light: "", // 300
//         accent: "#15B79E", //500
//         badge: "#CCFBEF", // 100
//         hover: "#2ED3B7", //400
//         extralight: "#",
//     },
//     {
//         primary: "#FECDCA", // 200
//         light: "", // 300
//         accent: "#F04438", // 500
//         badge: "#FEE4E2", // 100
//         hover: "#F97066", //400
//         extralight: "#",
//     },
//     {
//         primary: "#F6D0FE", // 200
//         light: "", // 300
//         accent: "#D444F1", // 500
//         badge: "#FBE8FF", // 100
//         hover: "#E478FA", //400
//         extralight: "#",
//     },
//     {
//         primary: "#CEEAB0", // 200
//         light: "", // 300
//         accent: "#669F2A", // 500
//         badge: "#E6F4D7", // 100
//         hover: "#86CB3C", //400
//         extralight: "#",
//     },
//     {
//         primary: "#B2CCFF", // 200
//         light: "", // 300
//         accent: "#2970FF", // 500
//         badge: "#D1E0FF", // 100
//         hover: "#528BFF", //400
//         extralight: "#",
//     },
//     {
//         primary: "#FFD6AE", // 200
//         light: "", // 300
//         accent: "#FF4405", // 500
//         badge: "#FFE6D5", // 100
//         hover: "#FF692E", //400
//         extralight: "#",
//     },
//     {
//         primary: "#DDD6FE", // 200
//         light: "", // 300
//         accent: "#875BF7", // 500
//         badge: "#ECE9FE", // 100
//         hover: "#875BF7", //700
//         extralight: "#",
//     },
// ]

export const ASIN_PRODUCTS_COLOR: TAsinProductColor[] = [
    // Primary Pairings below
    {
        default: "#088AB2", //600
        hover: "#0E7090", //700
        active: "#67E3F9", //300
        inActive: "#06AED4", //500
        bgHundred: "#CFF9FE",
    },
    {
        default: "#4CA30D", //600
        hover: "#3B7C0F", //700
        active: "#A6EF67", //300
        inActive: "#66C61C", //500
        bgHundred: "#E4FBCC",
    },
    {
        default: "#DD2590", //600
        hover: "#C11574", //700
        active: "#FAA7E0", //300
        inActive: "#EE46BC", //500
        bgHundred: "#FCE7F6",
    },
    {
        default: "#6938EF", //600
        hover: "#5925DC", //700
        active: "#BDB4FE", //300
        inActive: "#7A5AF8", //500
        bgHundred: "#EBE9FE",
    },
    {
        default: "#E04F16", //600
        hover: "#B93815", //700
        active: "#F7B27A", //300
        inActive: "#EF6820", //500
        bgHundred: "#FDEAD7",
    },
    {
        default: "#1570EF", //600
        hover: "#175CD3", //700
        active: "#84CAFF", //300
        inActive: "#2E90FA", //500
        bgHundred: "#D1E9FF",
    },
    {
        default: "#E31B54", //600
        hover: "#C01048", //700
        active: "#FEA3B4", //300
        inActive: "#F63D68", //500
        bgHundred: "#FFE4E8",
    },
    {
        default: "#099250", //600
        hover: "#087443", //700
        active: "#73E2A3", //300
        inActive: "#16B364", //500
        bgHundred: "#D3F8DF",
    },
    {
        default: "#3E4784", //600
        hover: "#363F72", //700
        active: "#B3B8DB", //300
        inActive: "#4E5BA6", //500
        bgHundred: "#EAECF5",
    },
    {
        default: "#57534E", //600
        hover: "#44403C", //700
        active: "#D7D3D0", //300
        inActive: "#79716B", //500
        bgHundred: "#F5F5F4",
    },
    {
        default: "#0E9384", //600
        hover: "#107569", //700
        active: "#5FE9D0", //300
        inActive: "#15B79E", //500
        bgHundred: "#CCFBEF",
    },
    {
        default: "#D92D20", //600
        hover: "#B42318", //700
        active: "#FDA29B", //300
        inActive: "#F04438", //500
        bgHundred: "#FEE4E2",
    },
    {
        default: "#BA24D5", //600
        hover: "#9F1AB1", //700
        active: "#EEAAFD", //300
        inActive: "#D444F1", //500
        bgHundred: "#FBE8FF",
    },
    {
        default: "#4F7A21", //600
        hover: "#3F621A", //700
        active: "#ACDC79", //300
        inActive: "#669F2A", //500
        bgHundred: "#E6F4D7",
    },
    {
        default: "#155EEF", //600
        hover: "#004EEB", //700
        active: "#84ADFF", //300
        inActive: "#2970FF", //500
        bgHundred: "#D1E0FF",
    },
    {
        default: "#E62E05", //600
        hover: "#BC1B06", //700
        active: "#FF9C66", //300
        inActive: "#FF4405", //500
        bgHundred: "#FFE6D5",
    },
    {
        default: "#7839EE", //600
        hover: "#6927DA", //700
        active: "#C3B5FD", //300
        inActive: "#875BF7", //500
        bgHundred: "#ECE9FE",
    },
    // {
    //     default: "#CA8504", //600
    //     hover: "#A15C07", //700
    //     active: "#FDE272", //300
    //     inActive: "#EAAA08", //500
    // },
    // Secondary Pairings below
    {
        default: "#22CCEE", //400
        hover: "#06AED4", //500
        active: "#CFF9FE", //100
        inActive: "#67E3F9", //300
        bgHundred: "#CFF9FE",
    },
    {
        default: "#85E13A", //400
        hover: "#66C61C", //500
        active: "#E4FBCC", //100
        inActive: "#A6EF67", //300
        bgHundred: "#E4FBCC",
    },
    {
        default: "#F670C7", //400
        hover: "#EE46BC", //500
        active: "#FCE7F6", //100
        inActive: "#FAA7E0", //300
        bgHundred: "#FCE7F6",
    },
    {
        default: "#9B8AFB", //400
        hover: "#7A5AF8", //500
        active: "#EBE9FE", //100
        inActive: "#BDB4FE", //300
        bgHundred: "#EBE9FE",
    },
    {
        default: "#F38744", //400
        hover: "#EF6820", //500
        active: "#FDEAD7", //100
        inActive: "#F7B27A", //300
        bgHundred: "#FDEAD7",
    },
    {
        default: "#53B1FD", //400
        hover: "#2E90FA", //500
        active: "#D1E9FF", //100
        inActive: "#84CAFF", //300
        bgHundred: "#D1E9FF",
    },
    {
        default: "#FD6F8E", //400
        hover: "#F63D68", //500
        active: "#FFE4E8", //100
        inActive: "#FEA3B4", //300
        bgHundred: "#FFE4E8",
    },
    {
        default: "#3CCB7F", //400
        hover: "#16B364", //500
        active: "#D3F8DF", //100
        inActive: "#73E2A3", //300
        bgHundred: "#D3F8DF",
    },
    {
        default: "#717BBC", //400
        hover: "#4E5BA6", //500
        active: "#EAECF5", //100
        inActive: "#B3B8DB", //300
        bgHundred: "#EAECF5",
    },
    {
        default: "#A9A29D", //400
        hover: "#79716B", //500
        active: "#F5F5F4", //100
        inActive: "#D7D3D0", //300
        bgHundred: "#F5F5F4",
    },
    {
        default: "#2ED3B7", //400
        hover: "#15B79E", //500
        active: "#CCFBEF", //100
        inActive: "#5FE9D0", //300
        bgHundred: "#CCFBEF",
    },
    {
        default: "#F97066", //400
        hover: "#F04438", //500
        active: "#FEE4E2", //100
        inActive: "#FDA29B", //300
        bgHundred: "#FEE4E2",
    },
    {
        default: "#E478FA", //400
        hover: "#D444F1", //500
        active: "#FBE8FF", //100
        inActive: "#EEAAFD", //300
        bgHundred: "#FBE8FF",
    },
    {
        default: "#86CB3C", //400
        hover: "#669F2A", //500
        active: "#E6F4D7", //100
        inActive: "#ACDC79", //300
        bgHundred: "#E6F4D7",
    },
    {
        default: "#528BFF", //400
        hover: "#2970FF", //500
        active: "#D1E0FF", //100
        inActive: "#84ADFF", //300
        bgHundred: "#D1E0FF",
    },
    {
        default: "#FF692E", //400
        hover: "#FF4405", //500
        active: "#FFE6D5", //100
        inActive: "#FF9C66", //300
        bgHundred: "#FFE6D5",
    },
    {
        default: "#A48AFB", //400
        hover: "#875BF7", //500
        active: "#ECE9FE", //100
        inActive: "#C3B5FD", //300
        bgHundred: "#ECE9FE",
    },

    // {
    //     default: "#FAC515", //400
    //     hover: "#EAAA08", //500
    //     active: "#FEF7C3", //100
    //     inActive: "#FDE272", //300
    // },
    // tertiary pairings
    {
        default: "#155B75", //800
        hover: "#164C63", //900
        active: "#06AED4", //500
        inActive: "#0E7090", //700
        bgHundred: "#CFF9FE",
    },
    {
        default: "#326212", //800
        hover: "#2B5314", //900
        active: "#66C61C", //500
        inActive: "#3B7C0F", //700
        bgHundred: "#E4FBCC",
    },

    {
        default: "#9E165F", //800
        hover: "#851651", //900
        active: "#EE46BC", //500
        inActive: "#C11574", //700
        bgHundred: "#FCE7F6",
    },
    {
        default: "#4A1FB8", //800
        hover: "#3E1C96", //900
        active: "#7A5AF8", //500
        inActive: "#5925DC", //700
        bgHundred: "#EBE9FE",
    },
    {
        default: "#932F19", //800
        hover: "#772917", //900
        active: "#EF6820", //500
        inActive: "#B93815", //700
        bgHundred: "#FDEAD7",
    },
    {
        default: "#1849A9", //800
        hover: "#194185", //900
        active: "#2E90FA", //500
        inActive: "#175CD3", //700
        bgHundred: "#FDEAD7",
    },
    {
        default: "#A11043", //800
        hover: "#89123E", //900
        active: "#F63D68", //500
        inActive: "#C01048", //700
        bgHundred: "#FFE4E8",
    },
    {
        default: "#095C37", //800
        hover: "#084C2E", //900
        active: "#16B364", //500
        inActive: "#087443", //700
        bgHundred: "#D3F8DF",
    },
    {
        default: "#293056", //800
        hover: "#101323", //900
        active: "#4E5BA6", //500
        inActive: "#363F72", //700
        bgHundred: "#EAECF5",
    },
    {
        default: "#292524", //800
        hover: "#1C1917", //900
        active: "#79716B", //500
        inActive: "#44403C", //700
        bgHundred: "#F5F5F4",
    },
    {
        default: "#125D56", //800
        hover: "#134E48", //900
        active: "#15B79E", //500
        inActive: "#107569", //700
        bgHundred: "#CCFBEF",
    },
    {
        default: "#912018", //800
        hover: "#7A271A", //900
        active: "#F04438", //500
        inActive: "#B42318", //700
        bgHundred: "#FEE4E2",
    },
    {
        default: "#821890", //800
        hover: "#6F1877", //900
        active: "#D444F1", //500
        inActive: "#9F1AB1", //700
        bgHundred: "#FBE8FF",
    },
    {
        default: "#335015", //800
        hover: "#2B4212", //900
        active: "#669F2A", //500
        inActive: "#3F621A", //700
        bgHundred: "#E6F4D7",
    },
    {
        default: "#0040C1", //800
        hover: "#00359E", //900
        active: "#2970FF", //500
        inActive: "#004EEB", //700
        bgHundred: "#D1E0FF",
    },
    {
        default: "#97180C", //800
        hover: "#771A0D", //900
        active: "#FF4405", //500
        inActive: "#BC1B06", //700
        bgHundred: "#FFE6D5",
    },
    {
        default: "#5720B7", //800
        hover: "#491C96", //900
        active: "#875BF7", //500
        inActive: "#6927DA", //700
        bgHundred: "#ECE9FE",
    },
    // {
    //     default: "#854A0E", //800
    //     hover: "#713B12", //900
    //     active: "#EAAA08", //500
    //     inActive: "#A15C07", //700
    // },
]
