import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { dateUtils } from "../utils/date"

export const getExtendedSearchpacks = async (pathParams = {}, queryParams = {}) => {
    const pathString = pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
    const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
    const url = `${API_ROUTES.GET_SEARCHPACK_WITHSEARCHTERM}${pathString}${queryString}`

    try {
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        const validatedResponse = await HttpHelper.validateResponse(result)

        const sortByCreatedAt = (data: { searchpacks: TSearchpack[] }) => {
            const { searchpacks } = data
            return dateUtils
                .sortObjectsByDate("created_at", searchpacks, "YYYY-MM-DDTHH:mm:ss.SSSZ", "desc")
                ?.map((searchpack: TSearchpack) => ({
                    ...searchpack,
                    search_terms: dateUtils
                        .sortObjectsByDate(
                            "created_at",
                            searchpack.search_terms?.filter((i) => !!i.created_at) || [],
                            "YYYY-MM-DDTHH:mm:ss.SSSZ",
                            "desc"
                        )
                        .concat(
                            searchpack.search_terms
                                ?.filter((i) => !i.created_at)
                                .sort((a, b) => a.term.localeCompare(b.term)) || []
                        ),
                }))
        }
        const sortedData = sortByCreatedAt(validatedResponse as { searchpacks: TSearchpack[] })

        return {
            trackpacks: sortedData as TSearchpack[],
        }
    } catch (error) {
        console.error("Failed to fetch dropdown list:", error)
        throw new Error("Error fetching dropdown list")
    }
}
