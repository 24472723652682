import { useSelector } from "react-redux"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { AuthStateSelector } from "../../../../selectors/authState.selector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
const SearchpackStepThreeView: React.FC<AddTrackPackProps> = ({
    asinHeaderText,
    icon,
    handleCloseDialog,
    handlePrev,
    buttonText,
    searchpackName,
}) => {
    const { getAccessLevel } = useSelector(AuthStateSelector)

    const ownerName =
        getAccessLevel?.first_name && getAccessLevel?.last_name
            ? getAccessLevel?.first_name
            : getAccessLevel && getAccessLevel?.username

    return (
        <>
            <div className="flex items-center justify-between">
                {icon}
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-[#344054] text-[24px] font-medium mt-[24px] ml-[6px] capitalize">
                {asinHeaderText} {ownerName}!
            </PrimaryText>
            <PrimaryText className="text-[16px] font-[300] mt-[16px] text-gray-700 mx-[6px] ">
                I’m setting up your new Searchpack called&nbsp;
                <PrimaryText className="text-[16px] font-[500] inline  text-gray-700 ">
                    {searchpackName}.&nbsp;
                </PrimaryText>
                <PrimaryText className="text-[16px] font-[300] text-gray-700 inline">
                    Keep up the great work!
                </PrimaryText>
            </PrimaryText>

            <div className="flex justify-between  items-center mt-[48px]">
                <div className="flex items-center">
                    {/* <BackChevronIcon />
                    <PrimaryText onClick={handlePrev} className="text-[18px]  cursor-pointer text-[#667085]">
                        Back
                    </PrimaryText> */}
                </div>

                <ButtonElement
                    type="submit"
                    size="large"
                    onClick={handleCloseDialog}
                    textClass="text-[18px] leading-[28px]"
                    className={"w-[99px] bg-[#A5F0FC] rounded-[12px] px-[30px] py-[8px] text-[#0E7090] "}
                >
                    {buttonText}
                </ButtonElement>
            </div>
        </>
    )
}
export default SearchpackStepThreeView
