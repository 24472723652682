export const RestoreIcon = () => {
    return (
        <>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.5 10.5002H19.25C22.1495 10.5002 24.5 12.8507 24.5 15.7502C24.5 18.6497 22.1495 21.0002 19.25 21.0002H14M3.5 10.5002L8.16667 5.8335M3.5 10.5002L8.16667 15.1668"
                    stroke="#088AB2"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
