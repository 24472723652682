import { useEffect, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { SearchpackActionCreator } from "../../../actions/searchpack.action"
import { ReactComponent as AddIcon } from "../../../assets/svgs/new-add-icon.svg"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useAppRouteReady } from "../../../hooks/app/searchpack/navigation"
import { useAppSearchParams } from "../../../hooks/app/searchpack/useAppSearchParams"
import { useLoadingStatus } from "../../../hooks/useLoadingStatus.hook"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../selectors/searchpack.selector"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import { SelectElement } from "../../elements/select.element"
import CommonTooltip from "../tooltipItems/commonTooltip.component"
import KeywordPerformnceAsinCard from "./keyword-performance/keywordPerformnceAsinCard.component"
import { RankTrackerAsinCard } from "./rank-tracker/rank-tracker-asin-card.component"
import { SearchpackAsinCard } from "./searchpackAsinCard.component"

export const SearchpackSelection = () => {
    const routeReady = useAppRouteReady()
    const { searchParams, getParamValue, updateSearchParams } = useAppSearchParams()
    const spId = getParamValue("sp_id", "0", Number) as Number

    const { control, setValue } = useForm()
    const dispatch = useDispatch()
    const { activeRankingTab, searchpackDropdownList, selectedSearchpack } = useSelector(SearchpackStateSelector)
    const { userActionData } = useSelector(AccountStateSelector)

    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useLoadingStatus(loading, RuntimeHelper.searchpackListLoading())

    const [allSearchpacks, setAllSearchpacks] = useState<any>()
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const [, setAmazontld] = useState<string>()

    useEffect(() => {
        if (userActionData !== undefined) {
            dispatch(
                SearchpackActionCreator.getSearchpackList(
                    {},
                    { as_user: userActionData && userActionData?.id + "", active_only: "true" }
                )
            )
        } else {
            dispatch(SearchpackActionCreator.getSearchpackList({}, { active_only: "true" }))
        }
    }, [dispatch, userActionData])

    useEffect(() => {
        let cleanup = true

        if (searchpackDropdownList?.searchpacks && searchpackDropdownList?.searchpacks?.length > 0 && routeReady) {
            const transformedResponse = {
                groups: searchpackDropdownList.searchpacks.map((group: any) => ({
                    name: group.name,
                    id: group.id,
                    amazon_tld: group.amazon_tld,
                })),
            }

            const defaultGroup =
                transformedResponse.groups.find((i) => Number(i.id) === Number(spId)) ||
                transformedResponse?.groups?.[0]

            if (defaultGroup) {
                cleanup = false
                setAllSearchpacks(transformedResponse as any)
                setSelectedIndex(defaultGroup?.id)
                setAmazontld(defaultGroup?.amazon_tld)
                setValue("searchpacks", defaultGroup?.id)
                dispatch(SearchpackActionCreator.setSelectedSearchPackName(defaultGroup?.name))
                updateSearchParams({ sp_id: `${defaultGroup.id || ""}` })
            }
        }

        if (cleanup) {
            setValue("searchpacks", undefined)
            setAllSearchpacks([])
            setSelectedIndex(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchpackDropdownList, userActionData, dispatch, setValue, searchParams])

    useEffect(() => {
        if (!!selectedSearchpack && !!allSearchpacks) {
            handleSelectElementChange(selectedSearchpack)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSearchpack, allSearchpacks])

    useEffect(() => {
        if (selectedIndex) {
            const options: { as_user?: string } = {}

            if (userActionData) {
                options.as_user = userActionData.id + ""
            }
            dispatch(SearchpackActionCreator.getSearchpackWithLastDataTime(selectedIndex, {}, options))
        }
    }, [selectedIndex, dispatch, userActionData])

    useEffect(() => {
        if (!selectedIndex) return

        dispatch(
            SearchpackActionCreator.setSelectedSearchPackValues({
                selectedSearchpackID: selectedIndex,
            })
        )

        updateSearchParams({ sp_id: `${selectedIndex || ""}` })
    }, [selectedIndex, dispatch, searchParams, updateSearchParams])

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAddSearchpackModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const handleSelectElementChange = (selectedValue: number) => {
        const selectedGroup = allSearchpacks?.groups?.find?.((group: any) => group.id === selectedValue)
        dispatch(SearchpackActionCreator.selectedSearchpackProduct({ selectedProducts: [] }))
        if (selectedGroup) {
            setSelectedIndex(selectedGroup.id)

            dispatch(
                SearchpackActionCreator.setSelectedSearchPackValues({
                    selectedSearchpackID: selectedGroup.id,
                })
            )
            dispatch(SearchpackActionCreator.setSelectedSearchPackName(selectedGroup?.name))
        }
    }

    return (
        <>
            <div className="flex flex-col gap-[24px] w-[304px]">
                <div className="w-full">
                    <div className="w-full p-[12px] px-3 pt-3 justify-start items-start inline-flex rounded-[16px]  border border-gray-50 bg-gray-50 ">
                        <div className="w-full gap-[12px]  flex flex-col">
                            <div className="flex justify-between ">
                                <div className="flex gap-[6px] items-center">
                                    <PrimaryText
                                        onClick={handleOpen}
                                        weight="medium"
                                        size="sm-medium"
                                        className="text-[#088AB2] font- leading-normal pl-[8px]"
                                    >
                                        Searchpacks
                                    </PrimaryText>
                                </div>
                                <div
                                    className="cursor-pointer outline-none"
                                    data-tooltip-id="dashboard-tooltip"
                                    data-tooltip-place="bottom"
                                    data-tooltip-offset={-8}
                                    data-tooltip-class-name={"!ml-[45px]"}
                                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                        <CommonTooltip className="" label="Add Searchpack" />
                                    )}
                                >
                                    <ButtonElement
                                        onClick={handleOpen}
                                        viewType="only-icon"
                                        className="p-[10px] bg-gradient-to-br from-cyan-100 to-cyan-200 rounded-[10px] disabled:bg-gray-100
                                    hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                                    active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400"
                                    >
                                        <AddIcon />
                                    </ButtonElement>
                                </div>
                            </div>
                            <div className="w-[280px] relative">
                                <SelectElement
                                    className="focus:outline focus:outline-cyan-300/30 pr-[8px] !w-[99%]"
                                    customBoxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(103, 227, 249, 0.20)"
                                    buttonTextClass="truncate whitespace-nowrap max-w-[220px] "
                                    reactHookControl={control}
                                    type="check"
                                    name="searchpacks"
                                    rightCheckBox={true}
                                    placeholder={"Select Searchpack"}
                                    isToolitpShow={false}
                                    disabled={isLoading}
                                    defaultValue={spId as unknown as any}
                                    options={
                                        allSearchpacks &&
                                        allSearchpacks?.groups?.map(
                                            (g: { id: string; name: string; amazon_tld: string }) => {
                                                return {
                                                    value: g?.id,
                                                    label: g?.name,
                                                    amazon_tld: g?.amazon_tld,
                                                }
                                            }
                                        )
                                    }
                                    onChange={handleSelectElementChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {activeRankingTab === "Keyword Performance" ? (
                    <KeywordPerformnceAsinCard />
                ) : activeRankingTab === "Rank Tracker" ? (
                    <RankTrackerAsinCard />
                ) : (
                    <SearchpackAsinCard />
                )}
            </div>
        </>
    )
}
