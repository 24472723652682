import dayjs from "dayjs"

import { img_url } from "../config/dashboard.config"
import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import {
    ensureMaxLength,
    extractIdentifier,
    extractVideoId,
    getColorsFromAsin,
    UtilHelper,
} from "../helpers/util.helper"
import { store } from "../store"

interface ActualAsinProductDatum {
    new_actual_asin?: string
    actual_asin: string
    product_data: ProductDatum[]
    main_product_data?: ProductDatum
    amazon_tld: string
    own_product: boolean
}

interface ProductDatum {
    actual_asin: string | null
    timestamp: string
    bsr_large: number | null
    bsr_small: number | null
    ratings_count: number | null
    rating: number | null
    price: number | null
    main_image: string
    carousel_images: string[] | null
    title: string
    description: null | string
    bullets: string[] | null
    videos: Video[] | null
    currency: string | null
    seller_info: SellerInfo | null
    availability: string | null
    success: boolean
    isFirst: boolean
    product_id: number
    main_video: MainVideo | null
}
interface SellerInfo {
    id: string | null
    link: string | null
    name: string | null
}
interface Video {
    video_url: string
    thumbnail_url: string
    title: string
    type: string
}
interface MainVideo {
    thumbnail_filename: string
    url: string
    type: string
    title: string
}
export interface Root {
    trackpack_product_data: TrackpackProductDaum[]
}

export interface TrackpackProductDaum {
    requested_asin: string

    new_actual_asin?: string
    actual_asin: string
    product_data: ProductDaum[]
    main_product_data?: ProductDatum
    amazon_tld: string
}

export interface ProductDaum {
    requested_asin: string
    actual_asin: string | null
    parent_asin?: string
    title: string
    description: null | string
    bullets: string[] | null
    main_image_filename: string
    is_image_in_s3?: boolean
    carousel_images: string[] | null
    videos: Videom[] | null
    seller: Seller | null
    availability: string | null
    small_category?: string
    large_category?: string
    currency: string | null
    is_data_available: boolean
    price: number | null
    small_bsr: number | null
    large_bsr: number | null
    rating: number | null
    ratings_total: number | null
    timestamp: string
    id: number
    main_video: MainVideo
}

export interface Videom {
    thumbnail_filename: string
    url: string
    type: string
    title: string
}

export interface Seller {
    id: number
    name: string
    link?: string
}

export interface ValueInterface {
    value: boolean
    timestamp: string
}

// Checks whether a given value is null or undefined. This utility is extensively reused in other parts of the code
const isNullOrUndefined = (value: any) => value === null || value === undefined

/* This function takes an array of product data and performs two primary operations:
 Averaging: Computes average values for specified keys (e.g., ratings, prices).
 Merging: Collects and sorts values of specified keys by their timestamps. */
const mergedData = (
    products: IProductData[],
    margeKeys: (keyof IProductData)[],
    AverageKeys: (keyof IProductData)[]
) => {
    const averageCalculatedValues: { [key: string]: number } = {}
    // const averageCalculatedValuesTotal: { [key: string]: number } = {}
    const mergingCalculatedValues: {
        [keys: string]: {
            timestamp: string
            [key: string]: (typeof products)[0][keyof IProductData]
        }[]
    } = {}

    products?.forEach((product) => {
        AverageKeys?.forEach((key: keyof IProductData) => {
            if (!isNullOrUndefined(product[key])) {
                averageCalculatedValues[key] = product[key] as number
                // averageCalculatedValues[key] = (averageCalculatedValues[key] || 0) + (product[key] as number)
                // averageCalculatedValuesTotal[key] = (averageCalculatedValuesTotal[key] || 0) + 1
            }
        })

        margeKeys.forEach((key: keyof IProductData) => {
            if (!isNullOrUndefined(product[key])) {
                mergingCalculatedValues[key] = [
                    ...(mergingCalculatedValues[key] ?? []),
                    { value: product[key], timestamp: product.timestamp, id: product.actual_asin },
                ]
            }
        })
    })

    margeKeys?.forEach((key: keyof IProductData) => {
        if (mergingCalculatedValues[key]) {
            mergingCalculatedValues[key]?.sort((a, b) => {
                return new Date(b.timestamp) < new Date(a.timestamp) ? 1 : -1
            })
        }
    })

    return { ...mergingCalculatedValues, ...averageCalculatedValues }
}
/* Compares two product data objects and removes duplicate or matching attributes based on specific keys. Special cases include:
 Handling nested structures like videos, main_video, and seller_info.
 Using helper methods like extractVideoId and extractIdentifier for base path removal and identifier extraction.*/
const compareAndRemove = (a: IProductData, b: IProductData, keys: (keyof IProductData)[]): IProductData => {
    if (a.success && b.success) {
        a = { ...a }
        keys?.forEach((key: keyof IProductData) => {
            type StringsType = string[] | string | Video
            const mr = (r: StringsType) => {
                const removeBasePath = (link: string | Video) => {
                    if (key === "carousel_images" || key === "main_image" || key === "videos") {
                        if (key === "videos") {
                            return extractVideoId((link as Video).video_url)
                        } else if (key === "main_image" || key === "carousel_images") {
                            return extractIdentifier(link as string)
                        } else {
                            const splitLink = (link as string).split("/")
                            return splitLink[splitLink.length - 1]
                        }
                    }
                    return link
                }
                return r && Array.isArray(r)
                    ? r.map((temp) => {
                          return removeBasePath(temp)
                      })
                    : removeBasePath(r)
            }
            if (a[key] !== undefined && a[key] !== null && b[key] !== undefined && b[key] !== null) {
                const va = mr(a[key] as StringsType)
                const vb = mr(b[key] as StringsType)
                if (key === "videos" && Array.isArray(va) && Array.isArray(vb)) {
                    va.sort()
                    vb.sort()
                    // if (JSON.stringify(va) === JSON.stringify(vb)) {
                    //     delete (a as Partial<IProductData>)[key]
                    // }
                }
                if (key === "main_video") {
                    // @ts-ignore
                    const titlesMatch = va.title === vb.title
                    // @ts-ignore
                    const urlsMatch = va.url === vb.url
                    // @ts-ignore
                    const thumbnailsMatch = va.thumbnail_filename === vb.thumbnail_filename
                    // @ts-ignore
                    const typesMatch = va.type === vb.type

                    if (urlsMatch && thumbnailsMatch && typesMatch && titlesMatch) {
                        // @ts-ignore
                        delete a[key]
                    }
                    // @ts-ignore
                    else if (va.title === "" && vb.title !== "") {
                        // @ts-ignore
                        delete a[key]
                    }
                }

                // if (key === "keywords") {
                //     // @ts-ignore
                //     if (va === vb) {
                //         // @ts-ignore
                //         delete a[key]
                //     }
                // }
                if (key === "seller_info") {
                    // @ts-ignore
                    if (va.id === vb.id) {
                        delete a[key]
                    }
                } else if (JSON.stringify(va) === JSON.stringify(vb)) {
                    delete a[key]
                }
            }
        })
        return a
    } else {
        return a.success ? a : b
    }
}

/* Fills missing fields in one product object (b) using corresponding fields from another product object (a).
   Returns a boolean indicating whether any changes were made.*/
const putDuplicateIfEmpty = (a: ProductDatum, b: ProductDatum, keys: (keyof ProductDatum)[]): boolean => {
    let changed = false
    keys.forEach((key: keyof ProductDatum) => {
        if (b && b[key] === null) {
            // @ts-ignore
            b[key] = a[key]
            changed = true
        }
    })

    return changed
}

// Compares two string arrays for equality by sorting and checking each element.
const arraysAreEqual = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) {
        return false
    }
    const sortedArr1 = [...arr1]
    const sortedArr2 = [...arr2]
    return sortedArr1?.every((value, index) => value === sortedArr2[index])
}

// Determines equality between arrays of video objects by comparing their extracted video IDs and thumbnails
const videosAreEqual = (arr1: Video[], arr2: Video[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false
    }

    const sortedArr1 = ensureMaxLength(
        arr1.slice().sort((a, b) => extractVideoId(a.video_url).localeCompare(extractVideoId(b.video_url))),
        10
    )

    const sortedArr2 = ensureMaxLength(
        arr2.slice().sort((a, b) => extractVideoId(a.video_url).localeCompare(extractVideoId(b.video_url))),
        10
    )

    for (let i = 0; i < sortedArr1.length; i++) {
        const videoId1 = extractVideoId(sortedArr1[i].video_url)
        const videoId2 = extractVideoId(sortedArr2[i].video_url)

        if (videoId1 !== videoId2 || sortedArr1[i].thumbnail_url !== sortedArr2[i].thumbnail_url) {
            return false
        }
    }

    return true
}

// Compares two image URL arrays by extracting unique identifiers from their filenames and sorting.
const carouselImagesAreEqual = (arr1: string[], arr2: string[]): boolean => {
    const extractIdentifier = (url: string) => {
        const parts = url.split("/")
        const filename = parts[parts.length - 1]
        return filename.split("_")[0]
    }
    if (arr1.length !== arr2.length) {
        return false
    }

    const extractAndSort = (arr: string[]): string[] => {
        return arr.map(extractIdentifier).sort()
    }

    const sortedArr1 = extractAndSort(arr1)
    const sortedArr2 = extractAndSort(arr2)

    return sortedArr1.every((value, index) => value === sortedArr2[index])
}

// Compares two main image URLs by extracting unique identifiers.
const mainImagesAreEqual = (val1: string, val2: string): boolean => {
    const extractIdentifier = (url: string) => {
        const parts = url.split("/")
        const filename = parts[parts.length - 1]
        return filename.split("_")[0]
    }

    return extractIdentifier(val1) === extractIdentifier(val2)
}

// Groups product data based on a time interval (e.g., "day"). Merges and processes the grouped data by applying transformations and deduplication logic.
const getGroupedProductData = (productData: IProductData[], by: "day") => {
    let temp: { [key: string]: IProductData[] } = {}

    productData.forEach((pd: IProductData) => {
        let day = dayjs(pd.timestamp).startOf(by).format()

        const grouped = temp[day]
        if (grouped) {
            temp[day] = [...grouped, pd]
        } else {
            temp[day] = [pd]
        }
    })

    const mergedProductData: IProductData[] = []

    const mergedKeys: (keyof IProductData)[] = [
        "actual_asin",
        "title",
        "bullets",
        "carousel_images",
        "description",
        "main_image",
        "reviewer",
        "videos",
        "price",
        "currency",
        "seller_info",
        "availability",
        "success",
        "requested_asin",
        "isFirst",
        "main_video",
    ]
    const marketValueKeys: (keyof IProductData)[] = ["bsr_small", "bsr_large", "rating", "ratings_count"]

    Object.keys(temp).forEach((key) => {
        const tempProducts = temp[key]

        mergedProductData.push({
            timestamp: key,
            ...mergedData(tempProducts, mergedKeys, marketValueKeys),
        } as IProductData)
    })

    mergedKeys?.forEach((key: keyof IProductData) => {
        let lastItem: { timestamp: string; value: string[] } | undefined = undefined

        for (let index = 0; index < mergedProductData?.length; index++) {
            const product = mergedProductData[index]

            const mergedTypeAtt = product[key] as { timestamp: string; value: string[] }[] | undefined
            if (mergedTypeAtt) {
                if (lastItem) {
                    mergedTypeAtt.unshift(lastItem)
                }

                lastItem = mergedTypeAtt.length === 1 ? mergedTypeAtt[0] : mergedTypeAtt[mergedTypeAtt.length - 1]
            }
        }
        return mergedProductData
    })

    mergedKeys?.forEach((key: keyof IProductData) => {
        for (let index = 0; index < mergedProductData?.length; index++) {
            if (
                key === "price" ||
                key === "seller_info" ||
                key === "availability" ||
                key === "carousel_images" ||
                key === "description" ||
                key === "bullets" ||
                key === "videos" ||
                key === "main_image" ||
                key === "title" ||
                key === "currency" ||
                key === "actual_asin" ||
                key === "main_video"
                // ||
                // key === "keywords"
            ) {
                if (
                    ["price", "availability", "description", "title", "actual_asin", "currency"].includes(key) &&
                    mergedProductData[index][key] &&
                    mergedProductData[index][key]?.[0]?.value === mergedProductData[index][key]?.[1]?.value
                ) {
                    delete mergedProductData[index][key]
                } else if (
                    key === "main_image" &&
                    mergedProductData[index][key] &&
                    mergedProductData[index][key]?.[0]?.value &&
                    mergedProductData[index][key]?.[1]?.value
                ) {
                    const areMainImagesEqual = mainImagesAreEqual(
                        mergedProductData[index][key]?.[0]?.value,
                        mergedProductData[index][key]?.[1]?.value
                    )
                    if (areMainImagesEqual) {
                        // @ts-ignore
                        delete mergedProductData[index][key]
                    }
                } else if (
                    key === "seller_info" &&
                    mergedProductData[index][key] &&
                    mergedProductData[index][key]?.[0]?.value?.name === mergedProductData[index][key]?.[1]?.value?.name
                ) {
                    delete mergedProductData[index][key]
                } else if (
                    key === "bullets" &&
                    mergedProductData[index][key] &&
                    mergedProductData[index]?.[key] &&
                    mergedProductData[index]?.[key]?.[0]?.value?.length ===
                        mergedProductData[index]?.[key]?.[1]?.value?.length
                ) {
                    const result = arraysAreEqual(
                        mergedProductData[index][key][0].value,
                        mergedProductData[index][key][1].value
                    )
                    if (result) {
                        // @ts-ignore
                        delete mergedProductData?.[index]?.[key]
                    }
                } else if (
                    key === "carousel_images" &&
                    mergedProductData[index][key] &&
                    mergedProductData[index]?.[key] &&
                    mergedProductData[index]?.[key]?.[0]?.value?.length ===
                        mergedProductData[index]?.[key]?.[1]?.value?.length
                ) {
                    const result = carouselImagesAreEqual(
                        mergedProductData[index][key][0].value,
                        mergedProductData[index][key][1].value
                    )
                    if (result) {
                        // @ts-ignore
                        delete mergedProductData?.[index]?.[key]
                    }
                } else if (
                    key === "videos" &&
                    mergedProductData[index]?.[key] &&
                    mergedProductData[index]?.[key]?.length > 1
                ) {
                    const videoArrays = mergedProductData[index][key]
                    let toRemoveIndices: number[] = []

                    for (let i = 0; i < videoArrays.length - 1; i++) {
                        const currentVideo = videoArrays[i].value
                        const nextVideo = videoArrays[i + 1].value

                        if (currentVideo.length === nextVideo.length) {
                            const result = videosAreEqual(currentVideo, nextVideo)

                            if (result) {
                                toRemoveIndices.push(i)
                            } else {
                                break
                            }
                        }
                    }

                    if (toRemoveIndices.length === videoArrays.length - 1) {
                        // @ts-ignore
                        delete mergedProductData?.[index]?.[key]
                    } else {
                        mergedProductData[index][key] = videoArrays.filter((_, idx) => !toRemoveIndices.includes(idx))
                    }
                } else if (
                    key === "main_video" &&
                    mergedProductData[index][key] &&
                    mergedProductData[index][key]?.[0]?.value &&
                    mergedProductData[index][key]?.[1]?.value
                ) {
                    const va = mergedProductData[index][key][0].value
                    const vb = mergedProductData[index][key][1].value

                    if (va.url !== vb.url || va.thumbnail_filename !== vb.thumbnail_filename || va.type !== vb.type) {
                        return
                    }

                    if (va.title === "" && vb.title === "") {
                        // @ts-ignore
                        delete mergedProductData[index][key]
                    } else if (va.title === "" && vb.title !== "") {
                        // @ts-ignore
                        delete mergedProductData[index][key]
                    }
                }

                // else if (
                //     key === "keywords" &&
                //     mergedProductData[index][key] &&
                //     mergedProductData[index][key]?.[0]?.value === mergedProductData[index][key]?.[1]?.value
                // ) {
                //     // @ts-ignore
                //     delete mergedProductData[index][key]
                // }
            }
        }
    })

    const keys = [...marketValueKeys, "success"]

    // @ts-ignore
    keys.forEach((key: keyof IProductData) => {
        let firstMissingData = true
        for (let index = 0; index < mergedProductData?.length; index++) {
            const product = mergedProductData[index]

            let value: any = "0"
            if (key === "success") {
                product[key]?.every((v: ValueInterface) => v.value === false) && (value = null)
            } else {
                value = product[key]
            }

            if (value == null) {
                if (firstMissingData && key) {
                    // @ts-ignore
                    product.warnings = product.warnings ? [...product.warnings, key] : [key]
                    firstMissingData = false
                }
            } else {
                firstMissingData = true
            }

            if (index === 0) {
                try {
                    if (product.actual_asin[0].value !== product.requested_asin![0].value) {
                        product.warnings = product.warnings ? [...product.warnings, "actual_asin"] : ["actual_asin"]
                    }
                } catch (error) {}
            }
        }
    })
    mergedProductData.forEach((product, index) => {
        if (index === 0) {
            product.isFirst = true
        } else {
            product.isFirst = false
        }
    })

    return mergedProductData
}
export class ProductService {
    /* Fetches product data from an API endpoint. Processes and integrates the fetched data with existing Redux state,
       preparing it for frontend consumption.*/
    static async getProductByGroupId(
        trackpackId: string,
        amazon_tld: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        embedData?: boolean
    ): Promise<IGroupProductDataResponse> {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        // Constructs the API URL using the provided trackpackId, pathParams, and queryParams.
        const url = `${API_ROUTES.GET_PRODUCTS_DATA}/${trackpackId}/product_data${pathString}${queryString}`

        // Sends a GET request to fetch product data using HttpHelper.sendRequest
        const apiResponse = await HttpHelper.sendRequest(url, {}, "GET")
        let updatedApiData: Root = (await apiResponse.json()) as Root

        let product_id = 0
        const reduxStates = store.getState()
        let importProductData: Root
        let rawData

        if (embedData) {
            /* Merges fetched data (updatedApiData) with previously stored data (reduxStates.product.storedRawData).
               Ensures deduplication of trackpack_product_data based on requested_asin. */

            // @ts-ignore
            const previousData = reduxStates?.product?.storedRawData?.[trackpackId]

            for (let i = 0; i < updatedApiData?.trackpack_product_data?.length; i++) {
                let exists: boolean = false
                for (let j = 0; j < previousData?.trackpack_product_data?.length; j++) {
                    let asin = updatedApiData?.trackpack_product_data[i]?.requested_asin
                    if (asin === previousData?.trackpack_product_data[j]?.requested_asin) {
                        exists = true
                        previousData.trackpack_product_data[j].product_data = [
                            ...previousData.trackpack_product_data[j].product_data,
                            ...updatedApiData.trackpack_product_data[i].product_data,
                        ]
                    }
                }
                if (!exists) {
                    previousData.trackpack_product_data.push(updatedApiData.trackpack_product_data[i])
                }
            }
            importProductData = { ...previousData }
            rawData = JSON.parse(JSON.stringify(importProductData))
        } else {
            // Directly assigns the fetched data to importProductData
            importProductData = updatedApiData
            rawData = JSON.parse(JSON.stringify(importProductData))
        }

        const products = importProductData.trackpack_product_data

        let groupedProduct = products.map((product) => {
            /*  Maps over trackpack_product_data to transform and normalize product data:
            Sets default values for null fields (e.g., empty strings for title and description).
            Constructs URL paths for images and videos.
            Extracts seller information.
            Ensures all fields adhere to the required structure. */

            let obj: ActualAsinProductDatum = {
                product_data: product.product_data.map((data: ProductDaum, index: number): ProductDatum => {
                    if (product_id < data.id) {
                        product_id = data.id
                    }
                    return data?.is_data_available
                        ? {
                              ...data,
                              product_id: data.id,
                              actual_asin: data.actual_asin,
                              timestamp: data.timestamp,
                              bsr_large: data.large_bsr ?? null,
                              bsr_small: data.small_bsr ?? null,
                              ratings_count: data.ratings_total ?? null,
                              rating: data.rating ?? null,
                              price: data.price ?? null,
                              main_image:
                                  data?.is_data_available && data.main_image_filename === null
                                      ? ""
                                      : `${img_url}${data.main_image_filename}`,
                              carousel_images:
                                  data?.is_data_available && data?.carousel_images === null
                                      ? [""]
                                      : data?.carousel_images?.length
                                      ? data.carousel_images.map((image: string) => `${img_url}${image}`)
                                      : [""],
                              title: data?.is_data_available && data.title === null ? "" : data.title,
                              description: data?.is_data_available && data.description === null ? "" : data.description,
                              bullets: data?.is_data_available && data?.bullets === null ? [""] : data?.bullets,

                              videos:
                                  data?.is_data_available && data.videos === null
                                      ? [{ video_url: "", thumbnail_url: "", title: "", type: "" }]
                                      : data.videos && data.videos.length > 0
                                      ? data.videos.map(
                                            (video: Videom): Video => ({
                                                video_url: video.url,
                                                thumbnail_url: `${img_url}${video.thumbnail_filename}`,
                                                title: video?.title,
                                                type: video?.type,
                                            })
                                        )
                                      : null,
                              currency: data.currency ?? "",
                              seller_info: data?.seller
                                  ? {
                                        name: data.seller?.name ?? null,
                                        id: data.seller?.id ? data.seller?.id + "" : null,
                                        link: data.seller?.link ?? null,
                                    }
                                  : null,
                              availability: data?.availability ?? null,
                              success: data?.is_data_available,
                              isFirst: false,
                              main_video: data?.main_video
                                  ? {
                                        thumbnail_filename: data.main_video.thumbnail_filename
                                            ? `${img_url}${data.main_video.thumbnail_filename}`
                                            : "",
                                        url: data.main_video?.url ?? "",
                                        type: data.main_video?.type ?? "",
                                        title: data.main_video?.title ?? "",
                                    }
                                  : null,
                              //   keywords: "",
                          }
                        : ({
                              success: false,
                              isFirst: false,
                              timestamp: data.timestamp,
                          } as ProductDatum)
                }),
                actual_asin: product.requested_asin,
                new_actual_asin: product.requested_asin,
                amazon_tld: amazon_tld,
                own_product: true,
            }
            obj.main_product_data = [...obj.product_data].reverse().find((p) => p?.success === true)

            return obj
        })

        groupedProduct = groupedProduct?.map((p) => {
            p.product_data?.sort((a, b) => {
                return new Date(b.timestamp) < new Date(a.timestamp) ? 1 : -1
            })

            const productData = []

            try {
                for (let index = 0; index < p.product_data.length; index++) {
                    let product = p.product_data[index]
                    product = p.product_data[index]

                    if (p.product_data[index + 1]) {
                        putDuplicateIfEmpty(product, p.product_data[index + 1], [
                            "title",
                            "bullets",
                            "carousel_images",
                            "description",
                            "main_image",
                            "videos",
                            "price",
                            "currency",
                            "seller_info",
                            "availability",
                            "main_video",
                            // "keywords"
                        ])
                    }

                    if (p.product_data[index - 1]) {
                        // @ts-ignore
                        const tempProduct = compareAndRemove(product, p.product_data[index - 1], [
                            "actual_asin",
                            "title",
                            "bullets",
                            "carousel_images",
                            "description",
                            "main_image",
                            "reviewer",
                            "videos",
                            "price",
                            "currency",
                            "seller_info",
                            "availability",
                            "requested_asin",
                            "main_video",
                            // "keywords"
                        ])

                        productData.push(tempProduct)
                    } else {
                        productData?.push({ ...product })
                    }
                }
            } catch (error) {
                console.error(error)
            }

            // @ts-ignore
            p.product_data = productData

            return { ...p }
        })

        const { selectedTrackpackData, selectedTrackpackValues } = reduxStates?.product

        const result = {
            groupProductData: groupedProduct as unknown as IGroupProductData[],
        }

        for (const product of result?.groupProductData) {
            const product_data = [...product?.product_data]
            product.product_data = getGroupedProductData(product_data, "day")
        }

        const defaultSelectedAsin = selectedTrackpackValues?.defaultSelectedAsin
        const sortedAsins = selectedTrackpackValues?.sortedAsins
        const { groupProductData } = result
        const sortedArrayObject = groupProductData.filter((p) => p?.actual_asin === defaultSelectedAsin)
        const remaining = groupProductData.filter((p) => p?.actual_asin !== defaultSelectedAsin)

        let remainingArray
        if (sortedAsins.length === 0 || sortedAsins === undefined) {
            remainingArray = remaining
        } else {
            remainingArray = remaining.sort((a, b) => {
                const indexA = sortedAsins.indexOf(a.actual_asin)
                const indexB = sortedAsins.indexOf(b.actual_asin)
                if (indexA === -1) return 1
                if (indexB === -1) return -1
                return indexA - indexB
            })
        }

        const updatedGroupProductData = [...sortedArrayObject, ...remainingArray]

        for (let i = 0; i < updatedGroupProductData.length; i++) {
            const actualAsin = updatedGroupProductData[i].actual_asin

            // Adds color information (color) for each product using getColorsFromAsin.
            const colorData = getColorsFromAsin(selectedTrackpackData, actualAsin)
            if (colorData) {
                updatedGroupProductData[i].color = colorData
            }
        }

        result.groupProductData = updatedGroupProductData

        localStorage.setItem("product_id", product_id.toString())

        // Updates Redux state with the processed data:
        let responseData = {
            // groupProductData for frontend rendering
            groupProductData: {
                ...reduxStates.product?.selectedGroupProducts?.groupProductData,
                [trackpackId]: result.groupProductData,
            },
            // rawData for preserving the original API response
            rawData: {
                ...reduxStates?.product?.storedRawData,
                [trackpackId]: rawData,
            },
        }
        // Returns an object containing the updated groupProductData and rawData.
        return responseData
    }

    static moveAllChangesToObject(to: IProductData, from: IProductData[], keys: (keyof IProductData)[]): void {
        from.forEach((p) => {
            keys.forEach((key: keyof IProductData) => {
                if (to[key] && p[key]) {
                    // @ts-ignore
                    to[key] = [...to[key], ...p[key]]
                } else if (p[key]) {
                    // @ts-ignore
                    to[key] = p[key]
                }

                delete p[key]
            })
        })
    }

    static async TrackpackaccountCreation(payload: Partial<ISelectedTrackpackPayload>): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.CREATE_TRACKPACK,

            { ...payload },

            "POST"
        )
        // @ts-ignore
        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
    static async additionalTrackpackaccountCreation(payload: Partial<ISelectedTrackpackPayload>): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.CREATE_ADDITIONAL_TRACKPACK,

            { ...payload },

            "POST"
        )
        // @ts-ignore
        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }

    static async getAllDrowndownList(pathParams?: { [key: string]: string }, queryParams?: { [key: string]: string }) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_ALL_TRACKPACKS_DROPDOWN_LIST}${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<IProductDropdownList>(result)
    }

    static async updateTrackpackName(id: string, payload: { name: string }): Promise<Response> {
        const url = `${API_ROUTES.UPDATE_TRACKPACK_NAME}/${id}/name`

        const result = await HttpHelper.sendRequest(
            url,

            { ...payload },

            "PUT"
        )
        // @ts-ignore
        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }

    static async updateSearchPackName(id: string, payload: { name: string; status: string }): Promise<Response> {
        const url = `${API_ROUTES.UPDATE_SEARCHPACK_NAME}/${id}`

        const result = await HttpHelper.sendRequest(
            url,

            { ...payload },

            "PATCH"
        )
        // @ts-ignore
        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }

    ////////////////// Archive Trackpack /////////////////////

    static async archiveTrakpack(payload: { id: number }): Promise<Response> {
        const url = `${API_ROUTES.ARCHIVE_TRACKPACK}`

        const result = await HttpHelper.sendRequest(url, { ...payload }, "POST")

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }
        // @ts-ignore
        return undefined
    }
    ////////////////// Archive Asins /////////////////////

    static async archiveAsins(payload: { id: number }): Promise<Response> {
        const url = `${API_ROUTES.ARCHIVE_ASINS}`

        const result = await HttpHelper.sendRequest(url, { ...payload }, "POST")

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }
        // @ts-ignore
        return undefined
    }
    ////////////////// Restore Asins /////////////////////

    static async restoreAsins(payload: { id: number }): Promise<Response> {
        const url = `${API_ROUTES.RESTORE_ASINS}`

        const result = await HttpHelper.sendRequest(url, { ...payload }, "POST")

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }
        // @ts-ignore
        return undefined
    }

    ////////////////// Restore Trackpack /////////////////////

    static async restoreTackpack(payload: { trackpack_id: number }): Promise<Response> {
        const url = `${API_ROUTES.RESTORE_TRACKPACK}`

        const result = await HttpHelper.sendRequest(url, { ...payload }, "POST")

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }
        // @ts-ignore
        return undefined
    }

    //////////////////////////// Check New Data Available ////////////////////////
    static async checkNewDataAvailable(
        trackpackId?: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_PRODUCTS_DATA}/${trackpackId}/has_product_data${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<Response>(result)
    }

    //////////////////////////// get Competitors ////////////////////////////////
    static async getCompetitorsData(pathParams?: { [key: string]: string }, queryParams?: { [key: string]: string }) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_COMPETITORS_DATA}${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<any>(result)
    }

    ///////////////////////// get Trackpack List //////////////////////////////

    static async getTrackpackList(pathParams?: { [key: string]: string }, queryParams?: { [key: string]: string }) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_TRACKPACKS_LIST}${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<IProductTrackpackList>(result)
    }
    ///////////////////////// Get first Trackpack Data /////////////////////////////

    static async getFirstTrackpackData(
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_FIRST_TRACKPACK_DATA}${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<TrackpackDatum>(result)
    }

    //////////////////////////// GET EXTENDED DATA //////////////////////////////////

    static async getExtendedTrackingData(
        trackpackId: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_EXTENDED_TRACKING_DATA}/${trackpackId}/extended${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<TrackpackDatum>(result)
    }

    //////////////////////////// CLAIM OWNERSHIP //////////////////////////////////

    static async claimownership(trackpack_id: number, asin: string, payload: { own: boolean }): Promise<Response> {
        const url = `${API_ROUTES.UPDATE_TRACKPACK_NAME}/${trackpack_id}/asins/${asin}/ownership`

        const result = await HttpHelper.sendRequest(url, { ...payload }, "PUT")

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return result
        }
        // @ts-ignore
        return undefined
    }
}
