import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"

import { OnboardingUserActionCreators } from "../../actions/onBoardingUser.action"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { useInputValidations } from "../../hooks/inputValidations.hook"
import { OnboardingUserStateSelector } from "../../selectors/onBoardingState.selector"
import { BubbleListToTop, BubblesList } from "../elements/bubblesList.element"
import { ButtonElement } from "../elements/button.element"
import { Container } from "../elements/container.element"
import { InputElement } from "../elements/input.element"
import { MarketingFooter } from "./marketingFooter.component"

import { Transition } from "@headlessui/react"
import Giphy from "../../assets/gifs/giphy.gif"
import { MarketingHeader } from "./marketingHeader.component"

export const FreeTrailForm = () => {
    const validationConfig = useInputValidations()

    const { t } = useTranslation("marketing", {
        keyPrefix: "freeTrialChat.conversation",
    })

    const userOnBoard = useSelector(OnboardingUserStateSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [completed, setCompleted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { control, handleSubmit } = useForm<IFreeTrialInput>({
        defaultValues: {
            name: userOnBoard?.name,
        },
    })

    const onSubmit: SubmitHandler<IFreeTrialInput> = (data) => {
        setIsLoading(true)

        dispatch(
            OnboardingUserActionCreators.createOnboardingUser({
                name: data.name,
            })
        )

        setIsLoading(false)
        navigate(ROUTES_CONFIG.emailChat)
    }

    return (
        <>
            <div className="xl:pt-[28px] lg:pt-[28px] md:pt-[20px] sm:pt-[20px] xs:pt-[20px]">
                <div className="flex justify-center 2xl:gap-[16px] xl:gap-[16px] lg:gap-[16px] md:gap-[16px] sm:gap-[0px] xs:gap-[0px]">
                    <MarketingHeader />
                    <div
                        className="flex flex-col justify-start items-start overflow-y-auto md:pr-[20px] lg:pr-[20px] w-[504px]  md:h-md-calc-onboarding lg:h-lg-calc-onboarding xl:h-xl-calc-onboarding"
                        // style={{ height: "calc(100vh - 250px)" }}
                    >
                        <div className={""}>
                            <BubbleListToTop>
                                <BubblesList
                                    interval={500}
                                    maxSleep={500}
                                    onFinish={() => {
                                        setCompleted(true)
                                    }}
                                    conversationItems={[
                                        {
                                            text: t("1"),
                                        },

                                        {
                                            src: Giphy,
                                            srcType: "custom",
                                            className:
                                                "lg:w-[188.78px] lg:h-[148px] md:w-[188.78px] md:h-[148px] sm:w-[137.76px] sm:h-[108px] xs:w-[137.76px] xs:h-[108px]",
                                        },

                                        {
                                            text: t("2"),
                                        },

                                        {
                                            text: t("3"),
                                        },

                                        {
                                            text: t("4"),
                                        },
                                    ]}
                                />
                            </BubbleListToTop>
                        </div>

                        <Transition
                            show={completed}
                            enter="transition ease-out duration-300 transform"
                            enterFrom="translate-y-full opacity-0"
                            enterTo="translate-y-0 opacity-100"
                            leave="transition ease-in duration-200 transform"
                            leaveFrom="translate-y-0 opacity-100"
                            leaveTo="translate-y-full opacity-0"
                        >
                            <div className="flex-1 xl:min-w-[375px] lg:min-w-[375px] md:min-w-[375px] sm:min-w-[240px] xs:min-w-[240px]">
                                <form onSubmit={handleSubmit(onSubmit)} className={"w-full"}>
                                    <Container>
                                        <InputElement
                                            name="name"
                                            type="text"
                                            defaultValue=""
                                            reactHookControl={control}
                                            reactHookValidations={{
                                                required: validationConfig.required,
                                            }}
                                            placeholder="Name here..."
                                            className=""
                                        />
                                        {completed && (
                                            <ButtonElement
                                                type="submit"
                                                loading={isLoading}
                                                size="large"
                                                className={
                                                    "sm:mt-[36px] xs:mt-[36px]  md:mt-[24px] lg:mt-[24px] xl:mt-[24px]  text-[#0E7090]"
                                                }
                                            >
                                                {t("button")}
                                            </ButtonElement>
                                        )}
                                    </Container>
                                </form>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>

            <MarketingFooter currentStep={1} />
        </>
    )
}
