import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import icon from "../../../assets/gifs/new-blinking-logo.gif"
import { PrimaryText } from "../../elements/primaryText.element"

const SearchpackHubOnboardingComponent = () => {
    const dispatch = useDispatch()

    const handleAddSearchpackFlow = () => {
        dispatch(
            RuntimeActionCreators.openAddSearchpackModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    return (
        <div
            style={{
                height: "calc(100vh - 164px)",
            }}
            className="bg-white p-[12px] flex-1 rounded-[16px] border border-gray-200"
        >
            <div className="border-2 border-[#088AB2] rounded-[4px]">
                <div className="max-w-[912px] w-full mx-auto py-[48px] px-[24px] flex flex-col items-center justify-center gap-[16px]">
                    <div className="w-[60px] h-[54px]">
                        <img src={icon} alt="blinking logo" className="h-full w-full" />
                    </div>
                    <PrimaryText size="sm-medium" weight="medium" className="leading-[24px] text-gray-600">
                        Welcome to my <span className="text=-[16px] font-[500] text-[#088AB2]">Searchpack Hub</span>
                    </PrimaryText>
                    <PrimaryText weight="light" size="small" className="text-center text-gray-600 max-w-[510px]">
                        Here’s where you set up and manage groups of keywords called Searchpacks. Every one you build
                        can be found here.
                    </PrimaryText>
                    <PrimaryText weight="light" size="small" className="text-center text-gray-600 max-w-[515px] w-full">
                        Add keywords to existing Searchpacks or archive those you no longer want to track. You can even
                        archive entire Searchpacks.
                    </PrimaryText>

                    <button
                        onClick={handleAddSearchpackFlow}
                        type="button"
                        className=" mt-[16px] py-[8px] px-[16px] rounded-[10px] inline-flex items-center justify-center text-[#088AB2] font-[500] text-[14px] leading-[22px]"
                        style={{
                            background: "linear-gradient(117.03deg, #CFF9FE 0%, #A5F0FC 100%)",
                        }}
                    >
                        Create you first Searchpack
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SearchpackHubOnboardingComponent
