export const CompetitorAsinIcon = () => {
    return (
        <>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20.9983 18.4764C22.6969 19.3296 24.1532 20.6989 25.2161 22.4112C25.4266 22.7503 25.5319 22.9199 25.5683 23.1546C25.6422 23.6317 25.316 24.2183 24.8716 24.4071C24.6529 24.5 24.407 24.5 23.915 24.5M18.665 13.4543C20.3937 12.5952 21.5817 10.8113 21.5817 8.75C21.5817 6.68866 20.3937 4.9048 18.665 4.04572M16.3317 8.75C16.3317 11.6495 13.9812 14 11.0817 14C8.18218 14 5.83168 11.6495 5.83168 8.75C5.83168 5.8505 8.18218 3.5 11.0817 3.5C13.9812 3.5 16.3317 5.8505 16.3317 8.75ZM2.98411 22.0947C4.84414 19.302 7.77928 17.5 11.0817 17.5C14.3841 17.5 17.3192 19.302 19.1792 22.0947C19.5867 22.7065 19.7905 23.0125 19.767 23.4032C19.7487 23.7075 19.5493 24.08 19.3062 24.2639C18.9939 24.5 18.5645 24.5 17.7056 24.5H4.45776C3.59888 24.5 3.16944 24.5 2.85719 24.2639C2.61407 24.08 2.41461 23.7075 2.39635 23.4032C2.37289 23.0125 2.57663 22.7065 2.98411 22.0947Z"
                    stroke="#0E7090"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}
