import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Outlet, useLocation, useNavigate } from "react-router" // Import useLocation
import { SearchpackActionCreator } from "../../../actions/searchpack.action"
import { KeywordPerformanceIcon } from "../../../assets/svgs/keywordPerformance.svg"
import { RankSnapShotIcon } from "../../../assets/svgs/rankSnapShot.svg"
import { RankTrackerIcon } from "../../../assets/svgs/RankTracker.svg"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { useAppSearchParams } from "../../../hooks/app/searchpack/useAppSearchParams"
import { urlUtils } from "../../../utils/url"
import { LightBoxGallery } from "../../lightBox.component"
import ChartModal from "../modals/chartModal.component"
import { TooltipGallery } from "../tooltipGallery.component"

const tabRoutes: any = {
    "Rank Snapshot": `${ROUTES_CONFIG.searchpackTracker}/rank-snapshot`,
    "Rank Tracker": `${ROUTES_CONFIG.searchpackTracker}/rank-tracker`,
    "Keyword Performance": `${ROUTES_CONFIG.searchpackTracker}/keyword-performance`,
}

const tabPathMapping: any = {
    "rank-snapshot": "Rank Snapshot",
    "rank-tracker": "Rank Tracker",
    "keyword-performance": "Keyword Performance",
}

const SearchpackSelectionTracker = ({ tabUrl = "Rank Snapshot" }) => {
    const { searchParams } = useAppSearchParams()
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(tabUrl)

    const getActiveTabFromUrl = useCallback(() => {
        const path = location.pathname
        const activeTab = Object.keys(tabPathMapping).find((key) => path.includes(key))
        return activeTab ? tabPathMapping[activeTab] : "Rank Snapshot"
    }, [location.pathname])

    useEffect(() => {
        const tabFromUrl = getActiveTabFromUrl()
        setActiveTab(tabFromUrl)
        dispatch(SearchpackActionCreator.setActiveTab(tabFromUrl))
    }, [dispatch, getActiveTabFromUrl])

    const handleTabRoute = (tab: string) => {
        const route = tabRoutes[tab]

        if (route) {
            navigate(
                urlUtils.formURL(route, {
                    ...(searchParams?.sp_id ? { sp_id: searchParams.sp_id } : {}),
                })
            )
        }

        setActiveTab(tab)
        dispatch(SearchpackActionCreator.setActiveTab(tab))
    }

    const tabs = ["Rank Snapshot", "Rank Tracker", "Keyword Performance"]

    return (
        <div
            className=""
            style={{
                width: "calc(100% - 324px)",
                border: "1px solid",
                borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
            }}
        >
            {/* Tabs Header */}
            <div className="flex items-center">
                {tabs.map((tab) => (
                    <button
                        key={tab}
                        className={`py-[14px] rounded-[12px] last:rounded-br-0 bg-gray-200 rounded-bl-none rounded-br-none text-[16px] font-[500] leading-[24px] inline-flex items-center justify-center gap-[8px] flex-1 ${
                            activeTab === tab
                                ? "bg-gray-50 text-gray-600 !z-[5]"
                                : "border border-white !border-b-0 border-b-transparent text-gray-400 rounded-bl-[12px] rounded-br-[12px]"
                        }
                            ${
                                activeTab === tab && (tab === "Rank Snapshot" || tab === "Rank Tracker")
                                    ? "right-shadow z-[2]"
                                    : ""
                            }
                            ${
                                activeTab === tab
                                    ? tab === "Keyword Performance"
                                        ? "left-shadow"
                                        : tab === "Rank Tracker"
                                        ? "left-shadow z-[2]"
                                        : ""
                                    : ""
                            }
                            ${activeTab === tab ? (tab === "Rank Tracker" ? "left-right-shadow" : "shadow-none") : ""}`}
                        style={{}}
                        onClick={() => handleTabRoute(tab)}
                    >
                        {tab === "Rank Snapshot" ? (
                            <RankSnapShotIcon
                                color={activeTab === tab ? "#088AB2" : "#98A2B3"}
                                width={"24"}
                                height={"24"}
                            />
                        ) : tab === "Rank Tracker" ? (
                            <RankTrackerIcon
                                color={activeTab === tab ? "#088AB2" : "#98A2B3"}
                                width={"24"}
                                height={"24"}
                            />
                        ) : (
                            <KeywordPerformanceIcon
                                color={activeTab === tab ? "#088AB2" : "#98A2B3"}
                                width={"24"}
                                height={"24"}
                            />
                        )}
                        {tab}
                    </button>
                ))}
            </div>

            {/* Tab Content */}
            <div className="bg-gray-200 relative z-[10] top-[-2px] rounded-bl-[16px] rounded-br-[16px] border-l border-r border-white">
                <div
                    className="bg-gray-50 border-none rounded-[16px]"
                    style={{
                        minHeight: "calc(100vh - 216px )",
                    }}
                >
                    <div
                        className={`tabs-content bg-gray-50 rounded-bl-[16px] rounded-br-[16px] p-[12px] relative z-50 pb-0 ${
                            activeTab === "Rank Snapshot"
                                ? "rounded-tl-none rounded-tr-[16px]"
                                : activeTab === "Rank Tracker"
                                ? "rounded-tl-[16px] rounded-tr-[16px]"
                                : activeTab === "Keyword Performance"
                                ? "rounded-tl-[16px] rounded-tr-0"
                                : ""
                        }`}
                    >
                        <Outlet />
                    </div>
                </div>
            </div>
            <TooltipGallery selectedView={"Graph"} />

            <LightBoxGallery />

            <ChartModal />
        </div>
    )
}

export default SearchpackSelectionTracker
