export const RightChevronIcon = (props: IPropsIcon) => {
    return (
        <>
            <svg
                className={props.className}
                width={props.width ?? "16"}
                height={props.height ?? "16"}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={props.onClick}
            >
                <path d="M6 12L10 8L6 4" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}
