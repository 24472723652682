import { SearchpackAction } from "../actions/searchpack.action"
import { SearchpackStateClass } from "../classes/searchpackState.class"

export const SearchpackReducer = (
    state: ISearchpackState = new SearchpackStateClass(),
    action: ISagaAction
): Partial<ISearchpackState> => {
    switch (action.type) {
        case SearchpackAction.CREATE_SEARCHPACK:
            return {
                ...state,
                selectedSearchpackPayload: {
                    ...state.selectedSearchpackPayload,
                    ...action.payload,
                },
            }
        case SearchpackAction.ACTIVE_TAB:
            return {
                ...state,
                activeRankingTab: action.payload,
            }
        case SearchpackAction.SET_SEARCHPACKS_LIST:
            return {
                ...state,
                searchpackDropdownList: action.payload,
            }
        case SearchpackAction.SET_SELECTED_SEARCHPACK:
            return {
                ...state,
                selectedSearchpack: action.payload,
            }
        case SearchpackAction.ADD_KEYWORDS_TO_SEARCHPACK:
            return {
                ...state,
                getSelectedSearchpackPayload: action.payload,
            }
        case SearchpackAction.GET_SEARCHPACK_WITH_LAST_DATA_TIME:
            return {
                ...state,
            }
        case SearchpackAction.SET_SELECTED_SEARCHPACK_VALUES:
            return {
                ...state,
                selectedSearchpackValues: action.payload,
            }
        case SearchpackAction.SET_SELECTED_SEARCHPACK_NAME:
            return {
                ...state,
                selectedSearchpackName: action.payload,
            }
        case SearchpackAction.SET_SELECTED_SEARCHPACK_TERMS:
            return {
                ...state,
                selectedSearchpackTerms: action.payload,
            }
        case SearchpackAction.SET_SEARCHPACKS_LAST_DATA_TIME:
            return {
                ...state,
                selectedSearchpackLastDataTime: action.payload,
            }
        case SearchpackAction.SET_RANKSNAPSHOT_DATA:
            return {
                ...state,
                ranksnapshotData: action.payload,
            }
        case SearchpackAction.SELECT_SEARCHPACK_PRODUCT:
            return {
                ...state,
                selectedSearchpackProducts: action.payload,
            }
        case SearchpackAction.SELECT_SEARCHPACK_PRODUCT_RANK:
            return {
                ...state,
                selectedSearchpackProductsRank: action.payload,
            }
        case SearchpackAction.GET_SEARCHPACK_SEARCHTERM_WITH_ID:
            return {
                ...state,
            }
        case SearchpackAction.SET_SEARCHPACK_WITHSEARCHTERM:
            return {
                ...state,
                selectedSearchpackWithTerm: action.payload,
            }
        case SearchpackAction.SET_KEYWORD_PAYLOAD:
            return {
                ...state,
                selectedKeywordPayload: action.payload,
            }
        case SearchpackAction.GET_SEARCHPACK_RANK_TRACKER:
            return {
                ...state,
                searchpackRankTracker: action.payload,
            }
        case SearchpackAction.SET_SEARCHPACK_RANK_TRACKER:
            return {
                ...state,
                searchpackRankTracker: action.payload,
            }

        case SearchpackAction.GET_RANK_TRACKER_LISTING_CHANGE:
            return {
                ...state,
            }
        case SearchpackAction.SET_RANK_TRACKER_LISTING_CHANGE:
            return {
                ...state,
                rankTrackerListingChange: action.payload,
            }
        case SearchpackAction.SET_SEARCHPACK_KEYWORD_PERFORMANCE:
            return {
                ...state,
                searchpackKeywordPerformance: action.payload,
            }

        case SearchpackAction.SELECT_SEARCHPACK_PERFORMANCE_KEYWORD:
            return {
                ...state,
                selectedSearchpackKeywordPerformance: action.payload,
            }
        case SearchpackAction.SELECT_SEARCHPACK_PERFORMANCE_KEYWORD_RANK:
            return {
                ...state,
                selectedSearchpackKeywordPerformanceRank: action.payload,
            }
        case SearchpackAction.GET_SEARCHPACK_KEYWORD_PERFORMANCE_BSR:
            return {
                ...state,
                searchpackKeywordPerformanceBsr: action.payload,
            }
        case SearchpackAction.SET_SEARCHPACK_KEYWORD_PERFORMANCE_BSR:
            return {
                ...state,
                searchpackKeywordPerformanceBsr: action.payload,
            }

        case SearchpackAction.SET_KEYWORD_PERFORMANCE_LISTING_CHANGE:
            return {
                ...state,
                keywordPerformanceListingChange: action.payload,
            }
        default:
            return state
    }
}
