export const TourCloseIcon = (props: IArrowProps) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8781_120431)">
                <path d="M9 3L3 9M3 3L9 9" stroke="#67E3F9" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_8781_120431">
                    <rect width="12" height="12" rx="6" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
