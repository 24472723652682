import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CartesianGrid, DotProps, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { SearchpackActionCreator } from "../../../../actions/searchpack.action"
import { BsrRankingIcon } from "../../../../assets/svgs/bsrRankingIcon.svg"
import { GraphSkeleton } from "../../../../assets/svgs/graph-skeleton.svg"
import { OrganicRankingIcon } from "../../../../assets/svgs/organicRanking.svg"
import { PaidRankingIcon } from "../../../../assets/svgs/paidRankingIcon.svg"
import { ASIN_PRODUCTS_COLOR } from "../../../../config/colors.config"
import dateHelper from "../../../../helpers/date"
import { convertToProductObject } from "../../../../helpers/util.helper"
import { usePerformanceKeywordGraphData } from "../../../../hooks/app/searchpack/keyword-performance/usePerformanceKeywordGraphData"
import { useDismissPopups } from "../../../../hooks/app/searchpack/useDismissPopups"
import { SearchpackStateSelector } from "../../../../selectors/searchpack.selector"
import { extendedDayjs } from "../../../../utils/dayjs"
import { PrimaryText } from "../../../elements/primaryText.element"
import {
    IRankTrackerGraphDotOnClick,
    RankTrackerGraphDot,
} from "../rank-tracker/graph-view/rank-tracker-graph-dot.component"
import { RankTrackerGraphTooltip } from "../rank-tracker/graph-view/rank-tracker-graph-tooltip.component"

export type KeywordPerformanceGraphViewProps = {
    selectedBSR: ("BSR L" | "BSR S")[]
    selectedFilter: ("Paid" | "Organic")[]
    selectedRange: number
    loading?: boolean
}

export type GraphData = {
    bsr_large?: number
    bsr_small?: number
    paid?: number
    organic?: number
    term_id?: number
    change_number?: number
    label: string
    asin: string
}

export const KeywordPerformanceGraphView = ({
    selectedRange,
    selectedBSR,
    selectedFilter,
    loading,
}: KeywordPerformanceGraphViewProps) => {
    const tooltip = useRef<HTMLDivElement>(null)

    const [value, setValue] = useState<{
        color: TAsinProductColor
        product: IProductData
        asin: string
    }>()
    const [tooltipVisible, setTooltipVisible] = useState(false)

    const dispatch = useDispatch()
    const { dismissPopups } = useDismissPopups()
    const { searchpackKeywordPerformanceBsr, selectedSearchpackValues, selectedSearchpackKeywordPerformance } =
        useSelector(SearchpackStateSelector)
    const { selectedProducts } = selectedSearchpackKeywordPerformance || {}

    const { keywordPerformanceListingChange, searchpackKeywordPerformance, selectedSearchpackKeywordPerformanceRank } =
        useSelector(SearchpackStateSelector)

    const filteredRankData = useMemo(() => {
        if (searchpackKeywordPerformance) {
            const selectedAsins = selectedProducts?.asin

            return searchpackKeywordPerformance?.search_rank_data_by_asin?.filter((term) => term.asin === selectedAsins)
        }
        return []
    }, [searchpackKeywordPerformance, selectedProducts])

    const over = useCallback(() => {
        setTooltipVisible(false)
    }, [])

    const selectedSearchpackKeywordsData = useMemo(() => {
        if (!filteredRankData) return []
        return filteredRankData
            ?.map(({ date_term_rank_data }) => {
                return date_term_rank_data?.map(({ term_id, date, is_sponsored, rank }) => {
                    return {
                        label: date,
                        asin: selectedProducts?.asin,
                        term_id,
                        ...(is_sponsored ? { paid: rank } : { organic: rank }),
                    } as {
                        label: string
                        asin: string
                        term_id: number
                        paid?: number
                        organic?: number
                    }
                })
            })
            .flat()
    }, [filteredRankData, selectedProducts])

    const graphData = usePerformanceKeywordGraphData(
        selectedProducts,
        selectedBSR?.[0],
        selectedFilter,
        selectedSearchpackKeywordPerformanceRank?.selectedProducts,
        selectedRange,
        searchpackKeywordPerformanceBsr?.bsr,
        selectedSearchpackKeywordsData,
        keywordPerformanceListingChange,
        over
    )

    useEffect(() => {
        if (selectedSearchpackValues?.selectedSearchpackID && selectedProducts?.asin) {
            dispatch(
                SearchpackActionCreator.getKeywordPerformanceListingChange(
                    selectedSearchpackValues?.selectedSearchpackID,
                    selectedProducts?.asin,
                    dateHelper.getSinceDate(selectedRange || 0) || undefined
                )
            )
        }
    }, [dispatch, selectedRange, selectedProducts?.asin, selectedSearchpackValues?.selectedSearchpackID])

    useEffect(() => {
        over && over()
    }, [selectedRange, over])

    const customMouseClick = useCallback(
        (e: IRankTrackerGraphDotOnClick) => {
            setTooltipVisible(false)
            dispatch(RuntimeActionCreators.closeAllPopup())
            setTimeout(() => {
                if (tooltip.current && keywordPerformanceListingChange?.objects) {
                    const productObject = convertToProductObject(e.changes, keywordPerformanceListingChange?.objects)

                    setTooltipVisible(true)
                    tooltip.current.style.top = `${e.cy! - 12}px`
                    tooltip.current.style.left = `${e.cx! - 0.85}px`

                    setValue({
                        color: e.color,
                        product: productObject,
                        asin: e.asin,
                    })
                }
            }, 300)
        },

        [dispatch, keywordPerformanceListingChange?.objects]
    )

    const DotView = (props: DotProps) => {
        try {
            if (!keywordPerformanceListingChange?.objects) return null

            const { payload } = props as DotProps & { payload: { asin: string; label: string } }
            const asin = payload.asin
            // Find the product object in listing_change array
            const productFromChanges = keywordPerformanceListingChange?.groupedListingChanges[asin]

            // Get the color for each ASIN
            const asinColor = selectedProducts?.color
            // Ensure the product object includes the color
            const product = productFromChanges && {
                product: productFromChanges,
                color: asinColor || ASIN_PRODUCTS_COLOR[0],
            }
            return (
                <RankTrackerGraphDot
                    {...props}
                    product={product}
                    payload={{ label: payload.label }}
                    customMoveClick={customMouseClick}
                    dataKey={`${asin}-organic`}
                />
            )
        } catch (error) {
            console.log(props)
            console.log(error)
        }
        return <></>
    }

    const onGraphClick = () => {
        dismissPopups()
        over()
    }

    const getView = () => {
        if (loading) return <GraphSkeleton />

        return (
            <div
                className={`rounded-[10px] border border-gray-200 bg-white`}
                style={{ height: "calc(100vh - 288px)" }}
                onClick={onGraphClick}
            >
                <div className={"py-[24px] px-[24px] pb-[29px] h-full"}>
                    <div className={"relative h-full"}>
                        {/* Left-Side Label */}
                        <div
                            className={
                                "h-[full] absolute top-1/2 left-0/4 -translate-x-1/2 -translate-y-1/2 -rotate-90"
                            }
                        >
                            <PrimaryText
                                size={"xs-medium"}
                                weight={"book"}
                                className={"flex tracking-[3px] h-full text-[#344054]"}
                                uppercase
                            >
                                SEARCH RANKING
                            </PrimaryText>
                        </div>

                        <div className={"mr-[-70px] absolute top-1/2 right-0 -translate-y-1/2 rotate-90"}>
                            <PrimaryText
                                size={"xs-medium"}
                                weight={"book"}
                                className={"flex tracking-[3px] mb-[20px] h-full text-[#344054]"}
                                uppercase
                            >
                                {selectedBSR.includes("BSR L") ? "BSR LARGE CATEGORY" : ""}
                                {selectedBSR.includes("BSR S") ? "BSR SMALL CATEGORY" : ""}
                            </PrimaryText>
                        </div>
                        <div className={"relative pb-[18px] graph"} style={{ height: "calc(100% - 20px)" }}>
                            <ResponsiveContainer width="98%" height="100%">
                                <LineChart
                                    width={400}
                                    height={200}
                                    data={graphData.data}
                                    margin={{
                                        top: 5,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="0 0" vertical={false} />

                                    <XAxis
                                        dataKey="timestamp"
                                        type="number"
                                        domain={["dataMin", "dataMax"]}
                                        tickLine={{ display: "none" }}
                                        tick={{ fill: "#98A2B3" }}
                                        axisLine={{ stroke: "#EAECF0" }}
                                        className={
                                            "text-[6px] mt-[2px] tracking-[1px] text-[#98A2B3] font-normal uppercase"
                                        }
                                        dy={11.6}
                                        angle={-45}
                                        ticks={graphData.range}
                                        tickFormatter={(tickValue) => {
                                            return extendedDayjs.unix(tickValue).format("MMM-DD")
                                        }}
                                        allowDuplicatedCategory={true}
                                    />

                                    <YAxis
                                        yAxisId="left"
                                        tickCount={8}
                                        domain={["dataMin", "dataMax"]}
                                        tickLine={{ display: "none" }}
                                        className={"text-[10px] tracking-[1px] font-normal"}
                                        tick={{ fill: "#98A2B3" }}
                                        axisLine={{ stroke: "#EAECF0" }}
                                        interval={0}
                                        tickFormatter={(tickValue) => {
                                            return tickValue
                                        }}
                                        reversed={true}
                                    />

                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        tickCount={8}
                                        domain={["dataMin", "dataMax"]}
                                        tickLine={{ display: "none" }}
                                        className={"text-[10px] tracking-[1px] font-normal"}
                                        tick={{ fill: "#98A2B3" }}
                                        axisLine={{ stroke: "#EAECF0" }}
                                        interval={0}
                                        tickFormatter={(tickValue) => {
                                            return tickValue
                                        }}
                                        reversed={true}
                                    />

                                    {selectedProducts?.asin && (
                                        <>
                                            {selectedBSR.includes("BSR L") && (
                                                <Line
                                                    yAxisId="right"
                                                    key={`line-brs-l-${selectedProducts.asin}`}
                                                    type="monotone"
                                                    data={graphData.data.filter((i) => !!i.bsr_large)}
                                                    dataKey={(data) => {
                                                        return data.bsr_large
                                                    }}
                                                    stroke={"#000"}
                                                    activeDot={<></>}
                                                    dot={<></>}
                                                    strokeWidth={1.6}
                                                    isAnimationActive={true}
                                                    strokeDasharray={"2 2"}
                                                />
                                            )}

                                            {selectedBSR.includes("BSR S") && (
                                                <Line
                                                    yAxisId="right"
                                                    key={`line-brs-s-${selectedProducts.asin}`}
                                                    type="monotone"
                                                    data={graphData.data.filter((i) => !!i.bsr_small)}
                                                    dataKey={(data) => {
                                                        return data.bsr_small
                                                    }}
                                                    stroke={"#000"}
                                                    activeDot={<></>}
                                                    dot={<></>}
                                                    strokeWidth={1.6}
                                                    isAnimationActive={true}
                                                    strokeDasharray={"2 2"}
                                                />
                                            )}

                                            <Line
                                                yAxisId={graphData.changesAxis}
                                                key={`line-change-${selectedProducts.asin}`}
                                                type="monotone"
                                                data={graphData.changes}
                                                dataKey={(data) => {
                                                    return data?.change_number
                                                }}
                                                stroke={"transparent"}
                                                activeDot={(props: DotProps) => {
                                                    return <DotView {...props} />
                                                }}
                                                dot={(props: DotProps) => {
                                                    return <DotView {...props} />
                                                }}
                                                strokeWidth={5}
                                                isAnimationActive={false}
                                                strokeDasharray={"0"}
                                            />

                                            {graphData.terms.map((product) => {
                                                return (
                                                    <>
                                                        {selectedFilter?.includes("Organic") ? (
                                                            product.data
                                                                .filter((i) => i.typeHint === "organic")
                                                                .map((line) => (
                                                                    <Line
                                                                        yAxisId="left"
                                                                        key={`line-organic-${selectedProducts.asin}-${product.term.id}-${line.start}-${line.end}`}
                                                                        type="monotone"
                                                                        dataKey={(data) => {
                                                                            return data.organic
                                                                        }}
                                                                        stroke={
                                                                            product?.term?.color?.default || "black"
                                                                        }
                                                                        strokeWidth={1.6}
                                                                        isAnimationActive={true}
                                                                        strokeDasharray={"0"}
                                                                        data={line.data}
                                                                        activeDot={<></>}
                                                                        dot={<></>}
                                                                    />
                                                                ))
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {selectedFilter?.includes("Paid") ? (
                                                            product.data
                                                                .filter((i) => i.typeHint === "paid")
                                                                .map((line) => (
                                                                    <Line
                                                                        yAxisId="left"
                                                                        key={`line-paid-${selectedProducts.asin}-${product.term.id}-${line.start}-${line.end}`}
                                                                        type="monotone"
                                                                        dataKey={(data) => {
                                                                            return data.paid
                                                                        }}
                                                                        stroke={
                                                                            product?.term?.color?.default || "black"
                                                                        }
                                                                        strokeWidth={1.6}
                                                                        isAnimationActive={true}
                                                                        strokeDasharray={"4 4"}
                                                                        data={line.data}
                                                                        activeDot={<></>}
                                                                        dot={<></>}
                                                                    />
                                                                ))
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                )
                                            })}
                                        </>
                                    )}

                                    <Tooltip cursor={false} wrapperStyle={{ display: "none" }} />
                                </LineChart>
                            </ResponsiveContainer>

                            <RankTrackerGraphTooltip
                                id={`1`}
                                over={over}
                                product={value?.product}
                                tooltipVisible={tooltipVisible}
                                tooltip={tooltip}
                                color={value?.color || ASIN_PRODUCTS_COLOR[0]}
                            />
                        </div>

                        {/* Bottom Labels */}
                        <div className="flex justify-center">
                            <div className="flex p-2 gap-3 bg-[#ECFDFF]">
                                <PrimaryText
                                    size={"xs-medium"}
                                    weight={"book"}
                                    uppercase
                                    className={
                                        "flex items-center justify-center text-gray-700 tracking-[5px] leading-[10px]"
                                    }
                                >
                                    PAID <PaidRankingIcon />
                                </PrimaryText>
                                <PrimaryText
                                    size={"xs-medium"}
                                    weight={"book"}
                                    uppercase
                                    className={
                                        "flex items-center justify-center text-gray-700 tracking-[5px] leading-[10px]"
                                    }
                                >
                                    ORGANIC <OrganicRankingIcon />
                                </PrimaryText>
                                <PrimaryText
                                    size={"xs-medium"}
                                    weight={"book"}
                                    uppercase
                                    className={
                                        "flex items-center justify-center text-gray-700 tracking-[5px] leading-[10px]"
                                    }
                                >
                                    BSR <BsrRankingIcon />
                                </PrimaryText>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return <>{getView()}</>
}
