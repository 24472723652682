import ReactDOMServer from "react-dom/server"
import { useLocation, useNavigate } from "react-router"

import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Tooltip } from "react-tooltip"
import { ProductsActionCreator } from "../../actions/products.action"
import { GraphIconSidebar } from "../../assets/svgs/graphIconSidebar.svg"
import { GridIconSidebar } from "../../assets/svgs/gridIcon.svg"
import { ListingAnalyzerIcon } from "../../assets/svgs/listingAnalyzerIcon.svg"
import { NewLogoutIcon } from "../../assets/svgs/newLogoutIcon.svg"
import { SearchPackIcon } from "../../assets/svgs/searchpack.svg"
import { SearchTrackerIcon } from "../../assets/svgs/searchTracker.svg"
import { SettingNewIcon } from "../../assets/svgs/settingSideIcon.svg"
import { AdminUserIcon } from "../../assets/svgs/tooltips/adminUserIcon.svg"
import { AccountUserIcon } from "../../assets/svgs/userAccountIcon.svg"
import { VideoIconSidebar } from "../../assets/svgs/videoIconsidebar.svg"
import { ENABLE_LISTING_ANALYZER, SEARCHPACK_ICONS } from "../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { UtilHelper } from "../../helpers/util.helper"
import { unregisterServiceWorker } from "../../hooks/notificationServiceWorker.hook"
import { AuthStateSelector } from "../../selectors/authState.selector"
import { ProductStateSelector } from "../../selectors/product.selector"
import { PrimaryText } from "../elements/primaryText.element"
import QuickTourModal from "./modals/quickTourModals/quickTour.component"
import QuickTourTrackpackModal from "./modals/quickTourModals/quickTourTrackpack.component"
import CommonTooltip from "./tooltipItems/commonTooltip.component"

export const SideNavigation = () => {
    const { token, getAccessLevel } = useSelector(AuthStateSelector)
    const { selectedDropdownList, trackpackDropdownList } = useSelector(ProductStateSelector)

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const clickRef: any = useRef(null)
    const { pathname } = location
    const handleLogoutClick = () => {
        localStorage.clear()
        unregisterServiceWorker()
        window.location.reload()
    }

    const [open, setOpen] = useState(false)
    const [isGridIconHovered, setIsGridIconHovered] = useState(false)
    const [isGraphIconHovered, setIsGraphIconHovered] = useState(false)
    const [isReportHovered, setIsReportHovered] = useState(false)
    const [isUserAccountIconHovered, setIsUserAccountIconHovered] = useState(false)
    const [isSettingIconHovered, setIsSettingIconHovered] = useState(false)
    const [isQuickTourIconHovered, setIsQuickTourIconHovered] = useState(false)
    const [isSearchTrackerIconHovered, setIsSearchTrackerIconHovered] = useState(false)
    const [isSearchpackHubIconHovered, setIsSearchpackHubIconHovered] = useState(false)

    const isGridIconActive = ROUTES_CONFIG.trackpackCentral === pathname
    const isGraphIconActive = ROUTES_CONFIG.dashboard === pathname
    const isReportActive = ROUTES_CONFIG.reports === pathname || ROUTES_CONFIG.reportSummary === pathname
    const isUserAccountActive = ROUTES_CONFIG.userAccounts === pathname
    const isSettingsIconActive = ROUTES_CONFIG.userSettingAlerts === pathname
    const isSearchTrackerIconActive = pathname.includes(ROUTES_CONFIG.searchpackTracker)
    const isSearchpackHubIconActive = ROUTES_CONFIG.searchpackhub === pathname

    useEffect(() => {
        if (
            pathname === ROUTES_CONFIG.dashboard &&
            getAccessLevel &&
            getAccessLevel?.user_settings?.initial_qt_completed === false &&
            trackpackDropdownList &&
            trackpackDropdownList?.trackpacks?.length === 0
        ) {
            setTimeout(() => {
                if (clickRef?.current) {
                    clickRef.current.click()
                }
            }, 0)
        }
    }, [
        getAccessLevel?.user_settings?.initial_qt_completed,
        trackpackDropdownList?.trackpacks?.length,
        pathname,
        getAccessLevel,
        trackpackDropdownList,
    ])

    useEffect(() => {
        if (
            pathname === ROUTES_CONFIG.trackpackCentral &&
            selectedDropdownList &&
            selectedDropdownList?.trackpacks?.length === 0
        ) {
            setTimeout(() => {
                if (clickRef?.current) {
                    clickRef.current.click()
                }
            }, 0)
        }
    }, [selectedDropdownList?.trackpacks?.length, pathname, selectedDropdownList])

    const [isClicked, setIsClicked] = useState(false)

    const handleAvatarClick = () => {
        setIsClicked(!isClicked) // Toggle the clicked state
    }

    return (
        <div className="w-[60px] h-full bg-gray-800 rounded-2xl justify-start items-start inline-flex">
            <div className="w-[60px] h-full flex-col justify-between items-center inline-flex">
                <div className="p-[6px]">
                    <div className="p-[6px] flex-col justify-start items-start gap-[12px] flex bg-gray-700 rounded-[10px]">
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom-end"
                            data-tooltip-class-name={"!ml-[60px]"}
                            data-tooltip-offset={-14}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip
                                    className="w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                    label="Trackpack Hub"
                                />
                            )}
                            onClick={() => {
                                navigate(ROUTES_CONFIG.trackpackCentral)
                                setOpen(false)
                            }}
                            onMouseEnter={() => setIsGridIconHovered(true)}
                            onMouseLeave={() => setIsGridIconHovered(false)}
                            className={`p-2 cursor-pointer rounded-[4px] justify-start items-start gap-2 inline-flex
                            ${
                                getAccessLevel?.is_staff && isGridIconActive && isGridIconHovered
                                    ? "bg-active-icon-hover-purple"
                                    : getAccessLevel?.is_staff && isGridIconActive && !isGridIconHovered
                                    ? "bg-purple-gradient"
                                    : isGridIconActive && isGridIconHovered && !getAccessLevel?.is_staff
                                    ? "bg-active-icon-hover-blue"
                                    : isGridIconActive && !isGridIconHovered && !getAccessLevel?.is_staff
                                    ? "bg-[#A5F0FC]"
                                    : "hover:bg-icons-hover-gradient"
                            }
                        `}
                        >
                            <div className="w-5 h-5 relative flex items-center justify-center ">
                                <GridIconSidebar
                                    color={
                                        getAccessLevel &&
                                        getAccessLevel?.is_staff &&
                                        isGridIconHovered &&
                                        ROUTES_CONFIG.trackpackCentral === pathname
                                            ? "#5925DC"
                                            : getAccessLevel &&
                                              !getAccessLevel?.is_staff &&
                                              isGridIconHovered &&
                                              ROUTES_CONFIG.trackpackCentral === pathname
                                            ? "#0E7090"
                                            : ROUTES_CONFIG.trackpackCentral === pathname &&
                                              getAccessLevel &&
                                              getAccessLevel?.is_staff
                                            ? "#6938EF"
                                            : ROUTES_CONFIG.trackpackCentral === pathname && !getAccessLevel?.is_staff
                                            ? "#088AB2"
                                            : getAccessLevel &&
                                              getAccessLevel?.is_staff &&
                                              isGridIconHovered &&
                                              ROUTES_CONFIG.trackpackCentral !== pathname
                                            ? "#9B8AFB"
                                            : getAccessLevel && getAccessLevel?.is_staff && !isGridIconHovered
                                            ? "#7A5AF8"
                                            : getAccessLevel && !getAccessLevel?.is_staff && isGridIconHovered
                                            ? "#22CCEE"
                                            : "#06AED4"
                                    }
                                />
                            </div>
                        </div>
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom-end"
                            data-tooltip-class-name={"!ml-[60px]"}
                            data-tooltip-offset={-14}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip
                                    className=" w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                    label="Listing Tracker"
                                />
                            )}
                            onClick={() => {
                                navigate(ROUTES_CONFIG.dashboard)
                                dispatch(ProductsActionCreator.selectedOptions({ selectedOption: "Graph" }))
                                setOpen(false)
                            }}
                            onMouseEnter={() => setIsGraphIconHovered(true)}
                            onMouseLeave={() => setIsGraphIconHovered(false)}
                            className={`p-2 cursor-pointer rounded-[4px] justify-start items-start gap-2 inline-flex
                            ${
                                getAccessLevel?.is_staff && isGraphIconActive && isGraphIconHovered
                                    ? "bg-active-icon-hover-purple"
                                    : getAccessLevel?.is_staff && isGraphIconActive && !isGraphIconHovered
                                    ? "bg-purple-gradient"
                                    : isGraphIconActive && isGraphIconHovered && !getAccessLevel?.is_staff
                                    ? "bg-active-icon-hover-blue"
                                    : isGraphIconActive && !isGraphIconHovered && !getAccessLevel?.is_staff
                                    ? "bg-[#A5F0FC]"
                                    : "hover:bg-icons-hover-gradient"
                            }
                        `}
                        >
                            <div className="w-5 h-5 relative flex items-center justify-center">
                                <GraphIconSidebar
                                    color={
                                        getAccessLevel &&
                                        getAccessLevel?.is_staff &&
                                        isGraphIconHovered &&
                                        ROUTES_CONFIG.dashboard === pathname
                                            ? "#5925DC"
                                            : getAccessLevel &&
                                              !getAccessLevel?.is_staff &&
                                              isGraphIconHovered &&
                                              ROUTES_CONFIG.dashboard === pathname
                                            ? "#0E7090"
                                            : ROUTES_CONFIG.dashboard === pathname &&
                                              getAccessLevel &&
                                              getAccessLevel?.is_staff
                                            ? "#6938EF"
                                            : ROUTES_CONFIG.dashboard === pathname && !getAccessLevel?.is_staff
                                            ? "#088AB2"
                                            : getAccessLevel &&
                                              getAccessLevel?.is_staff &&
                                              isGraphIconHovered &&
                                              ROUTES_CONFIG.dashboard !== pathname
                                            ? "#9B8AFB"
                                            : getAccessLevel && getAccessLevel?.is_staff && !isGraphIconHovered
                                            ? "#7A5AF8"
                                            : getAccessLevel && !getAccessLevel?.is_staff && isGraphIconHovered
                                            ? "#22CCEE"
                                            : "#06AED4"
                                    }
                                />
                            </div>
                        </div>
                        {ENABLE_LISTING_ANALYZER && (
                            <div
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-place="bottom-end"
                                data-tooltip-class-name={"!ml-[27px]"}
                                data-tooltip-offset={-14}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip
                                        className=" w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                        label="Listing Analyzer"
                                    />
                                )}
                                onClick={() => {
                                    navigate(ROUTES_CONFIG.reports)

                                    setOpen(false)
                                }}
                                onMouseEnter={() => setIsReportHovered(true)}
                                onMouseLeave={() => setIsReportHovered(false)}
                                className={`p-2 cursor-pointer rounded-[4px] justify-start items-start gap-2 inline-flex
                            ${
                                getAccessLevel?.is_staff && isReportActive && isReportHovered
                                    ? "bg-active-icon-hover-purple"
                                    : getAccessLevel?.is_staff && isReportActive && !isReportHovered
                                    ? "bg-purple-gradient"
                                    : isReportActive && isReportHovered && !getAccessLevel?.is_staff
                                    ? "bg-active-icon-hover-blue"
                                    : isReportActive && !isReportHovered && !getAccessLevel?.is_staff
                                    ? "bg-[#A5F0FC]"
                                    : "hover:bg-icons-hover-gradient"
                            }
                        `}
                            >
                                <div className="w-5 h-5 relative flex items-center justify-center">
                                    <ListingAnalyzerIcon
                                        color={
                                            getAccessLevel &&
                                            getAccessLevel?.is_staff &&
                                            isReportHovered &&
                                            (ROUTES_CONFIG.reports === pathname ||
                                                ROUTES_CONFIG.reportSummary === pathname)
                                                ? "#5925DC"
                                                : getAccessLevel &&
                                                  !getAccessLevel?.is_staff &&
                                                  isReportHovered &&
                                                  (ROUTES_CONFIG.reports === pathname ||
                                                      ROUTES_CONFIG.reportSummary === pathname)
                                                ? "#0E7090"
                                                : (ROUTES_CONFIG.reports === pathname ||
                                                      ROUTES_CONFIG.reportSummary === pathname) &&
                                                  getAccessLevel &&
                                                  getAccessLevel?.is_staff
                                                ? "#6938EF"
                                                : (ROUTES_CONFIG.reports === pathname ||
                                                      ROUTES_CONFIG.reportSummary === pathname) &&
                                                  !getAccessLevel?.is_staff
                                                ? "#088AB2"
                                                : getAccessLevel &&
                                                  getAccessLevel?.is_staff &&
                                                  isReportHovered &&
                                                  (ROUTES_CONFIG.reports !== pathname ||
                                                      ROUTES_CONFIG.reportSummary !== pathname)
                                                ? "#9B8AFB"
                                                : getAccessLevel && getAccessLevel?.is_staff && !isReportHovered
                                                ? "#7A5AF8"
                                                : getAccessLevel && !getAccessLevel?.is_staff && isReportHovered
                                                ? "#22CCEE"
                                                : "#06AED4"
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {SEARCHPACK_ICONS && (
                        <div className="p-[6px] flex-col justify-start items-start gap-[8px] flex bg-gray-700 rounded-[10px] mt-[6px]">
                            <div
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-place="bottom-end"
                                data-tooltip-class-name={"!ml-[28px]"}
                                data-tooltip-offset={-14}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip
                                        className="w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                        label="Searchpack Hub"
                                    />
                                )}
                                onClick={() => {
                                    navigate(ROUTES_CONFIG.searchpackhub)
                                    setOpen(false)
                                }}
                                onMouseEnter={() => setIsSearchpackHubIconHovered(true)}
                                onMouseLeave={() => setIsSearchpackHubIconHovered(false)}
                                className={`p-2 cursor-pointer rounded-[4px] justify-start items-start gap-2 inline-flex
                                ${
                                    getAccessLevel?.is_staff && isSearchpackHubIconActive && isSearchpackHubIconHovered
                                        ? "bg-active-icon-hover-purple"
                                        : getAccessLevel?.is_staff &&
                                          isSearchpackHubIconActive &&
                                          !isSearchpackHubIconHovered
                                        ? "bg-purple-gradient"
                                        : isSearchpackHubIconActive &&
                                          isSearchpackHubIconHovered &&
                                          !getAccessLevel?.is_staff
                                        ? "bg-active-icon-hover-blue"
                                        : isSearchpackHubIconActive &&
                                          !isSearchpackHubIconHovered &&
                                          !getAccessLevel?.is_staff
                                        ? "bg-[#A5F0FC]"
                                        : "hover:bg-icons-hover-gradient"
                                }
                            `}
                            >
                                <div className="w-5 h-5 relative flex items-center justify-center ">
                                    <SearchPackIcon
                                        color={
                                            getAccessLevel &&
                                            getAccessLevel?.is_staff &&
                                            isSearchpackHubIconHovered &&
                                            ROUTES_CONFIG.searchpackhub === pathname
                                                ? "#5925DC"
                                                : getAccessLevel &&
                                                  !getAccessLevel?.is_staff &&
                                                  isSearchpackHubIconHovered &&
                                                  ROUTES_CONFIG.searchpackhub === pathname
                                                ? "#0E7090"
                                                : ROUTES_CONFIG.searchpackhub === pathname &&
                                                  getAccessLevel &&
                                                  getAccessLevel?.is_staff
                                                ? "#6938EF"
                                                : ROUTES_CONFIG.searchpackhub === pathname && !getAccessLevel?.is_staff
                                                ? "#088AB2"
                                                : getAccessLevel &&
                                                  getAccessLevel?.is_staff &&
                                                  isSearchpackHubIconHovered &&
                                                  ROUTES_CONFIG.searchpackhub !== pathname
                                                ? "#9B8AFB"
                                                : getAccessLevel &&
                                                  getAccessLevel?.is_staff &&
                                                  !isSearchpackHubIconHovered
                                                ? "#7A5AF8"
                                                : getAccessLevel &&
                                                  !getAccessLevel?.is_staff &&
                                                  isSearchpackHubIconHovered
                                                ? "#22CCEE"
                                                : "#06AED4"
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-place="bottom-end"
                                data-tooltip-class-name={"!ml-[60px]"}
                                data-tooltip-offset={-14}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip
                                        className=" w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                        label="Search Tracker"
                                    />
                                )}
                                onClick={() => {
                                    navigate(ROUTES_CONFIG.searchpackTrackerRankSnapshot)
                                    setOpen(false)
                                }}
                                onMouseEnter={() => setIsSearchTrackerIconHovered(true)}
                                onMouseLeave={() => setIsSearchTrackerIconHovered(false)}
                                className={`p-2 cursor-pointer rounded-[4px] justify-start items-start gap-2 inline-flex
                                ${
                                    getAccessLevel?.is_staff && isSearchTrackerIconActive && isSearchTrackerIconHovered
                                        ? "bg-active-icon-hover-purple"
                                        : getAccessLevel?.is_staff &&
                                          isSearchTrackerIconActive &&
                                          !isSearchTrackerIconHovered
                                        ? "bg-purple-gradient"
                                        : isSearchTrackerIconActive &&
                                          isSearchTrackerIconHovered &&
                                          !getAccessLevel?.is_staff
                                        ? "bg-active-icon-hover-blue"
                                        : isSearchTrackerIconActive &&
                                          !isSearchTrackerIconHovered &&
                                          !getAccessLevel?.is_staff
                                        ? "bg-[#A5F0FC]"
                                        : "hover:bg-icons-hover-gradient"
                                }
                            `}
                            >
                                <div className="w-5 h-5 relative flex items-center justify-center">
                                    <SearchTrackerIcon
                                        color={
                                            getAccessLevel &&
                                            getAccessLevel?.is_staff &&
                                            isSearchTrackerIconHovered &&
                                            ROUTES_CONFIG.searchpackTracker === pathname
                                                ? "#5925DC"
                                                : getAccessLevel &&
                                                  !getAccessLevel?.is_staff &&
                                                  isSearchTrackerIconHovered &&
                                                  ROUTES_CONFIG.searchpackTracker === pathname
                                                ? "#0E7090"
                                                : ROUTES_CONFIG.searchpackTracker === pathname &&
                                                  getAccessLevel &&
                                                  getAccessLevel?.is_staff
                                                ? "#6938EF"
                                                : ROUTES_CONFIG.searchpackTracker === pathname &&
                                                  !getAccessLevel?.is_staff
                                                ? "#088AB2"
                                                : getAccessLevel &&
                                                  getAccessLevel?.is_staff &&
                                                  isSearchTrackerIconHovered &&
                                                  ROUTES_CONFIG.searchpackTracker !== pathname
                                                ? "#9B8AFB"
                                                : getAccessLevel &&
                                                  getAccessLevel?.is_staff &&
                                                  !isSearchTrackerIconHovered
                                                ? "#7A5AF8"
                                                : getAccessLevel &&
                                                  !getAccessLevel?.is_staff &&
                                                  isSearchTrackerIconHovered
                                                ? "#22CCEE"
                                                : "#06AED4"
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="flex-col justify-center items-center gap-3 flex mb-[6px]">
                    <div className="flex-col justify-center items-center gap-2 flex bg-gray-700 p-[6px] rounded-[10px]">
                        {getAccessLevel && getAccessLevel.is_staff && (
                            <div
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-place="bottom-end"
                                data-tooltip-offset={-12}
                                data-tooltip-class-name={"!left-[65px]"}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip
                                        className=" w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                        label="User Accounts"
                                    />
                                )}
                                onClick={() => {
                                    getAccessLevel && getAccessLevel.is_staff && navigate(ROUTES_CONFIG.userAccounts)
                                    setOpen(false)
                                }}
                                onMouseEnter={() => setIsUserAccountIconHovered(true)}
                                onMouseLeave={() => setIsUserAccountIconHovered(false)}
                                className={`p-2 cursor-pointer rounded-[4px] justify-start items-start gap-2 inline-flex
                                    ${
                                        getAccessLevel?.is_staff && isUserAccountActive && isUserAccountIconHovered
                                            ? "bg-active-icon-hover-purple"
                                            : getAccessLevel?.is_staff &&
                                              isUserAccountActive &&
                                              !isUserAccountIconHovered
                                            ? "bg-purple-gradient"
                                            : isUserAccountActive &&
                                              isUserAccountIconHovered &&
                                              !getAccessLevel?.is_staff
                                            ? "bg-active-icon-hover-blue"
                                            : isUserAccountActive &&
                                              !isUserAccountIconHovered &&
                                              !getAccessLevel?.is_staff
                                            ? "bg-[#A5F0FC]"
                                            : "hover:bg-icons-hover-gradient"
                                    }
                                            ${
                                                getAccessLevel && getAccessLevel.is_staff
                                                    ? "cursor-pointer"
                                                    : "cursor-not-allowed"
                                            }
                                `}
                            >
                                <div className="w-5 h-5 relative flex items-center justify-center">
                                    <AccountUserIcon
                                        color={
                                            getAccessLevel &&
                                            getAccessLevel?.is_staff &&
                                            isUserAccountIconHovered &&
                                            ROUTES_CONFIG.userAccounts === pathname
                                                ? "#5925DC"
                                                : getAccessLevel &&
                                                  !getAccessLevel?.is_staff &&
                                                  isUserAccountIconHovered &&
                                                  ROUTES_CONFIG.userAccounts === pathname
                                                ? "#0E7090"
                                                : ROUTES_CONFIG.userAccounts === pathname &&
                                                  getAccessLevel &&
                                                  getAccessLevel?.is_staff
                                                ? "#6938EF"
                                                : ROUTES_CONFIG.userAccounts === pathname && !getAccessLevel?.is_staff
                                                ? "#088AB2"
                                                : getAccessLevel &&
                                                  getAccessLevel?.is_staff &&
                                                  isUserAccountIconHovered &&
                                                  ROUTES_CONFIG.userAccounts !== pathname
                                                ? "#9B8AFB"
                                                : getAccessLevel &&
                                                  getAccessLevel?.is_staff &&
                                                  !isUserAccountIconHovered
                                                ? "#7A5AF8"
                                                : getAccessLevel &&
                                                  !getAccessLevel?.is_staff &&
                                                  isUserAccountIconHovered
                                                ? "#22CCEE"
                                                : "#06AED4"
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {/* <div className="p-2 cursor-not-allowed rounded-[10px] justify-start items-start gap-2 inline-flex">
                            <div
                                className="w-5 h-5 relative"
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-class-name={"!ml-[35px]"}
                                data-tooltip-place="bottom-end"
                                data-tooltip-offset={-6}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip
                                        className=" w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                        label="Learn More"
                                    />
                                )}
                            >
                                <VideoIconSidebar color={iconColor} />
                            </div>
                        </div> */}
                        <div className="relative" data-tooltip-id={"tour-tooltip"}>
                            <div
                                ref={clickRef}
                                // className="w-5 h-5 relative"
                                className={`p-2 rounded-[4px] justify-start items-start gap-2 inline-flex
                                        ${
                                            getAccessLevel?.is_staff && open && isQuickTourIconHovered
                                                ? "bg-active-icon-hover-purple"
                                                : getAccessLevel?.is_staff && open && !isQuickTourIconHovered
                                                ? "bg-purple-gradient"
                                                : open && isQuickTourIconHovered && !getAccessLevel?.is_staff
                                                ? "bg-active-icon-hover-blue"
                                                : open && !isQuickTourIconHovered && !getAccessLevel?.is_staff
                                                ? "bg-[#A5F0FC]"
                                                : "hover:bg-icons-hover-gradient"
                                        }
                                                ${
                                                    pathname === ROUTES_CONFIG.dashboard ||
                                                    pathname === ROUTES_CONFIG.trackpackCentral
                                                        ? "cursor-pointer"
                                                        : "cursor-not-allowed"
                                                }                                       
                                    `}
                                onMouseEnter={() => setIsQuickTourIconHovered(true)}
                                onMouseLeave={() => setIsQuickTourIconHovered(false)}
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-class-name={"!ml-[50px]"}
                                data-tooltip-place="bottom-end"
                                data-tooltip-offset={-19}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <>
                                        {!open && (
                                            <CommonTooltip
                                                className="w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                                label="Quick Tour"
                                            />
                                        )}
                                    </>
                                )}
                                onClick={
                                    pathname === ROUTES_CONFIG.dashboard || pathname === ROUTES_CONFIG.trackpackCentral
                                        ? () => setOpen(!open)
                                        : undefined
                                }
                            >
                                <VideoIconSidebar
                                    color={
                                        getAccessLevel && getAccessLevel?.is_staff && isQuickTourIconHovered && open
                                            ? "#5925DC"
                                            : getAccessLevel &&
                                              !getAccessLevel?.is_staff &&
                                              isQuickTourIconHovered &&
                                              open
                                            ? "#0E7090"
                                            : getAccessLevel &&
                                              getAccessLevel?.is_staff &&
                                              isQuickTourIconHovered &&
                                              !open
                                            ? "#9B8AFB"
                                            : getAccessLevel &&
                                              !getAccessLevel?.is_staff &&
                                              isQuickTourIconHovered &&
                                              !open
                                            ? "#22CCEE"
                                            : getAccessLevel && getAccessLevel?.is_staff
                                            ? "#7A5AF8"
                                            : "#06AED4"
                                    }
                                />
                            </div>
                            {open && (
                                <>
                                    {pathname === ROUTES_CONFIG.dashboard && (
                                        <Tooltip
                                            id="tour-tooltip"
                                            style={{
                                                padding: "12px",
                                                backgroundColor: "#088AB2",
                                                borderRadius: "16px",
                                                zIndex: 1000,
                                                maxWidth: "248px",
                                                boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
                                            }}
                                            place="right"
                                            delayShow={200}
                                            classNameArrow="custom-arrow light-blue"
                                            openOnClick
                                            clickable
                                            isOpen={open}
                                            render={() => {
                                                return (
                                                    <div>
                                                        <QuickTourModal
                                                            onClose={() => {
                                                                setOpen(false)
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    )}
                                    {pathname === ROUTES_CONFIG.trackpackCentral && (
                                        <Tooltip
                                            id="tour-tooltip"
                                            style={{
                                                padding: "12px",
                                                backgroundColor: "#088AB2",
                                                borderRadius: "16px",
                                                zIndex: 1000,
                                                maxWidth: "248px",
                                                boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
                                            }}
                                            place="right"
                                            delayShow={200}
                                            classNameArrow="custom-arrow light-blue"
                                            openOnClick
                                            clickable
                                            isOpen={open}
                                            render={() => {
                                                return (
                                                    <div>
                                                        <QuickTourTrackpackModal
                                                            onClose={() => {
                                                                setOpen(false)
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </div>

                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-class-name={"!left-[64px]"}
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={-19}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip
                                    className="w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                    label="Account Settings"
                                />
                            )}
                            onClick={() => {
                                navigate(ROUTES_CONFIG.userSettingAlerts)
                                setOpen(false)
                            }}
                            onMouseEnter={() => setIsSettingIconHovered(true)}
                            onMouseLeave={() => setIsSettingIconHovered(false)}
                            className={`p-2 cursor-pointer rounded-[4px] justify-start items-start gap-2 inline-flex
                            ${
                                getAccessLevel?.is_staff && isSettingsIconActive && isSettingIconHovered
                                    ? "bg-active-icon-hover-purple"
                                    : getAccessLevel?.is_staff && isSettingsIconActive && !isSettingIconHovered
                                    ? "bg-purple-gradient"
                                    : isSettingsIconActive && isSettingIconHovered && !getAccessLevel?.is_staff
                                    ? "bg-active-icon-hover-blue"
                                    : isSettingsIconActive && !isSettingIconHovered && !getAccessLevel?.is_staff
                                    ? "bg-[#A5F0FC]"
                                    : "hover:bg-icons-hover-gradient"
                            }
                        `}
                        >
                            <div className="w-5 h-5 justify-center items-center flex">
                                <div className="w-5 h-5 relative flex-col justify-start items-start flex">
                                    <SettingNewIcon
                                        color={
                                            getAccessLevel &&
                                            getAccessLevel?.is_staff &&
                                            isSettingIconHovered &&
                                            ROUTES_CONFIG.userSettingAlerts === pathname
                                                ? "#5925DC"
                                                : getAccessLevel &&
                                                  !getAccessLevel?.is_staff &&
                                                  isSettingIconHovered &&
                                                  ROUTES_CONFIG.userSettingAlerts === pathname
                                                ? "#0E7090"
                                                : ROUTES_CONFIG.userSettingAlerts === pathname &&
                                                  getAccessLevel &&
                                                  getAccessLevel?.is_staff
                                                ? "#6938EF"
                                                : ROUTES_CONFIG.userSettingAlerts === pathname &&
                                                  !getAccessLevel?.is_staff
                                                ? "#088AB2"
                                                : getAccessLevel &&
                                                  getAccessLevel?.is_staff &&
                                                  isSettingIconHovered &&
                                                  ROUTES_CONFIG.userSettingAlerts !== pathname
                                                ? "#9B8AFB"
                                                : getAccessLevel && getAccessLevel?.is_staff && !isSettingIconHovered
                                                ? "#7A5AF8"
                                                : getAccessLevel && !getAccessLevel?.is_staff && isSettingIconHovered
                                                ? "#22CCEE"
                                                : "#06AED4"
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div data-tooltip-id={"logout-tooltip"}>
                            <div className=" cursor-pointer">
                                <div
                                    onClick={handleAvatarClick}
                                    className={`w-9 h-9 relative rounded-[4px] justify-center items-center flex
                                        ${
                                            isClicked && getAccessLevel?.is_staff
                                                ? "bg-[#6938EF]"
                                                : isClicked
                                                ? "bg-[#088AB2] hover:bg-[#22CCEE]" // Color when clicked
                                                : getAccessLevel?.is_staff
                                                ? "bg-[#7A5AF8] hover:bg-[#9B8AFB]" // Admin colors
                                                : "bg-[#06AED4] hover:bg-[#22CCEE]" // User colors
                                        }
                                      `}
                                >
                                    {getAccessLevel && getAccessLevel?.is_staff ? (
                                        <AdminUserIcon />
                                    ) : (
                                        <div className="w-full font-light text-[20px] pt-[2px] leading-6 text-gray-200 h-full relative flex-col justify-center items-center flex border border-transparent ">
                                            {UtilHelper.getInitials(token?.username ?? "User")}
                                        </div>
                                    )}

                                    <Tooltip
                                        id="logout-tooltip"
                                        style={{
                                            padding: "6px 10px",
                                            backgroundColor: "#088AB2",
                                            borderRadius: "6px",
                                            zIndex: 1000,
                                            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                        }}
                                        place="right"
                                        delayShow={200}
                                        classNameArrow="custom-arrow light-blue"
                                        openOnClick
                                        clickable
                                        render={() => {
                                            return (
                                                <div>
                                                    <div
                                                        className="flex items-center gap-[6px] cursor-pointer"
                                                        onClick={handleLogoutClick}
                                                    >
                                                        <NewLogoutIcon />
                                                        <PrimaryText
                                                            weight="book"
                                                            size="small"
                                                            className="text-white leading-[20px]"
                                                            onClick={handleLogoutClick}
                                                        >
                                                            Logout
                                                        </PrimaryText>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Tooltip
                clickable
                id="dashboard-tooltip"
                style={{
                    backgroundColor: "transparent",
                    color: "#222",
                    zIndex: 9000,
                    padding: 0,
                    margin: 0,
                }}
                delayShow={200}
            />
            <Tooltip
                clickable
                id="datepicker-tooltip"
                style={{
                    backgroundColor: "transparent",
                    color: "#222",
                    zIndex: 9000,
                    padding: 0,
                    margin: 0,
                }}
                delayShow={200}
            />
        </div>
    )
}
