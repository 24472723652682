import { useMemo } from "react"
import dateHelper from "../../../helpers/date"
import { dateUtils } from "../../../utils/date"
import { extendedDayjs } from "../../../utils/dayjs"

export const useDateRange = (
    range: number,
    format: "string" | "number" = "string",
    hasToday: boolean = true,
    future = false,
    timezone = "utc"
) => {
    return useMemo(() => {
        if (!range) return []

        const dateRange = dateHelper.alterRangeForToday(
            dateUtils.calendar.formRangeFromToday(range, future, true, timezone),
            hasToday
        )

        if (format === "string") return dateRange
        return dateRange.map((i) => extendedDayjs.utc(i).unix())
    }, [format, future, hasToday, range, timezone])
}
