import { put } from "typed-redux-saga"
import { RuntimeActionCreators } from "../actions/runTime.action"

/**
 * A higher-order Redux-Saga Effect that wraps a given handler with automatic state management.
 * Returns a saga function that can be directly used in `takeLatest`, `takeEvery`, etc.
 */
export function apiSaga<T extends ISagaAction>(key: string, handler: (action: T) => Generator) {
    return function* (action: T) {
        const { ignorePreloader } = action

        try {
            yield put(RuntimeActionCreators.startLoading(key, !ignorePreloader))
            yield* handler(action)
        } catch (error) {
            throw error
        } finally {
            yield put(RuntimeActionCreators.stopLoading(key))
        }
    }
}
