import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AccountActionsCreater } from "../../../actions/account.action"
import { SearchpackActionCreator } from "../../../actions/searchpack.action"
import { ProductStateSelector } from "../../../selectors/product.selector"

export const useRefetchSPAfterModal = () => {
    const dispatch = useDispatch()
    const { addAsinToTrackpack } = useSelector(ProductStateSelector)

    return {
        refetchSP: useCallback(() => {
            if (!!addAsinToTrackpack?.sp_id && !!addAsinToTrackpack?.sp_tab) {
                const tab = addAsinToTrackpack?.sp_tab as SearchpackTrackerTabs | undefined

                if (tab === "rank-snapshot") {
                    dispatch(SearchpackActionCreator.getRanksnapshotData(addAsinToTrackpack?.sp_id))
                } else if (tab === "rank-tracker" || tab === "keyword-performance") {
                    dispatch(AccountActionsCreater.refetchSearchpacks({ select: addAsinToTrackpack?.sp_id }))
                }
            }
        }, [addAsinToTrackpack?.sp_id, addAsinToTrackpack?.sp_tab, dispatch]),
    }
}
