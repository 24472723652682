import dayjs from "dayjs"
import { extendedDayjs } from "./dayjs"

export type TOrder = "asc" | "desc"

/**
 * @note Do not export this function. Requires manual param binding
 */
function sortDateFunc(this: { order: TOrder; format: string }, date: string, anotherDate: string) {
    const order = this.order || "asc" // default order is asc
    const format = this.format || "YYYY-MM-DD" // default format is "YYYY-MM-DD"
    const dateObj = dayjs(date, format)
    const anotherDateObj = dayjs(anotherDate, format)

    if (dateObj.isSame(anotherDateObj)) return 0
    if (order === "asc") return dateObj.isBefore(anotherDateObj) ? -1 : 1
    return dateObj.isAfter(anotherDateObj) ? -1 : 1
}

/**
 * @note Do not export this function. Just supplementary function for sortDateArray
 */
const getSortDateFunc = (format: string, order: TOrder) => sortDateFunc.bind({ order, format })

const sortDateArray = (dateArr: string[], format = "YYYY-MM-DD", order: TOrder = "asc") => {
    return dateArr.sort(getSortDateFunc(format, order))
}

const sortDateArrayAsc = (dateArr: string[], format = "YYYY-MM-DD") => {
    return sortDateArray(dateArr, format, "asc")
}

const sortDateArrayDesc = (dateArr: string[], format = "YYYY-MM-DD") => {
    return sortDateArray(dateArr, format, "desc")
}

const sortObjectsByDate = <T extends Record<string, any>>(
    key: keyof T & string,
    data: T[],
    format = "YYYY-MM-DD",
    order: TOrder = "desc"
): T[] => {
    return data.sort((a, b) => getSortDateFunc(format, order)(a[key], b[key]))
}

const validDateOrUndefined = (date: string, format = "YYYY-MM-DD") => {
    const dateObj = extendedDayjs(date)
    if (!dateObj.isValid()) return undefined
    return date
}

const validDateObjectOrUndefined = (date: string | Date, format = "") => {
    const dateObj = !!format ? extendedDayjs(date, format) : extendedDayjs(date)
    if (!dateObj.isValid()) return undefined
    return dateObj.toDate()
}

const calendar = {
    formRange: (start: string, end: string) => {
        let startDate = extendedDayjs.utc(start, "YYYY-MM-DD")
        const endDate = extendedDayjs.utc(end, "YYYY-MM-DD")
        const range: string[] = []

        if (endDate.isSame(startDate, "day")) return [startDate.format("YYYY-MM-DD")]
        if (!endDate.isAfter(startDate, "day")) throw new Error("Invalid parameters!")

        do {
            range.push(startDate.format("YYYY-MM-DD"))
            startDate = startDate.add(1, "day")
        } while (!startDate.isAfter(endDate))

        return range
    },

    formRangeFromToday(days: number, future = true, inclusive = true, timezone = "utc") {
        const today = extendedDayjs()
            .tz(timezone)
            .add(inclusive ? 0 : future ? 1 : -1, "days")
            .format("YYYY-MM-DD")

        const lastDate = extendedDayjs()
            .tz(timezone)
            .add(future ? days - 1 : -(days - 1), "days")
            .format("YYYY-MM-DD")

        return future ? this.formRange(today, lastDate) : this.formRange(lastDate, today)
    },
}

export const dateUtils = {
    calendar,
    sortDateArray,
    sortDateArrayAsc,
    sortDateArrayDesc,
    sortObjectsByDate,
    validDateOrUndefined,
    validDateObjectOrUndefined,
}
