export const BackArrow = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.3334 10.0003H4.66675M4.66675 10.0003L10.5001 15.8337M4.66675 10.0003L10.5001 4.16699"
                stroke="#06AED4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
