export class SearchpackStateClass implements ISearchpackState {
    selectedSearchpackPayload?: ISelectedSearchpackPayload
    activeRankingTab?: string
    selectedSearchpackValues?: { selectedSearchpackID: number }
    selectedSearchpackLastDataTime?: ISearchpackLastDataTime
    ranksnapshotData?: IRankSnapshotData
    searchpackRankTracker?: ISearchpackRankTracker
    keywordPerformanceListingChange?: IGroupedListingChanges
    selectedSearchpackTerms?: TSearchpackKeyword[]
}
