export const EyeOffNewIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.47623 3.01633C4.64556 2.99129 4.82025 2.97786 5.00017 2.97786C7.12726 2.97786 8.52304 4.85488 8.99196 5.59737C9.04871 5.68724 9.07709 5.73217 9.09297 5.80148C9.1049 5.85353 9.10489 5.93564 9.09295 5.98769C9.07706 6.05699 9.04848 6.10222 8.99133 6.19268C8.86639 6.39043 8.67591 6.66832 8.42354 6.96972M2.8018 3.69246C1.90094 4.30357 1.28935 5.15261 1.00879 5.59673C0.951785 5.68698 0.923281 5.7321 0.907391 5.8014C0.895457 5.85345 0.895452 5.93555 0.90738 5.9876C0.923262 6.0569 0.951638 6.10183 1.00839 6.19169C1.47731 6.93418 2.87309 8.8112 5.00017 8.8112C5.85784 8.8112 6.59662 8.50603 7.20367 8.09312M1.25017 2.14453L8.75017 9.64453M4.11629 5.01065C3.89008 5.23685 3.75017 5.54935 3.75017 5.89453C3.75017 6.58489 4.30982 7.14453 5.00017 7.14453C5.34535 7.14453 5.65785 7.00462 5.88406 6.77841"
                stroke="#667085"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
