export const AddIcon = ({
    strokeColor,
    width,
    height,
    classname,
    strokWidth,
}: {
    strokeColor?: string
    width?: string
    height?: string
    classname?: string
    strokWidth?: string
}) => {
    return (
        <>
            <svg
                width={width ?? "16"}
                height={height ?? "16"}
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 2.5V9.5M2.5 6H9.5"
                    stroke={strokeColor || "#EAECF0"}
                    strokeWidth={strokWidth ?? "0.8"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}
