export const RightChevron = (props: IArrowProps) => {
    return (
        <svg
            width={props.width ?? "36"}
            height={props.height ?? "36"}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.5 23L21.3333 18L15.5 13"
                stroke={`${props.color}`}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
