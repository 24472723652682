import { TrackpackProductDaum } from "../services/product.service"

export enum ProductsAction {
    GET_PRODUCTS_BY_GROUP = "GET_PRODUCTS_BY_GROUP",
    STORE_PRODUCTS = "STORE_PRODUCTS",
    SELECT_PRODUCTS_VIEW = "SELECT_PRODUCTS_VIEW",
    SELECT_PRODUCTS_RESULTS = "SELECT_PRODUCTS_RESULTS",
    SELECT_PRODUCTS = "SELECT_PRODUCTS",
    SELECT_LOG_PRODUCT = "SELECT_LOG_PRODUCT",
    SELECT_PRODUCTS_RANGE = "SELECT_PRODUCTS_RANGE",
    SELECT_OPTION = "SELECT_OPTION",
    CREATE_TRACKPACK = "CREATE_TRACKPACK",
    CREATE_TRACKPACK_ACCOUNT = "CREATE_TRACKPACK_ACCOUNT",
    CREATE_ADDITIONAL_TRACKPACK_ACCOUNT = "CREATE_ADDITIONAL_TRACKPACK_ACCOUNT",
    GET_TRACKPACKS_DROPDOWN_LIST = "GET_TRACKPACKS_DROPDOWN_LIST",
    NEW_PRODUCT_DATA_AVAILABLE = "NEW_PRODUCT_DATA_AVAILABLE",
    SET_TRACKPACKS_DROPDOWN_LIST = "SET_TRACKPACKS_DROPDOWN_LIST",
    UPDATE_TRACK_NAME = "UPDATE_TRACK_NAME",
    UPDATE_SEARCHPACK_NAME = "UPDATE_SEARCHPACK_NAME",
    UPDATE_TRACK_NAME_SUCCESS = "UPDATE_TRACK_NAME_SUCCESS",
    ARCHIVE_TRACKPACK = "ARCHIVE_TRACKPACK",
    ARCHIVE_TRACKPACK_SUCCESS = "ARCHIVE_TRACKPACK_SUCCESS",
    ARCHIVE_ASINS = "ARCHIVE_ASINS",
    ARCHIVE_ASINS_SUCCESS = "ARCHIVE_ASINS_SUCCESS",
    RESTORE_TRACKPACK = "RESTORE_TRACKPACK",
    RESTORE_TRACKPACK_SUCCESS = "RESTORE_TRACKPACK_SUCCESS",
    RESTORE_ASINS = "RESTORE_ASINS",
    RESTORE_ASINS_SUCCESS = "RESTORE_ASINS_SUCCESS",
    STORE_RAW_PRODUCTS = "STORE_RAW_PRODUCTS",
    GET_COMPETITORS_LIST = "GET_COMPETITORS_LIST",
    SET_COMPETITORS_LIST = "SET_COMPETITORS_LIST",
    SET_COMPETITORS_LIST_CHIPS_SELECTED = "SET_COMPETITORS_LIST_CHIPS_SELECTED",
    // TRACKPACK LISTING DATA
    GET_TRACKPACKS_LIST = "GET_TRACKPACKS_LIST",
    GET_EXTENDED_TRACKPACKS_LIST = "GET_EXTENDED_TRACKPACKS_LIST",
    SET_TRACKPACKS_LIST = "SET_TRACKPACKS_LIST",
    // FIRST TRACKPACK EXTENDED DATA
    GET_FIRST_TRACKPACK_DATA = "GET_FIRST_TRACKPACK_DATA",
    SET_FIRST_TRACKPACK_DATA = "SET_FIRST_TRACKPACK_DATA",
    SET_SELECTED_TRACKPACK_VALUES = "SET_SELECTED_TRACKPACK_VALUES",
    GET_EXTENDED_TRACKING_DATA = "GET_EXTENDED_TRACKING_DATA",
    // Add Asin to trackpack
    SET_ADD_ASIN_TRACKPACK_DATA = "SET_ADD_ASIN_TRACKPACK_DATA",
    SET_ADD_ASIN_TRACKPACK_DATA_EMPTY = "SET_ADD_ASIN_TRACKPACK_DATA_EMPTY",
    CLAIM_OWNERSHIP_OF_ASIN = "CLAIM_OWNERSHIP_OF_ASIN",
}

export const ProductsActionCreator = {
    getProductsByGroup: (
        trackpackId: string,
        amazaon_tld?: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        embedData?: boolean
    ): ISagaAction => {
        return {
            type: ProductsAction.GET_PRODUCTS_BY_GROUP,
            payload: { trackpackId, amazaon_tld, pathParams, queryParams, embedData },
        }
    },

    storeProducts: (payload: IGroupProductDataResponse): ISagaAction => {
        return {
            type: ProductsAction.STORE_PRODUCTS,
            payload,
        }
    },
    selectedProductView: (payload: IProductSelectedView): ISagaAction => {
        return {
            type: ProductsAction.SELECT_PRODUCTS_VIEW,
            payload,
        }
    },
    selectedProductResult: (payload: IProductSelectedResults): ISagaAction => {
        return {
            type: ProductsAction.SELECT_PRODUCTS_RESULTS,
            payload,
        }
    },
    selectedProducts: (payload: ISelectedProducts): ISagaAction => {
        return {
            type: ProductsAction.SELECT_PRODUCTS,
            payload,
        }
    },
    selectedLogProduct: (payload: IGroupProductData): ISagaAction => {
        return {
            type: ProductsAction.SELECT_LOG_PRODUCT,
            payload,
        }
    },
    selectedProductRange: (payload: IProductSelectedRange): ISagaAction => {
        return {
            type: ProductsAction.SELECT_PRODUCTS_RANGE,
            payload,
        }
    },
    selectedOptions: (payload: ISelectedOption): ISagaAction => {
        return {
            type: ProductsAction.SELECT_OPTION,
            payload,
        }
    },
    selectedTrackpackPayload: (payload: Partial<ISelectedTrackpackPayload>): ISagaAction => {
        return {
            type: ProductsAction.CREATE_TRACKPACK,
            payload,
        }
    },

    createTrackpackAccount: (
        payload: Partial<ISelectedTrackpackPayload>,
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: ProductsAction.CREATE_TRACKPACK_ACCOUNT,
            payload,
            onSuccess,
            onError,
        }
    },
    addAsinToTrackpack: (
        payload: Partial<ISelectedTrackpackPayload>,
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: ProductsAction.CREATE_ADDITIONAL_TRACKPACK_ACCOUNT,
            payload,
            onSuccess,
            onError,
        }
    },
    getDropdownList: (
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: ProductsAction.GET_TRACKPACKS_DROPDOWN_LIST,
            payload: { pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    checkNewProductDataAvailable: (
        trackpackId: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ): ISagaAction => {
        return {
            type: ProductsAction.NEW_PRODUCT_DATA_AVAILABLE,
            payload: { trackpackId, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setDropdownList: (data: IProductDropdownList) => ({
        type: ProductsAction.SET_TRACKPACKS_DROPDOWN_LIST,
        payload: data,
    }),
    updateTrackpackName: (
        payload: { id: string; name: string },
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: ProductsAction.UPDATE_TRACK_NAME,
            payload,
            onSuccess,
            onError,
        }
    },
    updateSearchpackName: (
        payload: { id: string; name: string; status: string },
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: ProductsAction.UPDATE_SEARCHPACK_NAME,
            payload,
            onSuccess,
            onError,
        }
    },
    archiveTrackpack: (
        payload: { trackpack_id: number },
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: ProductsAction.ARCHIVE_TRACKPACK,
            payload,
            onSuccess,
            onError,
        }
    },
    archiveAsins: (
        payload: { trackpack_id: number; asins: string[] },
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: ProductsAction.ARCHIVE_ASINS,
            payload,
            onSuccess,
            onError,
        }
    },
    restoreAsins: (
        payload: { trackpack_id: number; asins: string[] },
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: ProductsAction.RESTORE_ASINS,
            payload,
            onSuccess,
            onError,
        }
    },
    restoreTrackpack: (
        payload: { trackpack_id: number },
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: ProductsAction.RESTORE_TRACKPACK,
            payload,
            onSuccess,
            onError,
        }
    },
    storeRawData: (payload: { [key: string]: TrackpackProductDaum }): ISagaAction => {
        return {
            type: ProductsAction.STORE_RAW_PRODUCTS,
            payload,
        }
    },
    getCompetitorsList: (
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: any },
        onSuccess?: (data?: any) => void,
        onError?: (data: any) => void
    ) => {
        return {
            type: ProductsAction.GET_COMPETITORS_LIST,
            payload: { pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setCompetitorsDataList: (data: ICompetitorsDataResponse) => ({
        type: ProductsAction.SET_COMPETITORS_LIST,
        payload: data,
    }),

    setCompetitorsDataListChipsSelected: (data: { blueChips: string[]; greenChips: string[] }) => ({
        type: ProductsAction.SET_COMPETITORS_LIST_CHIPS_SELECTED,
        payload: data,
    }),

    // trackpack list for dropdown
    getTrackpackList: (
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: ProductsAction.GET_TRACKPACKS_LIST,
            payload: { pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setTrackpackList: (data: IProductTrackpackList) => ({
        type: ProductsAction.SET_TRACKPACKS_LIST,
        payload: data,
    }),

    getFirstTrackpackData: (
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: ProductsAction.GET_FIRST_TRACKPACK_DATA,
            payload: { pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setTrackpackData: (data: TrackpackDatum) => ({
        type: ProductsAction.SET_FIRST_TRACKPACK_DATA,
        payload: data,
    }),
    ///////////// selected Trackpack ID //////////////////////
    setSelectedTrackPackValues: (payload: any) => ({
        type: ProductsAction.SET_SELECTED_TRACKPACK_VALUES,
        payload,
    }),

    getExtendedTrackingData: (
        trackpackId: string,
        pathParams?: { [key: string]: any },
        queryParams?: { [key: string]: any },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: ProductsAction.GET_EXTENDED_TRACKING_DATA,
            payload: { trackpackId, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setAddAsintoTrackpackData: (payload: {
        asin: string
        region: string
        sp_id: number
        sp_tab?: SearchpackTrackerTabs
    }) => {
        return {
            type: ProductsAction.SET_ADD_ASIN_TRACKPACK_DATA,
            payload,
        }
    },
    removeAddAsintoTrackpackData: () => {
        return {
            type: ProductsAction.SET_ADD_ASIN_TRACKPACK_DATA_EMPTY,
        }
    },
    claimOwnershipOfAsin: (
        payload: { trackpack_id: number; asin?: string; payload: { own: boolean } },
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: ProductsAction.CLAIM_OWNERSHIP_OF_ASIN,
            payload,
            onSuccess,
            onError,
        }
    },
}
