import { useNavigate } from "react-router"
import { ToolsIcon } from "../../../assets/svgs/toolsIcon.svg"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { getTooltipMessage } from "../../../helpers/util.helper"
import { urlUtils } from "../../../utils/url"
import { PrimaryText } from "../../elements/primaryText.element"

const CardCustomTooltipContent = ({ id, data, dataWarnings }: ICardTooltipProps) => {
    debugger
    const navigate = useNavigate()
    const parsedTooltipMessage = JSON.parse(dataWarnings)
    const tooltipMessage = getTooltipMessage(parsedTooltipMessage?.warnings ?? dataWarnings)
    const additionalMessage =
        parsedTooltipMessage?.warnings === "LISTING_CHANGE" ||
        parsedTooltipMessage?.warnings === "DIFFERENT_ASIN_RETURNED" ||
        parsedTooltipMessage?.warnings === "CHILD_ASIN_RETURNED"
            ? " returned"
            : parsedTooltipMessage?.warnings === "DATA_UNAVAILABLE"
            ? " unavailable"
            : parsedTooltipMessage?.warnings === "DIFFERENT_LARGE_CATEGORY"
            ? "Different"
            : ""

    const handleTrackpackRedirect = ({ trackPackId }: { trackPackId: number }) => {
        navigate(
            // FIXME: add filter search param
            urlUtils.formURL(ROUTES_CONFIG.trackpackCentral, { tp_id: `${trackPackId || ""}` }),
            {
                replace: true,
            }
        )
    }

    return (
        <>
            {id === "tracking-success-element" ? (
                <div className=" bg-[#DCFAE6]">
                    <div className="flex items-center gap-[2px]">
                        <PrimaryText
                            weight="medium"
                            size="xs-medium"
                            className=" leading-[14px] text-[#067647] whitespace-nowrap"
                        >
                            ASIN
                        </PrimaryText>
                        <PrimaryText weight="light" size="xs-medium" className="text-[#067647] leading-[14px]">
                            tracking
                        </PrimaryText>
                    </div>
                </div>
            ) : id === "tracking-off-element" ? (
                <div className=" bg-[#FEF0C7]  px-[4px]  flex flex-col gap-[4px]">
                    {data
                        ?.filter(
                            (v) =>
                                v === "bsr_large" ||
                                v === "bsr_small" ||
                                v === "rating" ||
                                v === "ratings_count" ||
                                v === "actual_asin"
                        )
                        .map((val: WarningType, index) => {
                            let warningString = ""
                            switch (val) {
                                case "bsr_large":
                                    warningString = "BSR L Cat."
                                    break
                                case "bsr_small":
                                    warningString = "BSR S Cat."
                                    break
                                case "rating":
                                    warningString = "Review Score"
                                    break
                                case "ratings_count":
                                    warningString = "Ratings Count"
                                    break
                                case "actual_asin":
                                    warningString = "Different ASIN"
                                    break
                            }
                            return (
                                <div key={index} className="flex items-center gap-[2px]">
                                    <PrimaryText
                                        weight="medium"
                                        size="xs-medium"
                                        className="text-[#B54708] leading-[14px] whitespace-nowrap"
                                    >
                                        {warningString}
                                    </PrimaryText>
                                    <PrimaryText
                                        weight="light"
                                        size="xs-medium"
                                        className="text-[#B54708] leading-[14px] whitespace-nowrap"
                                    >
                                        {warningString === "Different ASIN" ? "received" : "unavailable"}
                                    </PrimaryText>
                                </div>
                            )
                        })}
                </div>
            ) : id === "status-warning-tooltip" ? (
                <>
                    {additionalMessage && additionalMessage === "Different" ? (
                        <>
                            <div className="flex items-center gap-x-[12px] mt-[1px]">
                                <div className="flex items-center gap-x-[2px]">
                                    {additionalMessage && (
                                        <PrimaryText
                                            weight="book"
                                            size="xs-medium"
                                            className=" text-[#B54708] leading-[12px]"
                                        >
                                            {additionalMessage}
                                        </PrimaryText>
                                    )}
                                    <PrimaryText
                                        weight="medium"
                                        size="xs-medium"
                                        className=" text-[#B54708] leading-[12px]"
                                    >
                                        {tooltipMessage}
                                    </PrimaryText>
                                </div>
                                <div
                                    className="cursor-pointer"
                                    onClick={() => handleTrackpackRedirect(parsedTooltipMessage)}
                                >
                                    <ToolsIcon />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-center gap-x-[12px] mt-[1px]">
                                <div className="flex items-center gap-x-[2px]">
                                    <PrimaryText
                                        weight="medium"
                                        size="xs-medium"
                                        className=" text-[#B54708] leading-[12px]"
                                    >
                                        {tooltipMessage}
                                    </PrimaryText>
                                    {additionalMessage && (
                                        <PrimaryText
                                            weight="book"
                                            size="xs-medium"
                                            className=" text-[#B54708] leading-[12px]"
                                        >
                                            {additionalMessage}
                                        </PrimaryText>
                                    )}
                                </div>
                                <div
                                    className="cursor-pointer"
                                    onClick={() => handleTrackpackRedirect(parsedTooltipMessage)}
                                >
                                    <ToolsIcon />
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className={`bg-[#FEE4E2]`}>
                    <div className="flex items-center gap-[2px]">
                        <PrimaryText
                            weight="medium"
                            size="xs-medium"
                            className=" text-[#B42318] leading-[14px] whitespace-nowrap"
                        >
                            Data
                        </PrimaryText>
                        <PrimaryText
                            weight="light"
                            size="xs-medium"
                            className="text-[#B42318] leading-[14px] whitespace-nowrap"
                        >
                            unavailable
                        </PrimaryText>
                    </div>
                </div>
            )}
        </>
    )
}

export default CardCustomTooltipContent
