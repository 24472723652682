import { EXTENDED_TRACKPACK_LIST } from "../@store"
import { ITrackpackState } from "../@types/app/trackpack"
import { TrackpackStateClass } from "../classes/trackpackState.class"

export const TrackpackReducer = (
    state: ITrackpackState = new TrackpackStateClass(),
    action: ISagaAction
): Partial<ITrackpackState> => {
    switch (action.type) {
        case EXTENDED_TRACKPACK_LIST:
            return {
                ...state,
                [EXTENDED_TRACKPACK_LIST]: action.payload,
            }

        default:
            return state
    }
}
