import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ListingAnalyzerActionCreator } from "../../../actions/listingAnalyzer.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ReactComponent as Badge } from "../../../assets/svgs/badge.svg"
import { DownloadIcon } from "../../../assets/svgs/downloadIcon.svg"
import { ReactComponent as EditIcons } from "../../../assets/svgs/editIcons.svg"
import MainImageIcon from "../../../assets/svgs/filters/MainImageFilterIcon.svg"
import { ReactComponent as SummaryIcon } from "../../../assets/svgs/summaryIcon.svg"
import { img_url } from "../../../config/dashboard.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useAppSearchParams } from "../../../hooks/app/searchpack/useAppSearchParams"
import { useLoadingStatus } from "../../../hooks/useLoadingStatus.hook"
import { ListingAnalyzerStateSelector } from "../../../selectors/listingAnalyzerState.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import ReportSummarySkeleton from "../../common/skeletonLoader/reportSummarySkeleton.component"
import ReportTableSkeleton from "../../common/skeletonLoader/reportTableSkeleton.component"
import { PrimaryText } from "../../elements/primaryText.element"
import ReportSummary from "./reportSummary.component"

const ReportTable = () => {
    const { getParamValue } = useAppSearchParams()
    const reportId = getParamValue("report_id", "0", Number) as Number as number
    const dispatch = useDispatch()

    const { reportDataByID, reportSummary } = useSelector(ListingAnalyzerStateSelector)
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading = useLoadingStatus(loading, RuntimeHelper.reportDataByIDLoading())
    const isSummaryLoading = useLoadingStatus(loading, RuntimeHelper.reportSummaryLoading())

    const [, setHasScroll] = useState(false)
    const scrollableDivRef = useRef<HTMLDivElement | null>(null)
    const [showTopBorder, setShowTopBorder] = useState(false)

    useEffect(() => {
        if (scrollableDivRef.current) {
            setHasScroll(scrollableDivRef.current.scrollHeight > scrollableDivRef.current.clientHeight)
        }
    }, [reportDataByID, reportSummary])

    useEffect(() => {
        const scrollableDiv = scrollableDivRef.current
        const handleScroll = () => {
            if (scrollableDiv) {
                const scrollTop = scrollableDiv.scrollTop
                const isAtTop = scrollTop === 0

                setShowTopBorder(!isAtTop) // Set border visibility based on scroll position
            }
        }

        handleScroll() // Ensure the initial state is correct
        window.addEventListener("resize", handleScroll)
        scrollableDiv?.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("resize", handleScroll)
            scrollableDiv?.removeEventListener("scroll", handleScroll)
        }
    }, [])

    useEffect(() => {
        dispatch(
            ListingAnalyzerActionCreator.getReportDataByID(reportId, {}, {}, () => {
                dispatch(ListingAnalyzerActionCreator.getReportSummary(reportId))
            })
        )
    }, [reportId, dispatch])

    const sortedProducts = reportDataByID?.products?.sort(
        (a: { order: number }, b: { order: number }) => a.order - b.order
    )

    const questionTypeLabels = {
        CLICKABILITY: "Clickability",
        DIFFERENTIATION: "Differentiation",
        FEATURE_CLARITY: "Clarity of Product Features",
        PRODUCT_QUALITY: "Clarity of Product Quality",
        PRODUCTION_VALUE: "Trustability",
    }

    const products = reportDataByID?.products?.map((product: any) => ({
        report_product_id: product.report_product_id,
        CLICKABILITY: null,
        DIFFERENTIATION: null,
        FEATURE_CLARITY: null,
        PRODUCT_QUALITY: null,
        PRODUCTION_VALUE: null,
    }))

    reportDataByID?.report_questions?.forEach((question: any) => {
        const product = products?.find((p: any) => p?.report_product_id === question?.report_product_id)
        if (product && question?.question.type) {
            product[question.question.type] = question.response?.score || null
        }
    })

    const handleEditReportRedirect = () => {
        dispatch(ListingAnalyzerActionCreator.setReportData({ isEditMode: true }))
        dispatch(
            RuntimeActionCreators.openListingAnalyzerReportModal({
                open: true,
                stepNumber: "four",
            })
        )
    }

    return (
        <>
            <div className="flex items-center justify-between mt-[46px] mb-[12px]">
                <div className="flex items-center gap-[12px] mt-[12px]">
                    <div className="bg-[#67E3F9] p-[6px] inline-flex items-center justify-center rounded-[8px]">
                        <MainImageIcon width={"28"} height={"28"} strokeColor={"#088AB2"} />
                    </div>
                    <PrimaryText weight="medium" size="lg" className="text-[#088AB2]">
                        Main Image Analysis Report
                    </PrimaryText>
                </div>
                <div className="flex items-center gap-[8px] mt-[16px]">
                    <div
                        onClick={handleEditReportRedirect}
                        className="bg-white border cursor-pointer border-gray-200 p-[8px] rounded-[10px] inline-flex items-center justify-center"
                    >
                        <EditIcons />
                    </div>

                    <div className="bg-white hidden border cursor-pointer border-gray-200 p-[8px] rounded-[10px] inline-flex items-center justify-center">
                        <DownloadIcon />
                    </div>
                    <div className="bg-[#ECFDFF] hidden cursor-pointer border border-[#67E3F9] rounded-[10px] inline-flex items-center gap-[4px] justify-center px-[12px] py-[8px]">
                        <SummaryIcon />
                        <PrimaryText weight="medium" size="small" className="text-[#06AED4]">
                            Generate Brief
                        </PrimaryText>
                    </div>
                </div>
            </div>
            <>
                <div className={` ${showTopBorder ? "border-t-[1px] border-t-gray-200 mr-[1px]" : ""}`} />
                <div ref={scrollableDivRef} className="mr-[-26px] scrollable-container relative">
                    <div
                        style={{
                            width: "calc(100% - 22px)",
                        }}
                    >
                        {isLoading ? (
                            <ReportTableSkeleton />
                        ) : (
                            <>
                                <div>
                                    {/* Table Section */}
                                    <div className="border border-gray-200 rounded-[10px]">
                                        <div className="grid grid-cols-4 items-end justify-end bg-gray-100 py-[16px] pl-[16px] pr-[20px] rounded-tl-[11px] rounded-tr-[11px]">
                                            <PrimaryText
                                                size="small"
                                                weight="medium"
                                                className="col-span-1 flex items-end text-gray-600"
                                            >
                                                ASINs
                                            </PrimaryText>
                                            <div className="col-span-3 flex justify-end">
                                                {sortedProducts?.map((product: any) => (
                                                    <div key={product?.report_product_id} className="relative">
                                                        <img
                                                            src={`${img_url}${product?.main_image_filename}`}
                                                            alt={`Product ${product?.report_product_id}`}
                                                            className="w-[144px] h-[144px] mx-[38px] border border-gray-200 mt-[2px] bg-white object-contain rounded-[10px]"
                                                        />
                                                        {product.is_target && (
                                                            <div className="absolute right-[40px] top-0 drop-shadow-custom">
                                                                <Badge />
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="border-t">
                                            {Object.entries(questionTypeLabels)?.map(([key, metricLabel]) => {
                                                const values = products?.map((product: any) => product[key] ?? "-")
                                                return (
                                                    <div
                                                        key={key}
                                                        className={`grid grid-cols-4 gap-4 items-center bg-white py-[12px] pl-[32px] pr-[20px] border-b border-b-[#EEE]`}
                                                    >
                                                        <PrimaryText
                                                            weight="light"
                                                            size="small"
                                                            className="col-span-1 text-gray-600"
                                                        >
                                                            {metricLabel}
                                                        </PrimaryText>
                                                        <div className="col-span-3 flex items-center justify-end">
                                                            {values?.map((value: any, idx: any) => (
                                                                <PrimaryText
                                                                    weight="light"
                                                                    size="xs"
                                                                    key={idx}
                                                                    className="flex items-center justify-center text-gray-600 max-w-[220px] w-full"
                                                                >
                                                                    {value}
                                                                </PrimaryText>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div className="grid grid-cols-4 gap-4 items-center py-[12px] pl-[16px] pr-[20px] bg-gray-100 rounded-bl-[11px] rounded-br-[11px]">
                                            <PrimaryText
                                                weight="medium"
                                                size="small"
                                                className="col-span-1 text-gray-600"
                                            >
                                                Totals
                                            </PrimaryText>
                                            <div className="col-span-3 flex justify-end">
                                                {products
                                                    ?.map((product: any) => {
                                                        const totalScore = Object.keys(questionTypeLabels).reduce(
                                                            (sum, key) => {
                                                                const score = product[key]
                                                                return sum + (score || 0)
                                                            },
                                                            0
                                                        )
                                                        return totalScore
                                                    })
                                                    .map((total: any, idx: any) => (
                                                        <PrimaryText
                                                            key={idx}
                                                            size="xs"
                                                            weight="medium"
                                                            className="flex items-center justify-center max-w-[220px] w-full text-gray-600"
                                                        >
                                                            {total}
                                                        </PrimaryText>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>

                                    {isSummaryLoading ? (
                                        <ReportSummarySkeleton />
                                    ) : (
                                        reportSummary && (
                                            <ReportSummary
                                                reportSummary={reportSummary}
                                                sortedProducts={sortedProducts}
                                                marketPlace={reportDataByID?.trackpack?.amazon_tld}
                                            />
                                        )
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        </>
    )
}

export default ReportTable
