import { useCallback, useMemo, useState } from "react"
import { useSearchParams } from "react-router"

export const useAppSearchParams = (dependenencies: string[] = []) => {
    const [globalDependenencies] = useState(dependenencies)
    const [searchParams, setSearchParams] = useSearchParams()

    const searchParamsObj = useMemo(() => {
        const obj: Record<string, string> = {}

        for (const [key, value] of searchParams.entries()) {
            obj[key] = value
        }

        return obj
    }, [searchParams])

    const updateSearchParams = useCallback(
        (updates: Record<string, string>, dependenencies: string[] = []) => {
            const dependenencyFailed = !!globalDependenencies.concat(dependenencies).find((i) => !searchParamsObj[i])
            if (dependenencyFailed) return

            const params = new URLSearchParams(searchParams)

            Object.keys(updates).forEach((key) => {
                params.set(key, updates[key])
            })

            setSearchParams(params, {
                preventScrollReset: true,
            })
        },
        [globalDependenencies, searchParams, searchParamsObj, setSearchParams]
    )

    const getParamValueAsSet = useCallback(
        (key: string, separtor = ",") => {
            if (!searchParamsObj[key]) return []
            return searchParamsObj[key].split(separtor)
        },
        [searchParamsObj]
    )

    const getParamValue = useCallback(
        (key: string, fallback = "", convert?: NumberConstructor | StringConstructor | ArrayConstructor) => {
            if (!searchParamsObj[key]) {
                return convert ? convert(fallback as any) : fallback
            }

            return convert ? (convert(searchParamsObj[key] as any) as ReturnType<typeof convert>) : searchParamsObj[key]
        },
        [searchParamsObj]
    )

    return {
        searchParams: searchParamsObj,
        setSearchParams,
        updateSearchParams,
        getParamValue,
        getParamValueAsSet,
    }
}
