import { EXTENDED_TRACKPACK_LIST, GET_EXTENDED_TRACKPACK_LIST } from "../@store"

export const getExtendedTrackpackList = (
    pathParams?: Record<string, string | undefined>,
    queryParams?: Record<string, string | undefined>,
    onSuccess?: (data?: any) => void,
    onError?: () => void
) => {
    return {
        type: GET_EXTENDED_TRACKPACK_LIST,
        payload: { pathParams, queryParams },
        onSuccess,
        onError,
    }
}

export const setExtendedTrackpackList = (data: any) => {
    return {
        type: EXTENDED_TRACKPACK_LIST,
        payload: data,
    }
}
