import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"

export const useDismissPopups = () => {
    const dispatch = useDispatch()

    return {
        dismissPopups: useCallback(() => {
            dispatch(RuntimeActionCreators.closeAllPopup())
        }, [dispatch]),
    }
}
