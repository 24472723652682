export const NewAsinIcon = () => {
    return (
        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.44 2.07992L2.62 4.50658C2.25952 4.98723 2.07928 5.22755 2.08346 5.42871C2.0871 5.60376 2.16918 5.76793 2.30704 5.87587C2.46546 5.99992 2.76586 5.99992 3.36667 5.99992H20.6333C21.2341 5.99992 21.5345 5.99992 21.693 5.87587C21.8308 5.76793 21.9129 5.60376 21.9165 5.42871C21.9207 5.22755 21.7405 4.98723 21.38 4.50658L19.56 2.07992M4.44 2.07992C4.64533 1.80614 4.748 1.66925 4.87811 1.57052C4.99335 1.48308 5.12385 1.41783 5.26295 1.37811C5.42 1.33325 5.59111 1.33325 5.93333 1.33325H18.0667C18.4089 1.33325 18.58 1.33325 18.737 1.37811C18.8761 1.41783 19.0066 1.48308 19.1219 1.57052C19.252 1.66925 19.3547 1.80614 19.56 2.07992M4.44 2.07992L2.24667 5.00436C1.96963 5.37374 1.83111 5.55844 1.73275 5.76183C1.64547 5.94231 1.58181 6.13328 1.54335 6.33004C1.5 6.55177 1.5 6.78263 1.5 7.24436L1.5 20.9332C1.5 22.24 1.5 22.8934 1.75432 23.3926C1.97802 23.8316 2.33498 24.1886 2.77402 24.4123C3.27315 24.6666 3.92654 24.6666 5.23333 24.6666L18.7667 24.6666C20.0735 24.6666 20.7268 24.6666 21.226 24.4123C21.665 24.1886 22.022 23.8316 22.2457 23.3926C22.5 22.8934 22.5 22.24 22.5 20.9332V7.24436C22.5 6.78263 22.5 6.55177 22.4567 6.33004C22.4182 6.13328 22.3545 5.94231 22.2673 5.76183C22.1689 5.55844 22.0304 5.37375 21.7533 5.00436L19.56 2.07992M16.6667 10.6666C16.6667 11.9043 16.175 13.0912 15.2998 13.9664C14.4247 14.8416 13.2377 15.3332 12 15.3332C10.7623 15.3332 9.57534 14.8416 8.70017 13.9664C7.825 13.0912 7.33333 11.9043 7.33333 10.6666"
                stroke="#0E7090"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
