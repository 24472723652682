/* eslint-disable no-useless-escape */
export const REGEX_CONFIG = {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    zip: /^\d{5}$|^\d{5}-\d{4}$/,
    code: /^\d{6}$/,
    tel: /^\+[\d]{11,12}$/,
    name: /^[-a-zA-Z0-9_. ]{1,100}$/,
    last_name: /^[-a-zA-Z0-9_. ]{1,100}$/,
    notJustSpaces: /.*[^\s].*/,
    numbersOnly: /^\d+$/,
    numbersWithCommasOnly: /^[\d,]+$/,
    alphanumericUppercaseOnlyTenChars: /^[A-Z0-9]{10}$/,
    upToFiveAlphanumericUppercaseChars: /^(?:\s*)(?:[A-Z0-9]{10}(?:\s*)(?:,\s*(?![\s,])[A-Z0-9]{10}(?:\s*)){0,4})?$/,
    newPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>/?]{8,}$/,
    confirmPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>/?]{8,}$/,
    newAsin: /^[a-zA-Z0-9]{10}$/,
    asinTags: /^[a-zA-Z0-9]{10}$/,
    trackpackName: /^[-a-zA-Z0-9_. !"#$%&'()*+,\-./:;<=>?@[\\\]_^`{|}~"]{1,100}$/,
    optionalAsin: /^[-a-zA-Z0-9_. !@#$%^&*()]{1,100}$/,
    amazonAsinRegex:
        /^(?:https?:\/\/)?(?:www\.)?amazon\.(com|com.au|com.be|com.br|ca|cn|eg|fr|de|in|it|co.jp|com.mx|nl|pl|sa|sg|es|se|com.tr|ae|co.uk)(?:\/(?:[^/]+\/)?dp\/|\/gp\/product\/|\/ASIN\/)([A-Z0-9]{10})(?:[/?]|$)/,
}
