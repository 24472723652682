export const ArchiveIcon = (props: IArrowProps) => {
    return (
        <svg
            width={props.width ?? "28"}
            height={props.height ?? "28"}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.66683 9.32936C4.47549 9.32442 4.33654 9.31335 4.21162 9.2885C3.28601 9.10438 2.56245 8.38082 2.37833 7.45521C2.3335 7.22981 2.3335 6.95876 2.3335 6.41667C2.3335 5.87457 2.3335 5.60352 2.37833 5.37812C2.56245 4.45251 3.28601 3.72895 4.21162 3.54483C4.43702 3.5 4.70806 3.5 5.25016 3.5H22.7502C23.2923 3.5 23.5633 3.5 23.7887 3.54483C24.7143 3.72895 25.4379 4.45251 25.622 5.37812C25.6668 5.60352 25.6668 5.87457 25.6668 6.41667C25.6668 6.95876 25.6668 7.22981 25.622 7.45521C25.4379 8.38082 24.7143 9.10438 23.7887 9.2885C23.6638 9.31335 23.5248 9.32442 23.3335 9.32936M11.6668 15.1667H16.3335M4.66683 9.33333H23.3335V18.9C23.3335 20.8602 23.3335 21.8403 22.952 22.589C22.6165 23.2475 22.081 23.783 21.4225 24.1185C20.6738 24.5 19.6937 24.5 17.7335 24.5H10.2668C8.30665 24.5 7.32655 24.5 6.57786 24.1185C5.9193 23.783 5.38386 23.2475 5.04831 22.589C4.66683 21.8403 4.66683 20.8602 4.66683 18.9V9.33333Z"
                stroke={props.color ?? "#B42318"}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
